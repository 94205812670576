/**
 * Created by dpopov on 12.10.2017.
 */
import React from 'react';
import FileUploadProgress  from 'react-fileupload-progress';

import * as CONST from '../../const';

import myStyle from './style.module.css';

class FileUpload extends React.Component {
    constructor(params) {
        super(params);

        this.customFormRenderer = this.customFormRenderer.bind(this);
        this.customFormGetter = this.customFormGetter.bind(this);
        this.buttonUploadClick = this.buttonUploadClick.bind(this);
        this.customProcessUpload = this.customProcessUpload.bind(this);
        this.onFileUploaded = this.onFileUploaded.bind(this);
        this.onUploadError = this.onUploadError.bind(this);
        this.onUploadAbort = this.onUploadAbort.bind(this);

        this.uploadedFiles = {};

        this.state = {
            uploadButtonState: true
        }
    }

    hookBeforeSend(request) {
        request.withCredentials = true;
        return request;
    }

    onFileChanged(onSubmit) {
        const self = this;
        return (event) => {
            const choosedFile = event.target.value;
            if(choosedFile) {
                if(!self.uploadedFiles[choosedFile]) {
                    self.setState({ uploadButtonState: false });
                    this.btnSubmitUploadForm.click();
                }
                else self.setState({ uploadButtonState: true });
            } else self.setState({ uploadButtonState: true });
        }
    }

    customFormGetter() {
        return new FormData(this.fileUploadForm);
    }

    buttonUploadClick(event) {
        event.preventDefault();
        this.fileUploadChooser.click();
    }

    customFormRenderer(onSubmit) {
        const params = this.props.params || {};
        return (
            <div>
                <form id="FileUploadForm" ref={(input) => { this.fileUploadForm = input; }} className={myStyle['FileUpload_Hidden']}>
                    {Object.keys(params).map((paramName, _idx) => {
                        return <input key={_idx} type="hidden" name={paramName} value={params[paramName]}/>
                    })}
                    <input type="file" ref={(input) => { this.fileUploadChooser = input; }} name="file" onChange={this.onFileChanged(onSubmit)} />
                    <input type="submit" ref={(input) => { this.btnSubmitUploadForm = input; }} onClick={onSubmit} />
                </form>
            </div>
        );
    }

    customProcessUpload(progress, hasError, cancelHandler) {
        if (hasError || (progress > -1 && progress < 100)) {
            let barStyle = { width: progress + '%' };

            if (hasError) {
                if(typeof this.props.onError == 'function') this.props.onError();
                return;
            }

            return (
                <div>
                    <div className={myStyle['FileUpload_Process_Conatiner']}>
                        <div className={myStyle['FileUpload_Process_Bar']} style={barStyle} />
                    </div>
                    <button className={myStyle['FileUpload_CancelButton']} onClick={cancelHandler}><span>&times;</span></button>
                    <div style={{'clear':'left'}} />
                </div>
            );
        }
    }

    onFileUploaded(e, request) {
        const response = JSON.parse(request.response);

        if(typeof this.props.onUpload == 'function') this.props.onUpload(response);

        this.setState({ uploadButtonState: true });
    }

    onUploadError(e, request) {
        if(typeof this.props.onError == 'function') this.props.onError(e, request);
        this.setState({ uploadButtonState: true });
    }

    onUploadAbort(e, request) {
        if(typeof this.props.onUploadAbort) this.props.onUploadAbort(e, request);
        this.setState({ uploadButtonState: true });
    }

    render() {
        const api = this.props.api || '/support/upload';
        const buttonTitle = this.props.buttonTitle || 'Приложить файл';

        return (
            <div className={this.props.className}>
                <FileUploadProgress key='ex1' url={CONST.API + api}
                                    beforeSend={this.hookBeforeSend}
                                    formRenderer={this.customFormRenderer}
                                    formGetter={this.customFormGetter}
                                    progressRenderer={this.customProcessUpload}
                                    onProgress={(e, request, progress) => {}}
                                    onLoad={this.onFileUploaded}
                                    onError={this.onUploadError}
                                    method='post'
                                    onAbort={this.onUploadAbort}
                />

                <button type="button" onClick={this.buttonUploadClick} disabled={!this.state.uploadButtonState}>{buttonTitle}</button>

            </div>
        );
    }
}

export default FileUpload;