/**
 * Created by dpopov on 14.09.2017.
 */
import React from 'react';

import ChapterUser from './index';
import PanelPersonalSettings from '../../Panels/PanelPersonalSettings';

export default (props) => {
    return (
        <ChapterUser>
            <PanelPersonalSettings />
        </ChapterUser>
    );
}