import React from 'react';
import Panel from "../../Components/Panel";
import {getStyle} from "../../tools";

export default (props) => {

    const resultPay = props.match ? props.match.params.resultPay : props.resultPay;

    let panelTitleIcon = getStyle('icon-rub');
    let content = resultPay === 'success' ? 'Платеж успешно прошел' : 'Произошла ошибка пополнения';

    return (
        <Panel title='Пополнение счета' icon={panelTitleIcon}>
            <div className="b-row gray b-border">
                <h4>{content}</h4>
            </div>
        </Panel>
    )

}
