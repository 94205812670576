/**
 * Created by dpopov on 16.08.2017.
 */
import * as CONST from '../../const';

import 'moment/locale/ru';
import moment from 'moment';
moment.locale('ru');

const initialState = {
    panelLoadingState: false,
    error: false,
    errorText: "",
    financeDataLoaded: false,
    dataContract: "",
    disableForecast: null,
    payPerMonth: null,
    contacts: null,
    recommendMoney: 0,
    balance: 0,
    cards: [],
    recurrentCards: [],
    autoPays: [],
    status: {},
    forceCardSystem: null,
    BM: []
};

export default function PanelPayReducer(state = initialState, action) {
    switch(action.type) {
        case CONST.EMPTY_REDUCER: return {...initialState};
        case CONST.PAY.FINANCE_DETAIL_REQUEST_IN_PROCESS: {
            return {...state,
                panelLoadingState: true,
                financeDataLoaded: false,
                error: false,
                errorText: ""
            };
        }
        case CONST.PAY.FINANCE_DETAIL_SET_DETAIL: {
            return {...state,
                panelLoadingState: false,
                financeDataLoaded: true,
                error: false,
                errorText: "",
                disableForecast: action.payload.forecast ? moment.unix(action.payload.forecast) : null,
                payPerMonth: action.payload.payPerMonth,
                cards: action.payload.card,
                recurrentCards: action.payload.recurrentCards,
                autoPays: action.payload.autoPays,
                recommendMoney: action.payload.recommendMoney,
                forceContacts: action.payload.contacts,
                balance: action.payload.balance,
                dataContract: action.payload.contract,
                forceCardSystem: action.payload.forceCardSystem,
                status: action.payload.status
            }
        }
        case CONST.PAY.FINANCE_DETAIL_SET_ERROR: {
            return {...state,
                panelLoadingState: false,
                financeDataLoaded: false,
                error: true,
                errorText: action.payload
            }
        }
        case CONST.PAY.FINANCE_DETAIL_SET_BM: {
            if(action.payload) return {...state, BM: action.payload, panelLoadingState: false};
            else return state;
        }
        default: return state;
    }
}