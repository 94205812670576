/*eslint eqeqeq: ["off", "smart"]*/
import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import axios from 'axios';

import Panel from '../../Components/Panel';

import * as style from './style.module.css';
import * as CONST from '../../const';
import { getStyle, phoneFormat, getStdNotifyStruct } from '../../tools';
import * as PanelActions from "../PanelNotificationsSettings/actions";
import * as AuthActions from "../../Authenticate/actions";

class PanelChoosePrimaryPhone extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            loadingState: false,
            contract: props.authData.activeContract,
            mainNumber: null,
            deleteNumbers: {},
            appendNumbers: [],
            appendErrText: null,
            saveErrorText: null,
            myNumbers: props.authData.ai_phones ? props.authData.ai_phones.slice() : []
        };

        this.chooseMainNumber = this.chooseMainNumber.bind(this);
        this.markNumberForDeletion = this.markNumberForDeletion.bind(this);
        this.onAppendNumberClick = this.onAppendNumberClick.bind(this);
        this.onSaveClick = this.onSaveClick.bind(this);
        this.onClearErrorClick = this.onClearErrorClick.bind(this);
    }

    componentWillMount() {
//        this.props.elementActions.requestNotifySettings(this.props.authData.activeContract);
        this.requestCustomerPhoneNumbers(this.props.authData.activeContract);
    }

    componentWillReceiveProps(nextProps) {
        if(nextProps.authData.activeContract !== this.state.contract) {
            this.requestCustomerPhoneNumbers(nextProps.authData.activeContract);
        }
    }

    requestCustomerPhoneNumbers(contract) {
        this.setState({loadingState: true, saveErrorText: null});

        axios.get(CONST.API + '/notify/getPhonesNumbers', {
            withCredentials: true,
            params: {
                contract: contract,
                '.rnd': Math.ceil(Math.random()*1000000)
            }
        }).then((answer) => {
            const data = answer.data;

            if(data.result === 'success') {
                let Numbers = data.data.ai || [];
                if(data.data.ph) {
                    for(let i=0;i<data.data.ph.length; i++) {
                        if(Numbers.indexOf(data.data.ph[i]) === -1) Numbers.push(data.data.ph[i]);
                    }
                }
                this.setState({loadingState: false, myNumbers: Numbers, contract: contract});
            } else {
                this.setState({loadingState: false, saveErrorText: data.text});
            }
        })
    }

    chooseMainNumber(number) {
        let deletionNumbers = {...this.state.deleteNumbers};
        delete deletionNumbers[number];
        this.setState({mainNumber: number, deleteNumbers: deletionNumbers});
    }

    markNumberForDeletion(number) {
        const deletionNumbers = {...this.state.deleteNumbers};
        deletionNumbers[number] = true;
        this.setState({deleteNumbers: deletionNumbers});
    }

    onAppendNumberClick(event) {
        event.preventDefault();

        let Number = this.refs.inputAppendNumber.value;
        if(Number != phoneFormat(Number)) {
            Number = phoneFormat(Number);

            for(let i=0;i<this.state.myNumbers.length;i++) {
                if(Number === phoneFormat(this.state.myNumbers[i])) {
                    this.setState({appendErrText: 'Введённый номер существует в списке'});
                    return false;
                }
            }

            this.refs.inputAppendNumber.value = '';
            let appendNumbers = this.state.appendNumbers.slice();
            appendNumbers.push(Number);
            this.setState({mainNumber: Number, appendNumbers: appendNumbers, appendErrText: null});
        } else {
            this.setState({appendErrText: 'Номер должен состоять минимум из 10 цифр'});
        }
    }

    onSaveClick(event) {
        event.preventDefault();

        if(!this.state.mainNumber) {
            this.setState({saveErrorText: 'Необходимо выбрать основной номер', loadingState: false});
            return;
        }

        this.setState({loadingState: true, saveErrorText: null});

        let sendData = new FormData();
        sendData.append('contract', this.props.authData.activeContract);
        sendData.append('mainPhone', this.state.mainNumber);

        for(let i=0;i<Object.keys(this.state.deleteNumbers).length; i++) {
            let phone = Object.keys(this.state.deleteNumbers)[i];
            if(this.state.deleteNumbers[phone]) sendData.append('deletePhone[]', phone);
        }

        let phoneList = this.state.myNumbers.slice();
        phoneList.push(...this.state.appendNumbers);

        for(let i=0;i<phoneList.length;i++) {
            let phone = phoneList[i];
            if(!this.state.deleteNumbers[phone]) sendData.append('phoneList[]', phone);
        }

        axios.post(CONST.API + '/notify/updateMainNumber', sendData, {
            withCredentials: true
        }).then((answer) => {
            const data = answer.data;

            if(data.result === 'success') {
                this.props.AuthActions.requestCurrentUser(true, { force_ai: 1});
            } else {
                this.setState({loadingState: false, saveErrorText: data.text});
            }
        })
    }

    onClearErrorClick(e) {
        if(e) e.preventDefault();
        this.setState({saveErrorText: null});
    }

    render() {
        const title = this.props.title || 'Уточнение контактной информации';
        const loadingState = this.state.loadingState;
        let phoneList = this.state.myNumbers.slice();
        phoneList.push(...this.state.appendNumbers);

        return (
            <Panel title={title} isLoading={loadingState} icon={getStyle('icon-sett')} notify={getStdNotifyStruct(this.state.saveErrorText, this.onClearErrorClick)}>
                <div className={getStyle('b-row b-border')}>
                    <h4>Пожалуйста, укажите основной номер для уведомлений</h4>

                    <div className={style['phoneChooser-conatiner']}>
                        <table>
                            <thead>
                            <tr>
                                <th>Телефонный номер</th>
                                <th>Основной</th>
                                <th>Удалить</th>
                            </tr>
                            </thead>
                            <tbody>
                            {phoneList.map((phone, _phoneIndex) =>
                                <tr key={_phoneIndex}>
                                    <td>{phoneFormat(phone)}</td>
                                    <td><label className={getStyle('input-checkbox')}><input type="checkbox" name="main" checked={phone === this.state.mainNumber} onChange={() => this.chooseMainNumber(phone)} /><div className={getStyle('input')} /></label></td>
                                    <td>
                                        {this.state.mainNumber !== phone ?
                                            <label className={getStyle('input-checkbox')}><input type="checkbox" name="delete" checked={this.state.deleteNumbers[phone] || false} onChange={() => this.markNumberForDeletion(phone)} /><div className={getStyle('input')} /></label>
                                        : null}
                                    </td>
                                </tr>
                            )}
                            <tr>
                                <td>
                                    <input type="text" ref="inputAppendNumber" />
                                    {this.state.appendErrText ?
                                        <div className={style['phoneChooser-inputError']}>{this.state.appendErrText}</div>
                                    : null }
                                </td>
                                <td colSpan={2}><button onClick={this.onAppendNumberClick}>Добавить новый номер</button></td>
                            </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
                <div className={getStyle('b-row')}>
                    <div className={getStyle('f-left')}>
                        <button onClick={this.onSaveClick}>Сохранить</button>
                    </div>
                </div>
            </Panel>
        );
    }
}

function mapStateToProps(state) {
    return {
        authData: state.authenticate,
        notify: state.NotifySettings
    }
}

function mapDispatchToProps(dispatch) {
    return {
        elementActions: bindActionCreators(PanelActions, dispatch),
        AuthActions: bindActionCreators(AuthActions, dispatch)
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(PanelChoosePrimaryPhone);