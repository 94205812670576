/**
 * Created by dpopov on 07.11.2017.
 */
import React from 'react';
import { Link } from 'react-router-dom';

import { getStyle } from '../../tools';
import * as CONST from '../../const';

let internal = (promoName, title, uri, titleStyle) => {
    return (
        <Link to={CONST.ROOT_PATH + uri} className={getStyle('card-item-link')}>
            <div className={getStyle('card-img')}><img src={CONST.API + '/adv/getPromoImage?promo='+promoName} alt={""} /></div>
            <div className={getStyle('card-title')} dangerouslySetInnerHTML={{__html:title}} style={titleStyle} />
        </Link>
    );
};

let external = (promoName, title, uri, titleStyle) => {
    return (
        <a href={uri} target="_blank" rel="noopener noreferrer" className={getStyle('card-item-link')}>
            <div className={getStyle('card-img')}><img src={CONST.API + '/adv/getPromoImage?promo='+promoName} alt={""} /></div>
            <div className={getStyle('card-title')} dangerouslySetInnerHTML={{__html:title}} style={titleStyle} />
        </a>
    );
};

export default (props) => {
    const promoName = props.name;
    const title = props.title;
    const redirect_int = props.redirect_int;
    const redirect_ext = props.redirect_ext;
    const titleStyle = props.titleStyle;

    return (
        <div className={getStyle('card-item')}>
            {
                redirect_int ? internal(promoName, title, redirect_int, titleStyle) :
                    redirect_ext ? external(promoName, title, redirect_ext, titleStyle) :
                        internal(promoName, title, '/promo.' + promoName + '.html', titleStyle)
            }
        </div>
    );
}