/**
 * Created by dpopov on 16.10.2017.
 */
import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Link } from 'react-router-dom';

import Paginator from '../../Components/Panel/paginator';

import * as CONST from '../../const';
import * as PanelActions from './actions';

import { getStyle } from '../../tools';

import myStyle from './style.module.css';

class PanelTickets extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            ticketFrom: 0
        };

        this.changeDisplayPage = this.changeDisplayPage.bind(this);
    }

    componentWillMount() {
        const contract = this.props.authData.activeContract;
        const tickets = this.props.state.tickets[contract];

        if(typeof tickets == 'undefined') {
            this.props.elementActions.getTicketsList(contract, this.state.ticketFrom);
        }
    }

    changeDisplayPage(event) {
        event.preventDefault();

        const newFrom = event.currentTarget.getAttribute('data-from');
        const contract = this.props.authData.activeContract;

        this.setState({ticketFrom: newFrom});
        this.props.elementActions.getTicketsList(contract, newFrom);
    }

    componentWillReceiveProps(nextProps) {
        //const contract = nextProps.authData.activeContract;
        //this.setState({ ticketFrom: nextProps.state.tickets[contract].skip });
        //console.log(nextProps);
    }

    render() {
        const contract = this.props.authData.activeContract;
        const tickets = this.props.state.tickets[contract];


        return (
            <div className={getStyle('block side-block')}>
                <div className={getStyle("b-header")}>
                    <h3>Мои вопросы:</h3>
                </div>

                <div className={getStyle('side-nav')}>
                    {tickets ?
                        tickets.items.map((ticket, _idx) => {
                            //console.log(ticket);

                            const status = ticket.Status.match(/(new|open|stalled)/i) ? 'Активна' : 'Закрыта';

                            return <Link key={_idx} to={CONST.ROOT_PATH + '/support-' + ticket.id + ((ticket.crm)?'-c':'') + '.html'}>
                                {ticket.Content ?
                                    <div>{ticket.Content}</div>
                                    :
                                    <div>Заявка #{ticket.id}</div>
                                }
                                {ticket.Comment ?
                                    <div className={getStyle("t-small")}>{ticket.Comment}</div>
                                : <div className={getStyle("t-small")}>{status}</div>}
                            </Link>
                        })
                    : null }
                </div>

                {tickets ?
                    <div>
                        <Paginator currentLimit={tickets.count} displayFrom={tickets.skip} totalCount={tickets.total} hideShowCountSelector={true}
                                   toFirstCaption="<<" toEndCaption=">>" changeDisplayPage={this.changeDisplayPage}
                                   buttonStyle={myStyle['rt-paginator-button']}
                        />
                    </div>
                : null}
            </div>
        );
    }
}

function mapStateToProps(state) {
    return {
        authData: state.authenticate,
        state: state.support
    }
}

function mapDispatchToProps(dispatch) {
    return {
        elementActions: bindActionCreators(PanelActions, dispatch)
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(PanelTickets);