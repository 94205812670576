/**
 * Created by dpopov on 09.10.2017.
 */
/* eslint eqeqeq: ["off", "smart"] */
import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import axios from 'axios';

import Panel from '../../Components/Panel';
import { getStyle } from  '../../tools';

import * as CONST from '../../const';
import * as PanelActions from './actions';
import * as AuthActions from '../../Authenticate/actions';

import ConfirmPhoneInterface from '../../Components/Confirmation/Phone';

class PanelPersonalSettings extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            myPhone: [],
            myEmail: [],
            srcEmail: [],
            srcPhone: [],
            contract: props.authData.activeContract,
            confirmPhones: {},
            confirmEmails: {},
            changed: {}
        };

        this.onInputValueChange = this.onInputValueChange.bind(this);
        this.onSaveClick = this.onSaveClick.bind(this);

        this.onPhoneConfirmCancel = this.onPhoneConfirmCancel.bind(this);
        this.onEmailConfirmCancel = this.onEmailConfirmCancel.bind(this);
        this.handlerConfirmPhone = this.handlerConfirmPhone.bind(this);
        this.handlerConfirmEmail = this.handlerConfirmEmail.bind(this);
        this.diff = this.diff.bind(this);
    }

    componentWillReceiveProps(nextProps) {
        if(nextProps.authData.activeContract != this.state.contract) {
            this.setState({contract: nextProps.authData.activeContract });
            this.refreshPersonalSettings();
        }

        let updateState = {
            srcPhone: nextProps.personalSettings.phone.slice(0),
            srcEmail: nextProps.personalSettings.email.slice(0)
        };

        if(!this.state.changed.myPhone) updateState.myPhone = nextProps.personalSettings.phone.slice(0);
        if(!this.state.changed.myEmail) updateState.myEmail = nextProps.personalSettings.email.slice(0);

        this.setState(updateState);
    }

    componentWillMount() {
        this.refreshPersonalSettings();
    }

    refreshPersonalSettings() {
        const contract = this.props.authData.activeContract;
        this.props.panelActions.requestSettings(contract);
    }

    onInputValueChange(event) {
        const field = event.target.getAttribute('name');
        const index = parseInt(event.target.getAttribute('data-index')) || 0;
        let value = event.target.value;

        const stateUpdate = this.state;
        stateUpdate[field][index] = value;
        stateUpdate.changed[field] = true;

        this.setState(stateUpdate);
    }

    diff(src, dst, fn) {
        let answer = {};

        for(let i=0;i<src.length;i++) {
            let s = src[i];
            let d = dst[i];

            if(typeof fn === 'function') {
                s = fn(s?s:'');
                d = fn(d?d:'');
            }

            if(s!=d || this.props.forceCheck) answer[s] = d;
        }

        return answer;
    }

    onSaveClick(event) {
        event.preventDefault();

        let changedMails = this.diff(this.state.srcEmail, this.state.myEmail);
        let changedPhones = this.diff(this.state.srcPhone, this.state.myPhone,
            function(str) {
                return str.replace(/[^\d]/g, '');
            }
        );

      //  console.log('---', changedMails, changedPhones);
        this.setState({confirmPhones: changedPhones, confirmEmails: changedMails});
        //if(Object.keys(changedPhones).length) this.setState.
    }

    onPhoneConfirmCancel(newPhoneNum, oldPhoneNum) {
        let confirm = this.state.confirmPhones;
        let myPhones = this.state.myPhone;

        for(let i=0;i<myPhones.length;i++) {
            if(myPhones[i].replace(/[^\d]/g, '') == newPhoneNum) myPhones[i] = oldPhoneNum;
        }

        delete confirm[oldPhoneNum];

        this.setState({myPhone: myPhones, confirmPhones: confirm});
    }

    onEmailConfirmCancel(newEmail, oldEmail) {
     //   console.log('---', this.state);
        let confirm = this.state.confirmEmails;
        let myEmail = this.state.myEmail;

        for(let i=0;i<myEmail.length;i++) {
            if(myEmail[i] == newEmail) myEmail[i] = oldEmail;
        }

        delete confirm[oldEmail];

        this.setState({myEmail: myEmail, confirmEmails: confirm});
    }

    handlerConfirmPhone(contract, oldPhone, newPhone, code, onError, onSuccess = false) {
        const self = this;

        axios.get(CONST.API + '/contacts/confirmPhone', {
            withCredentials: true,
            params: {
                contract: contract,
                phone: newPhone,
                oldphone: oldPhone,
                code: code,
                '.rnd': Math.ceil(Math.random()*1000000)
            }
        }).then(function(answer) {
            const data = answer.data;
            if(data.result == 'success') {
                let confirm = self.state.confirmPhones;
                delete confirm[oldPhone];
                self.setState({ confirmPhones: confirm });

                self.props.authActions.requestCurrentUser(true, {
                    activeContract: contract,
                    force_ai: 1
                });

                if(typeof self.props.onConfirmPhone === 'function') self.props.onConfirmPhone();
                if(typeof onSuccess === 'function') onSuccess();
            } else onError(data.text);
        });

    }

    handlerConfirmEmail(contract, oldEmail, newEmail, code, onError, onSuccess = false) {
        const self = this;

        axios.get(CONST.API + '/contacts/confirmEmail', {
            withCredentials: true,
            params: {
                contract: contract,
                email: newEmail,
                oldEmail: oldEmail,
                code: code,
                '.rnd': Math.ceil(Math.random()*1000000)
            }
        }).then(function(answer) {
            const data = answer.data;
            if(data.result == 'success') {
                let confirm = self.state.confirmEmails;

                delete confirm[oldEmail];

                self.setState({ confirmEmails: confirm });
                self.props.panelActions.requestSettings(contract, 1);
                if(typeof onSuccess === 'function') onSuccess();
            } else onError(data.text);
        });

    }

    render() {
        const title = this.props.title || 'Личные данные';
        const icon = this.props.icon || getStyle('icon-user');
        const loadingState = this.props.personalSettings.loadingState || this.props.loadingState;

        const phone = this.state.myPhone;
        const email = this.state.myEmail;

        let confirm = null;
        let oldPhoneNum = null;
        let newPhoneNum = null;
        let oldEmail = null;
        let newEmail = null;

        if(Object.keys(this.state.confirmPhones).length) {
            oldPhoneNum = Object.keys(this.state.confirmPhones)[0];
            newPhoneNum = this.state.confirmPhones[oldPhoneNum];
        }

        if(Object.keys(this.state.confirmEmails).length) {
            oldEmail = Object.keys(this.state.confirmEmails)[0];
            newEmail = this.state.confirmEmails[oldEmail];
        }

        if ((oldPhoneNum && newPhoneNum) || (oldEmail && newEmail)) {
            confirm = <ConfirmPhoneInterface
                title={'Подтверждение изменения данных'}
                onCancel={this.onPhoneConfirmCancel}
                onCancelEmail={this.onEmailConfirmCancel}
                confirmHandler={this.handlerConfirmPhone}
                confirmHandlerEmail={this.handlerConfirmEmail}
                contract={this.state.contract}
                phoneNum={newPhoneNum}
                oldPhoneNum={oldPhoneNum}
                newEmail={newEmail}
                oldEmail={oldEmail} />
        }

        return (
            <Panel title={title} icon={icon} isLoading={loadingState} asFrame={this.props.asFrame}>
                {confirm}
                <table className={getStyle('left-pull form-table')}>
                    <tbody>
                    <tr>
                        <td className={getStyle('t-nowrap td-null')}>Мобильный телефон</td>
                        <td><input type="tel" name="myPhone" pattern="^[\s\-\+0-9\(\)]+$" value={phone[0] || ''} data-index="0" onChange={this.onInputValueChange} /></td>
                    </tr>
                    <tr>
                        <td className={getStyle('t-nowrap td-null')}>Электронная почта</td>
                        <td><input type="text" name="myEmail" value={email[0] || ''} data-index="0" onChange={this.onInputValueChange} /></td>
                    </tr>
                    <tr>
                        <td className={getStyle('t-nowrap td-null')} />
                        <td>
                            <button className={getStyle('btn-major')} onClick={this.onSaveClick}>Сохранить</button>
                        </td>
                    </tr>
                    </tbody>
                </table>
            </Panel>
        );
    }
}

function mapStateToProps(state) {
    return {
        authData: state.authenticate,
        personalSettings: state.PersonalSettings
    }
}

function mapDispatchToProps(dispatch) {
    return {
        //authActions: bindActionCreators(authenticateActions, dispatch),
        panelActions: bindActionCreators(PanelActions, dispatch),
        authActions: bindActionCreators(AuthActions, dispatch)
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(PanelPersonalSettings);