/**
 * Created by dpopov on 11.07.2017.
 */
import * as CONST from './../const';

import 'moment/locale/ru';
import moment from 'moment';
moment.locale('ru');

const initialState = {
    auth_type: 'lk2',
    activeContract: null,
    process: null,
    allowed: 0,
    clientFirstName: '',
    clientMiddleName: '',
    clientLastName: '',
    sex: null,
    region: null,
    ai_phones: [],
    ai_mails: [],
    other_phones: [],
    contracts: {},
    helpAsks: 0,
    formInProcess: false,
    contractsDetails: {},
    promoBackground: {},
    smallLinkActiveContract: null,
    statuses: {},
    firstLogin: false,
    firstVKLogin: false,
    changePassword: false,
    redirect: null,
    rosterLastChange: null,
    rosterLastRequest: null,
    loginScreenImage: null,
    authVK: null,
    vkUserPhoto: null,
    forgotPassword: false,
    error: null,
    error_text: null,
};

export default function AuthenticateReducer(state = initialState, action) {
    switch (action.type) {
        case CONST.EMPTY_REDUCER: return {...initialState};
        case CONST.AUTHENTICATE.IN_PROCESS: {
            return {...state,  process: action.payload }
        }
        case CONST.AUTHENTICATE.FORM_IN_PROCESS: {
            return {...state, formInProcess: action.payload }
        }
        case CONST.AUTHENTICATE.SWITCH_ACTIVE_CONTRACT: {
            return {...state, activeContract: action.payload }
        }
        case CONST.AUTHENTICATE.ERROR: {
            return {...state, error: action.payload.title, error_text: action.payload.text }
        }
        case CONST.AUTHENTICATE.SET_USER_INFO: {
            if(action.payload.auth_type !== 'lk2') return {...initialState};
            return {...state,
                auth_type: action.payload.auth_type,
                authVK: action.payload.authVK,
                vkUserPhoto: action.payload.vkUserPhoto,
                process: null,
                allowed: action.payload.access,
                clientFirstName: action.payload.clientFirstName || '',
                clientMiddleName: action.payload.clientMiddleName || '',
                clientLastName: action.payload.clientLastName || '',
                sex: action.payload.sex,
                ai_phones: action.payload.ai_phones,
                ai_mails: action.payload.ai_mails,
                other_phones: action.payload.other_phones || [],
                contracts: action.payload.contracts || {},
                helpAsks: action.payload.helpAsks,
                activeContract: state.activeContract ? state.activeContract : action.payload.defaultContract || Object.keys(action.payload.contracts)[0],
                promoBackground: action.payload.promoBackground,
                formInProcess: false,
                region: action.payload.region,
                statuses: action.payload.statuses,
                firstLogin: action.payload.firstLogin,
                firstVKLogin: action.payload.firstVKLogin,
                changePassword: action.payload.changePassword,
                redirect: action.payload.redirect,
                rosterLastChange: moment(action.payload.rosterLastChange),
                loginScreenImage: action.payload.loginScreenImage
            };
        }
        case CONST.AUTHENTICATE.SET_BILLING_INFO: {
            return {...state,
                allowed: state.allowed | CONST.ACCESS.PAY_SMALL_LINK,
//                activeContract: action.payload.contract,
                smallLinkActiveContract: action.payload.contract,
                ai_phones: action.payload.ai_phones,
                ai_mails: action.payload.ai_mails,
            }
        }
        case CONST.AUTHENTICATE.UPDATE_BALANCE: {
            let contracts = state.contracts;
            contracts[action.payload.contract] = action.payload.balance;
            return {...state, contracts: contracts};
        }
        case CONST.AUTHENTICATE.EMPTY_ACTIVE_CONTRACT: {
            return {...state, activeContract: null }
        }
        case CONST.NOTIFY.NOTIFY_SUCCESS_FORGOT_PASSWORD: {
            return {...state, forgotPassword: action.payload }
        }
        default:
            return state;
    }
}