/**
 * Created by dpopov on 29.08.2017.
 */
/*eslint default-case: ["off"]*/
import React from 'react';
import axios from 'axios';

import * as CONST from '../../const';

export default class payHiddenForm extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            extraField: null,
            extraFieldValue: null,
            displayForm: false
        };
    }

    componentDidMount() {
        const registerData = this.props.registerData;

        if(registerData) {
            axios.get(CONST.API + '/payments/register', {
                withCredentials: true,
                params: {...registerData, '.rnd': Math.ceil(Math.random()*1000000) }
            }).then((answer) => {
                if(answer.data.data) {
                    switch(registerData.sys) {
                        case 'gpb': this.setState({ extraField: 'o.notifyid', extraFieldValue: answer.data.data, displayForm: true }); return;
                        case 'sbercard': this.setState({ extraField: 'notifyid', extraFieldValue: answer.data.data, displayForm: true }); return;
                        case 'sbergoogle': this.setState({ extraField: 'notifyid', extraFieldValue: answer.data.data, displayForm: true }); return;
                        case 'rfibank': this.setState({ extraField: 'order_id', extraFieldValue: this.props.contract + '_' + answer.data.data, displayForm: true }); return;
                    }
                }
                this.setState({ displayForm: true });
            })
        } else this.setState({ displayForm: true });
    }

    render() {
        const url = this.props.url;
        let data = this.props.data || [];
        const method = this.props.method || 'get';

        if(this.state.displayForm && this.state.extraField) {
//            console.log(1, data);
            for(let i=0;i<data.length;i++) {
                if(data[i].name === this.state.extraField) {
                    data.splice(i, 1);
                    break;
                }
            }
            data.push({ name: this.state.extraField, value: this.state.extraFieldValue });
        }

        return (
            <div>
                {this.state.displayForm ?
                    <PActionForm url={url} data={data} method={method} />
                : null}
            </div>
        )
    }
}

class PActionForm extends React.Component {
    componentDidMount() {
        this.refs.form.submit();
    }

    render() {
        const url = this.props.url;
        const data = this.props.data || [];
        const method = this.props.method || 'get';

//        console.log(data);

        return (
            <form method={method} action={url} ref="form" target={'_parent'}>
                {data.map(item => (
                    <input key={item.name} type="hidden" name={item.name} value={item.value} />
                ))}
            </form>
        )
    }
}