/**
 * Created by dpopov on 06.09.2017.
 */
import React from 'react';
import { Link, withRouter } from 'react-router-dom';
import { getStyle } from "../../../tools";

import elementStyle from '../style.module.css';

import DatePicker from 'react-datepicker';

import 'moment/locale/ru';
import moment from 'moment';
moment.locale('ru');

export default (props) => {

    const btnThisMonth = props.btnThisMonth || {};
    const btnPrevMonth = props.btnPrevMonth || {};
    const btnThisYear  = props.btnThisYear || {};
    const period = props.period || { from: moment().add(-1, 'month'), to: moment()};
    // const serviceId = props.serviceId;
    // const chooserOnly = props.chooserOnly;

 //   console.log('##', period);

    const className = props.className || getStyle('b-row');

    const ButtonDisplay = withRouter(( {history} ) => (
        <Link
            to="#"
            className={getStyle('button btn-major hover')}
            onClick={(event) => {
                if(typeof period.onClick == 'function') period.onClick(event, history);
            }}
        >
            Показать
        </Link>
    ));

    const ButtonDisplayThisYear = withRouter(( {history} ) => (
        <button type="button" className={btnThisYear.className} id="thisYear"
            onClick={(event) => { if(typeof btnThisYear.onClick == 'function') btnThisYear.onClick(event, history); }}
        >
            За этот год
        </button>
    ));

    const ButtonDisplayThisMonth = withRouter(( {history} ) => (
        <button type="button" className={btnThisMonth.className} id="thisMonth"
            onClick={(event) => { if(typeof btnThisMonth.onClick == 'function') btnThisMonth.onClick(event, history); }}
        >
            За этот месяц
        </button>
    ));

    const ButtonDisplayPrevMonth = withRouter(( {history} ) => (
        <button type="button" className={btnPrevMonth.className} id="prevMonth"
            onClick={(event) => { if(typeof btnPrevMonth.onClick == 'function') btnPrevMonth.onClick(event, history); }}
        >
            Предыдущий месяц
        </button>
    ));

    return (
        <div className={className}>
            <div className={getStyle('f-left')}>
                <ButtonDisplayThisMonth />
                <ButtonDisplayPrevMonth />
                <ButtonDisplayThisYear />
            </div>
            <div className={[getStyle('f-right'), elementStyle['stat-chooser-right'] ].join(' ')}>
                За период с&nbsp;
                <DatePicker dropdownMode="select" className={getStyle('input-x')} placeholder="дд.мм.гггг" dateFormat="dd.MM.yyyy" onChange={period.fromChange} selected={period.from.toDate()} />
                &nbsp;по&nbsp;
                <DatePicker dropdownMode="select" className={getStyle('input-x')} placeholder="дд.мм.гггг" dateFormat="dd.MM.yyyy" onChange={period.toChange} selected={period.to.toDate()} minDate={period.from.toDate()}/>
                &nbsp;
                <ButtonDisplay />
            </div>
            {props.children}
        </div>
    )
}