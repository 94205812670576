/**
 * Created by dpopov on 14.09.2017.
 */
/*eslint jsx-a11y/anchor-is-valid: ["off"]*/
import React from 'react';

export default (props) => {

    const notifyStyle = props.className;
    const notifyTextStyle = props.notifyTextClassName;
    const notifyCloseClick = typeof props.closeClick == 'function' ? props.closeClick : null;
    const notifyCloseStyle = props.notifyCloseClassName;

    return (
        <div className={notifyStyle}>
            <div className={notifyTextStyle}>
                { props.children }
                { notifyCloseClick ?
                    <a href={"#"} className={notifyCloseStyle} onClick={notifyCloseClick}>×</a>
                    : null}
            </div>
        </div>

    );
}