import React from 'react';
import { Link } from 'react-router-dom';

import { getStyle } from '../../../tools';
import myStyle from '../style.module.css';
import * as CONST from "../../../const";

import 'moment/locale/ru';
import moment from 'moment';
moment.locale('ru');

export default (props) => {
    const anons = props.anons;
    const ruleId = props.ruleId;
    const hasDesc = props.hasDesc;
    const hasExternalDesc = props.hasExternalDesc;

    const linkHref = CONST.ROOT_PATH + (ruleId?'/tariff.'+ruleId+'.html':'/bonus.html');

    return (
        <div className={[myStyle['rule-stopped'], myStyle['bonus-anons']].join(' ')}>
            {moment().isBefore('2018-10-01 00:00:00') ? <small>(c 1 октября 2018 года)</small> : null}
            <div className={myStyle['bonus-speed-about-block']}>
                <div className={myStyle['bonus-speed-about-block-dropdown']}>
                    <span className={[getStyle('button a-color'), myStyle['help-me']].join(' ')}>?</span>
                    <div className={myStyle['bonus-speed-about']}>
                        {anons}
                        &nbsp;
                        {hasDesc ?
                            <Link to={linkHref} className={getStyle('a-color')}>Подробнее...</Link>
                        : hasExternalDesc ?
                            <a href={hasExternalDesc} className={getStyle('a-color')} target={"_blank"} >Подробнее...</a>
                        : null}
                    </div>
                </div>
            </div>
        </div>
    );
}

