/**
 * Created by dpopov on 14.09.2017.
 */
import React from 'react';

import ChapterUser from './index';
import PanelNotifySettings from '../../Panels/PanelNotificationsSettings';

export default (props) => {
    return (
        <ChapterUser>
            <PanelNotifySettings />
        </ChapterUser>
    );
}