/**
 * Created by dpopov on 14.09.2017.
 */
import * as CONST from '../../const';

const initialState = {
    loadingState: false,
    error: false,
    errorText: "",
    email: "",
    phone: "",
    limit: 50,
    mask: 0
};

export default function PanelNotificationsSettings(state = initialState, action) {
    switch(action.type) {
        case CONST.EMPTY_REDUCER: return {...initialState};
        case CONST.NOTIFY_SETTINGS.NOTIFY_REQUEST: {
            return {...state, loadingState: action.payload };
        }
        case CONST.NOTIFY_SETTINGS.NOTIFY_SET_DATA: {
            if(action.payload.error) {
                return {...state, loadingState: false, error: true, errorText: action.payload.error };
            }

            return {...state, loadingState: false, error: false, errorText: "",
                email: action.payload.data.email,
                phone: action.payload.data.phones,
                limit: action.payload.data.limit,
                mask: action.payload.data.mask
            }
        }
        case CONST.NOTIFY_SETTINGS.NOTIFY_SET_VALUE: {
            let newState = {...state};
            newState[action.payload.field] = action.payload.value;
            return newState;
        }
        default: return state;
    }
}