/**
 * Created by dpopov on 10.10.2017.
 */
import React from 'react';

import { connect } from 'react-redux';

import Panel from '../../Components/Panel/StaticPage';
import myStyle from './style.module.css';

import { getStyle } from '../../tools';

class PageContract extends React.Component {
    render() {
        const panelTitle = this.props.title || "Договор";
        const queryParams = { contract: this.props.authData.activeContract };

        //const containerStyle = myStyle['PageContract_container'];
        const containerStyle = getStyle('b-row');

        return (
            <Panel title={panelTitle} icon={getStyle('icon-user')} pageName="contract" queryParams={queryParams} bodyStyle={myStyle['PageContract_panelBody']} containerStyle={containerStyle}>

            </Panel>
        );
    }
}

function mapStateToProps(state) {
    return {
        authData: state.authenticate
    }
}

function mapDispatchToProps(dispatch) {
    return {
//        elementActions: bindActionCreators(SubNavBlock, dispatch),
//        authenticate: bindActionCreators(SubNavBlock, dispatch)
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(PageContract);