/**
 * Панель настройки уведомлений
 *
 * Created by dpopov on 14.09.2017.
 */
/*eslint eqeqeq: ["off", "smart"]*/
/*eslint default-case: ["off"]*/
/*eslint no-useless-escape: ["off"]*/
/*eslint no-fallthrough: ["off"]*/

import React from 'react';
import axios from 'axios';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import Panel from '../../Components/Panel';
import ConfirmPhoneInterface from '../../Components/Confirmation/Phone';
import { getStyle } from  '../../tools';
import myStyle from './style.module.css';
import * as CONST from '../../const';
import * as authenticateActions from '../../Authenticate/actions';
import * as NotifySettingsActions from './actions';

class PanelNotificationsSettings extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            notifyEmail: [""],
            notifyPhone: [""],
            notifyLimit: 0,
            notifyMask: 0,
            contract: props.authData.activeContract,
            notifyLoaded: false,
            changedPhones: {},

            errorInputBalance: false
        };

        this.refreshNotifySettings = this.refreshNotifySettings.bind(this);
        this.onChangeType = this.onChangeType.bind(this);
        this.onSaveClick = this.onSaveClick.bind(this);
        this.onInputValueChange = this.onInputValueChange.bind(this);

        this.onPhoneConfirmCancel = this.onPhoneConfirmCancel.bind(this);
        this.handlerConfirmPhone = this.handlerConfirmPhone.bind(this);
    }

    refreshNotifySettings() {
        const contract = this.props.authData.activeContract;
        this.setState({
            notifyLoaded: false
        });
        this.props.notifySettingsActions.requestNotifySettings(contract);
    }

    componentWillReceiveProps(nextProps) {
        if(nextProps.authData.activeContract !== this.state.contract) {
            this.refreshNotifySettings();
            this.setState({contract: this.state.contract, notifyLoaded: false});
        }

        if(this.state.notifyLoaded == false && nextProps.NotifySettings.loadingState == false && nextProps.NotifySettings.error == false) {
            this.setState({
                notifyLoaded: true,
                notifyEmail: nextProps.NotifySettings.email.slice(0) || [],
                notifyPhone: nextProps.NotifySettings.phone.slice(0) || [],
                notifyLimit: nextProps.NotifySettings.limit,
                notifyMask: nextProps.NotifySettings.mask
            });
        }
    }

    componentWillMount() {
        this.refreshNotifySettings();
    }

    onChangeType(event) {
        const bit = parseInt(event.target.getAttribute('data-bit'));
        let mask = this.state.notifyMask;

        this.setState({notifyMask: mask ^ bit });
    }

    diff(src, dst, fn) {
        let answer = {};

        for(let i=0;i<src.length;i++) {
            let s = src[i];
            let d = dst[i];

            if(typeof fn === 'function') {
                s = fn(s?s:'');
                d = fn(d?d:'');
            }

            if(s!=d) answer[s] = d;
        }

        return answer;
    }

    checkValidBalance(){

        const mask = this.state.notifyMask;

        let
            error = false,

            matchesLimit = (this.state.notifyLimit + '').match(/^([1-9]+(\d*|\d*(\.|,)\d+)|0(\.|,\d+))$/);

        if(mask & CONST.NOTIFY_TYPES.BALANCE_SMS || mask & CONST.NOTIFY_TYPES.BALANCE_EMAIL ) {
            error = !(matchesLimit instanceof Array);
            this.setState({errorInputBalance: error});
        }

        return error;
    }

    onSaveClick(event) {
        if(event) event.preventDefault();

        // проверка корректно введненного остатка на балансе для оповещения
        if(this.checkValidBalance())return;


        let changedPhones = this.diff(this.state.notifyPhone, this.props.NotifySettings.phone,
            function(str) {
                return str.replace(/[^\d]/g, '');
            }
        );

        this.setState({
            changedPhones: changedPhones
        });

        this.props.notifySettingsActions.updateNotifySetting(this.state.contract, this.state.notifyPhone, this.state.notifyEmail, this.state.notifyLimit, this.state.notifyMask, this.refreshNotifySettings);

        this.props.authActions.requestCurrentUser(true, {
            activeContract: this.state.contract,
            force_ai: 1
        });
    }

    onInputValueChange(event) {
        const field = event.target.getAttribute('name');
        let value = event.target.value;

        switch(field) {
            case 'limit':
                this.setState({ notifyLimit: value.replace(/[^\d\.,]/g, '')});
                this.checkValidBalance();
                break;
            case 'notifyPhone':
                value = value.replace(/[^\-\d\s\+\(\)0-9]/g, '');
            case 'notifyEmail':
                const index = parseInt(event.target.getAttribute('data-index'));
                let state = {};
                state[field] = this.state[field];
                state[field][index] = value;
                this.setState(state);

                break;
        }
    }

    onPhoneConfirmCancel(newPhoneNum, oldPhoneNum) {
        let confirm = this.state.changedPhones;
        let myPhones = this.state.notifyPhone.slice(0);

        for(let i=0;i<myPhones.length;i++) {
            if(myPhones[i].replace(/[^\d]/g, '') == newPhoneNum) myPhones[i] = oldPhoneNum;
        }

        delete confirm[newPhoneNum];

        this.setState({notifyPhone: myPhones, changedPhones: confirm});
    }

    handlerConfirmPhone(contract, oldPhone, newPhone, code, onError) {
        axios.get(CONST.API + '/notify/confirmPhone', {
            withCredentials: true,
            params: {
                contract: contract,
                phone: newPhone,
                oldphone: oldPhone,
                code: code,
		'.rnd': Math.ceil(Math.random()*1000000)
            }
        }).then((answer) => {
            const data = answer.data;
            if(data.result === 'success') {
                let confirm = this.state.changedPhones;
                delete confirm[newPhone];
                this.setState({notifyPhone: data.data.phone, changedPhones: confirm});
            } else if(typeof onError === 'function') onError(data.text);
        });
    }

    render() {
        // console.log(this.state);
        const title = this.props.title || "Настройка уведомлений";
        const icon = this.props.icon || getStyle('icon-sett');
        const loadingState = this.props.NotifySettings.loadingState;

        const mask = this.state.notifyMask;
        const limit = this.state.notifyLimit;

        const email = this.state.notifyEmail && this.state.notifyEmail.length ? this.state.notifyEmail : [ "" ];
        // const emailRows = email.length ? email.length - 1 : 0;
        const phone = this.state.notifyPhone && this.state.notifyPhone.length ? this.state.notifyPhone : [ "" ];

        const hasPhone = !!phone[0];
        const hasEmail = !!email[0];

        const HideSMS = hasPhone ? '' : ' hide';
        const HideEmail = hasEmail ? '' : ' hide';

        let confirmPhone = null;
        if(Object.keys(this.state.changedPhones).length && !loadingState) {
            const newPhoneNum = Object.keys(this.state.changedPhones)[0];
            const oldPhoneNum = this.state.changedPhones[newPhoneNum];

            confirmPhone = <ConfirmPhoneInterface onCancel={this.onPhoneConfirmCancel} confirmHandler={this.handlerConfirmPhone} noSendSMS={true}
                                                  contract={this.state.contract} phoneNum={newPhoneNum} oldPhoneNum={oldPhoneNum} />
        }


        const Frame = (
            <div>
                {confirmPhone}
                <table className={getStyle('left-pull form-table')}>
                    <tbody>
                    <tr>
                        <td className={getStyle('td-null t-nowrap')}>Email для уведомлений</td>
                        <td><input type="text" name="notifyEmail" value={email[0]} data-index="0" onChange={this.onInputValueChange} /></td>
                    </tr>
                    <tr>
                        <td className={getStyle('td-null t-nowrap')}>Телефон для уведомлений</td>
                        <td><input type="tel" name="notifyPhone" pattern="^[\s\-\+0-9\(\)]+$" value={phone[0]} data-index="0" onChange={this.onInputValueChange} /></td>
                    </tr>
                    </tbody>
                </table>
                <table className={getStyle('line v-mid gray left-pull' + (!hasPhone && !hasEmail ? ' hide' : ''))}>
                    <thead>
                    <tr>
                        <th>Получать уведомления</th>
                        <th className={getStyle(''+HideSMS)}>SMS</th>
                        <th className={getStyle(''+HideEmail)}>Email</th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr>
                        <td>Остаток на балансе менее <input type="text" className={[(this.state.errorInputBalance ? myStyle['border-solid-red'] : ''),getStyle('input-xs ')].join(' ')} name="limit" value={limit} onChange={this.onInputValueChange} /> руб.</td>
                        <td className={getStyle(''+HideSMS)}><label className={getStyle('input-checkbox')}><input type="checkbox" name="smsLimit" data-bit={CONST.NOTIFY_TYPES.BALANCE_SMS} checked={hasPhone ? mask & CONST.NOTIFY_TYPES.BALANCE_SMS : false} onChange={this.onChangeType} /><div className={getStyle('input')}></div></label></td>
                        <td className={getStyle(''+HideEmail)}><label className={getStyle('input-checkbox')}><input type="checkbox" name="emailLimit" data-bit={CONST.NOTIFY_TYPES.BALANCE_EMAIL} checked={hasEmail ? mask & CONST.NOTIFY_TYPES.BALANCE_EMAIL : false} onChange={this.onChangeType} /><div className={getStyle('input')}></div></label></td>
                    </tr>
                    <tr>
                        <td>Новости и обновления</td>
                        <td className={getStyle(''+HideSMS)}><label className={getStyle('input-checkbox')}><input type="checkbox" name="newsLimit" data-bit={CONST.NOTIFY_TYPES.NEWS_SMS} checked={hasPhone ? mask & CONST.NOTIFY_TYPES.NEWS_SMS : false} onChange={this.onChangeType} /><div className={getStyle('input')}></div></label></td>
                        <td className={getStyle(''+HideEmail)}><label className={getStyle('input-checkbox')}><input type="checkbox" name="newsLimit" data-bit={CONST.NOTIFY_TYPES.NEWS_EMAIL} checked={hasEmail ? mask & CONST.NOTIFY_TYPES.NEWS_EMAIL : false} onChange={this.onChangeType} /><div className={getStyle('input')}></div></label></td>
                    </tr>
                    <tr>
                        <td>Спецпредложения</td>
                        <td className={getStyle(''+HideSMS)}><label className={getStyle('input-checkbox')}><input type="checkbox" name="offersLimit" data-bit={CONST.NOTIFY_TYPES.OFFER_SMS} checked={hasPhone ? mask & CONST.NOTIFY_TYPES.OFFER_SMS : false} onChange={this.onChangeType} /><div className={getStyle('input')}></div></label></td>
                        <td className={getStyle(''+HideEmail)}><label className={getStyle('input-checkbox')}><input type="checkbox" name="offersLimit" data-bit={CONST.NOTIFY_TYPES.OFFER_EMAIL} checked={hasEmail ? mask & CONST.NOTIFY_TYPES.OFFER_EMAIL : false} onChange={this.onChangeType} /><div className={getStyle('input')}></div></label></td>
                    </tr>
                    </tbody>
                </table>
                <div className={getStyle('b-row')}>
                    <button type="button" className={getStyle('btn-major')} onClick={this.onSaveClick}>Сохранить</button>
                </div>
            </div>
        );

        if(this.props.asFrame) return Frame;

        return (
            <Panel title={title} icon={icon} isLoading={loadingState}>
                {Frame}
            </Panel>
        );
    }
}

function mapStateToProps(state) {
    return {
        NotifySettings: state.NotifySettings,
        authData: state.authenticate
    }
}

function mapDispatchToProps(dispatch) {
    return {
        authActions: bindActionCreators(authenticateActions, dispatch),
        notifySettingsActions: bindActionCreators(NotifySettingsActions, dispatch)
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(PanelNotificationsSettings);

