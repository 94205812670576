/**
 * Страница с ошибкой 404
 * Created by dpopov on 12.07.2017.
 */

import React from 'react';
import { connect } from 'react-redux';

import CheckSmallLink from '../../Authenticate/checkSmallLink';
import Panel404 from './panel404';

class Page404 extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            loadingState: false
        }
    }

    render() {
        const loadingState = this.state.loadingState;

        return (
            <CheckSmallLink>
                <Panel404 isLoading={loadingState} />
            </CheckSmallLink>
        )
    }
}

function mapStateToProps(state) {
    return {
        authData: state.authenticate,
//        state: state.panelPay
//        servicesData: state.panelServices
    }
}

function mapDispatchToProps(dispatch) {
    return {
        //elementActions: bindActionCreators(elementActions, dispatch)
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Page404);
