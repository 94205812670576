/**
 * Created by dpopov on 08.09.2017.
 */
/* eslint eqeqeq: ["off", "smart"] */
import { withRouter } from 'react-router-dom';

// import CSS from '../assets/css/main.css';
import '../assets/css/main.css';
import notifyStyle from './Components/Panel/innerNotify.module.css';
import * as CONST from "./const";
import React from "react";

import 'moment/locale/ru';
import moment from "moment";
moment.locale('ru');

export function getStyle(styles) {
    return styles;
    // При переходе на react-script обработка css изменилась.
    // if(!styles) return "";
    // const stylesArr = styles.replace(/\s{2,}/g, '').split(' ');
    // return stylesArr.map((item) => CSS[item]).join(' ');
}

export function getServiceLink(serviceId, serviceType) {

}

export function getStdNotifyStruct(text, onClearErrorClick) {
    let notify = {};
    if(text) {
        notify = {
            text: text,
            style: [ getStyle('top-notify'), notifyStyle['center']].join(' '),
            textStyle: [ getStyle('notify-message'), notifyStyle['error'], notifyStyle['inline']].join(' '),
            closeClick: onClearErrorClick,
            closeStyle: [ getStyle("button btn-close inv"), notifyStyle['btn-close']].join(' ')
        }
    }

    return notify;
}

// example:  num, 'минуту', 'минуты', 'минут'
export function declension(num, single, double, many) {
    if(num == 1) return single;
    if(num >= 5 && num <= 20) return many;
    return double;
}

export function addZeroes( num ) {
    let value = Number(num);
    let res = String(num).split(".");
    if(res.length == 1 || (res[1].length < 3)) {
        value = value.toFixed(2);
    }
    return value;
}

export function phoneFormat(phone) {
    if(!phone) return null;
    let numbers = phone.replace(/[^\d]/g, '');
    if(!numbers.match(/\d{10}$/)) return phone;

    return numbers.replace(/^.*(\d{3})(\d{3})(\d{2})(\d{2})$/, '+7 $1 $2-$3-$4');
}

export function phoneFormatForInput(num) {
    if(!num) return '';

    let numbers = num.replace(/\D/, '');

    if(numbers.match(/^7\d{10}$/)) return '+' + numbers;
    if(numbers.match(/^8\d{10}$/)) return '+7' + numbers.replace(/^8(\d{10})$/, '$1');
    if(numbers.match(/^\d{10}$/)) return '+7' + numbers;
    if(numbers.match(/^\d{7}$/)) return '+7812' + numbers;

    return numbers;
}

export function extractServicesForTemplate(servicesTree) {
    const today = moment();

    let answer = {
        internet: {
            appServiceId: null,
            activeHomeChargeRule: null,
            nextHomeChargeRule: null
        },
        tv: {
            appServiceId: null,
            activeHomeChargeRule: null,
            nextHomeChargeRule: null
        }
    };

    if(servicesTree) for(let i=0;i<servicesTree.length;i++) {
        const App = servicesTree[i];
        const AppServicesIDs = Object.keys(App.services);
        for(let appServiceID_i = 0; appServiceID_i < AppServicesIDs.length; appServiceID_i++) {
            const AppServiceID = AppServicesIDs[appServiceID_i];
            const hcrList = App.services[AppServiceID];

            let hcrItem = hcrList;

            // Ищем действующее в данный момент правило
            while (hcrItem) {
                if(today.isBetween(hcrItem.dateStart, hcrItem.dateStop)) {
                    if (hcrItem.Type & CONST.SERVICE_TYPES.INET && !answer.internet.activeHomeChargeRule) {
                        answer.internet.appServiceId = AppServiceID;
                        answer.internet.activeHomeChargeRule = hcrItem;
                        if(hcrItem.__next && !hcrItem.__next.isAb) answer.internet.nextHomeChargeRule = hcrItem.__next
                    }

                    if(hcrItem.Type & CONST.SERVICE_TYPES.TV && !answer.tv.activeHomeChargeRule) {
                        answer.tv.appServiceId = AppServiceID;
                        answer.tv.activeHomeChargeRule = hcrItem;
                        answer.tv.nextHomeChargeRule = hcrItem.__next;
                    }
                }

                if(today.isBefore(hcrItem.dateStart)) {
                    if (hcrItem.Type & CONST.SERVICE_TYPES.INET && !hcrItem.isAb) {
                        answer.internet.nextHomeChargeRule = hcrItem;
                    }
                }

                if(answer.internet.nextHomeChargeRule) break;

                hcrItem = hcrItem.__next;
            }
        }
    }

    return answer;
}

export function processInputHtmlStaticPage(html, servicesTree, authData, replacements) {
    if(!html) return html;

    if(html.match(/\{%[^%]+%\}/)) {
        // есть шаблонные перменнные
        const replaceInfo = extractServicesForTemplate(servicesTree);

        if(replaceInfo.internet.activeHomeChargeRule) {
            html = html.replace(/{%ACTIVE_INTERNET_RULE%}/gi, replaceInfo.internet.activeHomeChargeRule.id);
            html = html.replace(/{%NEXT_INTERNET_RULE%}/gi, replaceInfo.internet.nextHomeChargeRule ? replaceInfo.internet.nextHomeChargeRule.id : replaceInfo.internet.activeHomeChargeRule.id);
            html = html.replace(/{%INTERNET_SERVICE%}/gi, replaceInfo.internet.appServiceId)
        }

        if(replaceInfo.tv.activeHomeChargeRule) {
            html = html.replace(/{%ACTIVE_TV_RULE%}/gi, replaceInfo.internet.activeHomeChargeRule.id);
            html = html.replace(/{%NEXT_TV_RULE%}/gi, replaceInfo.internet.nextHomeChargeRule ? replaceInfo.internet.nextHomeChargeRule.id : replaceInfo.internet.activeHomeChargeRule.id);
            html = html.replace(/{%TV_SERVICE%}/gi, replaceInfo.internet.appServiceId)
        }

        if(authData) {
            let APPEAL = "Уважаемый абонент";
            if(authData.sex === '1') APPEAL = 'Уважаемый';
            if(authData.sex === '0') APPEAL = 'Уважаемая';

            let ABONENT = authData.clientFirstName + ' ' + authData.clientMiddleName;

            html = html.replace(/{%APPEAL%}/, APPEAL);
            html = html.replace(/{%ABONENT%}/, ABONENT);
        }

        html = html.replace(/\{%[^%]+%\}/g, 'error');
    }
    if(replacements) {
        for (let code in replacements) {
            let replace = new RegExp('{' + code + '}', "g");
            html = html.replace(replace, replacements[code]);
        }
    }

    return html;
}

export function StaticPageDisplayContainer(html, className, replacements) {
    for(let code in replacements) {
        let replace = new RegExp('{' + code + '}',"g");
        html = html.replace(replace, replacements[code]);
    }
    return withRouter(({history}) => (
        <div className={className} dangerouslySetInnerHTML={{__html: html}} onClick={(event) => {
            const target = event.target;
            const href = target.getAttribute('href');

            if (href) {
                // Клик по ссылке, посмотрим, не является ли она внутренним переходом
                let matches = href.match(/^internal:(.+)$/);
                if (matches) {
                    event.preventDefault();
                    const targetLink = CONST.ROOT_PATH + matches[1];
                    history.push(targetLink);
                    return;
                }
            }
        }} />
    ));
}

export function getUTM_PID() {
    // const url = new URL(window.location.href);
    // return url.searchParams.get('utm_pid');
    const params = getUrlVars();
    return params['utm_pid'];
}

export function getUrlVars(){
    let a={};
    window.location.href.replace(/[?&]+([^=&]+)=([^&#]*)/gi,function(d,b,c){a[b]=c});
    return a;
}
