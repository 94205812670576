/**
 * Created by dpopov on 16.08.2017.
 */
import * as CONST from '../../const';
import moment from 'moment';

moment.locale('ru');

const initialState = {
    panelLoadingState: false,
    ErrorText: null,
    limitFrom: 0,
    limitCount: 10,
    total: 0,
    periodStart: moment().startOf('month'),
    periodEnd: moment().endOf('month'),
    payments: []
};

export default function PanelPayHistoryReducer(state = initialState, action) {
    switch(action.type) {
        case CONST.EMPTY_REDUCER: return {...initialState};
        case CONST.PAYS_HISTORY.PANEL_PAY_HISTORY_IN_PROCESS: {
            return {...state, panelLoadingState: true, ErrorText: null };
        }
        case CONST.PAYS_HISTORY.PANEL_PAY_HISTORY_SET_DATA: {

            let newState = {...state,
                panelLoadingState: false,
                ErrorText: null,
                limitFrom: action.payload.limitFrom,
                limitCount: action.payload.limitCount,
                periodStart: moment(action.payload.periodStart),
                periodEnd: moment(action.payload.periodEnd),
                payments: action.payload.payments,
                total: action.payload.total
            };
            return newState;
        }
        case CONST.PAYS_HISTORY.PANEL_PAY_HISTORY_SET_ERROR: {
            return {...state, ErrorText: action.payload }
        }
        default: return state;
    }
}