/**
 * Created by dpopov on 09.10.2017.
 */
import * as CONST from '../../const';

const initialState = {
    loadingState: false,
    phone: [],
    email: [],
    error: null
};

export default function PanelPersonalSettingsReducer(state = initialState, action) {
    switch(action.type) {
        case CONST.EMPTY_REDUCER: return {...initialState};
        case CONST.PERSONAL_SETTINGS.REQUEST_DATA: return {...state, loadingState: action.payload };
        case CONST.PERSONAL_SETTINGS.SET_DATA: {
            return {...state,
                loadingState: false,
                error: action.payload.error,
                email: action.payload.email ? action.payload.email : [],
                phone: action.payload.phone ? action.payload.phone : []
            };
        }
        default: return state;
    }
};