/**
 * Created by dpopov on 24.08.2017.
 */
import * as CONST from '../../const';

const initialState = {
    text: [],
    errors: [],
    lastError: null
};

export default function PanelPayReducer(state = initialState, action) {
    switch(action.type) {
        case CONST.EMPTY_REDUCER: return {...initialState};
        case CONST.NOTIFY.NOTIFY_SET_DATA: return {...state, text: action.payload };
        case CONST.NOTIFY.NOTIFY_APPEND_ERROR: {
            let errors = state.errors;
            if(action.lastError) errors.push(state.lastError);
            return {...state, errors: errors, lastError: action.payload};
        }
        case CONST.NOTIFY.NOTIFY_POP_ERROR: {
            let errors = state.errors;
            let lastError = errors.pop();
            return {...state, errors: errors, lastError: lastError};
        }
        default: return state;
    }
}

