import React from 'react';

import * as CONST from '../../const';

import { Route } from 'react-router-dom';

import PromoPage from './PromoPage';

export const PromoRoutes = [
    <Route key={"promoRoute-1"} exact path={CONST.ROOT_PATH + "/static.:name.html"} component={PromoPage} />
];