import React from 'react';

import Panel from '../../../Components/Panel';
import {getStyle} from "../../../tools";
import * as CONST from "../../../const";
import * as style from "../../../Panels/PanelChoosePrimaryPhone/style.module.css";

export default function(props) {

    return (
        <Panel title="Уточнение контактной информации" isLoading={props.isLoading}>
            <div className={getStyle('b-row b-border t-center')}>
                <h4>Телефонный номер для уведомлений установлен</h4>

                <div>
                    {props.phoneNumber ? <div className={style['primaryNumber']}><h3>{props.phoneNumber}</h3></div> : null }
                    <div>
                        <a href={CONST.ROOT_PATH+'/'} className={getStyle('button')}>На главную</a>
                    </div>
                </div>

            </div>
        </Panel>
    );
}