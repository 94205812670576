/**
 * Created by dpopov on 15.08.2017.
 */
import React from 'react';

import * as CONST from '../../const';

import { Route } from 'react-router-dom';
// import CheckAccess from '../Authenticate';
// import Panel from '../Panel';

import pageViewItem from './pageViewNewsItem';
import pageListNews from './pageListNews';

export const NewsRoutes = [
    <Route key="NewsRoutes1" exact path={CONST.ROOT_PATH + "/news.html"} component={pageListNews} />,
    <Route key="NewsRoutes2" exact path={CONST.ROOT_PATH + "/news-:newsId.html"} component={pageViewItem}  />
];