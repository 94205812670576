/**
 * Created by dpopov on 08.09.2017.
 */
/*eslint eqeqeq: ["off", "smart"]*/
/*eslint array-callback-return: ["off"]*/

import React from 'react';

import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { withRouter } from 'react-router-dom';

import { getServiceData, requestServiceWays } from './tools';
import { getStyle } from '../../tools';

import axios from 'axios';

import * as authenticateActions from './../../Authenticate/actions';
import * as panelServicesActions from './actions';
import * as CONST from '../../const';

import elStyle from './style.module.css';
import notifyStyle from '../../Components/Panel/innerNotify.module.css';

import Panel from '../../Components/Panel';
import PopUp from '../../Components/Panel/PopUp';
import PanelHiSpeedCheck from '../PanelHiSpeedCheck';

class PanelApplyAbonement extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            loadingState: false,
            serviceId: props.serviceId,
            error: null,
            ways: {},
            waysKeysSort: [],
            selectLength: null,
            selectSpeed: null,
            needHiSpeedTicket: false,

            paymentNotificationAlert: false
        };

        this.requestServiceWays = this.requestServiceWays.bind(this);
        this.onSelectTariff = this.onSelectTariff.bind(this);
        this.btnApplyTariffClick = this.btnApplyTariffClick.bind(this);
        this.onClearErrorClick = this.onClearErrorClick.bind(this);
        this.onUpTheBalanceLinkClick = this.onUpTheBalanceLinkClick.bind(this);
        this.onCheckHiSpeedCancel = this.onCheckHiSpeedCancel.bind(this);
        this.onNotEnoughMoneyButtonClick = this.onNotEnoughMoneyButtonClick.bind(this);

        this.CheckHiSpeed = withRouter(({history}) => {
            return (
                <PanelHiSpeedCheck hcr={this.props.serviceId} autoClose={true} changeTariff={true}
                                   targetTariff={this.state.ways[this.state.selectLength][this.state.selectSpeed]['id']}
                                   onSuccess={() => {
                    this.registerAbonementAutoApply(history);
                    //history.push(CONST.ROOT_PATH+'/services.html');
                }} onCancel={this.onCheckHiSpeedCancel} />
            )
        });

        this.alertNotEnoughMoneyOkClicked = false;
    }

    componentDidMount() {
        this.requestServiceWays();
    }

    requestServiceWays() {
        const self = this;
        const contract = this.props.authData.activeContract;

        self.setState({ loadingState: true, error: null });

        requestServiceWays(contract, self.state.serviceId, 1,
            function(data) {
                const ways = data.data;

                let abons = {};
                ways.forEach(function(item, _i) {
                    const abonsKey = item['specialName'] ? item['specialName'] : item['abLength'];
                    if(!abons[abonsKey]) abons[abonsKey] = {};
                    abons[abonsKey][item['speed']] = item;
                });

                let waysLength = Object.keys(abons).sort((a, b) => {
                    if(a.match(/^\d+$/) && b.match(/^\d+/)) {
                        // оба элемента числа
                        a = Number.parseInt(a);
                        b = Number.parseInt(b);
                        if(a === b) return 0;
                        return a < b ? -1 : 1;
                    } else if(!a.match(/^\d+$/) || !b.match(/^\d+$/)) {
                        // один из элементов не является числом
                        if(!a.match(/^\d+$/)) return -1;
                        if(!b.match(/^\d+$/)) return 1;
                    } else {
                        // оба элемента не числа
                        if(a==b) return 0;
                        return a < b ? 1: -1;
                    }
                });

                self.setState({ loadingState: false, error: null, ways: abons, waysKeysSort: waysLength });
            },
            function(data) {
                self.setState({ loadingState: false, error: data.text, ways: {} });
            }
        );
    }

    onSelectTariff(event) {
        const length = event.target.getAttribute('data-ablength');
        const speed = event.target.getAttribute('data-speed');

        this.setState({ selectLength: length, selectSpeed: speed });
    }

    btnApplyTariffClick(event, history) {
        event.preventDefault();
        const self = this;

        const contract = this.props.authData.activeContract;

        const abLength = self.state.selectLength;
        const abSpeed = self.state.selectSpeed;
        const tariff = self.state.ways[abLength][abSpeed]['id'];

        self.setState({ loadingState: true });

        axios.get(CONST.API + '/services/switchTariff', {
            withCredentials: true,
            params: {
                contract: contract,
                hcr: self.state.serviceId,
                ab: 1,
                tariff: tariff,
		        '.rnd': Math.ceil(Math.random()*1000000)
            }
        }).then(function(answer) {
            const data = answer.data;
            if(data.result == 'success') {
                self.props.serviceActions.requestServicesList(contract, 'applyAbonement-btnApplyTariffClick');
                history.push(CONST.ROOT_PATH+'/services.html');
            } else {
                self.setState({ loadingState: false, error: data.text });
            }

        });
    }

    describeMonths(count) {
        let months = ['месяц', 'месяца', 'месяцев'];
        let num = count % 100;

        if (num > 19) num = num % 10;

        switch (num) {
            case 1:
                return months[0];
            case 2: case 3: case 4:
                return months[1];
            default:
                return months[2];
        }
    }

    onClearErrorClick(event) {
        event.preventDefault();
        this.setState({error: null});
    }

    registerAbonementAutoApply(history) {
        const { selectLength, selectSpeed, serviceId } = this.state;
        const contract = this.props.authData.activeContract;
        const balance = this.props.authData.contracts[contract];
        const tariff = this.state.ways[selectLength][selectSpeed]['id'];

        const needMoney = Math.ceil((this.state.ways[selectLength][this.state.selectSpeed]['price'] - balance) * 100) / 100;

        this.props.serviceActions.setRecommentPayment(needMoney, contract, true);

        // Проведём регистрацю БМ, для автоматического подключения
        axios.get(CONST.API + '/services/registerAbonementAutoApply', {
            withCredentials: true,
            params: {
                contract: contract,
                tariff: tariff,
                serviceId: serviceId
            }
        }).then((answer) => {
            history.push(CONST.ROOT_PATH+'/billing.html');
        }).catch(error => history.push(CONST.ROOT_PATH+'/billing.html'));
    }

    checkNeedHighSpeedTicket(event) {
//        console.log(event);
        if(event && typeof event.preventDefault === 'function') event.preventDefault();
        // const { selectLength, selectSpeed, serviceId } = this.state;
        const { selectLength, selectSpeed } = this.state;
        const contract = this.props.authData.activeContract;
        const balance = this.props.authData.contracts[contract];
        // const tariff = this.state.ways[selectLength][selectSpeed]['id'];

        const needMoney = Math.ceil((this.state.ways[selectLength][this.state.selectSpeed]['price'] - balance) * 100) / 100;

        this.props.serviceActions.setRecommentPayment(needMoney, contract);

        const tariffData = this.state.ways[selectLength][selectSpeed];

        if(tariffData.speed > 100 && (!tariffData.hasHiSpeedMarker || !tariffData.hasHiSpeedRules)) {
            // Скоростные тарифы. Но у абонента нет правил-маркеров и правил со скоростями > 100 Мбит/с
            this.setState({ needHiSpeedTicket: true });
            return true;
        }

        return false;
    }

    onCheckHiSpeedCancel(event) {
        if(event) event.preventDefault();
        this.setState({needHiSpeedTicket: false});
    }

    onUpTheBalanceLinkClick(event) {
        if(!this.checkNeedHighSpeedTicket()) {
            event.preventDefault();
            return true;
        }

        return true;
//        return true;

        // Проведём регистрацю БМ, для автоматического подключения
//        this.registerAbonementAutoApply();
        //console.log(contract, tariff, serviceId);
    }

    onNotEnoughMoneyButtonClick(event) {
        event.preventDefault();
        this.setState({paymentNotificationAlert: true});
    }

    render() {
        const self=this;
        const ActiveTariff = getServiceData(this.props.servicesData.services, this.state.serviceId);

        const balance = self.props.authData.contracts[self.props.authData.activeContract];

        const ApplyTariff = withRouter(({history}) => (<div className={getStyle('b-row')}>
            <div className={getStyle('f-left')}>
                <button type="button" className={getStyle("btn-big btn-major")} onClick={(event) => self.btnApplyTariffClick(event, history)}>Подключить абонемент</button>
            </div>
            <div className={[getStyle('f-auto t-small t-mute t-em'), elStyle['tariff-info-text']].join(' ')}>

            </div>
        </div>
        ));

//        const BalanceLinkCaption = "ОК";
        const BalanceLinkCaption = "Пополнить счёт и подключить";
        const BalanceLink = withRouter(({history}) => (
            <button type={"button"} className={getStyle('button')} onClick={(event) => {
                event.preventDefault();
                if(this.alertNotEnoughMoneyOkClicked) return;

                this.alertNotEnoughMoneyOkClicked = true;

                if(!this.checkNeedHighSpeedTicket('balanceLink')) {
                    this.registerAbonementAutoApply(history);
                }
            }}>{BalanceLinkCaption}</button>
        ));

        let notify = {};
        if(this.state.error) {
            notify = {
                text: this.state.error,
                style: [ getStyle('top-notify'), notifyStyle['center']].join(' '),
                textStyle: [ getStyle('notify-message'), notifyStyle['error'], notifyStyle['inline']].join(' '),
                closeClick: this.onClearErrorClick,
                closeStyle: [ getStyle("button btn-close inv"), notifyStyle['btn-close']].join(' ')
            }
        }

        const CheckHiSpeed = this.CheckHiSpeed;

        return (
            <Panel title="Подключение абонемента" icon={getStyle('icon-wifi')} isLoading={this.state.loadingState} notify={notify}>
                {this.state.needHiSpeedTicket ?
                    <CheckHiSpeed param1={'param1value'} />
                : null}
                {this.state.paymentNotificationAlert ?
                    <PopUp title={null}>
                        <table className={getStyle("form-table v-mid line")}>
                            <tbody>
                            <tr><td>
                                После пополнения баланса активация услуги произойдет в течение 15 минут
                            </td></tr>
                            <tr>
                                <td className={getStyle('t-center v-mid-force')}>
                                    <BalanceLink/>
                                </td>
                            </tr>
                            </tbody>
                        </table>
                    </PopUp>
                : null}
                {ActiveTariff ? [
                    <div className={getStyle('b-row gray')} key="1">
                        Ваш текущий тариф: <strong>{ActiveTariff.name}</strong> за <strong>{ActiveTariff.cost}</strong> руб/мес
                    </div>,
                    <div className={getStyle('b-row gray')} key="1a">
                        <strong>Вам доступны абонементы привязанные к новым тарифным планам</strong>
                    </div>,
                    <table className={getStyle('line v-mid left-pull')} key="2">
                        <tbody>
                        {self.state.waysKeysSort.map((abLength, _i) => (
                            [ <tr key={_i}>
                                <td className={abLength.match(/^\d+$/) ? [getStyle("td-null t-nowrap t-3"), elStyle['ab-length-rotate']].join(' ') : [ getStyle("td-null t-3"), elStyle['specialAb'], elStyle['ab-length-rotate']].join(' ')} dangerouslySetInnerHTML={{__html: abLength + ' ' + self.describeMonths(abLength)}} />
                                <td>
                                    <table className={getStyle("no-pull default-pull")}>
                                        <tbody>
                                        {Object.keys(self.state.ways[abLength]).map((speed, _i) => (
                                            <tr className={getStyle("interactive")} key={_i}>
                                                <td className={getStyle("td-null t-nowrap")}>
                                                    <label className={getStyle('input-radio')}>
                                                        <input type="radio" name="tariff" data-ablength={abLength} data-speed={speed}
                                                               onChange={self.onSelectTariff} checked={self.state.selectSpeed == speed && self.state.selectLength == abLength}
                                                        />
                                                        <div className={getStyle('input')}></div>
                                                    </label>
                                                </td>
                                                {speed != 0 ?
                                                    <td>
                                                        <strong>{speed}</strong> Мбит/с
                                                    </td>
                                                : null }
                                                <td className={getStyle(balance < self.state.ways[abLength][speed]['price']?"t-mute":"")}>{Math.ceil(self.state.ways[abLength][speed]['price']*100)/100} руб. ({Math.ceil(self.state.ways[abLength][speed]['cost']*100)/100} руб/мес)</td>
                                                <td className={getStyle("t-right")}> </td>
                                            </tr>
                                        ))}
                                        </tbody>
                                    </table>
                                </td>
                            </tr>,
                            self.state.selectLength == abLength ?
                                <tr key={_i + 's'}>
                                    <td colSpan="2" className={elStyle['clear-padding']}>
                                    {balance < self.state.ways[abLength][self.state.selectSpeed]['price'] ?
                                        <table className={getStyle("left-pull v-mid")}>
                                            <tbody>
                                            <tr className={getStyle("gray")}>
                                                <td className={getStyle("td-null")}><i className={getStyle("m-icon icon-rub border")} /></td>
                                                <td>К сожалению, у Вас недостаточно средств на балансе (не хватает {
                                                    Math.ceil((self.state.ways[abLength][self.state.selectSpeed]['price'] - balance) * 100) / 100
                                                } руб.)</td>
                                                <td className={getStyle("t-right")}>
                                                    {/*<button onClick={this.onNotEnoughMoneyButtonClick}>Пополнить счёт и подключить</button>*/}
                                                    <BalanceLink/>
                                                </td>
                                            </tr>
                                            </tbody>
                                        </table>
                                        : <ApplyTariff /> }
                                    </td>
                                </tr>
                                :null
                            ]
                        ))}
                        </tbody>
                    </table>
                ]: [
                    <div key="1"><h3>Услуга не найдена</h3></div>
                ]}
            </Panel>
        );
    }
}

function mapStateToProps(state) {
    return {
        authData: state.authenticate,
        servicesData: state.panelServices
    }
}

function mapDispatchToProps(dispatch) {
    return {
        serviceActions: bindActionCreators(panelServicesActions, dispatch),
        authenticate: bindActionCreators(authenticateActions, dispatch)
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(PanelApplyAbonement);
