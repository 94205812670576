/**
 * Created by dpopov on 19.07.2017.
 */
import React from 'react';
import ChapterBilling from './index';

import PanelPay from '../../Panels/PanelPay';

export default function pagePay(props) {
    const payment = props.match ? props.match.params.payType : 'card';
    const smallLink = props.forceNonRouteData ? props.smallLink : (props.match ? props.match.params.smallLink : props.smallLink);
    const title = props.title;
    const prioritetBalance = props.prioritetBalance?props.prioritetBalance:false;

    return (
        <ChapterBilling smallLink={smallLink}>
            <PanelPay paymentSelected={payment} smallLink={smallLink} title={title} prioritetBalance={prioritetBalance} isFrame={false} noPrefixLinks={props.forceNonRouteData} forceState={props.originalState} />
        </ChapterBilling>
    );
}

export function framePay(props) {

    const payment = props.match ? props.match.params.payType : 'card';
    const smallLink = props.smallLink ? props.smallLink : (props.match ? props.match.params.smallLink : props.smallLink);
    const title = props.title;

    let newProps = Object.assign({authenticate: props.authData}, props);

    return (
        <ChapterBilling smallLink={true}>
            <PanelPay paymentSelected={payment} smallLink={smallLink} noPrefixLinks={smallLink} title={title} isFrame={true} forceState={newProps} />
        </ChapterBilling>
    );
}