/**
 * Панель смены пароля
 *
 * Created by dpopov on 14.09.2017.
 */
/*eslint eqeqeq: ["off", "smart"]*/
import React from 'react';
import axios from 'axios';

import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import Panel from '../../Components/Panel';
import { getStyle } from  '../../tools';

import * as CONST from '../../const';
import * as authenticateActions from '../../Authenticate/actions';

import myStyle from './style.module.css';

class PanelPassword extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            oldPassword: "",
            newPassword: "",
            confirmNewPassword: "",
            loadingState: false,
            statusText: "",
            statusTextType: 0                   // 0 - не показывать, 1 - успешно, 2 - ошибка
        };

        this.onPasswordInput = this.onPasswordInput.bind(this);
        this.updatePassword = this.updatePassword.bind(this);
        this.hideNotify = this.hideNotify.bind(this);
    }

    updatePassword(event) {
        if(event) event.preventDefault();
        const api = this.props.apiPasswordUpdate || '/auth/changePassword';

        const self = this;
        const contract = self.props.authData.activeContract;

        self.setState({loadingState: true});
        if(typeof self.props.onRequestBegin === 'function') self.props.onRequestBegin();

        axios.get(CONST.API + api, {
            withCredentials: true,
            params: {
                contract: contract,
                old: self.state.oldPassword,
                new: self.state.newPassword,
                newConfirm: self.state.confirmNewPassword,
		'.rnd': Math.ceil(Math.random()*1000000)
            }
        }).then(function(answer) {
            const data = answer.data;
            if(data.result === 'success') {
                self.setState({ loadingState: false, statusTextType: 1, statusText: "", oldPassword: "", newPassword: "", confirmNewPassword: "" });
                if(typeof self.props.onPasswordUpdated === 'function') self.props.onPasswordUpdated(data);
            } else {
                self.setState({ loadingState: false, statusTextType: 2, statusText: data.text });
                if(typeof self.props.onError === 'function') self.props.onError(data);
            }
        });
    }

    onPasswordInput(event) {
        const element = event.target;

        let newState = {};
        newState[element.getAttribute('name')] = element.value;

        this.setState(newState);
    }

    hideNotify(event) {
        event.preventDefault();

        this.setState({ statusTextType: 0, statusText: ""});
    }

    render() {
        const icon = this.props.icon || getStyle('icon-sett');
        const title = this.props.title || "Смена пароля";
        const asFrame = this.props.asFrame || false;
        const askOldPassword = typeof this.props.askOldPassword === 'undefined' ? true : this.props.askOldPassword;

        const loadingState = this.state.loadingState;
        const buttonSaveDisabled = ((this.state.newPassword != this.state.confirmNewPassword) || (this.state.newPassword.length < 6)) || (this.state.oldPassword == "" && askOldPassword);

        let notify = {
            text: null,
            style: [ getStyle('top-notify'), myStyle['center'], myStyle['panel-password'] ],
            textStyle: [getStyle('notify-message'), myStyle['inline'], this.state.statusTextType == 1 ? myStyle['success']: myStyle['error']],
            closeClick: this.hideNotify,
            closeStyle: [ getStyle("button btn-close inv"), myStyle['btn-close'] ]
        };

        if(this.state.statusTextType) notify.text = this.state.statusTextType == 1 ? "пароль был успешно обновлён" : this.state.statusText;

        const Frame = (
            <table className={getStyle('left-pull form-table')}>
                <tbody>
                {askOldPassword ?
                    <tr>
                        <td className={getStyle('t-nowrap td-null')}>Старый пароль</td>
                        <td><input type="password" name="oldPassword" onChange={this.onPasswordInput} value={this.state.oldPassword} /></td>
                    </tr>
                : null }
                <tr>
                    <td className={getStyle('t-nowrap td-null')}>Новый пароль</td>
                    <td>
                        <input type="password" name="newPassword" onChange={this.onPasswordInput} value={this.state.newPassword} />
                        { this.state.newPassword.length < 6 ?
                            <span className={getStyle('aside-caption color-main')}>Не менее 6 символов</span>
                            : null }
                    </td>
                </tr>
                <tr>
                    <td className={getStyle('t-nowrap td-null')}>Новый пароль еще раз</td>
                    <td>
                        <input type="password" name="confirmNewPassword" onChange={this.onPasswordInput} value={this.state.confirmNewPassword} />
                        { this.state.newPassword != this.state.confirmNewPassword ?
                            <span className={getStyle('aside-caption color-main')}>Пароли не совпадают</span>
                            : null }
                    </td>
                </tr>
                <tr>
                    <td className={getStyle('t-nowrap td-null')}>&nbsp;</td>
                    <td><button className={[getStyle('btn-major'), buttonSaveDisabled?myStyle['disabled']:null].join(' ')} disabled={buttonSaveDisabled} onClick={this.updatePassword}>Сохранить</button></td>
                </tr>
                </tbody>
            </table>
        );

        if(asFrame) return Frame;

        return (
            <Panel icon={icon} title={title} isLoading={loadingState}
                   notifyText={notify.text} notifyStyle={notify.style.join(' ')} notifyTextStyle={notify.textStyle.join(' ')}
                   notifyCloseClick={notify.closeClick} notifyCloseStyle={notify.closeStyle.join(' ')}
            >
                {Frame}
            </Panel>
        );
    }
}

function mapStateToProps(state) {
    return {
        authData: state.authenticate
    }
}

function mapDispatchToProps(dispatch) {
    return {
        authActions: bindActionCreators(authenticateActions, dispatch)
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(PanelPassword);

