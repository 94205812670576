/**
 * Created by dpopov on 12.07.2017.
 */
import React from 'react';
import { getStyle } from "../../tools";

const Row = (props) => {
    const rowStyles = [props.noRow?'':getStyle('row')].concat(props.className);

    return (
        <div className={rowStyles.join(' ')}>
            <div className={getStyle('wrap')}>
                {props.children}
            </div>
        </div>
    )
};

export default Row;
