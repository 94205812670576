/**
 * Created by dpopov on 19.07.2017.
 */
import React from 'react';
import { connect } from 'react-redux';

import * as CONST from '../../const';

import SubNavBlock from '../../Components/SubNavBlock';

import { Route } from 'react-router-dom';
import CheckAccess from '../../Authenticate';

import PageBilling from './pageBilling';
import PageBillingHistory from './pageBillingHistory';
import PageBillingBonus from './pageBillingBonus';
import PageBillingSettings from './pageBillingSettings'
import PageBillingHistoryWriteOffofFunds from './pageBillingHistoryWriteOffofFunds';
import PageBillingOperations from './pageBiliingOperations';
import PageResultPay from './PageResultPay';

export const BillingRoutes = [
    <Route key="ChapterBilling1" path={CONST.ROOT_PATH + "/billing.html"} component={CheckAccess(PageBilling, CONST.ACCESS.READ, true)} />,
    <Route key="ChapterBilling2" path={CONST.ROOT_PATH + "/billing.:payType.html"} component={CheckAccess(PageBilling, CONST.ACCESS.READ, true)} />,
    <Route key="ChapterBilling1sl" path={CONST.ROOT_PATH + "/:smallLink/billing.html"} component={CheckAccess(PageBilling, CONST.ACCESS.READ | CONST.ACCESS.PAY_SMALL_LINK, true)} />,
    <Route key="ChapterBilling2sl" path={CONST.ROOT_PATH + "/:smallLink/billing.:payType.html"} component={CheckAccess(PageBilling, CONST.ACCESS.READ | CONST.ACCESS.PAY_SMALL_LINK, true)} />,
    <Route key="ChapterBilling3" path={CONST.ROOT_PATH + "/billing-history.html"} component={CheckAccess(PageBillingHistory, CONST.ACCESS.READ, true)} />,
    <Route key="ChapterBilling4" path={CONST.ROOT_PATH + "/billing-bonus.html"} component={CheckAccess(PageBillingBonus, CONST.ACCESS.READ, true)} />,
    <Route key="ChapterBilling5" path={CONST.ROOT_PATH + "/billing-setting.html"} component={CheckAccess(PageBillingSettings, CONST.ACCESS.READ, true)} />,
    <Route key="ChapterBilling6" path={CONST.ROOT_PATH + "/billing-charges.html"} component={CheckAccess(PageBillingHistoryWriteOffofFunds, CONST.ACCESS.READ, true)} />,
    <Route key="ChapterBilling7" path={CONST.ROOT_PATH + '/billing-operations.html'} component={CheckAccess(PageBillingOperations, CONST.ACCESS.READ, true)} />,
    <Route key="ChapterBilling8" path={CONST.ROOT_PATH + "/billing-pay.:resultPay.html"} component={CheckAccess(PageResultPay, CONST.ACCESS.READ, true)} />,
];

// <Route path="/billing.html" component={PageBilling} />


class ChapterBilling extends React.Component {
    render() {
        let menuItems = {};
        menuItems[CONST.ROOT_PATH + '/billing.html'] = 'Пополнение счёта';
        //menuItems[CONST.ROOT_PATH + '/billing-history.html'] = 'История платежей';
        //menuItems[CONST.ROOT_PATH + '/billing-history.html'] = 'Платежи';
        //menuItems[CONST.ROOT_PATH + '/billing-charges.html'] = 'Начисления';
        menuItems[CONST.ROOT_PATH + '/billing-operations.html'] = 'История операций';
        menuItems[CONST.ROOT_PATH + '/billing-bonus.html'] = 'Бонус за друзей';
        menuItems[CONST.ROOT_PATH + '/billing-setting.html'] = 'Приостановка обслуживания';

        return (
            <div>
                {!this.props.smallLink ?
                    <SubNavBlock title="Баланс" items={menuItems}/>
                : null }
                {this.props.children}
            </div>
        )
    }

}

function mapStateToProps(state) {
    return {
//        services: state.panelServices
//        authData: state.authenticate,
//        servicesData: state.panelServices
    }
}

function mapDispatchToProps(dispatch) {
    return {
//        elementActions: bindActionCreators(SubNavBlock, dispatch),
//        authenticate: bindActionCreators(SubNavBlock, dispatch)
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ChapterBilling);
