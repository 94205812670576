import React from 'react';
import {connect, Provider} from 'react-redux';
import Panel from "../../../Components/Panel";
import { getStyle } from "../../../tools";
import axios from "axios";
import * as CONST from "../../../const";
import myStyle from "../../../Panels/PanelPay/style.module.css";
import PopUp from "../../../Components/Panel/PopUp";
import {ConnectedRouter} from "connected-react-router";
import {Route, Switch} from "react-router-dom";
import Page404 from "../../../Pages/Page404";
import { framePay as FrameBilling } from "../../ChapterBilling/pageBilling";
import configureStore, {configureHistory} from "../../../.redux/controlStore.pay-frame";

function homeRedirect() {
    document.location = '/';

}

class SLink_OneClickSubscription extends React.Component {

    constructor(init) {

        super(init);

        this.state = {

            displayFramePay: false,
            disableButtonConnectTariff : true,

            noMoney : false,
            topAmount: 0,
            isAb: false,
            switchDate: null,
            contract: null,
            service: null,

            eventResultChangeTariff:null,

        }
    }

    componentWillMount() {

        let sendData = new FormData();

        sendData.append('slink', this.props.link);
        sendData.append('tariffs',this.props.tariffs);

        axios.post(CONST.API + '/staticPages/getDataForTargetTariff', sendData, {
            withCredentials: true
        }).then((answer) => {
            if(answer.data.result === 'error') document.location.href = '/';
            let data = answer.data.data;

            if (data['targetTariffAb'] * parseFloat(data.targetTariff['AmountDefault']) > parseFloat(data.balance)) {

                let topAmount =  data['targetTariffAb']
                    ? (data['targetTariffAb'] * parseFloat(data.targetTariff['AmountDefault']) - parseFloat(data.balance)).toFixed(2)
                    : (parseFloat(data.targetTariff['AmountDefault']) - parseFloat(data.balance)).toFixed(2);

                this.setState({
                    noMoney: true,
                    topAmount: topAmount,
                    balance:  data.balance,
                    contract: data.contract,
                    ai_mails: data.ai_mails,
                    ai_phones: data.ai_phones,
                    service: data.service,
                    hID: data['HCR']['hID'],
                });
            }else {
                this.setState({
                    switchDate: data.switchDate,
                    disableButtonConnectTariff: false,
                    contract: data.contract,
                    service: data.service,
                    balance:  data.balance,
                    ai_mails: data.ai_mails,
                    ai_phones: data.ai_phones,
                    hID: data['HCR']['hID'],
                })
            }
        });
    }

    onClickConnectTariff = () => {

        let sendData = new FormData();

        sendData.append('tariffs',this.props.tariffs);
        sendData.append('contract',this.state.contract);
        sendData.append('service',this.state.service);
        sendData.append('slink',this.props.link);

        axios.post(CONST.API + '/lkcontract/applyTariffForMonth', sendData, {
            withCredentials: true
        }).then((answer) => {
            this.setState({
                eventResultChangeTariff: answer,
            });
            setTimeout(homeRedirect, 5000);
        });
    };

    onClickTopUpBalance = () => {

        // Проведём регистрацю БМ, для автоматического подключения
        axios.get(CONST.API + '/services/registerAbonementAutoApply', {
            withCredentials: true,
            params: {
                contract: this.state.contract,
                tariff: this.props.tariffs[0],
                serviceId: this.state.hID,
                slink: this.props.link
            }
        });

        this.setState({displayFramePay: true})

    };

    render() {
        const meta = this.props.meta;
        let store = configureStore();
        let history = configureHistory(store);
        let date = new Date();
        date.setTime(this.state.switchDate*1000);
        let
            month = parseInt(date.getMonth())+1,
            day = parseInt(date.getDate()),
            outputDate = (day >= 10 ? day : '0' + day) + '.' + (month >= 10 ? month : '0' + month) + '.' + date.getFullYear() + ' '+ date.getHours()+':'+(date.getMinutes()<10?'0':'') + date.getMinutes();

        let newProps = {...this.props};
        newProps.smallLink = this.props.link;
        newProps.authData.activeContract = newProps.contract;
        newProps.authData.contracts = {[newProps.contract]: this.state.balance };
        if(!newProps.authData.ai_phones.length && this.state.ai_phones) newProps.authData.ai_phones = this.state.ai_phones;
        if(!newProps.authData.ai_mails.length && this.state.ai_mails) newProps.authData.ai_mails = this.state.ai_mails ;

        return (

            <Panel title={meta[0]} >
                {this.state.displayFramePay ?
                    <PopUp onCancel={() => this.setState({displayFramePay: false}) }   title="Пополнение счета" blockStyle={myStyle['popup-block-my']}>
                        <Provider store={store}>
                            <ConnectedRouter history={history}>
                                <Switch>
                                    <Route exact path="/" render={(p) => <FrameBilling  {...p} {...newProps} smallLink={this.props.link} topAmount={this.state.topAmount}/>}/>
                                    <Route exact path="/billing.:payType.html" render={(p) => <FrameBilling {...p} {...newProps} smallLink={this.props.link} topAmount={this.state.topAmount}/>}/>
                                    <Route exact path={CONST.ROOT_PATH + "/billing.:payType.html"} render={(p) => <FrameBilling {...p} topAmount={this.state.topAmount} {...newProps} smallLink={this.props.link} />}/>
                                    <Route component={Page404}/>
                                </Switch>
                            </ConnectedRouter>
                        </Provider>
                    </PopUp>
                    : null}
                {this.state.eventResultChangeTariff
                    ?
                    <div className={getStyle('b-row')}>
                        <div  className={getStyle('t-center')}>
                            <div dangerouslySetInnerHTML={{__html: meta[2] || 'Благодарим за подключение!'}} />
                        </div>
                    </div>
                    :
                    <div>
                        <div className={getStyle('b-row b-border')}>
                            <div dangerouslySetInnerHTML={{__html: meta[1]}} />
                        </div>
                        <div className={getStyle('b-row')}>
                            {this.state.noMoney && this.state.topAmount
                                ?
                                <div  className={getStyle('t-center')}>
                                    <p>Недостаточно средств. Пополните баланс на {this.state.topAmount}</p>
                                        <button
                                            className={[getStyle(this.state.disableButtonConnectTariff ? 'btn-major' : ''), myStyle['btn-one-click']].join(' ')}
                                            onClick={this.onClickTopUpBalance}>
                                            Пополнить
                                        </button>

                                    </div>
                                :
                                this.props.tariffs.map( (tariff, index) =>
                                    <div key={index} className={getStyle('t-center')}>

                                        <p>Возможная дата подключения: {outputDate}</p>
                                        <button
                                            className={[getStyle(this.state.disableButtonConnectTariff ? 'btn-major' : ''), myStyle['btn-one-click']].join(' ')}
                                            onClick={this.onClickConnectTariff}
                                            disabled={this.state.disableButtonConnectTariff || this.state.noMoney}>
                                            Подключить
                                        </button>
                                    </div>
                                )}
                        </div>
                    </div>
                }
            </Panel>
        );
    }
}

function mapStateToProps(state) {
    return {
        authData: state.authenticate,
    }
}



export default connect(mapStateToProps)(SLink_OneClickSubscription);