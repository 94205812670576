import * as CONST from '../../../const';
import axios from 'axios';
import { createPayloadFromAnswer } from '../../../Authenticate/actions';

export function checkStatus(params) {
    return (dispatch, getState, args) => {

        if(params) params['.rnd'] = Math.ceil(Math.random()*1000000);
        else params = { '.rnd': Math.ceil(Math.random()*1000000) };

        axios.get(CONST.API+"/auth/status", {
            withCredentials: true,
            params: params
        }).then(function(result) {
            const payload = createPayloadFromAnswer(result.data || {});
            dispatch({type: CONST.AUTHENTICATE.SET_USER_INFO, payload: payload});
            if(!payload.access) dispatch({type: CONST.AUTHENTICATE.EMPTY_ACTIVE_CONTRACT, payload: null });
        }).catch(function (error) {
            // Произошла обшибка. Повторим запрос спустя некоторое время
            // console.log('error', error);
            // setTimeout(function() {
            //     requestCurrentUser()(dispatch, getState, args);
            // }, 3000);
        });
    }

}