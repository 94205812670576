import React from 'react';
import '../../forceStyle.css';
import {getStyle} from "../../../../tools";
import 'moment/locale/ru';
import myStyle from "../../style.module.css";
import moment from 'moment';
import DatePicker, { registerLocale } from "react-datepicker/es";
import ru from 'date-fns/locale/ru';
import Select from 'react-select';


registerLocale('ru-RU', ru);
moment.locale('ru');

class HotelFilter extends React.Component{

    state = {
        selectedOption: null,
    };

    render(){
        const { selectedOption } = this.state;

        return (
            <div className={getStyle('b-row')}>
                <div className={getStyle('f-left')}>
                    <h3>Фильтр</h3>
                    <form onSubmit={this.props.submitFilter}>
                        <table className={getStyle('form-table')}>
                            <tbody>
                            <tr>
                                <td>Фамилия гостя</td>
                                <td><input type="text" name='guestName' className={getStyle('g8-4')} onChange={(event)=>this.props.onChangeDataFilter(event.target.name, event.target.value )} value={this.props.data.guestName}/></td>
                            </tr>
                            <tr>
                                <td>Номер брони</td>
                                <td><input type="text" name='pmsRegNum' className={getStyle('g8-2')} onChange={(event)=>this.props.onChangeDataFilter(event.target.name, event.target.value )} value={this.props.data.pmsRegNum}/></td>
                                <td>Номер комнаты</td>
                                <td>
                                    {this.props.listRoom.length ?
                                        <Select
                                            value={selectedOption}
                                            className={myStyle['w-100']}
                                            placeholder=' '
                                            onChange={selectedOption=>{this.setState({selectedOption}); this.props.onChangeDataFilter('roomNumber', selectedOption.value )}}
                                            options={this.props.listRoom}
                                        />
                                        :<input type="text" name='roomNumber' className={getStyle('g8-2')} onChange={(event)=>this.props.onChangeDataFilter(event.target.name, event.target.value )} value={this.props.data.roomNumber}/>
                                    }                                </td>
                            </tr>
                            <tr>
                                <td>Период с</td>
                                <td>

                                    <DatePicker
                                        onSelect={(d)=>{this.props.onChangeDataFilter('timeIn', d)}}
                                        onChange={(d)=>{this.props.onChangeDataFilter('timeIn', d)}}
                                        dateFormat="dd.MM.yyyy HH:mm"
                                        showTimeSelect
                                        locale={'ru-RU'}
                                        timeFormat="HH:mm"
                                        selected={this.props.data.timeIn}
                                        timeCaption="Время"
                                    />
                                </td>
                                <td>по</td>
                                <td>
                                    <DatePicker
                                        onSelect={(d)=>{this.props.onChangeDataFilter('timeOut', d)}}
                                        onChange={(d)=>{this.props.onChangeDataFilter('timeOut', d)}}
                                        dateFormat="dd.MM.yyyy HH:mm"
                                        locale={'ru-RU'}
                                        timeFormat="HH:mm"
                                        timeCaption="Время"
                                        showTimeSelect
                                        selected={this.props.data.timeOut}
                                    />
                                </td>
                            </tr>
                            <tr>
                                <td>Только активные</td>
                                <td><input type="checkbox" onChange={()=>{this.props.onChangeDataFilter('filterActive',!(this.props.data.filterActive))}} checked={this.props.data.filterActive} name='filterActive' className={getStyle('g8-2')} /></td>
                            </tr>

                            </tbody>
                        </table>
                        <div className={getStyle('f-right')}>
                            <input type="submit" className={getStyle('btn-major')} value="Применить"/>
                            <button onClick={()=>{this.props.onClearDataFilter(()=>{this.setState({selectedOption:null});})}}>Сбросить</button>
                        </div>
                    </form>
                </div>
            </div>
        )
    }
}

export default HotelFilter;