import React from 'react';
import { PAYMENTS } from "./index";
import PayByCard from './payCardInterface';
import PayByQiwi from './payQiwiInterface';
import PayByYandex from './payYandexInterface';
import PayByWM from './payWMInterface';
import PayBySMS from './paySMSInterface';
import PayByCheck from './payCheckInterface';
import PayByGoogle from './payGoogle';
import PayBySberbank from './Sberbank'

const PaymentInterface = function (props) {
    const { paymentSelected, ...passProps } = props;

    switch (paymentSelected) {
        case PAYMENTS.card: return <PayByCard {...passProps} />;
        case PAYMENTS.qiwi: return <PayByQiwi {...passProps} />;
        case PAYMENTS.yandex: return <PayByYandex {...passProps} />;
        case PAYMENTS.wm: return <PayByWM {...passProps} />;
        case PAYMENTS.sms: return <PayBySMS {...passProps} />;
        case PAYMENTS.check: return <PayByCheck {...passProps} />;
        case PAYMENTS.google: return <PayByGoogle {...passProps} />;
        case PAYMENTS.sber: return <PayBySberbank {...passProps} />;
        default: return <div/>;
    }
};

export default PaymentInterface;