export const REDUX = {
    INITIALISE_PROCESS: 'GPAY_INITIALISE_PROCESS',
    INITIALISE_RESULT: 'GPAY_INITIALISE_RESULT',
    INITIALISE_ERROR: 'GPAY_INITIALISE_ERROR',
};

export const GOOGLE_PAY_SDK = 'https://pay.google.com/gp/p/js/pay.js';
export const GOOGLE_PAY_ENVIRONMENT = 'TEST';

export const merchantInfo = {
    // @todo a merchant ID is available for a production environment after approval by Google
    // See {@link https://developers.google.com/pay/api/web/guides/test-and-deploy/integration-checklist|Integration checklist}
    // merchantId: '01234567890123456789',
    merchantName: 'OOO OBIT'
};

export const baseRequest = {
    apiVersion: 2,
    apiVersionMinor: 0,
};

const tokenizationSpecification = {
    type: 'PAYMENT_GATEWAY',
    parameters: {
        'gateway': 'sberbank',
        'gatewayMerchantId': 'obit'
    }
};

const allowedCardNetworks = ["MASTERCARD", "VISA"];
const allowedCardAuthMethods = ["PAN_ONLY", "CRYPTOGRAM_3DS"];

export const baseCardPaymentMethod = {
    type: 'CARD',
    parameters: {
        allowedAuthMethods: allowedCardAuthMethods,
        allowedCardNetworks: allowedCardNetworks
    }
};

export const cardPaymentMethod = Object.assign(
    {tokenizationSpecification: tokenizationSpecification},
    baseCardPaymentMethod
);
