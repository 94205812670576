/**
 * Created by dpopov on 10.10.2017.
 */
import React from 'react';

import ChapterHelp from './index';

import TicketsList from '../../Panels/PanelSupport/PanelTickets';
import SupportForm from '../../Panels/PanelSupport';
import ChatPage from '../../Panels/PanelSupport/PanelChat';

import {getStyle} from '../../tools';
import * as obitStyle from '../../../assets/css/obit.module.css';

export default (props) => {
    var ticket;
    var crm;
     if (props.match.params.id) {
         ticket = props.match.params.id.replace(/\D+/, "");
         crm = /c$/.test(props.match.params.id);
     }


    return (
        <ChapterHelp>
            {ticket ?
                <ChatPage ticket={ticket} crm={crm}/>
                :
                <div className={getStyle('row')}>
                    <div className={[getStyle('wrap'), obitStyle['support-order']].join(' ')}>
                        <TicketsList key="1"/>
                        <SupportForm key="2"/>
                    </div>
                </div>
            }
        </ChapterHelp>
    )
}