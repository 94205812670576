import React from 'react';
import { connect } from 'react-redux';

import { getServiceData } from "./tools";
import Panel from '../../Components/Panel';
import myStyle from './style.module.css';

import 'moment/locale/ru';
import moment from 'moment';
moment.locale('ru');

class FrameInternetSpeedBonus extends React.Component {
    constructor(props) {
        super(props);
        this.bonusApplied = moment().isAfter(moment('2018-10-01 00:00:00'));
        //this.myRef = React.createRef();
    }

    componentDidMount() {
        //const domNode = ReactDOM.findDOMNode(this.myRef.current);
        //console.log(this.myRef, domNode);
        //domNode.scrollIntoView();
        this.myRef.scrollIntoView();
        window.scrollTo(0, 0);
    }

    render() {
        const appServiceId = this.props.appServiceId;

        const servicesData = this.props.servicesData.services;

        const serviceData = getServiceData(servicesData, appServiceId);
        if(!serviceData) return null;

        const speed = serviceData.speed;
        const bonusSpeed = serviceData.bonusSpeed;
        const totalSpeed = speed + bonusSpeed;


        return (
            <Panel {...this.props}>
                <div className={[myStyle['inet-collect-speed-frame-container']].join(' ')} ref={r => {this.myRef = r}}>
                    <h3>Скорость Интернета{this.bonusApplied?'':<span> с&nbsp;1&nbsp;октября</span>}: {totalSpeed} Мбит/с</h3>
                    <div className={[myStyle['bonus-row']].join(' ')}>
                        <div className={[myStyle['column-item']].join(' ')}>
                            <h4>Тарифная: {speed}&nbsp;Мбит/с</h4>
                        </div>
                        <div className={[myStyle['column-item']].join(' ')}>
                            <h4>Бонусная: {bonusSpeed}&nbsp;Мбит/с</h4>
                        </div>
                    </div>
                </div>
            </Panel>
        );
    }
}

function mapStateToProps(state) {
    return {
        authData: state.authenticate,
        servicesData: state.panelServices
    }
}

function mapDispatchToProps(dispatch) {
    return {

    }
}

export default connect(mapStateToProps, mapDispatchToProps)(FrameInternetSpeedBonus);