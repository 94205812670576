/**
 * панель с услугами абонента
 *
 * Created by dpopov on 12.07.2017.
 */
/*eslint eqeqeq: ["off", "smart"]*/

import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Link } from 'react-router-dom';
import * as authenticateActions from '../../Authenticate/actions';
import * as panelServicesActions from './actions';
import * as CONST from '../../const';

import Panel from '../../Components/Panel';

import CheckRoster from './CheckRoster';

import styleObit from '../../../assets/css/obit.module.css';
import myStyle from './style.module.css';

import { PagesLinks } from '../../Chapters/ChapterServices';

import { getStyle } from '../../tools';

import BonusSpeedDisplay from './Components/bonusSpeedDisplay';
import TariffDescDisplay from './Components/tariffDescDisplay';

import 'moment/locale/ru';
import moment from 'moment';
moment.locale('ru');

class PanelServices extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            activeContract: props.authData.activeContract
        }
    }

    // componentWillMount() {
    //     const activeContract = this.props.authData.activeContract;
    //     if(!this.props.servicesData.services) {
    //         this.requestServicesData(activeContract);
    //         return;
    //     }
    //     if(!Object.keys(this.props.servicesData.services).length || this.props.forceReload) this.requestServicesData(activeContract);
    // }
    //
    // componentWillReceiveProps(nextProps) {
    //     const nextActiveContract = nextProps.authData.activeContract;
    //
    //     if(nextActiveContract != this.state.activeContract) {
    //         this.requestServicesData(nextActiveContract);
    //         return;
    //     }
    //
    //     if(nextProps.authData.rosterLastChange.isAfter(nextProps.servicesData.lastRosterDataReceived) && nextProps.servicesData.panelLoadingState === false) {
    //         this.requestServicesData(nextActiveContract);
    //         return;
    //     }
    // }
    //
    // requestServicesData(contract) {
    //     this.setState({activeContract: contract});
    //     this.props.elementActions.requestServicesList(contract);
    // }

    render() {
        const panelTitle = (this.props.title || 'Сводка по вашим услугам'); // + ' ' + this.state.activeContract;
        const loadingState = this.props.servicesData.panelLoadingState;
//        const services = this.props.servicesData.services;
        const servicesTree = this.props.servicesData.servicesTree;
        const isError = this.props.servicesData.error;
        const errorText = this.props.servicesData.errorText;
        const panelIcon = this.props.icon || getStyle('icon-list');
        const showServiceTypeIcon = this.props.showServiceTypeIcon || false;

        const servicesFilter = this.props.servicesFilter;

        let content = null;
        let monthCost = 0;

        if(!loadingState) {
            const today = moment();

            let servicesElems = [];
            if(true) for(let i=0; i<servicesTree.length; i++) {
                const App = servicesTree[i];
                const AppServicesIDs = Object.keys(App.services);
                const AppStopDate = App.dateStop ? moment(App.dateStop) : null;
                const AppStartDate = App.dateStart ? moment(App.dateStart) : null;
                // Идём по услугам
                for(let appServiceID_i = 0; appServiceID_i < AppServicesIDs.length; appServiceID_i++) {
                    const AppServiceID = AppServicesIDs[appServiceID_i];
                    const hcrList = App.services[AppServiceID];
                    let futureRule = false;

                    let hcrItem = hcrList;
                    let activeHCR = null;

                    let skipIt = false;

                    // Ищем действующее в данный момент правило
                    while (hcrItem) {
                        if(servicesFilter) {
                            // У нас есть фильтр по услугам
                            if(servicesFilter == 'inet' && !(hcrItem.Type & CONST.SERVICE_TYPES.INET)) { skipIt = true; break; }
                            if(servicesFilter == 'phone' && !(hcrItem.Type & CONST.SERVICE_TYPES.PHONE)) { skipIt = true; break; }
                            if(servicesFilter == 'tv' && !(hcrItem.Type & CONST.SERVICE_TYPES.TV)) { skipIt = true; break; }
                        }

                        if(today.isBetween(hcrItem.dateStart, hcrItem.dateStop)) {
                            // Нашли активное правило
                            activeHCR = hcrItem;
                            break;
                        }

                        hcrItem = hcrItem.__next;
                    }

                    if(skipIt) continue;

                    if(!activeHCR && hcrList && today.isBefore(hcrList.dateStop)) {
                        activeHCR = hcrList;
                        futureRule = today.isBefore(hcrList.dateStart);
                    }

                    if(activeHCR) {
                        const awaitConnect = activeHCR.dateStart.isSame('2030-01-01 00:00:00');

                        if(!activeHCR['isAb'] && !awaitConnect) monthCost += Math.ceil(activeHCR['cost']*100)/100;

                        let link = '';
                        if(servicesFilter) {
                            // Если есть фильтр по услугам, то ссылку будем форсить (для пакетов)
                            if(servicesFilter === 'inet') link = PagesLinks.inet;
                            if(servicesFilter === 'phone') link = PagesLinks.phone;
                            if(servicesFilter === 'tv') link = PagesLinks.tv;
                        } else {
                            if (activeHCR['Type'] & CONST.SERVICE_TYPES.INET) link = PagesLinks.inet;
                            if (activeHCR['Type'] & CONST.SERVICE_TYPES.PHONE) link = PagesLinks.phone;
                            if (activeHCR['Type'] & CONST.SERVICE_TYPES.TV) link = PagesLinks.tv;
                        }
                        //link = link.replace(/html$/, activeHCR.id+'.html');
                        link = link.replace(/html$/, AppServiceID+'.html');

                        servicesElems[AppServiceID] = (
                            <tr key={activeHCR.id}>
                                {showServiceTypeIcon ?
                                    <td className={getStyle('td-mob-collapse')}>
                                        <div className={
                                            activeHCR['Type'] & CONST.SERVICE_TYPES.INET ? getStyle('m-icon icon-wifi') :
                                            activeHCR['Type'] & CONST.SERVICE_TYPES.PHONE ? getStyle('m-icon icon-phone') :
                                            activeHCR['Type'] & CONST.SERVICE_TYPES.TV ? getStyle('m-icon icon-tv') :
                                            null
                                        }></div>
                                    </td>
                                    : null}
                                <td className={getStyle('td-mob-collapse')}>
                                    {activeHCR['name']}
                                    {activeHCR['tariff'] == 380 ?
                                    ' до '+ activeHCR.dateStop.format('D MMMM YYYY')
                                        :null}
                                    {activeHCR.isStop ?
                                        <div className={myStyle['rule-stopped']}>
                                            {activeHCR.__next ? activeHCR.__next.name :
                                                activeHCR.__prev ? activeHCR.__prev.name :
                                                    null
                                            }
                                        </div>
                                    : null}

                                    { !activeHCR.isStop && (activeHCR['Type'] & CONST.SERVICE_TYPES.INET) && activeHCR['anons'] ?
                                        <TariffDescDisplay anons={activeHCR['anons']} ruleId={activeHCR['tariff']} hasDesc={activeHCR['hasDesc']} />
                                    : null }

                                    { !activeHCR.isStop && (activeHCR['Type'] & CONST.SERVICE_TYPES.INET) && (activeHCR['bonusSpeed'] - activeHCR['temporaryBonusSpeed'] > 0) ?
                                        <BonusSpeedDisplay bonusSpeed={activeHCR['bonusSpeed'] - activeHCR['temporaryBonusSpeed']} ruleId={activeHCR['id']} />
                                    : null }

                                    { !activeHCR.isStop && (activeHCR['Type'] & CONST.SERVICE_TYPES.INET) && activeHCR['temporaryBonusSpeed'] > 0 && activeHCR['bonusSpeedPeriods'] ?
                                        activeHCR['bonusSpeedPeriods'].map((item) => {
                                            switch (item.PeriodReason) {
                                                case 'bonus_virusCvartirus':
                                                    return <BonusSpeedDisplay key={item['PeriodReason']} bonusSpeed={item['BonusSpeed']} ruleId={activeHCR['id']}
                                                                              hideLink={false} aboutExternalLink={"https://www.obit.ru/upload/docs/akciya_sidim_doma.pdf"}
                                                                              beforeText={""} afterText={"Double Speed"}
                                                                              aboutText={"Действует до "+moment(item['PeriodTo']).format('D MMMM YYYY')}
                                                    />;
                                                default:
                                                    return <BonusSpeedDisplay key={item['PeriodReason']} bonusSpeed={item['BonusSpeed']} ruleId={activeHCR['id']}
                                                                             hideLink={true} aboutText={"Действует до "+moment(item['PeriodTo']).format('D MMMM YYYY')}
                                                                              beforeText={""} afterText={item['PeriodNote']}
                                                    />;
                                            }
                                        })
                                    : null}

                                    {AppStartDate && today.isBefore(AppStartDate) ?
                                        <div className={myStyle['rule-stopped']}>Услуга будет подключена с {AppStartDate.format('D MMMM YYYY')}</div>
                                    : null }
                                    {AppStopDate ?
                                        <div className={myStyle['rule-stopped']}>Услуга будет отключена {AppStopDate.format('D MMMM YYYY')}</div>
                                    : null}
                                </td>
                                <td className={getStyle('td-mob-collapse')}>
                                    {awaitConnect ?
                                        <div>Ожидает подключения</div>
                                     : futureRule ?
                                        <div>с {activeHCR.dateStart.format('D MMMM YYYY')}</div>
                                    : null}
                                    {activeHCR['isAb'] ?
                                        <div>до {activeHCR.dateStop.format('D MMMM YYYY')}</div>
                                        :
                                        awaitConnect ? null : <div><strong>{activeHCR['cost'] ?  Math.ceil(activeHCR['cost']*100)/100 : '0'}</strong> руб/мес</div>
                                    }
                                </td>
                                <td className={getStyle('td-mob-collapse t-right')}>
                                    { link ? <Link to={link} className={getStyle('button')}>Детали</Link> : null }
                                </td>
                            </tr>
                        );

                    }
                }
            }
/*
            if(false) for(let i=0; i<services.length; i++) {
                const service = services[i];

                if(service.dateStop) continue;

                // Выделим список услуг в спецификации
                const appServices = Object.keys(service.services);

                // Пройдёмся по услугам
                for(let asI=0; asI < appServices.length; asI++) {
                    const hcrList = service.services[appServices[asI]];

                    let activeHCR = null;
                    let plannedHCR = [];
                    let hasSpecialAbs = null;
                    let myTariffs = [];

                    for(let hcrI=0; hcrI < hcrList.length; hcrI++) {
                        const HCR = hcrList[hcrI];

                        if(servicesFilter) {
                            // У нас есть фильтр по услугам
                            if(servicesFilter == 'inet' && !(HCR.Type & CONST.SERVICE_TYPES.INET)) continue;
                            if(servicesFilter == 'phone' && !(HCR.Type & CONST.SERVICE_TYPES.PHONE)) continue;
                            if(servicesFilter == 'tv' && !(HCR.Type & CONST.SERVICE_TYPES.TV)) continue;
                        }

                        let hcrBegin = moment(HCR['dateStart']);
                        let hcrEnd = moment(HCR['dateStop']);

                        myTariffs.push(HCR.tariff);

                        if(today.isBetween(hcrBegin, hcrEnd)) {
                            // Активное правило
                            activeHCR = HCR;
                        } else if(today.isBefore(hcrBegin)) {
                            // Правило будет работать в будущем
                            plannedHCR.push(HCR);
                        }

                        if(HCR.specialAb && HCR.specialAb.length) {
                            if(!hasSpecialAbs) hasSpecialAbs = HCR;
                        }
                    }

                    if(activeHCR) {
                        if(!activeHCR['isAb']) monthCost += activeHCR['cost'];

                        var link = '';
                        if(activeHCR['Type'] & CONST.SERVICE_TYPES.INET) link = PagesLinks.inet;
                        if(activeHCR['Type'] & CONST.SERVICE_TYPES.PHONE) link = PagesLinks.phone;
                        if(activeHCR['Type'] & CONST.SERVICE_TYPES.TV) link = PagesLinks.tv;

                        link = link.replace(/html$/, activeHCR.id+'.html');

                        servicesElems[service.id] = (
                            <tr key={activeHCR.id}>
                                {showServiceTypeIcon ?
                                    <td className={getStyle('td-mob-collapse')}>
                                        <div className={
                                            activeHCR['Type'] & CONST.SERVICE_TYPES.INET ? getStyle('m-icon icon-wifi') :
                                            activeHCR['Type'] & CONST.SERVICE_TYPES.PHONE ? getStyle('m-icon icon-phone') :
                                            activeHCR['Type'] & CONST.SERVICE_TYPES.TV ? getStyle('m-icon icon-tv') :
                                            null
                                        }></div>
                                    </td>
                                : null}
                                <td className={[style['td-mob-collapse']].join(' ')}>
                                    {activeHCR['name']}
                                    {activeHCR['tariff'] == 380 ?
                                        ' до '+moment(activeHCR['dateStop']).format('D MMMM YYYY')
                                    :null}
                                </td>
                                <td className={[style['td-mob-collapse']].join(' ')}>
                                    {activeHCR['isAb'] ?
                                        <div>до {moment(activeHCR['dateStop']).format('D MMMM YYYY')}</div>
                                        :
                                        <div><strong>{activeHCR['cost']}</strong> руб/мес</div>
                                    }
                                </td>
                                <td className={[style['td-mob-collapse'], style['t-right']].join(' ')}>
                                    { link ? <Link to={link} className={style['button']}>Детали</Link> : null }
                                    { hasSpecialAbs ?
                                        hasSpecialAbs.specialAb.map((item, _i) => {
                                            return myTariffs.indexOf(item.tariff) === -1 ? (<div key={_i} className={myStyle['specialAbButton']}>
                                                <Link to={CONST.ROOT_PATH + '/service-applyab.'+hasSpecialAbs.id+'.html'} className={ getStyle('button') }>{item.specialName}</Link>
                                            </div>) : null
                                        })
                                    : null}
                                </td>
                            </tr>
                        );

                    }
                }
            }
*/

            content = isError ?
                (<div className={styleObit['errorContainer']}>
                    <div className={styleObit['errHead']}>Ошибка</div>
                    <div className={styleObit['errBody']}>{errorText}</div>
                </div>)
                :
                (
                    <div>
                        <table className={getStyle('line v-mid left-pull table-mob-collapse')}>
                            <tbody>
                            {servicesElems}
                            </tbody>
                            <tfoot>
                                <tr>
                                    {showServiceTypeIcon ? <td className={getStyle('td-mob-collapse')}></td> : null }
                                    <td className={getStyle('td-mob-collapse')}>Итого</td>
                                    <td className={getStyle('td-mob-collapse')}><strong>{monthCost}</strong> руб/мес</td>
                                    <td className={getStyle('td-mob-collapse t-right')}>
                                        <Link to="/order.html" className={[getStyle('button btn-major btn-big'), styleObit['hideme'] ].join(' ')}>Создать заявку на новую услугу</Link>
                                    </td>
                                </tr>
                            </tfoot>
                        </table>
                    </div>
                )
        }

        return (
            <CheckRoster from={"PanelServices"}>
                <Panel title={panelTitle} icon={panelIcon} isLoading={loadingState} className={myStyle['force-overflow-y-visible']}>
                    {content}
                </Panel>
            </CheckRoster>
        )
    }
}

function mapStateToProps(state) {
    return {
        authData: state.authenticate,
        servicesData: state.panelServices
    }
}

function mapDispatchToProps(dispatch) {
    return {
        elementActions: bindActionCreators(panelServicesActions, dispatch),
        authenticate: bindActionCreators(authenticateActions, dispatch)
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(PanelServices);