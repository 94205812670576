/**
 * Created by dpopov on 09.10.2017.
 */
import axios from 'axios';
import * as CONST from '../../const';

export function requestSettings(contract, force = 0) {
    return (dispatch, getState, args) => {
        dispatch({ type: CONST.PERSONAL_SETTINGS.REQUEST_DATA, payload: true });

        axios.get(CONST.API + '/contacts/getContacts', {
            withCredentials: true,
            params: {
                contract: contract,
                force: force,
		'.rnd': Math.ceil(Math.random()*1000000)
            }
        }).then(function(answer) {
            const data = answer.data;
            if(data.result === 'success') {
                dispatch({ type: CONST.PERSONAL_SETTINGS.SET_DATA, payload: { error: null, email: data.data.email, phone: data.data.phone }});
            } else {
                dispatch({ type: CONST.PERSONAL_SETTINGS.SET_DATA, payload: { error: data.text, data: {} }});
            }
        });
    }
}

