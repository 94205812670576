/**
 * Created by dpopov on 11.07.2017.
 */
/*eslint eqeqeq: ["off", "smart"]*/
/*eslint no-useless-concat: ["off"]*/
/*eslint array-callback-return: ["off"]*/
import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Link } from 'react-router-dom';
import ScrollLock from 'react-scrolllock';

import * as authenticateActions from './code/Authenticate/actions';
//import Row from './code/Row';

import Header from './code/Components/Header';
import Footer from './code/Components/Footer';

import AuthForm from './code/Authenticate/form';

//import style from './assets/css/main.css';
// import imgLogo from './assets/images/logo-main.png';
import Panel from './code/Components/Panel';
import PanelFirstLogin from './code/Panels/PanelFirstLogin';
import PanelChoosePrimaryPhone from './code/Panels/PanelChoosePrimaryPhone';

import Notify from './code/Components/Notify';

import * as CONST from './code/const';

import CheckSmallLink from './code/Authenticate/checkSmallLink';

import {getStyle, StaticPageDisplayContainer} from "./code/tools";
import axios from "axios";

class LKInterface extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            periodicalCheck: null,
            promoBackground: null,
            padding: 0,
            promoUrl: '',
            promoDesc: '',
            promoDivClass: ''
        };

        this.periodicalAuthCheck = this.periodicalAuthCheck.bind(this);
        this.periodicalBalanceUpdate = this.periodicalBalanceUpdate.bind(this);
        this.setDesc = this.setDesc.bind(this);
    }

    componentDidMount() {
        this.props.authenticate.requestCurrentUser();
           this.periodicalAuthCheck();
//        this.periodicalBalanceUpdate();
    }

    //shouldComponentUpdate(nextProps, nextState) {
    //    if(this.state != nextState) return true;
    //    if(this.props != nextProps) return true;
    //    return false;
    //}
    //
    //componentWillUpdate(nextProps, nextState) {
    //    console.log('update', 'LKInterface')
    //}

    setDesc(desc, contract) {
        const promoMatch = desc.match(/{promocode}/);

        if(promoMatch) {
            let self = this;

            axios.get(CONST.API + '/adv/getPersonalPromo', {
                withCredentials: true,
                params: {
                    contract: contract,
                }
            }).then((answer) => {
                const data = answer.data;
                let hide = false;

                if(data.result === 'success') {
                    Object.keys(data.data).map((key) => {
                        const promo = data.data[key];

                        if (promo.options && promo.options['hideDescription']){
                            hide = true;
                            self.setState({
                                promoDesc: ""
                            });
                            return null;
                        }
                    });
                }

                if(!hide) {
                    axios.get(CONST.API + '/staticPages/getPromoCode', {
                        withCredentials: true,
                        params: {
                            contract: contract,
                        }
                    }).then((answer) => {
                        const data = answer.data;
                        if(data.result === 'success') {

                            self.setState({
                                promoDesc: desc.replace(/{promocode}/, data.data)
                            });
                        } else {
                            //console.log('Error axios response', data.text)
                        }
                    })
                }

            })
        }else{
            this.setState({
                promoDesc: desc
            });
        }
    }

    componentWillReceiveProps(nextProps) {
//        console.log(nextProps);
//        console.log(this.context.router);
//        return;

        if(nextProps.state.authenticate.promoBackground && nextProps.state.authenticate.activeContract && !nextProps.state.authenticate.firstVKLogin) {
            //console.log(this.refs, nextProps.state.authenticate.promoBackground);
            if(this.state.promoBackground != nextProps.state.authenticate.promoBackground.image ||
                (nextProps.state.authenticate.promoBackground.description
                    && nextProps.state.authenticate.activeContract !== this.props.state.authenticate.activeContract)
            ) {

                if(nextProps.state.authenticate.promoBackground.description) {
                    this.setDesc(nextProps.state.authenticate.promoBackground.description, nextProps.state.authenticate.activeContract)
                }

                this.setState({
                    promoBackground: nextProps.state.authenticate.promoBackground.image,
                    promoUrl: nextProps.state.authenticate.promoBackground.url
                });

                if(nextProps.state.authenticate.promoBackground.image == null) {
                    // Очистим картинку
                    document.body.style.background = null;
                    this.setState({ padding: 0 })
                } else {
                    // Установим картинку
                    this.setState({ padding: nextProps.state.authenticate.promoBackground.padding });
                    const hash = nextProps.state.authenticate.promoBackground.hash;

                    if(nextProps.state.authenticate.promoBackground.style) {
                        let styleText = '.' + hash + ' {' + nextProps.state.authenticate.promoBackground.style + ' }';

                        if(nextProps.state.authenticate.promoBackground.divstyle) styleText += "\n" + '.' + hash + 'div {' + nextProps.state.authenticate.promoBackground.divstyle + ' }';

                        Object.keys(nextProps.state.authenticate.promoBackground).map((itemName) => {
                            const itemMatch = itemName.match(/^image(\d+)?$/);
                            if(itemMatch) {
                                let _dReg = new RegExp('(.*)'+CONST.ADV_DELIMITER_IMAGE+'(.*)','i');
                                let d = nextProps.state.authenticate.promoBackground[itemName].match(_dReg);
                                const isApiImage = nextProps.state.authenticate.promoBackground[itemName].match(/^api:(.*)/i);
                                let styleImageText = '';
                                if(d) {
                                    let urlsI = '';
                                    d[0].split(CONST.ADV_DELIMITER_IMAGE).map(function (_item) {
                                        urlsI += urlsI ? ',url(' :  'url(';
                                        urlsI += isApiImage ?
                                            CONST.API + '/adv/getBigAdvImage?n='+_item.split(':')[1]+'&bigadv=' + nextProps.state.authenticate.promoBackground.name + '&resolution=' + itemMatch[1]
                                            : nextProps.state.authenticate.promoBackground[_item];
                                        urlsI += ')';
                                    });
                                    styleImageText = "."+hash+" { background: "+ urlsI+" }";
                                }else {
                                    const image = isApiImage ?
                                        CONST.API + '/adv/getBigAdvImage?bigadv=' + nextProps.state.authenticate.promoBackground.name + '&resolution=' + itemMatch[1]
                                        : nextProps.state.authenticate.promoBackground[itemName];
                                    styleImageText = "."+hash+" { background-image: url('"+image+"'); }";
                                }


                                styleText += "\n";


                                if(nextProps.state.authenticate.promoBackground['style'+itemMatch[1]]) styleImageText += "\n."+hash+" { "+nextProps.state.authenticate.promoBackground['style'+itemMatch[1]]+" }";
                                if(nextProps.state.authenticate.promoBackground['divstyle'+itemMatch[1]]) styleImageText += "\n."+hash+"div { "+nextProps.state.authenticate.promoBackground['divstyle'+itemMatch[1]]+" }";

                                if(itemMatch[1]) {
                                    styleText += '@media screen and (max-width: '+itemMatch[1]+'px) { '+styleImageText+' }';
                                } else {
                                    styleText += styleImageText;
                                }
                            }
                        });

                        let styleElem = document.getElementById('promo-background');
                        if(!styleElem) {
                            styleElem = document.createElement('style');
                            styleElem.setAttribute('id', 'promo-background');
                            document.body.appendChild(styleElem);
                        }

                        //document.body.style.background = null;
                        styleElem.textContent = styleText;

                        document.body.classList.add(hash);
                        if(this.refs.promoDiv) this.refs.promoDiv.classList.add(hash+'div');
                        this.setState({promoDivClass: hash+'div'});
                    } else {

                        let image = nextProps.state.authenticate.promoBackground.image;
                        const isApiImage = image.match(/^api:(.*)/i);
                        if (isApiImage) {
                            image = CONST.API + '/adv/getBigAdvImage?bigadv=' + nextProps.state.authenticate.promoBackground.name;
                        }
                        document.body.classList.remove(hash);
                        if(this.refs.promoDiv) this.refs.promoDiv.remove(hash+'div');
                        this.setState({promoDivClass: ''});
                        document.body.style.backgroundImage = "url('" + image + "')";
                        document.body.style.backgroundPosition = "top center";
                        document.body.style.backgroundRepeat = "no-repeat";
                        document.body.style.backgroundColor = nextProps.state.authenticate.promoBackground.color;
                    }
                }
            }
        } else {
            // Очистим картинку
            this.setState({
                promoBackground: null,
                padding: 0,
                promoUrl: '',
                promoDesc: ''
            });
            document.body.style.background = null;
            let styleElem = document.getElementById('promo-background');
            if(styleElem) styleElem.textContent = null;
        }
    }

    periodicalBalanceUpdate() {
        if(this.props.state.authenticate.activeContract) this.props.authenticate.updateBalance(this.props.state.authenticate.activeContract);
        setTimeout(this.periodicalBalanceUpdate, 10000);
    }

    periodicalAuthCheck() {
        if(this.props.state.authenticate.activeContract) this.props.authenticate.requestCurrentUser(true, { activeContract: this.props.state.authenticate.activeContract });
        setTimeout(this.periodicalAuthCheck, 10000);
    }

//    shouldComponentUpdate(nextProps, nextState) {
//        console.log(nextProps);
//        return true;
//    }

    render222() {
        // const authInPorcess = this.props.state.authenticate ? this.props.state.authenticate.process : 1;
        // const allowed = this.props.state.authenticate ? this.props.state.authenticate.allowed : 0;

        //console.log(this.props);

        const style = {
            paddingTop: this.state.padding
        };

        let promoUrl =  this.state.promoUrl;


        return (
            <div id="lkInterface">
                <Notify />
                { promoUrl ?
                    promoUrl.match('/^internal:/') ?
                        <Link to={CONST.ROOT_PATH + promoUrl.replace(/^internal:/, '')}><div style={style}></div></Link>
                        :
                        <a href={promoUrl} target="_blank" rel="noopener noreferrer"><div style={style}></div></a>
                    :
                    this.state.padding ?
                        <div style={style}></div>
                        : null
                }

                <div className={getStyle('js')}>
                    <ScrollLock />
                    <Header />
                    {this.props.children}
                    <Footer />
                </div>

            </div>
        );
    }

    render() {
        const authInPorcess = this.props.state.authenticate ? this.props.state.authenticate.process : 1;
        const allowed = this.props.state.authenticate ? this.props.state.authenticate.allowed : 0;
        const firstVKLogin = this.props.state.authenticate ? this.props.state.authenticate.firstVKLogin : false;

        const firstLogin = this.props.state.authenticate ? (this.props.state.authenticate.firstLogin || this.props.state.authenticate.changePassword) : false;
        const ai_phones = this.props.state.authenticate && this.props.state.authenticate.ai_phones ? this.props.state.authenticate.ai_phones : [];

        const { error, error_text } = this.props.state.authenticate;
        //console.log(this.props);

        const styleFix = {
            paddingTop: this.state.padding
        };

        const StaticDesc = StaticPageDisplayContainer(this.state.promoDesc, "header-desc_text");

        return (
            <div id="lkInterface">
                <Notify />
                { this.state.promoUrl ?
                    this.state.promoUrl.match(/^internal:/) ?
                        <Link to={CONST.ROOT_PATH + this.state.promoUrl.replace(/^internal:/, '')}>
                            {this.state.promoDesc.length ?
                                <div style={styleFix} className={this.state.promoDivClass}>
                                    <div className="row">
                                        <div className="wrap header-desc">
                                            <StaticDesc />
                                        </div>
                                    </div>
                                </div>
                                :
                                <div style={styleFix} className={this.state.promoDivClass} />
                            }

                        </Link>
                        :
                        <a href={this.state.promoUrl} target="_blank" rel="noopener noreferrer"><div style={styleFix} className={this.state.promoDivClass} /></a>
                : null}

                {this.state.promoDesc && this.state.promoDesc.length && (!this.state.promoUrl || !this.state.promoUrl.length) ?
                    <div style={styleFix} className={this.state.promoDivClass}>
                        <div className="row">
                            <div className="wrap header-desc">
                                <StaticDesc />
                            </div>
                        </div>
                    </div>
                    :null}

                {error || error_text ?
                    <Panel title={error || 'Неизвестная ошибка'}>
                        <div className={getStyle('b-row b-border')}>
                            {error_text || 'Неизвестная ошибка'}
                        </div>
                        <div className={getStyle('b-row center gray')}>
                            <Link to={CONST.ROOT_PATH} className={getStyle('button')} onClick={() => {
                                this.props.authenticate.Logout();
                            }}>Вход в ЛК</Link>
                        </div>
                    </Panel>
                    :

                    <CheckSmallLink>
                        {authInPorcess ?
                            <Panel title="Проверка доступа" icon={getStyle('icon-sett')} isLoading={true}/>
                            :
                            <div className={["lkInterfaceRoot"].join(' ')}>
                                {(allowed && !firstVKLogin) ?
                                    firstLogin ?
                                        <PanelFirstLogin/>
                                        :
                                        ai_phones.length > 1 ?
                                            <PanelChoosePrimaryPhone/>
                                            :
                                            <div className={getStyle('js')}>
                                                <Header/>
                                                {this.props.children}
                                                <Footer/>
                                            </div>
                                    :
                                    <AuthForm/>
                                }
                            </div>
                        }
                    </CheckSmallLink>
                }
            </div>
        )
    }
}

function mapStateToProps(state) {
    return {
        state: state
    }
}

function mapDispatchToProps(dispatch) {
    return {
        elementActions: null,
        authenticate: bindActionCreators(authenticateActions, dispatch)
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(LKInterface);