/**
 * Created by dpopov on 06.09.2017.
 */
/*eslint default-case: ["off"]*/

import React from 'react';

import ChapterServices from './index'

import Panel from '../../Components/Panel';

import { getStyle } from '../../tools';
import * as CONST from '../../const';

import 'moment/locale/ru';
import moment from 'moment';
import myStyle from "../../Panels/PanelServices/style.module.css";
import './style.css'
moment.locale('ru');

export default function (props) {
    return (
        <ChapterServices>
            <Panel title="Оборудование" icon={getStyle('icon-list')} className={myStyle['force-overflow-y-visible']}>
                <div style={{margin: "20px 40px 40px", paddingBottom: "40px"}}>
                    <p>Мы устанавливаем и настраиваем дополнительное оборудование. На него распространяются добровольные <a href={CONST.API + "/staticPages/file?name=garant.pdf"} className="a-color">cверхгарантийные обязательства «ОБИТ».</a>
                        <br/>
                        Перечень оборудования:</p>


                    {/*<div style={{margin: "10px 0 20px"}}>*/}
                    {/*    <h5 style={{marginBottom: "15px"}}><b>WiFi-роутер D-link 842 (комфорт-класс)</b></h5>*/}
                    {/*    <ul className="list__circle">*/}
                    {/*        <li>Внешний порт - 1 шт., 1000 Мбит/с</li>*/}
                    {/*        <li>Количество портов внутренней сети (LAN) - 4</li>*/}
                    {/*        <li>Скорость портов LAN- 1000 Мбит/с</li>*/}
                    {/*        <li>Cтандарт Wi-Fi: 802.11a/b/g/n/ac, частота 2.4/5.0 Ггц</li>*/}
                    {/*        <li>Размеры (ШxВxГ) - 205 x 136 x 44 мм</li>*/}
                    {/*    </ul>*/}
                    {/*    <table className={getStyle('line v-mid left-pull table-mob-collapse')}>*/}
                    {/*        <tfoot>*/}
                    {/*        <tr>*/}
                    {/*            <td className={getStyle('td-mob-collapse')}>Итого</td>*/}
                    {/*            <td className={getStyle('td-mob-collapse')}><strong>3500</strong> руб.</td>*/}
                    {/*        </tr>*/}
                    {/*        </tfoot>*/}
                    {/*    </table>*/}
                    {/*</div>*/}

                    <div style={{margin: "10px 0 20px"}}>
                        <h5 style={{marginBottom: "15px"}}><b>WiFi-роутер ASUS RT-N11P (эконом-класс)</b></h5>
                        <ul className="list__circle">
                            <li >Внешний порт - 1 шт., 100Мбит/с</li>
                            <li>Количество портов внутренней сети (LAN) - 4</li>
                            <li>Скорость портов LAN - 100 Мбит/с</li>
                            <li>Размеры (ШxВxГ) - 146x24x111 мм</li>
                        </ul>
                        <p><a className="a-color" href={CONST.API + "/staticPages/file?name=RT-N11.pdf"}>Инструкция по настройке</a></p>
                        <table className={getStyle('line v-mid left-pull table-mob-collapse')}>
                            <tfoot>
                            <tr>
                                <td className={getStyle('td-mob-collapse')}>Итого</td>
                                <td className={getStyle('td-mob-collapse')}><strong>1500</strong> руб.</td>
                            </tr>
                            </tfoot>
                        </table>
                    </div>

                    <div style={{margin: "10px 0 20px"}}>
                        <h5 style={{marginBottom: "15px"}}><b>WiFi-роутер TP-link Archer С5 или С2 (комфорт-класс)</b></h5>
                        <ul className="list__circle">
                            <li>Внешний порт - 1 шт., 1000 Мбит/с</li>
                            <li>Количество портов внутренней сети (LAN) - 4</li>
                            <li>Скорость портов LAN- 1000 Мбит/с</li>
                            <li>Размеры (ШxВxГ) - 230×40×145 мм</li>
                        </ul>
                        <p><a className="a-color" href={CONST.API + "/staticPages/file?name=TP-Link.pdf"}>Инструкция по настройке</a></p>
                        <table className={getStyle('line v-mid left-pull table-mob-collapse')}>
                            <tfoot>
                            <tr>
                                <td className={getStyle('td-mob-collapse')}>Итого</td>
                                <td className={getStyle('td-mob-collapse')}><strong>3500</strong> руб.</td>
                            </tr>
                            </tfoot>
                        </table>
                    </div>

                    <div style={{margin: "10px 0 20px"}}>
                        <h5  style={{marginBottom: "15px"}}><b>Телевизионная приставка TVIP S-Box v.410</b></h5>
                        <p>Размеры - 67х67х15 мм </p>
                        <i>Возможна рассрочка на год с ежемесячным платежом 330 руб.</i>
                        <table className={getStyle('line v-mid left-pull table-mob-collapse')}>
                            <tfoot>
                            <tr>
                                <td className={getStyle('td-mob-collapse')}>Итого</td>
                                <td className={getStyle('td-mob-collapse')}><strong>3600</strong> руб.</td>
                            </tr>
                            </tfoot>
                        </table>
                    </div>

                    <div style={{margin: "10px 0 20px"}}>
                        <h5  style={{marginBottom: "15px"}}><b>Коммутатор TP Link TL-SG1005D</b></h5>
                        <ul className="list__circle">
                            <li>Количество портов - 5 шт. 1Гбит/сс</li>
                            <li>Размеры (ШxВxГ) - 108×28×165 мм</li>
                        </ul>

                        <table className={getStyle('line v-mid left-pull table-mob-collapse')}>
                            <tfoot>
                            <tr>
                                <td className={getStyle('td-mob-collapse')}>Итого</td>
                                <td className={getStyle('td-mob-collapse')}><strong>950</strong> руб.</td>
                            </tr>
                            </tfoot>
                        </table>
                    </div>
                </div>
            </Panel>
        </ChapterServices>
    )
}