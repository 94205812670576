/**
 * Created by dpopov on 11.10.2017.
 */
import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Link } from 'react-router-dom';

import * as CONST from '../../const';

import * as PanelActions from './actions';

import { getStyle } from '../../tools';

class PanelFAQChapters extends React.Component {
    componentWillMount() {
        if(!this.props.state.chapters.length) {
            this.props.elementActions.getChapters();
        }
    }

    render() {
        // const loadingState = this.props.state.chapterLoading;
        const srcChapters = this.props.state.chapters;
        const activeChapter = this.props.state.activeChapter;

        return (
            <div>
                <div className={getStyle('b-header')}>
                    <h3>Разделы:</h3>
                </div>
                <div className={getStyle('side-nav')}>
                    {srcChapters.map((chapterData) => {
                        let itemClass = '';
                        if(chapterData.chapter === activeChapter) itemClass += getStyle('active');

                        return <Link to={CONST.ROOT_PATH + '/qna/' + chapterData.chapter} key={chapterData.chapter} className={itemClass}>{chapterData.title}</Link>
                    })}
                </div>
            </div>
        );
    }
}

function mapStateToProps(state) {
    return {
        state: state.FAQ
    }
}

function mapDispatchToProps(dispatch) {
    return {
        elementActions: bindActionCreators(PanelActions, dispatch)
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(PanelFAQChapters);