import React from 'react';
import myStyle from './style.module.css';
import './forceStyle.css';
import {connect} from 'react-redux';
import {getStyle} from "../../tools";
import PopUp from "../../Components/Panel/PopUp";
import Panel from "../../Components/Panel";
import * as HotelActions from './actions';
import {bindActionCreators} from "redux";
import 'moment/locale/ru';
import moment from 'moment';
import {registerLocale} from "react-datepicker/es";
import ru from 'date-fns/locale/ru';
import imgLogo from '../../../assets/images/logo_site.png'

import Filter from './Components/Filter';
import Clock from './Components/Clock';
import * as authenticateActions from "../../Authenticate/actions";
import PopUpAddUser,{PopUpOutFromHotel,PopUpEditDataUser,PopUpRemoveUser, PopUpListRoom, PopUpAddNewRoom, PopUpLoadNewListRoom} from './Components/Forms';

registerLocale('ru-RU', ru);
moment.locale('ru');

class HotelUsers extends React.Component {

    constructor(props) {

        super(props);

        this.state = {

            displayWindowAddUser: false,
            displayWindowEditUser: false,
            displayWindowOutFromHotel: false,
            displayWindowConfirmRemove: false,
            displayWindowListRoom: false,
            displayWindowAddNewRoom: false,
            displayWindowLoadNewListRoom: false,

            filter: {
                guestName: '',
                pmsRegNum: '',
                roomNumber: '',
                filterActive: true,
                timeIn: null,
                timeOut: null,
            },

            sort: {
                field: 'timeIn',
                direction: 'ASC'
            },

            newUser: {
                guestName: '',
                pmsRegNum: '',
                roomNumber: '',
                filterActive: true,
                timeIn: moment(),
                timeOut: moment().add(1, 'days'),
            },

            dataEditUser: {},

            timeOutFromHotel: moment().add(1, 'hour'),
        };
    }

    componentDidMount() {
        this.props.HotelActions.request(
            2,
            { limit:this.props.limit || this.props.state.displayCount,page:1},
            this.state.dataEditUser,
            () => {
                this.setState({
                    displayWindowEditUser: false,
                    dataEditUser: {
                        guestName: '',
                        roomNumber: '',
                        pmsRegNum: '',
                    }
                })
            },
            this.getFilterData()
        );

        this.props.HotelActions.getListRoom();
    }

    checkGuestByRegNum = (pmsRegNum, callback) => {
        this.props.HotelActions.request(
            5,
            {},
            {},
            callback,
            {
                filterPmsRegNum:pmsRegNum
            }
        );
    };

    submitAddUser = (event) => {

        event.preventDefault();

        if(this.state.newUser.timeIn > this.state.newUser.timeOut) {

            document.getElementsByClassName('timeIn')[0].style.border = 'solid 2px red';

        } else {
            this.checkGuestByRegNum(this.state.newUser.pmsRegNum, this._addUser);
        }

    };

    changeLimitClick = (event) => {
        event.preventDefault();

        const newCountValue = event.target.getAttribute('data-count');

        if (newCountValue !== this.props.state.displayCount) {
            this.props.HotelActions.request(
                2,
                {limit:newCountValue,page:1},
                this.state.dataEditUser,
                () => {
                    this.setState({
                        dataEditUser: {
                            guestName: '',
                            roomNumber: '',
                            pmsRegNum: '',
                        }
                    });
                    this.props.HotelActions.setNewLimit(newCountValue);
                },
                this.getFilterData()
            );
        }
    };

    _addUser = (pmsRegNumIsSet) => {

        document.getElementsByClassName('timeIn')[0].style.border = '';

        if(pmsRegNumIsSet)document.getElementsByName('pmsRegNum')[0].style.border = 'solid 2px red';
        else {

            let userData = {
                guestName: this.state.newUser.guestName ? this.state.newUser.guestName : null,
                roomNumber: this.state.newUser.roomNumber ? this.state.newUser.roomNumber : null,
                pmsRegNum: this.state.newUser.pmsRegNum ? this.state.newUser.pmsRegNum : null,
                timeIn: this.state.newUser.timeIn ? moment(this.state.newUser.timeIn).unix() : null,
                timeOut: this.state.newUser.timeOut ? moment(this.state.newUser.timeOut).unix() : null,
            };


            this.props.HotelActions.request(
                1,
                {limit: this.props.limit || this.props.state.displayCount, page: 1},
                userData,
                () => {
                    this.props.HotelActions.getListRoom();
                    this.setState({
                        displayWindowAddUser: false,
                        newUser: {
                            guestName: '',
                            pmsRegNum: '',
                            roomNumber: '',
                            filterActive: true,
                            timeIn: moment(),
                            timeOut: moment().add(1, 'days'),
                        },
                    })
                },
                this.getFilterData()
            );
        }
    };

    getFilterData = () => {
        return {
            filterDateFromLE: this.state.filter.timeOut ? moment(this.state.filter.timeOut).unix() : null,
            filterDateTillGE: this.state.filter.timeIn ? moment(this.state.filter.timeIn).unix() : null,
            filterGuestName: this.state.filter.guestName ? this.state.filter.guestName : null,
            filterPmsRegNum: this.state.filter.pmsRegNum ? this.state.filter.pmsRegNum : null,
            filterRoomNumber: this.state.filter.roomNumber ? this.state.filter.roomNumber : null,
            filterActive: this.state.filter.filterActive
        };
    };

    changeDisplayPage = (event) => {
        event.preventDefault();

        const currentPage = this.props.state.displayPage;
        const newPage = event.target.getAttribute('data-page');

        if (currentPage !== newPage){
            this.props.HotelActions.request(
                2,
                { limit:this.props.limit || this.props.state.displayCount,page:newPage},
                this.state.dataEditUser,
                () => {
                    this.setState({
                        dataEditUser: {
                            guestName: '',
                            roomNumber: '',
                            pmsRegNum: '',
                        }
                    });
                    this.props.HotelActions.setNewPage(newPage);
                },
                this.getFilterData()
            );

        }
    };

    openWindowAddUser = (room) => {
        //console.log('!!!!!!!!!!',room);
        this.setState({
            newUser: {
                guestName: '',
                pmsRegNum: '',
                roomNumber:  room,
                filterActive: true,
                timeIn: moment(),
                timeOut: moment().add(1, 'days'),
            },
            displayWindowAddUser: true,

        })
    };


    closeWindowAddUser = () => {
        this.setState({
            displayWindowAddUser: false,
        })
    };


    closeWindowConfirmRemove = () => {
        this.setState({displayWindowConfirmRemove: false})
    };

    changeDateOnWindowOutFromHotel = (date) => {

        this.setState({timeOutFromHotel: date});
    };


    openWindowOutFromHotel = (event) => {
        let childNodes = event.target.parentNode.parentNode.childNodes;

        this.setState({
            displayWindowOutFromHotel: true,
            dataEditUser: {
                guestName: childNodes[0].textContent,
                roomNumber: childNodes[1].textContent,
                pmsRegNum: childNodes[2].textContent,
            }
        });
    };
    openWindowConfirmRemove = (event) => {
        let childNodes = event.target.parentNode.parentNode.childNodes;

        this.setState({
            displayWindowConfirmRemove: true,
            dataEditUser: {
                guestName: childNodes[0].textContent,
                roomNumber: childNodes[1].textContent,
                pmsRegNum: childNodes[2].textContent,
            }
        });
    };

    closeWindowOutFromHotel = () => {
        this.setState({
            displayWindowOutFromHotel: false,
            dataEditUser: {
                guestName: '',
                roomNumber: '',
                pmsRegNum: '',

            },
            timeOutFromHotel: moment().add(1, 'hour')
        })
    };
    closeWindowConfirmRemove = () => {
        this.setState({
            displayWindowConfirmRemove: false,
            dataEditUser: {
                guestName: '',
                roomNumber: '',
                pmsRegNum: '',

            },
        })
    };

    submitOutFromHotel = (event) => {
        event.preventDefault();
        this.props.HotelActions.request(
            4,
            { limit:this.props.limit || this.props.state.displayCount,page:1},
            {...this.state.dataEditUser, timeOut: moment(this.state.timeOutFromHotel).unix()},
            () => {
                this.props.HotelActions.getListRoom();
                this.setState({
                    displayWindowOutFromHotel: false,
                    dataEditUser: {
                        guestName: '',
                        roomNumber: '',
                        pmsRegNum: '',

                    },
                    timeOutFromHotel: moment().add(1, 'hour'),
                })
            },
            this.getFilterData()
        );
    };

    closeWindowEditDataUser = () => {
        this.setState({displayWindowEditUser: false});
    };

    openWindowEditDataUser = (event) => {
        let childNodes = event.target.parentNode.parentNode.childNodes;

        this.setState({
            displayWindowEditUser: true,
            dataEditUser: {
                guestName: childNodes[0].textContent,
                roomNumber: childNodes[1].textContent,
                pmsRegNum: childNodes[2].textContent,
            }
        });
    };

    submitEditUser = (event) => {

        event.preventDefault();

        this.props.HotelActions.request(
            3,
            { limit:this.props.limit || this.props.state.displayCount,page:1},
            this.state.dataEditUser,
            () => {
                this.setState({
                    displayWindowEditUser: false,
                    dataEditUser: {
                        guestName: '',
                        roomNumber: '',
                        pmsRegNum: '',
                    }
                })
            },
            this.getFilterData()
        );
    };

    onChangeDataOnFormAddUser = (field, value) => {

        if(field === 'timeIn' && !(value > this.state.newUser.timeOut)) {
            document.getElementsByClassName('timeIn')[0].style.border = '';
        }
        let newUser = this.state.newUser;
        newUser[field] = value;
        this.setState({newUser:newUser});
    };

    onChangeDataFilter = (field, value) => {

        let filter = this.state.filter;
        filter[field] = value;
        this.setState({filter: filter})
    };





    onClearDataFilter = (callback) => {
        this.setState({
            filter: {
                guestName: '',
                pmsRegNum: '',
                roomNumber: '',
                filterActive: true,
                timeIn: null,
                timeOut: null,
            },
        });

        if (typeof callback === 'function') callback();
    };

    submitFilter = (event) => {
        event.preventDefault();

        this.props.HotelActions.request(
            2,
            { limit:this.props.limit || this.props.state.displayCount,page:1},
            this.state.dataEditUser,
            () => {
                this.setState({
                    displayWindowEditUser: false,
                    dataEditUser: {
                        guestName: '',
                        roomNumber: '',
                        pmsRegNum: '',
                    }
                })
            },
            this.getFilterData()
        );
    };

    removeUser = (e) => {
        e.preventDefault();
        this.props.HotelActions.request(
            0,
            { limit:this.props.limit || this.props.state.displayCount,page:1},
            this.state.dataEditUser,
            () => {
                this.props.HotelActions.getListRoom();
                this.setState({
                    displayWindowConfirmRemove: false,
                    dataEditUser: {
                        guestName: '',
                        roomNumber: '',
                        pmsRegNum: '',
                    }
                })
            },
            this.getFilterData()
        );
    };

    logout = () => {
        this.props.authActions.Logout();
    };

    onChangeDataOnFormEditUser = (field, value) => {
        let dataEditUser = this.state.dataEditUser;
        dataEditUser[field] = value;
        this.setState({dataEditUser: dataEditUser});
    };

    doSort = (field, direction) => {

        window.event.preventDefault();

        if(this.state.sort.field !== field || this.state.sort.direction !== direction) {

            this.props.HotelActions.request(
                2,
                { limit:this.props.limit || this.props.state.displayCount,page:1},
                this.state.dataEditUser,
                () => {
                    let sort = this.state.sort;
                    sort = {
                        field: field,
                        direction: direction
                    };
                    this.setState({
                        sort:sort
                    });
                },
                {...this.getFilterData(), orderBy: field + ' ' + direction}
            );
        }
    };

    handlerAddRoom = (room) => {

        if(!room) return;

        this.props.HotelActions.addRoom(
            room,
            ()=>{
                this.setState({displayWindowAddNewRoom: false});
                this.props.HotelActions.getListRoom();
            }
        );
    };

    handlerBtnLoadNewListRoom = (fileListRoom) => {



        this.props.HotelActions.loadNewListRoom(
            fileListRoom,
            ()=>{
                this.setState({displayWindowLoadNewListRoom: false});
                this.props.HotelActions.getListRoom();
            }
        );
    };



    render() {

        const
            usersItems = this.props.state.usersList || [],
            currentLimit = this.props.state.displayCount,
            totalItems = this.props.state.totalUsers,
            currentPage = this.props.state.displayPage || 1,
            pagesCount = Math.ceil(totalItems / currentLimit);

        let
            users = [],
            paginator = [],
            head = [];

        if (pagesCount !== 1) {
            if (currentPage !== 1) {
                paginator.push(<button className={getStyle('button')} onClick={this.changeDisplayPage} data-page={1}>В начало</button>)
            }

            for (let p = currentPage; p <= currentPage + 1; p++) {
                if (p > pagesCount) break;
                paginator.push(
                    <button className={[getStyle('button'), p === currentPage ? getStyle('active') : null].join(' ')} onClick={this.changeDisplayPage} key={p} data-page={p}>{p}</button>
                );
            }

            if (currentPage !== pagesCount) {
                paginator.push(
                    <button key={"pagination-button-" + pagesCount} className={getStyle('button')} onClick={this.changeDisplayPage} data-page={pagesCount}>В конец</button>
                );
            }
        }

        for (let p = 0; p < usersItems.length; p++) {

            let
                timeIn = moment(usersItems[p].timeIn * 1000).format('LLL'),
                timeOut = moment(usersItems[p].timeOut * 1000).format('LLL'),
                active = moment().isBetween(moment(usersItems[p].timeIn * 1000), moment(usersItems[p].timeOut * 1000));

            users.push(
                <tr key={"td-user-" + p} className={getStyle(!active ? 'gray' : '')}>
                    <td>{usersItems[p].guestName}</td>
                    <td>{usersItems[p].roomNumber}</td>
                    <td>{usersItems[p].pmsRegNum}</td>
                    <td>{timeIn}</td>
                    <td>{timeOut}</td>
                    <td><button className={getStyle(!active ? 'btn-major' : '')} disabled={!active} onClick={this.openWindowEditDataUser}>изменить</button></td>
                    <td><button className={getStyle('btn-major')} disabled={!active} onClick={this.openWindowOutFromHotel}>выезд</button></td>
                    <td><button className={getStyle('btn-major')} disabled={!active} onClick={this.openWindowConfirmRemove}>удалить</button></td>
                </tr>
            );
        }

        let columns = {
            guestName: 'Фамилия гостя',
            roomNumber: 'Номер комнаты',
            pmsRegNum: 'Номер брони',
            timeIn: 'Время заселения',
            timeOut: 'Время выселения'
        };



        for (let column in columns) {

            head.push(
                <th key={"th-column-" + column}>
                    {columns[column]}
                    <span onClick={() => this.doSort(column, 'DESC')} title='DESC' className={myStyle['cursor-pointer']} href='#'>
                        <span className={(this.state.sort.field === column && this.state.sort.direction === 'DESC' ? myStyle['active-sort'] : '') }>&dArr;</span>
                    </span>
                    <span onClick={() => this.doSort(column, 'ASC')} title='ASC' className={myStyle['cursor-pointer']} href='#'>
                        <span className={(this.state.sort.field === column && this.state.sort.direction === 'ASC' ? myStyle['active-sort'] : '') }>&uArr;</span>
                    </span>

                </th>
            )
        }




        let bottom, usersList;

        if(usersItems.length > 0) {

             usersList=(
                <table className={[myStyle['rm-z-index'], getStyle('line v-mid left-pull')].join(' ')}>
                    <thead>
                    <tr>{head}</tr>
                    </thead>
                    <tbody>{users}</tbody>
                </table>
            );
            bottom = (
                <div className={[myStyle['rm-z-index'], getStyle('b-row')].join(' ')}>
                    <div className={getStyle('f-left')}>
                        {paginator}
                    </div>
                    <div className={getStyle('f-right')}>
                        Показать по&emsp;
                        <button type="button" className={currentLimit === 10 ? getStyle('active') : null}
                                onClick={this.changeLimitClick} data-count={10}>10
                        </button>
                        <button type="button" className={currentLimit === 50 ? getStyle('active') : null}
                                onClick={this.changeLimitClick} data-count={50}>50
                        </button>
                        <button type="button" className={currentLimit === 100 ? getStyle('active') : null}
                                onClick={this.changeLimitClick} data-count={100}>100
                        </button>
                    </div>
                </div>
            );
        } else {
            usersList=(
                <table className={[myStyle['rm-z-index'], getStyle('t-center')].join(' ')}>
                    <tbody>
                    <tr><td>По данному фильтру нет гостей</td></tr>
                    </tbody>
                </table>
            );
        }


        return (
            <Panel  >

                <img src={imgLogo} alt="OBIT" className={myStyle['logo']}/>
                <Clock />
                {this.state.displayWindowOutFromHotel ?
                    <PopUp title="Выезд гостя" onCancel={this.closeWindowOutFromHotel} blockStyle={myStyle['popup-block-my']}>
                        <PopUpOutFromHotel onSubmit={this.submitOutFromHotel} timeOutFromHotel={this.state.timeOutFromHotel} onChange={this.changeDateOnWindowOutFromHotel}/>
                    </PopUp>
                    : null}
                {this.state.displayWindowEditUser ?
                    <PopUp title="Редактирование" onCancel={this.closeWindowEditDataUser} blockStyle={myStyle['popup-block-my']}>
                        <PopUpEditDataUser onSubmit={this.submitEditUser} data={this.state.dataEditUser} onChange={this.onChangeDataOnFormEditUser} />
                    </PopUp>
                    : null}
                {this.state.displayWindowAddUser ?
                    <PopUp title="Новый гость" onCancel={this.closeWindowAddUser} popupStyle={myStyle['z-i-3000']} blockStyle={myStyle['popup-block-my']}>
                        <PopUpAddUser listRoom={this.props.state.listRoom} onSubmit={this.submitAddUser} data={this.state.newUser} onChange={this.onChangeDataOnFormAddUser}/>
                    </PopUp>
                    : null}
                {this.state.displayWindowConfirmRemove ?
                    <PopUp title="Подтверждение Удаления" onCancel={this.closeWindowConfirmRemove} blockStyle={myStyle['popup-block-my']}>
                        <PopUpRemoveUser onConfirmYes={this.removeUser} onConfirmNo={this.closeWindowConfirmRemove}/>
                    </PopUp>
                    : null}
                {this.state.displayWindowAddNewRoom ?
                    <PopUp title="Добавление комнаты"  popupStyle={myStyle['z-i-3000']} onCancel={()=>{this.setState({displayWindowAddNewRoom:false})}} blockStyle={myStyle['popup-add-new-room']}>
                        <PopUpAddNewRoom onSubmit={this.handlerAddRoom} onCancel={()=>{this.setState({displayWindowAddNewRoom:false})}}/>
                    </PopUp>
                    : null}
                {this.state.displayWindowLoadNewListRoom ?
                    <PopUp title="Загрузка нового списка комнат" popupStyle={myStyle['z-i-3000']} onCancel={()=>{this.setState({displayWindowLoadNewListRoom:false})}} blockStyle={myStyle['popup-load-new-list-room']}>
                        <PopUpLoadNewListRoom onSubmit={this.handlerBtnLoadNewListRoom} onCancel={()=>{this.setState({displayWindowLoadNewListRoom:false})}}/>
                    </PopUp>
                    : null}

                {this.state.displayWindowListRoom ?
                    <PopUp title="Список доступных комнат"    onCancel={()=>{this.setState({displayWindowListRoom:false})}} blockStyle={[myStyle['popup-block-my'],myStyle['popup-list-room']].join(' ')}>
                        <PopUpListRoom
                            onClick={this.openWindowAddUser}
                            listRoom={this.props.state.listRoom}
                            booked={this.props.state.booked}
                            onClickBtnLoadNewListRoom={()=>{this.setState({displayWindowLoadNewListRoom:true})}}
                            onClickBtnAddRoom={()=> {this.setState({displayWindowAddNewRoom:true})}}
                        />
                        </PopUp>
                        : null}
                <div className={getStyle('b-row')}>
                    <button type="button" className={getStyle('f-left btn-popup orange')}
                            onClick={(e) => { e.preventDefault(); this.openWindowAddUser('')} }>Добавить гостя
                    </button>
                   <button type="button" className={getStyle('f-left btn-popup orange')} onClick={()=> this.setState({displayWindowListRoom:true})}>Список комнат</button>
                    <button type="button" className={getStyle('f-right btn-popup')} onClick={this.logout}>Выход</button>
                </div>

                <Filter
                    data={this.state.filter}
                    onClearDataFilter={this.onClearDataFilter}
                    onChangeDataFilter={this.onChangeDataFilter}
                    submitFilter={this.submitFilter}
                    listRoom={this.props.state.listRoom}
                />

                {usersList}
                {bottom}
            </Panel>
        );
    }
}

function mapStateToProps(state) {
    return {
        state: state.hotel,
    }
}

function mapDispatchToProps(dispatch) {
    return {
        HotelActions: bindActionCreators(HotelActions, dispatch),
        authActions: bindActionCreators(authenticateActions, dispatch)
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(HotelUsers);
