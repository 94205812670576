/**
 * Created by dpopov on 01.11.2017.
 */
import React from 'react';

import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

//import { getServiceData, requestServiceWays, findParentService } from './tools';
import { getStyle, getStdNotifyStruct } from '../../tools';

import axios from 'axios';

import * as authenticateActions from '../../Authenticate/actions';
import * as panelServicesActions from './actions';
import * as CONST from '../../const';

//import elStyle from './style.css';

import Panel from '../../Components/Panel';
// import { PanelIsLoading } from '../../Components/Panel';

import 'moment/locale/ru';
import moment from 'moment';
moment.locale('ru');

const initialState = {
    loadingState: false,
    serviceId: null,
    activeContract: null,
    errorString: null,
    prefix: '',
    prefixData: null
};

class PanelPhoneDirectionCost extends React.Component {
    constructor(props) {
        super(props);

        this.state = {...initialState,
            activeContract: props.authData.activeContract,
            serviceId: props.serviceId
        };

        this.onErrorCloseClick = this.onErrorCloseClick.bind(this);
        this.onCheckClick = this.onCheckClick.bind(this);
        this.onPrefixInputChange = this.onPrefixInputChange.bind(this);
    }

    onErrorCloseClick(event) {
        event.preventDefault();

        this.setState({errorString: null});
    }

    getPrefixInformation(prefix) {
        this.setState({loadingState: true});

        axios.get(CONST.API + '/phone/directioncost', {
            withCredentials: true,
            params: {
                'contract': this.props.authData.activeContract,
                'srvid': this.state.serviceId,
                'prefix': prefix,
		'.rnd': Math.ceil(Math.random()*1000000)
            }
        }).then((answer) => {
            const data = answer.data;

            if(data.result === 'success') {
                if(data.data.count) {
                    this.setState({loadingState: false, prefixData: data.data.directions[0] });
                } else {
                    this.setState({loadingState: false });
                }
            } else {
                this.setState({ loadingState: false, errorString: data.text });
            }
        });
    }

    onPrefixInputChange(event) {
        this.setState({prefix: event.target.value});
        if(event.target.value.length > 3) this.getPrefixInformation(event.target.value);
    }

    onCheckClick(event) {
        event.preventDefault();
        this.getPrefixInformation(this.state.prefix);
    }

    render() {
        const title = this.props.title || 'Стоимость направления';
        const icon = this.props.icon || getStyle('icon-rub');
        // const loadingState = this.state.loadingState;

        return (
            <Panel title={title} icon={icon} notify={getStdNotifyStruct(this.state.errorString, this.onErrorCloseClick)}>
                <div className={getStyle('b-row b-border')}>
                    Укажите номер телефона&nbsp;
                    <input type="text" name="prefix" value={this.state.prefix} onChange={this.onPrefixInputChange} />&nbsp;
                    <button type="button" onClick={this.onCheckClick}>Проверить</button>
                </div>
                {this.state.prefixData ?
                    <table className={getStyle('line v-mid left-pull gray')}>
                        <tbody>
                        <tr>
                            <td>Стоимость вызова</td>
                            <td>{this.state.prefixData.Price} руб/мин</td>
                        </tr>
                        <tr>
                            <td>Название направления</td>
                            <td>{this.state.prefixData.DirectionName}</td>
                        </tr>
                        <tr>
                            <td>Группа направлений</td>
                            <td>{this.state.prefixData.DirectionGroupName}</td>
                        </tr>
                        <tr>
                            <td>Префикс</td>
                            <td>{this.state.prefixData.Prefix}</td>
                        </tr>
                        </tbody>
                    </table>
                : null}
            </Panel>
        );
    }
}

function mapStateToProps(state) {
    return {
        authData: state.authenticate,
        servicesData: state.panelServices
    }
}

function mapDispatchToProps(dispatch) {
    return {
        elementActions: bindActionCreators(panelServicesActions, dispatch),
        authenticate: bindActionCreators(authenticateActions, dispatch)
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(PanelPhoneDirectionCost);