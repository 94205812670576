/* eslint eqeqeq: ["off", "smart"] */
import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import Panel from '../../Components/Panel';
import * as panelServicesActions from "./actions";
import * as authenticateActions from "../../Authenticate/actions";

class CheckRosterData extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            activeContract: props.authData.activeContract
        }
    }

    componentDidMount() {
        const activeContract = this.props.authData.activeContract;
        if(!this.props.servicesData.services) {
            if(!this.props.servicesData.panelLoadingState) this.requestServicesData(activeContract);
            return;
        }
        if(!Object.keys(this.props.servicesData.services).length || this.props.forceReload) this.requestServicesData(activeContract);
    }

    componentWillReceiveProps(nextProps) {
        const nextActiveContract = nextProps.authData.activeContract;

        if(nextActiveContract != this.state.activeContract) {
            this.requestServicesData(nextActiveContract);
            return;
        }

        if(nextProps.authData.rosterLastChange.isAfter(nextProps.servicesData.lastRosterDataReceived) && nextProps.servicesData.panelLoadingState === false) {
            this.requestServicesData(nextActiveContract);
            return;
        }
    }


    requestServicesData(contract) {
        this.setState({activeContract: contract});
        this.props.elementActions.requestServicesList(contract, this.props.from);
    }

    render() {
        const loadingState = this.props.servicesData.panelLoadingState;

        return loadingState || !this.props.servicesData.services ?
            <Panel title={"Запрос данных"} isLoading={true} />
            :
            <div>
                {this.props.children}
            </div>
        ;
    }
}

function mapStateToProps(state) {
    return {
        authData: state.authenticate,
        servicesData: state.panelServices
    }
}

function mapDispatchToProps(dispatch) {
    return {
        elementActions: bindActionCreators(panelServicesActions, dispatch),
        authenticate: bindActionCreators(authenticateActions, dispatch)
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(CheckRosterData);