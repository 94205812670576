/**
 * Created by dpopov on 12.07.2017.
 */
/* eslint eqeqeq: ["off", "smart"] */
/* eslint no-useless-escape: ["off"] */
/* eslint no-script-url: ["off"] */
/* eslint jsx-a11y/anchor-is-valid: ["off"] */
import React from 'react';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import {Link} from 'react-router-dom';
import * as authenticateActions from '../../Authenticate/actions';
import Row from '../Row';

import {getStyle} from '../../tools';

import * as CONST from '../../const';
import myStyle from './style.module.css';

import styleObit from '../../../assets/css/obit.module.css';
import imgLogo from '../../../assets/images/logo_site.png';

class Header extends React.Component {
    constructor(props) {
        super(props);

        this.interfaceLogout = this.interfaceLogout.bind(this);
        this.switchActiveContract = this.switchActiveContract.bind(this);
    }

    interfaceLogout(event) {
        event.preventDefault();
        this.props.authenticate.Logout();
    }

    switchActiveContract(contract) {
        return (event) => {
            //event.preventDefault();
            this.props.authenticate.switchActiveContract(contract);
            this.props.authenticate.requestCurrentUser(true, {activeContract: contract});
        }
    }

    render() {

        const currentRegion = CONST.REGION_DATA[this.props.state.authenticate.region] ? CONST.REGION_DATA[this.props.state.authenticate.region] : CONST.REGION_DATA.default;

        const authData = this.props.state.authenticate;

        if (!authData.activeContract) return null;


        const vkUserPhoto = (authData.vkUserPhoto && authData.authVK) ?
            {
                backgroundImage: 'url(' + authData.vkUserPhoto + ')',
                marginTop: '5px',
                marginRight: '5px',
            } : null;

        const vkUserPhotoClass = (authData.vkUserPhoto && authData.authVK) ?
            'vk-icon-mL' : '';

        const contracts = authData.contracts;
        const contract = authData.activeContract;
        const balance = contracts[contract];
        const contractsNum = Object.keys(authData.contracts);
        const contractsCount = contractsNum.length;

        const helpAsks = authData.helpAsks ? authData.helpAsks[contract] : 0;

        const hasPromoBackground = authData.promoBackground && authData.promoBackground.image ? true : false;

        const aFirstName = authData.clientFirstName;
        const aLastName = authData.clientLastName;
        const aMiddleName = authData.clientMiddleName;
        // const aMiddleName = authData.clientMiddleName;
        // const aLastName = authData.clientLastName;

        //const pathname = this.props.state.router.location.pathname || "";
        const pathname = this.props.state.router.location.pathname || "";

        // eslint-disable-next-line
        // const hasPromoImage = typeof STATIC_CONF !== 'undefined' ? STATIC_CONF.promoImage : true;

        const urlStyles = {
            '/': [],
//            '/support.html': [ getStyle('a-color') ],
            '/support.html': [],
            '/billing.html': [],
            '/dogovor.html': [],
            '/services.html': [],
            '/qna.html': [],
            '/user-setting.html': [],
            '/bonus-speed.html': [getStyle('a-color')]
        };

        if (pathname.match(/\/service[^\.]*?\.html$/)) urlStyles['/services.html'].push(getStyle('active'));
        if (pathname.match(/\/billing[^\.]*?\.html$/)) urlStyles['/billing.html'].push(getStyle('active'));
        if (pathname.match(/\/dogovor[^\.]*?\.html$/)) urlStyles['/dogovor.html'].push(getStyle('active'));
        if (pathname.match(/\/(support|qna)[^\.]*?\.html$/)) urlStyles['/qna.html'].push(getStyle('active'));

        const fixPaddingOnPromoImage = hasPromoBackground ? {paddingTop: '0px', paddingBottom: '0px'} : {};

        // <Link to={CONST.ROOT_PATH + '/_h4OSH1nnIG'} className={urlStyles['/'].join(' ')}><img src={imgLogo} alt="OBIT" /></Link>
        // <Link to={CONST.ROOT_PATH} className={urlStyles['/'].join(' ')}><img src={imgLogo} alt="OBIT" /></Link>
        return (
            <Row>
                <div
                    className={hasPromoBackground ? [getStyle('block'), styleObit['zindex9'], styleObit['fixOverFlowY']].join(' ') : ''}>
                    <div className={hasPromoBackground ? getStyle('b-header small') : ''}>
                        <div className={getStyle('row header')} style={fixPaddingOnPromoImage}>
                            <div className={[getStyle('b logo-type'), myStyle['logo-pos']].join(' ')}>
                                <Link to={CONST.ROOT_PATH} className={urlStyles['/'].join(' ')}><img src={imgLogo}
                                                                                                     alt="OBIT"/></Link>
                                {/*<Link to={CONST.ROOT_PATH + '/c/2lqFrn6e579'} className={urlStyles['/'].join(' ')}><img src={imgLogo} alt="OBIT" /></Link>*/}
                                {/*<Link to={CONST.ROOT_PATH + '/_LevVKcU1ps'} className={urlStyles['/'].join(' ')}><img src={imgLogo} alt="OBIT" /></Link>*/}
                                {/*<Link to={CONST.ROOT_PATH + '/0w!bDW9KjhD7'} className={urlStyles['/'].join(' ')}><img src={imgLogo} alt="OBIT" /></Link>*/}
                                {/*<Link to={CONST.ROOT_PATH + '/_IDKL6G5qlt'} className={urlStyles['/'].join(' ')}><img src={imgLogo} alt="OBIT" /></Link>*/}
                                {/*<Link to={CONST.ROOT_PATH + '/c/2u4TAgf96FB'} className={urlStyles['/'].join(' ')}><img src={imgLogo} alt="OBIT" /></Link>*/}
                            </div>
                            <div className={getStyle('b phone-block')}>
                                <div className={getStyle('phone')}>
                                    <a href={"tel:" + currentRegion.link_phone}>
                                        <i className={getStyle('s-icon phone')}/>
                                        &nbsp;{currentRegion.phone}
                                    </a>
                                </div>
                                <div className={getStyle('send-message t-small')}>
                                    {/*<Link to={CONST.ROOT_PATH + '/bonus-speed.html'} className={urlStyles['/bonus-speed.html'].join(' ')}>Копи скорость!</Link>*/}
                                    <Link to={CONST.ROOT_PATH + "/support.html"}
                                          className={urlStyles['/support.html'].join(' ')}>Написать в
                                        техподдержку</Link>
                                </div>
                            </div>
                            <div className={getStyle('b info-block')}>
                                <div className={getStyle('info-balance')}>
                                    <Link to={CONST.ROOT_PATH + "/billing.html"}
                                          className={urlStyles['/billing.html'].join(' ')}>Баланс: {balance ? balance : '0'} руб.</Link>
                                </div>
                                <div className={getStyle('info-number t-small')}>
                                    <div className={myStyle['choose-contract-block']}>
                                        {contractsCount == 1 ?
                                            <Link to={CONST.ROOT_PATH + "/dogovor.html"}
                                                  className={urlStyles['/dogovor.html'].join(' ')}>Договор
                                                № {contract}</Link>
                                            :
                                            <div className={myStyle['choose-contract-block-dropdown']}>
                                                <Link to={CONST.ROOT_PATH + "/dogovor.html"}
                                                      className={urlStyles['/dogovor.html'].join(' ')}>
                                                    <span
                                                        className={myStyle['contract-title-num']}>Договор&nbsp;№&nbsp;{contract}</span>
                                                    <span
                                                        className={myStyle['contract-title-txt']}>Договор&nbsp;№&nbsp;{contract}</span>
                                                </Link>
                                                {/*<a href={"javascript:void(0);"} className={myStyle['contract-block-btn']}><i className={[getStyle('m-icon icon-list'), myStyle['choose-contract-icon']].join(' ')}/></a>*/}
                                                <ul className={myStyle['contracts-menu']}>
                                                    {contractsNum.map((item) => {
                                                        return item == authData.activeContract ? null :
                                                            <li key={item}><Link to={CONST.ROOT_PATH}
                                                                                 onClick={this.switchActiveContract(item)}>{item}</Link>
                                                            </li>;
                                                    })}
                                                </ul>
                                            </div>
                                        }
                                    </div>
                                </div>
                            </div>
                            <div className={getStyle('b nav-block')}>
                                <div className={getStyle('info-balance')}>
                                    <Link to={CONST.ROOT_PATH + "/services.html"}
                                          className={urlStyles['/services.html'].join(' ')}>Услуги</Link>
                                    <Link to={CONST.ROOT_PATH + "/qna.html"}
                                          className={urlStyles['/qna.html'].join(' ')}>Помощь {helpAsks ?
                                        <span className={getStyle('label-count')}>{helpAsks}</span> : ""}</Link>
                                </div>
                                <div className={getStyle('info-number t-small')}>
                                    {/*<a href="https://my.obit.ru/" className=''>Старая версия</a>*/}
                                    <Link to={CONST.ROOT_PATH + '/bonus-speed.html'}
                                          className={urlStyles['/bonus-speed.html'].join(' ')}>Копи скорость!</Link>
                                </div>
                            </div>
                            <div className={getStyle('user-block')}>
                                <div className={getStyle('user-block-dropdown')}>
                                    <a href="#" className={getStyle('user-block-btn')}>
                                        <div style={vkUserPhoto} className={[getStyle('m-icon icon-user'), vkUserPhotoClass].join(' ')}/>
                                        <div className={[getStyle('user-name')].join(' ')}>{aLastName} {aFirstName} {aMiddleName}</div>
                                    </a>
                                    <ul className={getStyle('user-menu')}>
                                        <li><Link to={CONST.ROOT_PATH + "/user-setting.html"}
                                                  className={urlStyles['/user-setting.html'].join(' ')}>Настройки</Link>
                                        </li>
                                        <li><Link to={CONST.ROOT_PATH + "/"} onClick={this.interfaceLogout}
                                                  className={getStyle('button')}>Выйти</Link></li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Row>
        )
    }
}

function mapStateToProps(state) {
    return {
        state: state
    }
}

function mapDispatchToProps(dispatch) {
    return {
        elementActions: null,
        authenticate: bindActionCreators(authenticateActions, dispatch)
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Header);