import React from 'react';

import { getStyle } from '../../../tools';
import style from './style.module.css';

export default function(props) {
    if (!props.items) return null;

    return (
        <table className={[getStyle('line v-top left-pull'), style['ph-stat-v3-table']].join(' ')}>
            <thead>
            <tr>
                <th rowSpan={2}>Номер телефона</th>
                <th colSpan={2}>Исходящие вызовы</th>
                <th colSpan={2}>Входящие вызовы</th>
                <th rowSpan={2}>Стоимость</th>
            </tr>
            <tr>
                <th>Длительность</th>
                <th>Кол-во вызовов</th>
                <th>Длительность</th>
                <th>Кол-во вызовов</th>
            </tr>
            </thead>
            <tbody>
            {props.items.map((item, _i) => {
                return (
                    <tr key={_i}>
                        {/*<td>{item.ToNumber}</td>*/}
                        <td className={getStyle('t-center')}>{item.UserIDNumber ?
                            item.UserIDNumber.match(/\d{10}#/) ?
                                item.UserIDNumber.replace(/^.*(\d{3})(\d{7})#(\d+)/, '+7 ($1) $2 ext. $3')
                                : item.UserIDNumber
                            : item.ToNumber}</td>
                        <td className={getStyle('t-right')}>{item.callsDurationOUT}</td>
                        <td className={getStyle('t-right')}>{item.callsCountOUT}</td>
                        <td className={getStyle('t-right')}>{item.callsDurationIN}</td>
                        <td className={getStyle('t-right')}>{item.callCountIN}</td>
                        <td className={getStyle('t-right')}>{item.CalcPrice}</td>
                    </tr>
                );
            })}
            </tbody>
        </table>
    );
}