/*eslint eqeqeq: ["off"]*/

// Информационная панель о текущем баланесе и акциях
import React from 'react';
import {getStyle} from "../../tools";
import myStyle from "./style.module.css";

/* Статусы состояния договора
*   0 - "Активен"
*   1 - "Закрыт"
*   2 - "Отложенный платеж"
*   3 - "Обслуживание приостановлено"
*   4 - "Отключен за неуплату"
*   5 - "Отключен за неуплату (отлож. плат. недоступен)"
*   6 - "Услуги ограничены"
*/

const PaymentInformation = function(props) {
    const { state, displayBalance, makeTrialPayClick } = props;
    const { status, disableForecast, recommendMoney, payPerMonth, BM } = state;

    return (
    [
        <div key={"PaymentInfo"} className={status.status == 4 || status.status == 2 ? getStyle('b-row yellow b-border') : getStyle('b-row gray b-border')}>
        Баланс: <strong>{displayBalance}</strong> руб.
        {disableForecast && <span>, хватит до <strong>{disableForecast.format('DD.MM.YY')}</strong> {/*, {state.disableForecast.fromNow()} */}</span> }
        {status.status >= 3 ? <strong key="1">&nbsp;Услуги приостановлены.&nbsp;</strong> : null}
        {status.status == 2 ?
            /*<div className={getStyle('b-row yellow b-border')}>
                <div className={getStyle('aside-caption color-main')}> */
            <span><br/>Активирован сервис "Отложенный платеж". Необходимо пополнить счет на сумму {recommendMoney} руб. во избежание ограничений предоставления услуг.</span>
            /*     </div>
             </div> */
            : null}
        {/*{(status == 5 || status == 4) && !this.props.smallLink ?*/}
        {/*<div className={getStyle('b-row yellow b-border')}>*/}
        {/*<i className={getStyle("m-icon icon-warn border f-left")}>!</i>*/}
        {/*<div className={getStyle("aside-caption color-main")}>*/}

        {/*/!*Вы не можете воспользоваться обещанным платежом. Необходимо пополнить счёт любым другим доступным способом*!/*/}
        {/*</div>*/}
        {/*</div>*/}
        {/*:null}*/}
        {payPerMonth ? [ <br key={1} />, 'Ежемесячный платёж за все услуги: ' + Math.ceil(payPerMonth*100)/100 + ' руб.' ]: null }

        {status.status >= 3 /* && status.status != 5 */ && (-displayBalance + 300 > 0)?  [
                recommendMoney ? <div key={1}><br />Необходимо пополнить счет на сумму не менее {-displayBalance + 300} руб. для возобновления предоставления услуг<br/>Услуги станут доступны в течение 15 минут после оплаты</div> : null,
                /*this.props.smallLink &&*/ status.status == 5 ? null :
                <div className={myStyle['btn-promise-pay-float']} key="2">
                    <button key="2.1" type="button" className={status.status==5?getStyle('btn-disable'):getStyle("btn-major")} disabled={status.status==5} onClick={makeTrialPayClick}>Отложенный платеж</button>
                </div>
            ]
            : null }

    </div>,
        BM && BM.length ?
            <div key={"BM"} className={getStyle('b-row yellow b-border')}>
                {BM.map((item, _i) => {
                    return (
                        <div key={_i} dangerouslySetInnerHTML={{__html: item}} />
                    );
                })}
            </div>
            : null
    ]);

};

export default PaymentInformation;