import React from 'react';
import { connect } from 'react-redux';
//import { withRouter } from 'react-router-dom';

import Panel404 from '../../Pages/Page404/panel404';
import PhoneChooser from './PhoneChooser';
import PageRate from "./RtRate";

import OneClickSubscription from './OneClickSubscription';
import * as CONST from '../../const';
import * as AuthActions from "../../Authenticate/actions";
import {bindActionCreators} from "redux";
import {getUrlVars, getUTM_PID} from "../../tools";

class ChapterSLink extends React.Component {

    getPhoneIndex() {
        // const url = new URL(window.location.href);
        // let phoneIndex = parseInt(url.searchParams.get('ph'));
        const params = getUrlVars();
        let phoneIndex = params['ph'];
        if(isNaN(phoneIndex)) phoneIndex = null;
        return phoneIndex;
    }

    constructor(props) {
        super(props);

        this.state = {
            slink: props.slinkData.link,
            loadingState: false,
            phoneIndex: this.getPhoneIndex()
        };
    }

    componentWillReceiveProps(nextProps, nextContext) {
        if(nextProps.slinkData.link !== this.state.slink) {
            this.setState({
                slink: nextProps.slinkData.link,
                phoneIndex: this.getPhoneIndex()
            })
        }
    }

    onSuccess = (cParams) => {
        const Params = this.props.slinkData ? this.props.slinkData.Params : {};

        switch(Params.chapterPage) {
            case 'PhoneChooser': this.props.AuthActions.requestCurrentUser(true, { force_ai: 1, activeContract:  cParams.contract}); break;
            default: break;
        }

        const redirectPath = CONST.ROOT_PATH + '/';
        if(cParams.__history)
             cParams.__history.push(redirectPath);
        else
            window.location.href = redirectPath;
    };

    render() {
        // console.log(this.props);

        const Params = this.props.slinkData ? this.props.slinkData.Params : {};

        switch(Params.chapterPage) {
            case 'PhoneChooser': return <PhoneChooser {...Params} contract={this.props.slinkData.contract}
                                                      phoneIndex={this.state.phoneIndex} _pid={getUTM_PID()}
                                                      link={this.props.slinkData.link}
                                                      onSuccess={this.onSuccess}
            />;
            case 'OneClickSubscription': return <OneClickSubscription {...Params} contract={this.props.slinkData.contract}
                                                      link={this.props.slinkData.link}

            />;
            case 'RtRate': return <PageRate {...Params} ticket={this.props.slinkData.Params.rt_ticket} link={this.props.slinkData.link}/>
            default: return <Panel404/>;
        }
    }
}

function mapStateToProps(state) {
    return {
        authData: state.authenticate
    }
}

function mapDispatchToProps(dispatch) {
    return {
        AuthActions: bindActionCreators(AuthActions, dispatch)
        //        elementActions: bindActionCreators(SubNavBlock, dispatch),
//        authenticate: bindActionCreators(SubNavBlock, dispatch)
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ChapterSLink);