/**
 * Created by dpopov on 12.07.2017.
 */
import React from 'react';
import Row from '../Row';

import stylePanel from './style.module.css';
import AjaxLoadImage from '../../../assets/images/ajax_loader_orange_64.gif';

import InnerNotify from './innerNotify';

import { getStyle} from "../../tools";

export const PanelIsLoading = (
    <div className={stylePanel['inProcessContainer']}>
        <div className={[getStyle('block'), stylePanel['InProcess']].join(' ')} />
        <div className={stylePanel['InProcessImage']}><img src={AjaxLoadImage} alt=""/></div>
    </div>
);


const Panel = (props) => {
    const blockStyles = [ getStyle('block') ].concat(props.className);
    const blockHeaderStyles = [ getStyle('b-header') ].concat(props.headerClassName);

    const panelTitle = props.title || '';
    const iconStyle = props.icon ? [ getStyle('m-icon border') ].concat(props.icon) : null;

    const stripTitle = props.stripTitle || false;

    const rowClassName = [].concat(props.rowClassName || []);

    const noRow = props.noRow;

    const asFrame = props.asFrame;

    //console.log(props);

    const notifyText = props.notify ? props.notify.text : props.notifyText;
    const notifyStyle = props.notify ? props.notify.style : props.notifyStyle;
    const notifyTextStyle = props.notify ? props.notify.textStyle : props.notifyTextStyle;
    const notifyCloseClick = props.notify ? props.notify.closeClick : typeof props.notifyCloseClick == 'function' ? props.notifyCloseClick : null;
    const notifyCloseStyle = props.notify ? props.notify.closeStyle : props.notifyCloseStyle;

    const Frame = (
        <div className={blockStyles.join(' ')} data-test="123123">
            { stripTitle || asFrame ? null :
                <div className={blockHeaderStyles.join(' ')}>
                    <h3>
                        { iconStyle ? <div className={iconStyle.join(' ')} /> : "" }
                        { panelTitle }
                    </h3>
                </div>
            }
            <div>
                {notifyText ?
                    <InnerNotify className={notifyStyle} notifyTextClassName={notifyTextStyle} closeClick={notifyCloseClick} notifyCloseClassName={notifyCloseStyle}>
                        {notifyText}
                    </InnerNotify>
                    : null}
                {props.isLoading ? PanelIsLoading : null}
                {props.children}
            </div>
        </div>
    );

    if(asFrame) return Frame;

    return (
        <Row className={rowClassName} noRow={noRow}>
            {Frame}
        </Row>
    )
};

export default Panel;