/**
 * Created by dpopov on 11.09.2017.
 */
/*eslint eqeqeq: ["off", "smart"]*/
/*eslint array-callback-return: ["off", "smart"]*/

import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Link } from 'react-router-dom';
import { getServiceDataFromTree } from './tools';
import { getStyle } from '../../tools';
import * as authenticateActions from '../../Authenticate/actions';
import * as panelServicesActions from './actions';
import * as CONST from '../../const';
import Panel from '../../Components/Panel';
import { PanelIsLoading } from '../../Components/Panel';
import Oops from '../../Components/Oops';
import 'moment/locale/ru';
import moment from 'moment';
import TariffDescriptionDisplay from "./Components/tariffDescDisplay";

moment.locale('ru');

class PanelTVService extends React.Component {
    initialState() {
        return {
            loadingState: false,
            serviceId: null,
            tvDetails: false,
            loadingParamsState: false,
            ipTVParams: null,
            errorTVParams: null,
            activeContract: null
        };
    }

    constructor(props) {
        super(props);

        this.state = {...this.initialState(), serviceId: props.serviceId, activeContract: props.authData.activeContract };

        this.displayTVParameters = this.displayTVParameters.bind(this);
        this.btnApplyClick = this.btnApplyClick.bind(this);
    }

    componentWillReceiveProps(nextProps) {
        if(nextProps.serviceId != this.state.serviceId) this.setState({...this.initialState(), serviceId: nextProps.serviceId, activeContract: nextProps.authData.activeContract });
    }

    componentDidUpdate(prevProps, prevState) {
        if(prevProps.serviceId != this.state.serviceId) this.refreshExtras();
    }

    refreshExtras() {
        const ExtrasForHCR = this.props.servicesData.ExtrasForHCR;
        const Extras = this.props.servicesData.Extras;
        const ExtrasLoadingState = this.props.servicesData.loadingExtrasState;

        if((!Extras || ExtrasForHCR != this.state.serviceId) && !ExtrasLoadingState) this.props.elementActions.requestExtras(this.props.authData.activeContract, this.state.serviceId);
    }

    displayTVParameters(event) {
        event.preventDefault();

        const ipTVParams = this.props.servicesData.ipTVParams;
        const ParamsForHCR = this.props.servicesData.ParamsForHCR;

        if(!ipTVParams || ParamsForHCR != this.state.serviceId) this.props.elementActions.requestIPTVParams(this.props.authData.activeContract, this.state.serviceId);

        this.setState({ tvDetails: !this.state.tvDetails });
    }

    btnApplyClick(event) {
        event.preventDefault();

        let currentExtras = this.props.servicesData.Extras.active;
        let newExtras = [];

        const applied = event.target.getAttribute('data-applied');
        const extraId = event.target.getAttribute('data-extra');

        if(applied == '1') {
            // отключаем
//            console.log(1);
            Object.keys(currentExtras).forEach(function(extraRuleId) {
                if(currentExtras[extraRuleId] != extraId) newExtras.push(currentExtras[extraRuleId]);
            });
        } else {
//            console.log(2);
            // подключаем
            Object.keys(currentExtras).forEach(function(extraRuleId) {
                newExtras.push(currentExtras[extraRuleId]);
            });
            if(newExtras.indexOf(extraId) == -1) newExtras.push(extraId);       // небольшая "защита" от дурака
        }

//        console.log(newExtras);

        this.props.elementActions.applyExtras(this.props.authData.activeContract, this.state.serviceId, newExtras.join(','));
    }

    render() {
        const self=this;
        const title = this.props.title || "Цифровое ТВ";
        const icon = this.props.icon || getStyle('icon-tv');
        const loadingParamsState = this.props.servicesData.loadingIPTvParamsState;
        const loadingState = this.props.servicesData.panelLoadingState || this.props.servicesData.loadingExtrasState;

        let prevTarif = null;

        const ActiveTariff = getServiceDataFromTree(this.props.servicesData.servicesTree, this.state.serviceId);
        if(!ActiveTariff || this.props.authData.activeContract != this.state.activeContract) return (
            <Panel title={title} icon={icon} isLoading={loadingState}>
                {!loadingState ? <Oops /> : null }
            </Panel>
        );

        if(ActiveTariff.isStop) {
            let iterator = ActiveTariff;
            // Пройдёмся в будущее
            while(iterator.__next) {
                iterator = iterator.__next;
                if(!iterator) break;
                if(iterator.isStop) continue;

                prevTarif = iterator;
                break;
            }
            if(!prevTarif) {
                iterator = ActiveTariff;
                while (iterator.__prev) {
                    iterator = iterator.__prev;
                    if(!iterator) break;
                    if(iterator.isStop) continue;

                    prevTarif = iterator;
                    break;
                }
            }
        }

//        console.log(prevTarif, this.props.servicesData);

        const ipTVParams = this.props.servicesData.ipTVParams;
        const Extras = !ActiveTariff.isStop ? this.props.servicesData.Extras : prevTarif.extras;

        let ActiveExtras = [];

        if(Extras.possible) Extras.possible.map((item) => {
            if(item.applied) {
                const pushItem = item;
                item.ruleOptions = Extras.activeOptions[item.id];
                ActiveExtras.push(item);
            }
        });

        if(ActiveExtras.length) ActiveExtras.sort((a, b) => {
            const aDate = typeof a.ruleOptions === 'object' ? a.ruleOptions.DateTo : null;
            const bDate = typeof b.ruleOptions === 'object' ? b.ruleOptions.DateTo : null;

            if(aDate || bDate) {
                if(!aDate && bDate) return 1;
                if(!bDate && aDate) return -1;

                if(aDate !== bDate) return moment(aDate).isBefore(bDate) ? 1 : -1;
            }

            return a.name < b.name ? -1 : 1;
        });

//        console.log(ActiveExtras);

//        console.log(ActiveTariff);

        // console.log(this.props.servicesData.services, ActiveTariff.parentService);
//        const ParentService = ActiveTariff.parentService ? findParentService(this.props.servicesData.services, ActiveTariff.parentService) : null;
        // console.log(ParentService);

        let extCost = ActiveTariff.extcost;
        if(Extras.active) {
            extCost = 0;
            Object.keys(Extras.active).forEach(function(extRuleID) {
                const extraId = Extras.active[extRuleID];
                Extras.possible.forEach(function(possibleExtra) {
                    if(possibleExtra.id == extraId) extCost += possibleExtra.amount;
                });
            });
        }

        let stbIndex = 1;

        return (
            <Panel title={title} icon={icon} isLoading={loadingState}>
                {[
                <table className={getStyle('line v-mid left-pull')} key="1">
                    <tbody>
                    <tr>
                        <td>Тариф</td>
                        <td>{ActiveTariff.name}</td>
                        <td className={getStyle('t-right')}>
                            {ActiveTariff.parentService?
                                // ParentService ? <Link to={CONST.ROOT_PATH + '/service-tv.'+ParentService.id+'.html'} className={getStyle('button')}>Основная услуга</Link> : null
                                <Link to={CONST.ROOT_PATH + '/service-tv.'+ActiveTariff.parentService+'.html'} className={getStyle('button')}>Основная услуга</Link>
                                :
                                (!ActiveTariff.isStop ? <Link to={CONST.ROOT_PATH + '/service-changetariff.'+ActiveTariff.id+'.html'} className={getStyle('button')}>Сменить тариф</Link> : null)
                            }
                        </td>
                    </tr>
                    {!ActiveTariff.isStop ?
                        [
                        <tr key={1}>
                            <td>Дата активации</td>
                            <td><strong>{moment(ActiveTariff.dateStart).format('DD.MM.YYYY')}</strong></td>
                            <td className={getStyle('t-right')}> </td>
                        </tr>,
                        <tr key={2}>
                            <td>Действует до</td>
                            <td><strong>{moment(ActiveTariff.dateStop).format('DD.MM.YYYY')}</strong></td>
                            <td className={getStyle('t-right')}> </td>
                        </tr>
                        ]
                        :
                        <tr>
                            <td>
                                {prevTarif.dateStart.isBefore(ActiveTariff.dateStart) || prevTarif.dateStart.isEqualNode(ActiveTariff.dateStart) ?
                                    "Тариф до приостанвки" : "Тариф после приостановки"
                                }
                            </td>
                            <td>{prevTarif.name}</td>
                            <td></td>
                        </tr>
                    }
                    <tr>
                        <td>
                            {!ActiveTariff.isStop ? "Базовый пакет" :
                                (prevTarif.dateStart.isBefore(ActiveTariff.dateStart) || prevTarif.dateStart.isEqualNode(ActiveTariff.dateStart) ? "Базовый пакет до приостановки":"Базовый пакет после приостановки")
                            }
                        </td>
                        <td><strong>{!ActiveTariff.isStop ? ActiveTariff.hscost : prevTarif.hscost}</strong> руб/мес</td>
                        <td className={getStyle('t-right')}> </td>
                    </tr>
                    <tr>
                        <td>Дополнительные пакеты</td>
                        <td><strong>{extCost}</strong> руб/мес</td>
                        <td className={getStyle('t-right')}> </td>
                    </tr>
                    {ActiveExtras.length ?
                        <tr>
                            <td colSpan="3">
                                <table className={getStyle('v-mid left-pull')}>
                                    <tbody>
                                    {ActiveExtras.map((item, _i) => {
                                        return (
                                            <tr key={_i}>
                                                <td>{item.name}</td>
                                                <td>
                                                    {item.amount ? <span><strong>{item.amount}</strong> руб/мес</span> : <strong>бесплатно</strong>}
                                                    {typeof item.options === 'object' && item.options.Description && <TariffDescriptionDisplay anons={item.options.Description} hasExternalDesc={item.options.DescriptionLink} />}
                                                </td>
                                                <td>
                                                    {typeof item.ruleOptions === 'object' && item.ruleOptions.DateTo && <span><strong>до {moment(item.ruleOptions.DateTo).format('DD.MM.YYYY')}</strong></span>}
                                                </td>
                                                <td className={getStyle('t-right')}>
                                                    {item.applied && !ActiveTariff.isStop ?
                                                        <button type="button" onClick={this.btnApplyClick} data-applied={item.applied?1:0} data-extra={item.id}>Отключить</button>
                                                        :
                                                        <button type="button" onClick={this.btnApplyClick} data-applied={item.applied?1:0} data-extra={item.id}>Подключить</button>
                                                    }
                                                </td>
                                            </tr>
                                        )
                                    })}
                                    </tbody>
                                </table>
                            </td>
                        </tr>
                    : null}
                    </tbody>
                </table>,
                    <div className={[getStyle('b-row'), self.state.tvDetails?getStyle('gray b-border'):null].join(' ')} key="2">
                        <button type="button" className={[getStyle('btn-dropdown'), self.state.tvDetails?getStyle('active'):null].join(' ')}
                                onClick={self.displayTVParameters}>Параметры приставок и Смарт-ТВ</button>
                    </div>,
                    <div className={[getStyle('dropdonw-block gray'), self.state.tvDetails?null:getStyle('hide')].join(' ')} key="3">
                        { loadingParamsState ? PanelIsLoading : null }
                        { ipTVParams && ipTVParams.hasSTB ?
                            <table className={getStyle('line v-top left-pull')}>
                                <tbody>
                                <tr>
                                    <td colSpan="2"><h3>Параметры приставок</h3></td>
                                </tr>
                                { ipTVParams.params.map((item, _i) => (
                                    item.type == 'stb' ?
                                        <tr key={_i}>
                                            <td>Приставка</td>
                                            <td>IP адрес: <strong>{item.ip}</strong></td>
                                            <td>Mask: <strong>{item.mask}</strong></td>
                                            <td>Gateway: <strong>{item.gw}</strong></td>
                                            <td>DNS1: <strong>{item.dns[0]}</strong></td>
                                            <td className={getStyle('t-right')}> </td>
                                        </tr>
                                        :null
                                ))}
                                </tbody>
                            </table>
                        : null}
                        { ipTVParams && ipTVParams.hasSmart ?
                            <table className={getStyle('line v-top left-pull')}>
                                <tbody>
                                <tr>
                                    <td colSpan="2"><h3>Параметры SMART-TV</h3></td>
                                </tr>
                                { ipTVParams.params.map((item, _i) => (
                                    item.type == 'smart' ?
                                        [
                                            <tr key={_i+'-1'}>
                                                <td rowSpan="2">Параметры доступа</td>
                                                <td>Логин <strong>{item.login}</strong></td>
                                                <td className={getStyle('t-right')}> </td>
                                            </tr>,
                                            <tr key={_i+'-2'}>
                                                <td className={getStyle('no-pull')}>Пароль <strong>{item.password}</strong></td>
                                                <td className={getStyle('t-right')}> </td>
                                            </tr>
                                        ]
                                        :null
                                ))}
                                </tbody>
                            </table>
                        : null}
                    </div>
                ]}
            </Panel>
        );
    }
}

function mapStateToProps(state) {
    return {
        authData: state.authenticate,
        servicesData: state.panelServices
    }
}

function mapDispatchToProps(dispatch) {
    return {
        elementActions: bindActionCreators(panelServicesActions, dispatch),
        authenticate: bindActionCreators(authenticateActions, dispatch)
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(PanelTVService);