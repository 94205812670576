/**
 * Created by dpopov on 24.08.2017.
 */
/* eslint eqeqeq: ["off", "smart"] */
/* eslint jsx-a11y/anchor-is-valid: ["off"] */

import React from 'react';

import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { getStyle } from "../../tools";

import styleNotify from './style.module.css';
import * as NotifyActions from './actions';

class Notify extends React.Component {
    constructor(props) {
        super(props);

        this.clearNotify = this.clearNotify.bind(this);
    }

    clearNotify(event) {
        event.preventDefault();

        if(this.props.state.lastError) {
            this.props.elementActions.clearLastError();
        } else {
            this.props.elementActions.clearLastNotify(this.props.state.text);
        }
    }

    render() {
        const notifyText = this.props.state.text;
        let content = null;

        const displayText = this.props.state.lastError ? this.props.state.lastError : notifyText.length ? notifyText[notifyText.length - 1] : null;

        if(displayText) {
            content = (
                <div className={[getStyle('wrap'), styleNotify['notify']].join(' ')}>
                    <div className={getStyle('notify-message')}>
                        {displayText}
                        <a href="#" className={getStyle('button btn-close inv')} onClick={this.clearNotify}>×</a>
                    </div>
                </div>
            );
        } else {
//            content = (
//                <div className={[style['wrap'], styleNotify['notify']].join(' ')}>
//                    <div className={[style['notify-message'], styleNotify['notify-center']].join(' ')}>
//                        <div className={styleNotify['hdr1']}><a href="tel:+7 (812) 622-00-00">+7 812 622-0000 <span> — офис</span></a></div>
//                        <div className={styleNotify['hdr1']}><a href="tel:+7 (812) 622-00-03">+7 812 622-0003 <span> — техническая поддержка</span></a></div>
//                    </div>
//                </div>
//            );
        }

        return (
            <div className={getStyle('row top-notify')}>{content}</div>
        );
    }
}

function mapStateToProps(state) {
    return {
        authData: state.authenticate,
        state: state['notify']
//        servicesData: state.panelServices
    }
}

function mapDispatchToProps(dispatch) {
    return {
        elementActions: bindActionCreators(NotifyActions, dispatch)
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Notify);
