/**
 * Created by dpopov on 24.08.2017.
 */
/*eslint jsx-a11y/anchor-is-valid: ["off"]*/
/*eslint no-script-url: ["off"]*/
import React from 'react';

import stylePanel from './style.module.css';
import { getStyle } from "../../tools";
import AjaxLoadImage from '../../../assets/images/ajax_loader_orange_64.gif';

const PopUp = (props) => {
    const iconStyle = [].concat(props.iconStyle);
    const panelTitle = props.title || '';
    const onCancel = props.onCancel;
    const isLoading = props.isLoading || false;

    const blockStyles = [ getStyle("block popup-block") ].concat(props.blockStyle);
    const popupStyles = [ getStyle("popup") ].concat(props.popupStyle);
    const blockRowStyle = [ getStyle("b-row") ].concat(props.blockRowStyle);

    return (
        <div className={popupStyles.join(" ")}>
            <div className={getStyle("popup-bg")} />
            <div className={blockStyles.join(" ")}>
                {panelTitle ?
                    <div className={getStyle("b-header")}><h3>
                        { isLoading ?
                            <div className={[stylePanel['popup-loading']].join(' ')} />
                            : null }
                        { iconStyle ? <div className={iconStyle.join(' ')} /> : null }
                        {panelTitle}
                        { onCancel ? <a href="javascript:void(0);" onClick={onCancel} className={getStyle("btn-popup-close m-icon f-right")}>×</a> : null}
                    </h3></div>
                : null}
                <div className={blockRowStyle.join(' ')}>
                    { isLoading ?
                        <div className={[stylePanel['popup-loading']].join(' ')}>
                            <div className={[stylePanel['InProcessImage'], stylePanel['InProcessImage-top81']].join(' ')}><img src={AjaxLoadImage} alt={""} /></div>
                        </div> : null }
                    {props.children}
                </div>
            </div>
        </div>
    );
};

export default PopUp;