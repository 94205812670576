/**
 * Created by dpopov on 31.08.2017.
 */
import React from 'react';

import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
// import { Link } from 'react-router-dom';

import PanelSuspendServices from './PanelSuspendServices';
import PanelSuspendHistory from './PanelSuspendHistory';

import * as suspendActions from './actions';

import 'moment/locale/ru';
import moment from 'moment';
moment.locale('ru');

class SuspendService extends React.Component {
    componentWillMount() {
    }

    componentDidMount() {
    }

    render() {
//        const panelTitle = this.props.title || "История приостановок";
        const state = this.props.state;

//        let displayElements = [];

        let hasActiveSuspend = false;

        if(state.history.length) {
            // Есть история. Проверим, нет ли активных правил.
            // Если активные правила есть, то панель с историей покажем выше панели создания
            state.history.forEach(function(historyItem) {
                if(historyItem.active) hasActiveSuspend = true;
            });
        }

        return (
            hasActiveSuspend?(
                <div>
                    <PanelSuspendHistory />
                    <PanelSuspendServices />
                </div>
            ):(
                <div>
                    <PanelSuspendServices />
                    <PanelSuspendHistory />
                </div>
            )
        );
    }
}

function mapStateToProps(state) {
    return {
        state: state.suspendData
//        services: state.panelServices
//        authData: state.authenticate,
//        servicesData: state.panelServices
    }
}

function mapDispatchToProps(dispatch) {
    return {
        elementActions: bindActionCreators(suspendActions, dispatch)
//        authenticate: bindActionCreators(SubNavBlock, dispatch)
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(SuspendService);