/**
 * Created by dpopov on 17.08.2017.
 */
import React from 'react';
import { getStyle } from "../../tools";

import myStyle from './style.module.css';
import elStyle from './style.module.css';

import Phone from 'react-phone-number-input'
import ContactsForCheck from './contactsForCheck';
//import 'react-phone-number-input/rrui.css'
import 'react-phone-number-input/style.css'
import InputContractNumber from './inputContractNumber';


export default (props) => {
    const recommendMoney = props.recommendMoney;

    let subPanelHeaderClassName = null;
    let subPanelBodyClassName = null;

    if(props.isFrame) {
        subPanelHeaderClassName = myStyle['iframe-subpanel-header'];
        subPanelBodyClassName = myStyle['iframe-subpanel-body'];
    }

    return (
        <div>
        <div className={getStyle('b-row doble-col')}>
            <div className={getStyle('col-main')}>
                <table className={getStyle('form-table')}><tbody>
                <InputContractNumber {...props} />
                <tr>
                    <td>Номер телефона</td>
                    <td><Phone country="RU" countries={["RU"]} type="text" className={getStyle('wide')} value={props.inputValues.phoneNumber || ''} name="phoneNumber" onChange={props.inputPhoneChange} /></td>
                </tr>
                <tr>
                    <td>Сумма платежа</td>
                    <td>
                        <input type="text" value={recommendMoney} className={getStyle('input-x')} onChange={props.amountChange} name="amount" />
                        <button type="button" className={getStyle('btn-major f-right')} onClick={props.makePayment} disabled={props.payButtonDisabled}>Оплатить</button>
                    </td>
                </tr>
                </tbody></table>
                {props.requireContacts ? <ContactsForCheck {...props} /> : null }
            </div>

            <div className={getStyle('col-side')}>
                <div className={getStyle('b-header')}><h4 className={subPanelHeaderClassName}><i className={getStyle('m-icon icon-star border')} /> Условия оплаты</h4></div>
                <ul className={[getStyle('dash t-small'), subPanelBodyClassName].join(' ')}>
                    <li>Комиссия за проведение операции - 0%</li>
                    <li>Mинимальная сумма остатка денежных средств на Лицевом счете Абонента после совершения платежа - 0 &#8381;</li>
                    <li>Минимальная сумма одного платежа — 1 &#8381;</li>
                    <li>Максимальная сумма единовременного платежа — 15 000 &#8381;</li>
                    <li>Максимальная сумма платежей в сутки — 40 000 &#8381;</li>
                    <li>Максимальная сумма платежей в месяц — 40 000 &#8381;</li>
                </ul>
            </div>
        </div>
        <div className={getStyle('b-row')}>
            <h4 className={subPanelHeaderClassName}>Особые условия для абонентов Megafon</h4>
            <ul className={[getStyle('dash t-small'), subPanelBodyClassName].join(' ')}>
                <li>«Мобильные платежи» доступны всем абонентам МегаФона – физическим лицам, а также сотрудникам корпоративных клиентов, у которых подключена услуга «Персональный бюджет». Подробности подключения Мобильных платежей на персональном счете. Услугой не могут воспользоваться абоненты, обслуживающиеся по кредитной системе расчетов, а также при финансовой блокировке номера. За исключением сервиса «Парковки», им могут воспользоваться все абоненты, в том числе и кредитные.</li>
                <li>Платеж невозможен за счет денежных средств, зачисленных на Лицевой счет в виде скидок на услуги связи Оператора, средств, полученных в рамках услуги «Мобильный перевод», а также за счет авансового платежа, вносимого Абонентом при заключении договора об оказании услуг связи с Оператором.</li>
                <li>МК становится невозможной при обращении абонента в КЦ МегаФон с просьбой об отключении услуги либо с помощью USSD команды *191</li>
                <li>Совершая платёж вы соглашаетесь с условиями <a href="http://moscow.megafon.ru/download/~federal/oferts/oferta_m_platezhi.pdf" target="_blank" className={elStyle['oferta']} rel="noopener noreferrer">Оферты</a></li>
            </ul>
        </div>
        </div>
    )
}