/**
 * Created by dpopov on 14.07.2017.
 */
import * as CONST from '../../const';

import 'moment/locale/ru';
import moment from 'moment';
moment.locale('ru');

const initialState = {
    panelLoadingState: true,
    services: {},
    servicesTree: {},
    error: false,
    errorText: "",
    cumulativeServiceTypes: 0,
    historyData: {},
    historyRequestState: false,

    loadingIPTvParamsState: false,
    ParamsForHCR: null,
    ipTVParams: {},

    loadingExtrasState: false,
    ExtrasForHCR: null,
    Extras: {},

    lastRosterDataReceived: null,
    recommendMoney: {},
    forceRecommendMoney: {}
};

function __appendToList(list, appendValue) {
    if (!list) list = {...appendValue, __next: null, __prev: null};
    else {
        let currentListElement = list;
        while (currentListElement.__next) {
            currentListElement = currentListElement.__next;
        }
        currentListElement.__next = {...appendValue, __next: null};
        currentListElement.__next.__prev = currentListElement;
    }
    return {...list};
}

function __servicesDataToTree(services) {
    let Tree = [];

    if(!services) return Tree;

    let today = moment();

    for(let app_i = 0; app_i < services.length; app_i++) {
        const app = services[app_i];

        if(today.isAfter(moment(app.dateStop))) continue;

        const appServicesId = Object.keys(app.services);
        if(!appServicesId.length) continue;

        Tree.push({...app, services: {}});
        const appIndex = Tree.length - 1;

        for(let appSrv_i = 0; appSrv_i < appServicesId.length; appSrv_i++) {
            const appServiceID = appServicesId[appSrv_i];
            Tree[appIndex].services[appServiceID] = undefined;

            let hcrList = {...app.services[appServiceID]};

            // Выстроим список правил в последовательность. От минимальной dateStart
            while(Object.keys(hcrList).length !== 0) {
                const hcrIndexes = Object.keys(hcrList);
                let minimalDate = null;
                let minimalDateRuleIndex = null;

                for(let hcr_i = 0; hcr_i < hcrIndexes.length; hcr_i++) {
                    let HCR = hcrList[hcrIndexes[hcr_i]];
                    HCR.dateStart = moment(HCR.dateStart);
                    HCR.dateStop = moment(HCR.dateStop);
                    const _begin = HCR.dateStart;

                    if(!minimalDate || (minimalDate && minimalDate.isAfter(_begin))) {
                        minimalDate = _begin;
                        minimalDateRuleIndex = hcrIndexes[hcr_i];
                    }
                }
                Tree[appIndex].services[appServiceID] = __appendToList(Tree[appIndex].services[appServiceID], {...hcrList[minimalDateRuleIndex]});
                delete hcrList[minimalDateRuleIndex];
            }
        }
    }

    return Tree;
}

export default function PanelServicesReducer(state = initialState, action) {
    switch(action.type) {
        case CONST.EMPTY_REDUCER: return {...initialState};
        case CONST.SERVICES.PANEL_SERVICES_REQUEST_IN_PROCESS: {
            return {...state, panelLoadingState: action.payload };
        }
        case CONST.SERVICES.PANEL_SERVICES_SET_DATA:  {
            return {...state,
                panelLoadingState: false,
                error: action.payload.error,
                errorText: action.payload.errorText,
                services: action.payload.services,
                servicesTree: __servicesDataToTree(action.payload.services),
                cumulativeServiceTypes: action.payload.cumulativeServiceTypes,
                lastRosterDataReceived: moment()
            };
        }
        case CONST.SERVICES.PANEL_SERVICES_INET_REQUEST_HISTORY: {
            return {...state, historyRequestState: action.payload };
        }
        case CONST.SERVICES.PANEL_SERVICES_INET_SET_HISTORY: {
            return {...state, historyRequestState: false, historyData: action.payload }
        }
        case CONST.SERVICES.PANEL_SERVICES_IPTV_REQUEST_DETAILS: {
            return {...state, loadingIPTvParamsState: action.payload };
        }
        case CONST.SERVICES.PANEL_SERVICES_IPTV_DETAILS_SET: {
            return {...state, loadingIPTvParamsState: false, ParamsForHCR: action.payload.hcr, ipTVParams: action.payload.data }
        }
        case CONST.SERVICES.PANEL_SERVICES_REQUEST_EXTRAS: {
            return {...state, loadingExtrasState: action.payload };
        }
        case CONST.SERVICES.PANEL_SERVICES_EXTRAS_SET: {
            return {...state, loadingExtrasState: false, ExtrasForHCR: action.payload.hcr, Extras: action.payload.data };
        }
        case CONST.SERVICES.SET_RECOMMEND_PAYMENT: {
            if(action.payload.force) return {...state, forceRecommendMoney: {...state.forceRecommendMoney, [action.payload.contract]: action.payload.sum } };
            return {...state, recommendMoney: {...state.recommendMoney, [action.payload.contract]: action.payload.sum } };
        }
        default: return state;
    }
}