import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import axios from 'axios';

import Panel from '../../Components/Panel';

import * as style from './style.module.css';
import * as obitStyle from '../../../assets/css/obit.module.css';
import * as CONST from '../../const';
import { getStyle, getStdNotifyStruct } from '../../tools';
import * as PanelActions from "../PanelNotificationsSettings/actions";
import * as AuthActions from "../../Authenticate/actions";

class PanelChoosePrimaryPhone extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            loadingState: false,
            contract: props.authData.activeContract,
            mainNumber: null,
            deleteNumbers: {},
            appendNumbers: [],
            appendErrText: null,
            saveErrorText: null,
            myNumbers: props.authData.ai_phones ? props.authData.ai_phones.slice() : [],

            changeNumberInterface: false,
            confirmCodeInterface: false,

            resendSMSafter: 60
        };

        this.onClearErrorClick = this.onClearErrorClick.bind(this);
        this.onConfirmButtonClick = this.onConfirmButtonClick.bind(this);
        this.onChangeNumberButtonClick = this.onChangeNumberButtonClick.bind(this);
        this.onConfirmNewNumberButtonClick = this.onConfirmNewNumberButtonClick.bind(this);
        this.onConfirmCodeButtonClick = this.onConfirmCodeButtonClick.bind(this);
    }

    componentWillMount() {
//        this.props.elementActions.requestNotifySettings(this.props.authData.activeContract);
        if(!this.props.Numbers) this.requestCustomerPhoneNumbers(this.props.forceContract || this.props.authData.activeContract);
    }

    componentWillReceiveProps(nextProps) {
        if(!this.props.Numbers && nextProps.authData.activeContract !== this.state.contract) {
            this.requestCustomerPhoneNumbers(nextProps.authData.activeContract, !!this.props.forceContract);
        }
    }

    requestCustomerPhoneNumbers(contract) {
        this.setState({loadingState: true, saveErrorText: null});

        if(this.props.authData.other_phones) {
            let Numbers = this.props.authData.ai_phones.slice().map(item => item.replace(/[^\d]+/g, ''));
            let OtherNumbers = this.props.authData.other_phones.slice().map(item => item.replace(/[^\d]+/g, ''));

            for(let i=0;i<OtherNumbers.length; i++) {
                if(Numbers.indexOf(OtherNumbers[i]) === -1) Numbers.push(OtherNumbers[i]);
            }

            this.setState({loadingState: false, myNumbers: Numbers, contract: contract});
            return;
        }

        const appendParams = this.props.appendRequestsData || {};

        axios.get(CONST.API + '/notify/getPhonesNumbers', {
            withCredentials: true,
            params: {...appendParams,
                contract: contract,
                '.rnd': Math.ceil(Math.random()*1000000)
            }
        }).then((answer) => {
            const data = answer.data;

            if(data.result === 'success') {
                let Numbers = data.data.ai || [];
                if(data.data.ph) {
                    for(let i=0;i<data.data.ph.length; i++) {
                        if(Numbers.indexOf(data.data.ph[i]) === -1) Numbers.push(data.data.ph[i]);
                    }
                }
                this.setState({loadingState: false, myNumbers: Numbers, contract: contract});
            } else {
                this.setState({loadingState: false, saveErrorText: data.text});
            }
        })
    }

    onConfirmButtonClick(phoneNum) {
        return (event) => {
            if(event) event.preventDefault();

            this.setState({loadingState: true});

            const _phoneNum = phoneNum.replace(/[^\d]+/g, '');
            const appendParams = this.props.appendRequestsData || {};

            let sendData = new FormData();
            sendData.append('contract', this.props.authData.activeContract);
            sendData.append('mainPhone', _phoneNum);

            Object.keys(appendParams).forEach(function(key) {
                sendData.append(key, appendParams[key]);
            });

            let phoneList = this.state.myNumbers.slice();
            phoneList.push(...this.state.appendNumbers);

            for(let i=0;i<phoneList.length;i++) {
                let phone = phoneList[i];
                sendData.append('phoneList[]', phone);
            }

            axios.post(CONST.API + '/notify/updateMainNumber', sendData, {
                withCredentials: true
            }).then((answer) => {
                const data = answer.data;

                if(data.result === 'success') {
                    this.props.AuthActions.requestCurrentUser(true, { force_ai: 1, activeContract:  this.props.authData.activeContract});
                } else {
                    this.setState({loadingState: false, saveErrorText: data.text});
                }
            })
        }
    }

    onChangeNumberButtonClick(event) {
        event.preventDefault();
        this.setState({changeNumberInterface: true});
    }

    onConfirmNewNumberButtonClick(event) {
        event.preventDefault();

        const newNumber = this.refs.newNumber.value;
        const contract = this.props.forceContract || this.props.authData.activeContract;

        if(newNumber.replace(/[^\d]+/g, '').length < 10) {
            this.setState({saveErrorText: 'Телефонный номер должен состоять минимум из 10 цифр'});
            return;
        }

        this.setState({loadingState: true, saveErrorText: null});

        this.sendCodeBySMS(newNumber, contract, (data) => {
            this.setState({ loadingState: false, confirmCodeInterface: true });
            this.__counterTick(60);
        })
    }

    onConfirmCodeButtonClick(event) {
        event.preventDefault();

        const code = this.refs.confirmCode.value;
        const newNumber = this.refs.newNumber.value;
        const onConfirmButtonClick = typeof this.props.onConfirmButtonClick === 'function' ? this.props.onConfirmButtonClick : this.onConfirmButtonClick;
        const contract = this.props.forceContract || this.props.authData.activeContract;

        this.setState({loadingState: true, saveErrorText: null});
        this.checkSMSCode(newNumber, contract, code, (data) => {
            if(data.confirmed) {
                onConfirmButtonClick(newNumber)(event);
            } else {
                this.setState({loadingState: false, saveErrorText: 'Введённый код не совпадает'});
            }
        })
    }

    onClearErrorClick(e) {
        if(e) e.preventDefault();
        this.setState({saveErrorText: null});
    }

    sendCodeBySMS(phone, contract, onSuccess) {
        const appendParams = this.props.appendRequestsData || {};
        axios.get(CONST.API + '/homeuser/confirmPhone', {
            withCredentials: true,
            params: {...appendParams,
                DogovorNum: contract,
                phone: phone,
                '.rnd': Math.ceil(Math.random()*1000000)
            }
        }).then((answer) => {
            const data = answer.data;
            if(data.result === 'success') {
                if(typeof onSuccess === 'function') onSuccess(data);
            } else {
                this.setState({saveErrorText: data.text, loadingState: false});
            }
        });
    }

    checkSMSCode(phone, contract, code, onSuccess) {
        const appendParams = this.props.appendRequestsData || {};
        axios.get(CONST.API + '/homeuser/confirmPhone', {
            withCredentials: true,
            params: {...appendParams,
                DogovorNum: contract,
                phone: phone,
                code: code,
                '.rnd': Math.ceil(Math.random()*1000000)
            }
        }).then((answer) => {
            const data = answer.data;
            if(data.result === 'success') {
                if(typeof onSuccess === 'function') onSuccess(data.data);
            } else {
                this.setState({saveErrorText: data.text, loadingState: false});
            }
        });
    }

    __secondToText(second) {
        let high = second % 100;
        if(10 <= high && high < 20) return second + ' секунд';

        let low = high % 10;
        if(low === 1) return second + ' секунду';
        if(2 <= low && low <= 4) return second + ' секунды';

        return second + ' секунд';
    }

    __counterTick(num) {
        setTimeout(() => {
            this.setState({resendSMSafter: num});
            if(num !== 0) this.__counterTick(num-1);
        }, 1000);
    }


    render() {
        const title = this.props.title || 'Уточнение контактной информации';
        const loadingState = this.props.isLoading || this.state.loadingState;
        let phoneList = this.props.Numbers || this.state.myNumbers.slice();
        phoneList.push(...this.state.appendNumbers);

        let displayNumber = this.props.displayNumber;

        if(!displayNumber) {
            for (let i = 0; i < phoneList.length; i++) {
                let item = phoneList[i].replace(/[^\d]+/g, '');
                if (item.match(/\d{10}$/)) {
                    displayNumber = item.replace(/^.*(\d{3})(\d{3})(\d{2})(\d{2})$/, '+7 $1 $2-$3-$4');
                    i = phoneList.length + 1;
                }
            }
        }

        const onConfirmButtonClick = typeof this.props.onConfirmButtonClick === 'function' ? this.props.onConfirmButtonClick : this.onConfirmButtonClick;

        return (
            <Panel title={title} isLoading={loadingState} icon={getStyle('icon-sett')}
                   notify={getStdNotifyStruct(this.state.saveErrorText, this.onClearErrorClick)}>
                <div className={getStyle('b-row b-border t-center')}>
                    <h4>Пожалуйста, укажите номер телефона для уведомлений</h4>

                    {!this.state.changeNumberInterface ?
                        <div>
                            <div className={style['primaryNumber']}><h3>{displayNumber}</h3></div>

                            <div>
                                <button onClick={onConfirmButtonClick(displayNumber)}>Подтвердить</button>
                                <button onClick={this.onChangeNumberButtonClick} className={obitStyle['grey']}>Изменить</button>
                            </div>
                        </div>
                        :
                        <div>
                            <div className={style['primaryNumber']}><input type="text" defaultValue={displayNumber} ref="newNumber" disabled={this.state.confirmCodeInterface} /></div>
                            {!this.state.confirmCodeInterface ?
                                <div>
                                    <button onClick={this.onConfirmNewNumberButtonClick}>Подтвердить</button>
                                </div>
                                :
                                <div>
                                    <div>
                                        На указанный номер было отправлено СМС с кодом подтверждения.<br />
                                        Введите его в поле ниже и нажмите {"\u00ab"}Подтвердить{"\u00bb"}
                                    </div>
                                    <div className={style['confirmCode']}><input type="text" ref="confirmCode" /></div>
                                    <div className={style['singleButton']}><button onClick={this.onConfirmCodeButtonClick}>Подтвердить</button></div>
                                    <div className={style['singleButton']}><button onClick={this.onConfirmNewNumberButtonClick}
                                                                                   disabled={this.state.resendSMSafter !== 0}
                                                                                   className={this.state.resendSMSafter !== 0 ? obitStyle['grey'] : null}>
                                        Отправить код повторно {this.state.resendSMSafter !== 0? "через "+this.__secondToText(this.state.resendSMSafter):null}
                                    </button></div>
                                </div>
                            }
                        </div>
                    }
                </div>
            </Panel>
        );
    }
}

function mapStateToProps(state) {
    return {
        authData: state.authenticate,
        notify: state.NotifySettings
    }
}

function mapDispatchToProps(dispatch) {
    return {
        elementActions: bindActionCreators(PanelActions, dispatch),
        AuthActions: bindActionCreators(AuthActions, dispatch)
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(PanelChoosePrimaryPhone);