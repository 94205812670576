import React from "react";
import {getStyle} from "../../../../tools";
import myStyle from '../../style.module.css';

class Clock extends React.Component {

    state = {};

    static checkTime(i) {
        if (i < 10) {
            i = "0" + i;
        }
        return i;
    }

    componentDidMount() {
        this.displayCurrentTime();
    }

    displayCurrentTime = () => {
        let
            tm = new Date(),
            y = tm.getFullYear(),
            day = parseInt(tm.getDate()),
            month = parseInt(tm.getMonth()) + 1,
            h = tm.getHours(),
            m = tm.getMinutes(),
            s = tm.getSeconds();

        m = Clock.checkTime(m);
        s = Clock.checkTime(s);

        this.setState({
            clock: h + ":" + m + ":" + s,
            date: (day > 9 ? day : '0' + day) + '.' + (month > 9 ? month : '0' + month) + '.' + y
        });

        setTimeout(this.displayCurrentTime, 500);
    };


    render() {
        return (
            <div className={[getStyle('f-right'), myStyle['clock']].join(' ')}>
                <p className={myStyle['clock-time']}>{this.state.date} {this.state.clock}</p>
            </div>
        )
    }


}

export default Clock;