/**
 * Created by dpopov on 14.08.2017.
 */
import * as CONST from '../../const';
import axios from 'axios';

export function getNewsTitle(limit, from) {
    return (dispatch, getState, args) => {
        const DefaultLimit = 10;

        dispatch({ type: CONST.NEWS.PANEL_NEWS_REQUEST_LIST_IN_PROCESS, payload: true});
        dispatch({ type: CONST.NEWS.PANEL_NEWS_SET_LIMIT_COUNT, payload: limit || DefaultLimit });

        axios.get(CONST.API + '/news/getNewsList', {
            withCredentials: true,
            params: {
                limit: limit || DefaultLimit,
                from: from || 0,
		'.rnd': Math.ceil(Math.random()*1000000)
            }
        }).then(function(result) {
            if(result.data.result === 'success') {
                dispatch({ type: CONST.NEWS.PANEL_NEWS_REQUEST_LIST_SET_DATA, payload: result.data.data });
            } else {
                dispatch({ type: CONST.NEWS.PANEL_NEWS_REQUEST_LIST_SET_DATA, payload: [] });
            }
        });
    }
}

export function getNewsItem(id) {
    return (dispatch, getState, args) => {
        dispatch({ type: CONST.NEWS.PANEL_NEWS_REQUEST_ITEM_IN_PROCESS, payload: { id: id } });

        axios.get(CONST.API + '/news/getNewsItem', {
            withCredentials: true,
            params: {
                id: id,
		'.rnd': Math.ceil(Math.random()*1000000)
            }
        }).then(function(result) {
            if(result.data.result === 'success') {
                dispatch({ type: CONST.NEWS.PANEL_NEWS_REQUEST_ITEM_SET_DATA, payload: {id: id, data: result.data.data } });
            }
        });
    }
}
