/**
 * Created by dpopov on 14.09.2017.
 */
import React from 'react';
import { connect } from 'react-redux';
// import { bindActionCreators } from 'redux';

import * as CONST from '../../const';

import SubNavBlock from '../../Components/SubNavBlock';

import { Route } from 'react-router-dom';
import CheckAccess from '../../Authenticate';

import PageInfo from './pageInfo';
import PagePassword from './pagePassword';
import PageSettings from './pageSettings';
import PanelVK from "../../Panels/PanelVK";

export const UserRoutes = [
    <Route key="UserRoutes1" exact path={CONST.ROOT_PATH + "/user-setting.html"} component={CheckAccess(PageSettings, CONST.ACCESS.READ, true)} />,
    <Route key="UserRoutes2" exact path={CONST.ROOT_PATH + "/user-password.html"} component={CheckAccess(PagePassword, CONST.ACCESS.READ, true)} />,
    <Route key="UserRoutes3" exact path={CONST.ROOT_PATH + "/user-info.html"} component={CheckAccess(PageInfo, CONST.ACCESS.READ, true)} />
];

export const PagesLinks = {
    'settings': CONST.ROOT_PATH + '/user-settings.html',
    'password': CONST.ROOT_PATH + '/user-password.html',
    'info': CONST.ROOT_PATH + '/user-info.html'
};

class ChapterUser extends React.Component {

    render() {
        let menuItems = [];
        menuItems[CONST.ROOT_PATH + "/user-setting.html"] = "Уведомления";
        menuItems[CONST.ROOT_PATH + "/user-info.html"] = "Личные данные";
        menuItems[CONST.ROOT_PATH + "/user-password.html"] = "Пароль";

        return (
            <div>
                <SubNavBlock title="Настройки" items={menuItems}/>
                {this.props.children}
                {(!!this.props.authData.authVK && document.location.pathname === CONST.ROOT_PATH + '/user-info.html')? <PanelVK /> : null}
            </div>
        );
    }
}

function mapStateToProps(state) {
    return {
//        services: state.panelServices
        authData: state.authenticate
//        servicesData: state.panelServices
    }
}

function mapDispatchToProps(dispatch) {
    return {
//        elementActions: bindActionCreators(SubNavBlock, dispatch),
//        authenticate: bindActionCreators(SubNavBlock, dispatch)
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ChapterUser);
