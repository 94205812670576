import PanelServiceReducer from './Panels/PanelServices/reducer';
import Authenticate from './Authenticate/reducer';
import PanelNewsReducer from './Panels/PanelNews/reducer';
import PanelPayHistoryReducer from './Panels/PanelPayHistory/reducer';
import PanelPayReducer from './Panels/PanelPay/reducer';
import NotifyReducer from './Components/Notify/reducer';
import SuspendReducer from './Panels/SuspendService/reducer';
import NotificationsSettingsReducer from './Panels/PanelNotificationsSettings/reducer';
import PanelPersonalSettingsReducer from './Panels/PanelPersonalSettings/reducer';
import PanelFAQReducer from './Panels/PanelFAQ/reducer';
import PanelSupportReducer from './Panels/PanelSupport/reducer';
import GooglePayReducer from './Components/GooglePay/reducer';

export default {
    authenticate: Authenticate,
    panelServices: PanelServiceReducer,
    panelNews: PanelNewsReducer,
    panelPayHistory: PanelPayHistoryReducer,
    panelPay: PanelPayReducer,
    notify: NotifyReducer,
    suspendData: SuspendReducer,
    NotifySettings: NotificationsSettingsReducer,
    PersonalSettings: PanelPersonalSettingsReducer,
    FAQ: PanelFAQReducer,
    support: PanelSupportReducer,
    GooglePay: GooglePayReducer,
}