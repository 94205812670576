/**
 * Created by dpopov on 24.08.2017.
 */
/*eslint eqeqeq: ["off", "smart"]*/

import React from 'react';
import PopUp from '../../Components/Panel/PopUp';

import { connect } from 'react-redux';

import DayPickerInput from 'react-day-picker/DayPickerInput';
import LocaleUtils from 'react-day-picker/moment';
import 'react-day-picker/lib/style.css';

import papStyle from './style.module.css';

import * as CONST from '../../const';
import axios from 'axios';
import { getStyle } from "../../tools";

import 'moment/locale/ru';
import moment from 'moment';
moment.locale('ru');

// const DatePickerStyles = {
//     'day': DatePickerStylesSrc['DayPicker'],
//     'navBar': DatePickerStylesSrc['navBar'],
//     'body': DatePickerStylesSrc['body'],
//     'caption': DatePickerStylesSrc['caption'],
//     'weekday': DatePickerStylesSrc['weekday']
// };

class PanelAutoPayments extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            selectedCard: null,
            changed: false,
            requestInProcess: false,
            errorFields: {},
            constructPay: {
                AutoType: 0,
                AmountValue: null,
                shedulePeriodType: 0,
                sheduleTime: null,
                shedulePeriodParam: null,
                balanceLimit: null,
                monthLimit: null,
                sheduleStart: null
            }
        };

        this.refElements = {};

        this.formElementValueChange = this.formElementValueChange.bind(this);
        this.btnMakeRuleClick = this.btnMakeRuleClick.bind(this);
        this.btnDisableRuleClick = this.btnDisableRuleClick.bind(this);
        this.handleDayChange = this.handleDayChange.bind(this);
        this.findActiveRule = this.findActiveRule.bind(this);
        this.appendCard = this.appendCard.bind(this);
        this.handleCardChange = this.handleCardChange.bind(this);
    }

    formElementValueChange(event) {
        const elementName = event.target.getAttribute("name");
        const elementValue = event.target.value;

        let values = this.state.constructPay;
        values[elementName] = elementValue;

        this.setState({ constructPay: values, changed: true });
    }

    handleDayChange(selectedDate) {
//        console.log(selectedDate);
        let values = this.state.constructPay;
        values['sheduleStart'] = selectedDate;
        this.setState({ constructPay: values, changed: true });
    }

    handleCardChange(event) {
        if(event.target.value == 'appendCard') {
            this.appendCard();
        } else {
            this.setState({selectedCard: event.target.value});
        }
    }

    btnMakeRuleClick(event) {
        let self = this;
        if(event) event.preventDefault();
        this.refs.btnMakeRule.disabled = true;
        this.setState({ requestInProcess: true });

        // Проверим корректность ввода информации
        let values = {...this.state.constructPay};
        const activePay = this.findActiveRule();
        let checkResult = true;

        let errorFields = {};

        if(!values.AutoType && activePay) values.AutoType = activePay.AutoType;

        if(!values.sheduleTime && activePay && activePay.sheduleTime) values.sheduleTime = activePay.sheduleTime;
        else if(!values.sheduleTime && this.refElements.scheduleTime) values.sheduleTime = this.refElements.scheduleTime.value;

        if(!values.shedulePeriodType && activePay && activePay.shedulePeriodType) values.shedulePeriodType = activePay.shedulePeriodType;

//        console.log(values, typeof values.sheduleTime);

        if(values.AutoType == 2) {
            // По порогу баланса
            if(!values.monthLimit) values.monthLimit = '10000';
            if(!values.balanceLimit || !values.balanceLimit.match(/^\d+$/)) errorFields['balanceLimit'] = true;
            if(parseInt(values.monthLimit) > 10000) errorFields['monthLimit'] = true;
        } else if(values.AutoType == 1) {
            if(values.sheduleStart === null) values.sheduleStart = moment();
            if(!(values.sheduleStart instanceof moment)) errorFields['sheduleStart'] = true;
            if(values.sheduleTime && typeof values.sheduleTime !== 'number' && !values.sheduleTime.match(/^\d{1,2}$/)) errorFields['sheduleTime'] = true;
            if(values.shedulePeriodParam === null) values.shedulePeriodParam = '1';

            switch(parseInt(values.shedulePeriodType)) {
                case 1:
                    if(!values.shedulePeriodParam) values.shedulePeriodParam = '1';
                    if(!values.shedulePeriodParam.match(/^\d$/)) errorFields['shedulePeriodParam'] = true;
                    if(parseInt(values.shedulePeriodParam) > 7 || parseInt(values.shedulePeriodParam) < 0) values.shedulePeriodParam = 1;

                    break;
                case 2:
//                    console.log('2', values.shedulePeriodParam, parseInt(values.shedulePeriodParam));
                    if(!parseInt(values.shedulePeriodParam)) errorFields['shedulePeriodParam'] = true;
                    if(parseInt(values.shedulePeriodParam) > 31 || parseInt(values.shedulePeriodParam) < 0) errorFields['shedulePeriodParam'] = true;

                    break;
                case 3:
                    if(!values.shedulePeriodParam.match(/^\d$/)) errorFields['shedulePeriodParam'] = true;

                    break;
                default: {
                    errorFields['shedulePeriodType'] = true;
                }
            }
        } else {
            // нужеен выбор
            return false;
        }

        if(Object.keys(errorFields).length) checkResult = false;

        if(!checkResult) {
            this.refs.btnMakeRule.disabled = false;
            this.setState({errorFields: errorFields, requestInProcess: false });
            return;
        }

        values['AccountAutoPayID'] = activePay['AccountAutoPayID'];
        values['contract'] = self.props.authData.activeContract;
        values['card'] = this.state.selectedCard;

        if(!values['card']) {
            const cards = this.props.state.recurrentCards || [];
            values['card'] = cards[0].cardID;
        }

        axios.get(CONST.API + '/payments/updateAutoPayment', {
            withCredentials: true,
            params: {...values, '.rnd': Math.ceil(Math.random()*1000000)}
        }).then(function(result) {
            if(result.data.result == 'success') {
                self.props.onClose(null, { 'refresh': true });
            } else {
                this.refs.btnMakeRule.disabled = false;
                self.setState({ requestInProcess: false });
            }
        });
    }

    btnDisableRuleClick(event) {
        event.preventDefault();
        this.refs.btnDisableRule.disabled = true;
        this.setState({ requestInProcess: true });

        const self = this;

        const activePay = this.findActiveRule();
        if(!activePay['AccountAutoPayID']) {
            this.props.onClose();
            return;
        }

        axios.get(CONST.API + '/payments/disableAutoPayment', {
            withCredentials: true,
            params: {
                'contract': self.props.authData.activeContract,
                'id': activePay['AccountAutoPayID'],
		        '.rnd': Math.ceil(Math.random()*1000000)
            }
        }).then(function(result) {
            if(result.data.result == 'success') {
                self.props.onClose(null, { 'refresh': true });
            } else {
                self.refs.btnDisableRule.disable = false;
                self.setState({ requestInProcess: false });
            }
        })
    }

    findActiveRule() {
        const autoPays = this.props.state.autoPays || [];

        let activePay = {};
        autoPays.forEach(function(autoPay) {
            if(autoPay.StopDate) return true;
            activePay = autoPay;
            return false;
        });

        return activePay;
    }

    elStyle(name, styles) {
        if(this.state.errorFields[name]) return [papStyle['error']].concat(styles).join(' ');
        return (styles || []).join(' ');
    }

    appendCard() {
        this.props.onClose(null, { 'appendCard': true });
    }

    render() {
        const onClose = this.props.onClose;
        const iconStyles = [ getStyle('m-icon icon-rub border') ];
        const constructPay = this.state.constructPay;
        const requestInProcess = this.state.requestInProcess;

        let activePay = this.findActiveRule();

        const hasActivePay = activePay.AccountAutoPayID;

        const changeType = activePay.AutoType ? "modify" : "new";
        const formChanged = this.state.changed;

        const cards = this.props.state.recurrentCards || [];

        const AutoType = constructPay.AutoType || activePay.AutoType || 0;
        const AmountValue = constructPay.AmountValue !== null ? constructPay.AmountValue : activePay.AmountValue || "";
        const shedulePeriodType = constructPay.shedulePeriodType || activePay.shedulePeriodType || 0;
        const sheduleTime = constructPay.sheduleTime !== null? constructPay.sheduleTime : activePay.sheduleTime || 9;

        let shedulePeriodParam = constructPay.shedulePeriodParam !== null ? constructPay.shedulePeriodParam : activePay.shedulePeriodParam || 1;
        if(shedulePeriodType == 1 && shedulePeriodParam > 7) shedulePeriodParam = 1;

        const monthLimit = constructPay.monthLimit !== null ? constructPay.monthLimit : activePay.monthLimit || "";
        const balanceLimit = constructPay.balanceLimit !== null ? constructPay.balanceLimit : activePay.balanceLimit || "";
        const sheduleStart = constructPay.sheduleStart !== null? moment(constructPay.sheduleStart) : activePay.sheduleStart ? moment(activePay.sheduleStart) : moment();

        return (
            <PopUp title="Настройки автоплатежа" onCancel={onClose} iconStyle={iconStyles} blockStyle={papStyle['block']} isLoading={requestInProcess}>
                <table className={getStyle('form-table')}>
                    { cards.length == 0 ?
                        <tbody>
                        <tr>
                            <td>Банковская карта</td>
                            <td><button type="button" onClick={this.appendCard}>Подключить карту</button></td>
                        </tr>
                        </tbody>
                    :
                        <tbody>
                        <tr>
                            <td>Банковская карта</td>
                            <td><select name="card" value={this.state.selectedCard || ''} onChange={this.handleCardChange}>
                                {cards.map(card => (
                                    <option key={card.cardRef} value={card.cardID}>{card.card}</option>
                                ))}
                                <option key="acard" value="appendCard">Подключить карту</option>
                            </select></td>
                        </tr>
                        <tr>
                            <td>Автоплатеж</td>
                            <td>
                                <select name="AutoType" value={AutoType} onChange={this.formElementValueChange} className={this.elStyle('AutoType', [])}>
                                    {AutoType == 0 ? <option value="0">Выберите тип платежа...</option> : null}
                                    <option value="1">по расписанию</option>
                                    <option value="2">по порогу баланса</option>
                                </select>
                            </td>
                        </tr>
                        { AutoType == 1 ? [
                            <tr key="1">
                                <td>Сумма пополнения</td>
                                <td>
                                    <input type="text" name="AmountValue" value={AmountValue} onChange={this.formElementValueChange} className={this.elStyle('AmountValue', [])} />
                                </td>
                            </tr>,
                            <tr key="2">
                                <td>Расписание</td>
                                <td>
                                    <select name="shedulePeriodType" value={shedulePeriodType} onChange={this.formElementValueChange} className={this.elStyle('shedulePeriodType', [])}>
                                        { shedulePeriodType == 0 ? <option value="0">...</option> : null }
                                        <option value="1">Еженедельно</option>
                                        <option value="2">Ежемесячно</option>
                                        <option value="3">Периодически</option>
                                    </select>
                                </td>
                            </tr>,
                            (
                                shedulePeriodType == 1? <tr key="3">
                                    <td>День недели</td>
                                    <td>
                                        <select name="shedulePeriodParam" value={shedulePeriodParam} onChange={this.formElementValueChange} className={this.elStyle('shedulePeriodParam', [])}>
                                            <option value="1">Понедельник</option>
                                            <option value="2">Вторник</option>
                                            <option value="3">Среда</option>
                                            <option value="4">Четверг</option>
                                            <option value="5">Пятница</option>
                                            <option value="6">Суббота</option>
                                            <option value="7">Воскресенье</option>
                                        </select>
                                    </td>
                                </tr>
                                : shedulePeriodType == 2? <tr key="3">
                                    <td>День месяца</td>
                                    <td><input type="text" name="shedulePeriodParam" value={shedulePeriodParam} onChange={this.formElementValueChange} className={this.elStyle('shedulePeriodParam', [])} /></td>
                                </tr>
                                : shedulePeriodType == 3? <tr key="3">
                                    <td>Период платежа, дн</td>
                                    <td><input type="text" name="shedulePeriodParam" value={shedulePeriodParam} onChange={this.formElementValueChange} className={this.elStyle('shedulePeriodParam', [])} /></td>
                                </tr>: null
                            ),
                            <tr key="4">
                                <td>Первый платёж не ранее</td>
                                <td><DayPickerInput value={sheduleStart.format('DD.MM.YYYY')} dayPickerProps={{localeUtils: LocaleUtils, locale:"ru"}}  className={this.elStyle('sheduleStart', [getStyle('forceInputStyle')])} onDayChange={this.handleDayChange} placeholder="дд.мм.гггг" format="DD.MM.YYYY" /></td>
                            </tr>,
                            <tr key="5">
                                <td>Время платежа:</td>
                                <td>
                                    <select name="sheduleTime" value={sheduleTime} onChange={this.formElementValueChange} ref={(input) => this.refElements.scheduleTime = input }>
                                        <option value="0">00:00</option>
                                        <option value="1">01:00</option>
                                        <option value="2">02:00</option>
                                        <option value="3">03:00</option>
                                        <option value="4">04:00</option>
                                        <option value="5">05:00</option>
                                        <option value="6">06:00</option>
                                        <option value="7">07:00</option>
                                        <option value="8">08:00</option>
                                        <option value="9">09:00</option>
                                        <option value="10">10:00</option>
                                        <option value="11">11:00</option>
                                        <option value="12">12:00</option>
                                        <option value="13">13:00</option>
                                        <option value="14">14:00</option>
                                        <option value="15">15:00</option>
                                        <option value="16">16:00</option>
                                        <option value="17">17:00</option>
                                        <option value="18">18:00</option>
                                        <option value="19">19:00</option>
                                        <option value="20">20:00</option>
                                        <option value="21">21:00</option>
                                        <option value="22">22:00</option>
                                        <option value="23">23:00</option>
                                    </select>
                                </td>
                            </tr>
                        ] :
                        AutoType == 2 ? [
                            <tr key="1">
                                <td>Сумма пополнения</td>
                                <td>
                                    <input type="text" name="AmountValue" value={AmountValue} onChange={this.formElementValueChange} className={this.elStyle('AmountValue', [])} />
                                </td>
                            </tr>,
                            <tr key="2">
                                <td>Порог баланса, руб.:</td>
                                <td><input type="text" name="balanceLimit" value={balanceLimit} onChange={this.formElementValueChange} className={this.elStyle('balanceLimit', [])} /></td>
                            </tr>,
                            <tr key="3">
                                <td>Лимит пополнения, руб./мес.:</td>
                                <td><input type="text" name="monthLimit" value={monthLimit} onChange={this.formElementValueChange} className={this.elStyle('monthLimit', [])} /></td>
                            </tr>
                        ] : null }
                        <tr><td colSpan="2">
                            { formChanged ?
                                <button type="button" className={[getStyle('btn-major btn-big')].join(' ')} onClick={this.btnMakeRuleClick} ref="btnMakeRule">
                                    {changeType == "new" ? "Создать" : "Сохранить изменения" }
                                </button> : null }

                            <button type="button" className={[getStyle('btn-major btn-big')].join(' ')} onClick={this.btnDisableRuleClick} ref="btnDisableRule">
                                {hasActivePay ? "Отключить" : "Отмена" }
                            </button>
                        </td></tr>
                        </tbody>
                    }
                </table>
            </PopUp>
        );
    }
}

function mapStateToProps(state) {
    return {
        authData: state.authenticate,
        state: state.panelPay
//        servicesData: state.panelServices
    }
}

function mapDispatchToProps(dispatch) {
    return {
//        elementActions: bindActionCreators(PanelActions, dispatch)
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(PanelAutoPayments);
