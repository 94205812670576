import React from 'react';
import {getStyle} from "../../../tools";
import InputContractNumber from "../inputContractNumber";
import ContactsForCheck from "../contactsForCheck";
import myStyle from "../style.module.css";

const ALLOW_CARD_CHOOSE = false;
const ALLOW_RECURRENT_PAYS = false;

class Sberbank extends React.Component {

    paymentClick = (event) => {
        // props.makePayment
        event.preventDefault();
        this.props.makePayment(event);

//        this.registerOrder('88000000', 10)
    };

    // registerOrder = (contract, amount) => {
    //     const data = new FormData();
    //
    //     data.set('userName', 'obit-api');
    //     data.set('password', 'obit');
    //     data.set('amount', amount);
    //     data.set('returnUrl', 'https://my.obit.ru/sberbank');
    //     data.set('jsonParams', JSON.stringify({ contract: contract }));
    //
    //
    //     axios.post('https://3dsec.sberbank.ru/payment/rest/register.do', data, {})
    //         .then((response) => {
    //             console.log(response)
    //         });
    // };

    render() {
        const props = this.props;
        //const { cardList, recommendMoney, hasAutoPayment, smallLink, isFrame } = this.props;
        const { recommendMoney, hasAutoPayment, smallLink, isFrame } = this.props;

        let subPanelHeaderClassName = null;
        let subPanelBodyClassName = null;

        if(props.isFrame) {
            subPanelHeaderClassName = myStyle['iframe-subpanel-header'];
            subPanelBodyClassName = myStyle['iframe-subpanel-body'];
        }

        const cardsOptions = [];

        cardsOptions.push(<option value="" key="lastOption">новая банковская карта</option>);

        return (
            <div>
                <div className={getStyle('b-row doble-col')} key="2">
                    <div className={getStyle('col-main')}>
                        <table className={getStyle('form-table')}><tbody>
                        {isFrame ?
                            <InputContractNumber {...props} />
                            :
                            ALLOW_CARD_CHOOSE && <tr>
                                <td>Банковская карта</td>
                                <td>
                                    <select name="cards" className={getStyle('wide')} onChange={props.inputValueChange}
                                            value={props.inputValues.cards}>{cardsOptions}</select>
                                </td>
                            </tr>
                        }
                        <tr>
                            <td>Сумма платежа</td>
                            <td>
                                <input type="text" value={recommendMoney} className={getStyle('input-x')} onChange={props.amountChange} />
                                <button type="button" className={getStyle('btn-major f-right')} onClick={this.paymentClick} disabled={props.payButtonDisabled}>Оплатить</button>
                            </td>
                        </tr>
                        {smallLink || isFrame ? null :
                            ALLOW_RECURRENT_PAYS && <tr>
                                <td colSpan={2}>
                                    <button type="button" className={getStyle('btn-popup')}
                                            onClick={props.autoPayments}>
                                        { hasAutoPayment ? "Изменить автоплатёж" : "Включить автоплатеж" }
                                    </button>
                                </td>
                            </tr>
                        }
                        </tbody></table>
                        {props.requireContacts ? <ContactsForCheck {...props} /> : null }
                    </div>

                    <div className={getStyle('col-side')}>
                        <div className={getStyle('b-header')}><h4 className={subPanelHeaderClassName}><i className={getStyle('m-icon icon-star border')} /> Условия оплаты</h4></div>
                        <ul className={[getStyle('dash t-small'), subPanelBodyClassName].join(' ')}>
                            <li>Комиссия за проведение операции - 0%</li>
                            <li>Для оплаты (ввода реквизитов Вашей карты) Вы будете перенаправлены на платёжный шлюз ПАО СБЕРБАНК. Соединение с платёжным шлюзом и передача информации осуществляется в защищённом режиме с использованием протокола шифрования SSL. В случае если Ваш банк поддерживает технологию безопасного проведения интернет-платежей Verified By Visa, MasterCard SecureCode, MIR Accept, J-Secure для проведения платежа также может потребоваться ввод специального пароля.</li>
                            <li>Настоящий сайт поддерживает 256-битное шифрование. Конфиденциальность сообщаемой персональной информации обеспечивается ПАО СБЕРБАНК. Введённая информация не будет предоставлена третьим лицам за исключением случаев, предусмотренных законодательством РФ. Проведение платежей по банковским картам осуществляется в строгом соответствии с требованиями платёжных систем МИР, Visa Int., MasterCard Europe Sprl, JCB.</li>
                            <li>«ОБИТ» не имеет доступа к вводимой информации</li>
                            <li>«ОБИТ» не хранит данные банковских карт на своих серверах</li>
                        </ul>
                    </div>
                </div>

            </div>
        );
    }
}

export default Sberbank;