/**
 * Created by dpopov on 19.07.2017.
 */
import React from 'react';
import ChapterBilling from './index';

import SuspendService from '../../Panels/SuspendService';

export default (props) => {
    return (
        <ChapterBilling>
            <SuspendService />
        </ChapterBilling>
    );
}