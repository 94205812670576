/**
 * История списаний
 *
 * Created by dpopov on 21.02.2018.
 */
/* eslint eqeqeq: ["off", "smart"] */

import React from 'react';

import { connect } from 'react-redux';
// import { bindActionCreators } from 'redux';
// import { Link } from 'react-router-dom';

import axios from 'axios';

import Panel from '../../Components/Panel';
import Paginator from '../../Components/Panel/paginator';

import * as CONST from '../../const';
import { getStyle, getStdNotifyStruct, addZeroes } from '../../tools';

import 'moment/locale/ru'
import moment from 'moment';
moment.locale('ru');

const initialState = {
    loadingState: false,
    errorText: null,
    limitFrom: 0,
    limitCount: 10,
    total: 0,
    periodStart: moment().startOf('month'),
    periodEnd: moment().endOf('month'),
    writeOffs: [],
    activeContract: null
};

const PERIODS = {
    CURRENT_MONTH: 1,
    PREV_MONTH: 2,
    CURRENT_YEAR: 3,
    ALL_TIME: 4
};

class PanelWriteOffofFunds extends React.Component {
    constructor(params) {
        super(params);

        this.state = {...initialState};

        this.requestData = this.requestData.bind(this);
        this.switchPeriod = this.switchPeriod.bind(this);
        this.changeDisplayPage = this.changeDisplayPage.bind(this);
        this.changeDisplayLimit = this.changeDisplayLimit.bind(this);
        this.onClearErrorClick = this.onClearErrorClick.bind(this);
    }

    _displayPeriodType(date) {
        if(date.startOf('month').format() == moment().startOf('month').format()) return PERIODS.CURRENT_MONTH;
        if(date.startOf('month').format() == moment().subtract(1, 'months').startOf('month').format()) return PERIODS.PREV_MONTH;
        if(date.startOf('day').format('YYYY MM') == moment().subtract(1, 'year').startOf('day').format('YYYY MM')) return PERIODS.CURRENT_YEAR;

        return PERIODS.ALL_TIME;
    }

    componentWillMount() {
        this.requestData(this.props.authData.activeContract, this.state.periodStart, this.state.periodEnd, this.state.limitFrom, this.state.limitCount);
    }

    componentWillReceiveProps(nextProps) {
        if(nextProps.authData.activeContract !== this.state.activeContract) {
            this.requestData(nextProps.authData.activeContract, this.state.periodStart, this.state.periodEnd, this.state.limitFrom, this.state.limitCount);
        }
    }

    requestData(contract, periodStart, periodEnd, limitFrom, limitCount) {
        this.setState({
            loadingState: true,
            //periodStart: periodStart,
            //periodEnd: periodEnd,
            //activeContract: contract
        });

        axios.get(CONST.API + '/finance/getWriteOffs', {
            withCredentials: true,
            params: {
                contract: contract,
                periodStart: periodStart.format(),
                periodEnd: periodEnd.format(),
                limitFrom: limitFrom,
                limitCount: limitCount
            }
        }).then((answer) => {
            if(this.refs.dataTable) this.refs.dataTable.scrollIntoView(true);
            const data = answer.data;
            if(data.result === 'success') {
                this.setState({
                    loadingState: false,
                    activeContract: contract,
                    periodStart: moment(data.data.periodStart),
                    periodEnd: moment(data.data.periodEnd),
                    total: data.data.total,
                    limitFrom: data.data.limitFrom,
                    limitCount: data.data.limitCount,
                    writeOffs: data.data.writeOffs,
                    errorText: null
                });
            } else {
                this.setState({loadingState: false, errorText: data.text });
            }
        });
    }

    switchPeriod(event) {
        const periodType = parseInt(event.target.getAttribute('data-type'));
        const activePeriod = this._displayPeriodType(this.state.periodStart);
        if(activePeriod == periodType) return;

        let periodStart = null;
        let periodEnd = null;
        switch(periodType) {
            case PERIODS.CURRENT_MONTH:
                periodStart = moment().startOf('month');
                periodEnd = moment().endOf('month');

                break;
            case PERIODS.PREV_MONTH:
                periodStart = moment().subtract(1, 'months').startOf('month');
                periodEnd = moment().subtract(1, 'months').endOf('month');

                break;
            case PERIODS.CURRENT_YEAR:
             //   console.log(1);
                periodStart = moment().subtract(1, 'year').startOf('day');
                periodEnd = moment().endOf('year');

                break;
            default:
                periodStart = moment().subtract(100, 'year').startOf('day');
                periodEnd = moment().endOf('year');

                break;
        }

        this.requestData(this.props.authData.activeContract, periodStart, periodEnd, this.state.limitFrom, this.state.limitCount);
    }

    changeDisplayPage(event) {
        event.preventDefault();

        const newFrom = event.target.getAttribute('data-from');
        this.requestData(this.state.activeContract, this.state.periodStart, this.state.periodEnd, newFrom, this.state.limitCount);
    }

    changeDisplayLimit(event) {
        event.preventDefault();

        const newLimit = event.target.getAttribute('data-count');
        this.requestData(this.state.activeContract, this.state.periodStart, this.state.periodEnd, this.state.limitFrom, newLimit);
    }

    onClearErrorClick(event) {
        if(event) event.preventDefault();
        this.setState({errorText: null});
    }

    render() {
        const title = this.props.title || 'История начислений';
        const loadingState = this.state.loadingState;

        const activePeriod = this._displayPeriodType(this.state.periodStart);

        const notify = getStdNotifyStruct(this.state.errorText, this.onClearErrorClick);

        return (
            <Panel title={title} icon={getStyle('icon-rub')} isLoading={loadingState} notify={notify}>
                    <div>
                        <div className={getStyle('b-row gray')}>
                            <div className={getStyle('f-left')}>
                                <button type="button" className={activePeriod==PERIODS.CURRENT_MONTH?getStyle('active'):null} data-type={PERIODS.CURRENT_MONTH} onClick={this.switchPeriod}>За этот месяц</button>
                                <button type="button" className={activePeriod==PERIODS.PREV_MONTH?getStyle('active'):null} data-type={PERIODS.PREV_MONTH} onClick={this.switchPeriod}>Предыдущий месяц</button>
                                <button type="button" className={activePeriod==PERIODS.CURRENT_YEAR?getStyle('active'):null} data-type={PERIODS.CURRENT_YEAR} onClick={this.switchPeriod}>За год</button>
                            </div>
                        </div>
                        <table className={getStyle('line v-top left-pull')} ref="dataTable">
                            <thead>
                            <tr>
                                <th>Дата</th>
                                <th>Сумма</th>
                                <th>Комментарий</th>
                            </tr>
                            </thead>
                            <tbody>
                            {this.state.writeOffs.length ?
                                this.state.writeOffs.map((item, i) => {
                                    let description = item.Description;

                                    if(!item.isAbonenemt && item.Mask) {
                                        if(item.Mask & 1) description = 'Абонентская плата за доступ в Интернет';
                                        if(item.Mask & 2) description = 'Абонентская плата за цифровое ТВ';
                                        if(item.Mask & 4) description = 'Абонентская плата за телефонную связь';

                                        if(item.Mask & 1 && item.Mask & 2) description = 'Абонентская плата за пакет (интернет + ТВ)';
                                        if(item.Mask & 1 && item.Mask & 4) description = 'Абонентская плата за пакет (интернет + телефон)';
                                        if(item.Mask & 1 && item.Mask & 2 && item.Mask & 4) description = 'Абонентская плата за пакет (интернет + ТВ + телефон)';
                                    }

                                    return <tr key={i}>
                                        <td>{moment(item.chargeDate).format('DD.MM.YYYY')}</td>
                                        <td>{addZeroes((Math.ceil(item.Amount * 100)/100))}</td>
                                        <td>{description.split("\n").map((text, textIndex) => {
                                            return <div key={textIndex}>{text}</div>
                                        })}</td>
                                    </tr>
                                })
                                :
                                <tr><td colSpan="3">За выбранный период начислений не совершалось</td></tr>
                            }
                            </tbody>
                        </table>
                        <Paginator currentLimit={this.state.limitCount} displayFrom={this.state.limitFrom} totalCount={this.state.total} changeDisplayPage={this.changeDisplayPage} changeLimitClick={this.changeDisplayLimit} />
                    </div>
            </Panel>
        );
    }
}

function mapStateToProps(state) {
    return {
        authData: state.authenticate
    }
}

function mapDispatchToProps(dispatch) {
    return {

    }
}

export default connect(mapStateToProps, mapDispatchToProps)(PanelWriteOffofFunds);