/**
 * Created by dpopov on 13.12.2017.
 */
import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import axios from 'axios';

import Panel from '../../Components/Panel/PopUp';

import * as PanelActions from '../PanelPay/actions';
import style from './style.module.css';
import * as CONST from '../../const';
import { getStyle } from '../../tools';

import 'moment/locale/ru';
import moment from 'moment';
moment.locale('ru');


class PayPromisePanel extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            loadingState: false,
            notifyText: null
        };

        this.makeTrialPayClick = this.makeTrialPayClick.bind(this);
    }

    makeTrialPayClick(event) {
        event.preventDefault();
        this.setState({ loadingState: true, notifyText: null });

        axios.get(CONST.API + '/payments/delayPayment', {
            withCredentials: true,
            params: {
                contract: this.props.authData.activeContract,
		'.rnd': Math.ceil(Math.random()*1000000)
            }
        }).then((answer) => {
            const data = answer.data;

            if(data.result === 'success') {
                this.setState({loadingState: false});
                this.props.elementActions.getFinanceDetails(this.props.authData.activeContract);
                if(typeof this.props.onCancel === 'function') this.props.onCancel(event);
            } else {
                this.setState({loadingState: false, notifyText: data.text});
            }
        })
    }

    render() {
        const onCancel = this.props.onCancel;

        const till = moment().add(3, 'days');

        return (
            <Panel title='Условия предоставления сервиса "Отложенный платёж"' onCancel={onCancel} blockStyle={style['block']} blockRowStyle={style['root-row']} isLoading={this.state.loadingState}>
                <div className={getStyle('b-row b-border')}>
                    При подключении данного сервиса, услуги связи (предоставление которых было прекращено в связи с задолженностью)
                    будут восстановлены на срок до <b>{till.format('DD.MM.YYYY')}</b>. До этой даты вы обязуетесь внести на счет сумму равную абонентскому платежу за месяц.
                </div>
                <div className={getStyle('b-row b-border')}>До момента поступления полного абонентского платежа повторная активация данного сервиса будет невозможна!</div>
                <div className={getStyle('b-row b-border')}>При использовании сервиса 'Отложенный платеж' предоставление услуг связи будет возобновлено в течение 15 минут.</div>
                <div className={getStyle('b-row t-center')}>
                    <button type="button" onClick={this.makeTrialPayClick}>Активировать</button>
                    <button type="button" onClick={onCancel}>Отмена</button>
                </div>
            </Panel>
        );
    }
}

function mapStateToProps(state) {
    return {
        authData: state.authenticate,
        state: state.panelPay
//        servicesData: state.panelServices
    }
}

function mapDispatchToProps(dispatch) {
    return {
        elementActions: bindActionCreators(PanelActions, dispatch)
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(PayPromisePanel);
