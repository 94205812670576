import React from 'react';
import Phone from 'react-phone-number-input'

import { getStyle } from '../../tools';
//import * as CONST from "../../const";

export default class ContactsForCheck extends React.Component {
    render() {
        const {
            updateStateValues = () => {},
            _state = {}
        } = this.props;

        const { checkMask, checkPhone, checkMail } = _state;

        return (
            <table className={getStyle('form-table')}>
                <tbody>
                <tr>
                    <td colSpan={2}><label className={getStyle('input-checkbox clearfix')}><input type="checkbox" name="newsLimit" data-bit={1} checked={!!(checkMask & 1)} onChange={() => updateStateValues({checkMask: checkMask ^ 1})} /><div className={getStyle('input f-left')}></div> <div className={getStyle('aside-caption')}>Отправить чек по SMS</div></label></td>
                </tr>
                {checkMask & 1 ?
                    <tr>
                        <td>Номер телефона</td><td><Phone country="RU" countries={["RU"]} type="text" className={getStyle('wide')} value={checkPhone || ''} name="phoneNumber" onChange={(number) => updateStateValues({checkPhone: number})} /></td>
                    </tr>
                : null}
                <tr>
                    <td colSpan={2}><label className={getStyle('input-checkbox clearfix')}><input type="checkbox" name="newsLimit" data-bit={1} checked={!!(checkMask & 2)} onChange={() => updateStateValues({checkMask: checkMask ^ 2})} /><div className={getStyle('input f-left')}></div> <div className={getStyle('aside-caption')}>Отправить чек по E-Mail</div></label></td>
                </tr>
                {checkMask & 2 ?
                    <tr>
                        <td>Адрес E-Mail</td><td><input type="text" value={checkMail} className={getStyle('wide')} onChange={(event) => updateStateValues({ checkMail: event.currentTarget.value })} /></td>
                    </tr>
                :null}

                </tbody>
            </table>
        );
    }
}