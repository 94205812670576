/**
 * Created by dpopov on 03.11.2017.
 */
import React from 'react';
import { Link } from 'react-router-dom';

import * as CONST from '../../const';
import { getStyle } from '../../tools';

export default(props) => {
    const className = props.className || getStyle('b-row t-center');

    return (
        <div className={className}>
            <div>
                {props.children ?
                    props.children
                    :
                    <h3>Упс. Что-то пошло не так</h3>
                }
                <Link to={CONST.ROOT_PATH} className={getStyle('button')}>На главную страницу</Link>
            </div>
        </div>
    )
}