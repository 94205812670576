/**
 * Created by dpopov on 11.07.2017.
 */
/* eslint eqeqeq: ["off", "smart"] */
/* eslint jsx-a11y/anchor-is-valid: ["off", "smart"] */
//import React, { Component } from 'react';
import React from 'react';
import { connect } from 'react-redux';
import Panel from '../Components/Panel';
import AuthForm from '../Authenticate/form';

import { getStyle } from "../tools";
import styleAuth from './style.module.css';

export default function CheckAccess(Component, RequireAccess, ComponentIsPage) {
    class CheckAccess extends React.Component {
        constructor(props) {
            super(props);

            this.state = {...this.state,
                displayAuthForm: false
            };

            this.handlerAuthLink = this.handlerAuthLink.bind(this);
            this.handlerCancelClick = this.handlerCancelClick.bind(this);
        }

        handlerAuthLink(event) {
            event.preventDefault();
            this.setState({displayAuthForm: true});
        }

        handlerCancelClick(event) {
            event.preventDefault();
            this.setState({displayAuthForm: false});
        }

        render() {
            let component = ComponentIsPage?
                <div>
                    <Panel title="Ошибка доступа" icon={getStyle('icon-sett')}>
                        <div className={styleAuth['deniedContainer']}>
                            <div className={styleAuth['deniedText']}>
                                Для доступа к данной функции необходима&nbsp;
                                <a href="#" onClick={this.handlerAuthLink} className={getStyle('a-color')}>авторизация</a>
                            </div>
                        </div>
                    </Panel>
                    { this.state.displayAuthForm ?
                        <div>
                            <AuthForm>
                                <button className={getStyle('btn-major btn-big')} onClick={this.handlerCancelClick}>Отмена</button>
                            </AuthForm>
                        </div>
                        : null
                    }
                </div>
                : null;

            if(this.props.access.allowed & RequireAccess) component = <Component {...this.props} />;

            return component;
        }
    }

    function mapStateToProps(state) {
        return {
            access: state.authenticate,
            _state: state
        }
    }

    return connect(mapStateToProps)(CheckAccess);
}