/**
 * Панель "Пополнение счёта"
 * Created by dpopov on 19.07.2017.
 */
/* eslint eqeqeq: ["off", "smart"] */
/* eslint no-useless-escape: ["off"] */
/* eslint default-case: ["off"] */
/* eslint no-fallthrough: ["off"] */

import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Link } from 'react-router-dom';
import axios from 'axios';
import Panel from '../../Components/Panel';
import myStyle from './style.module.css';
import PaymentInterface from "./payInterface";
import HiddenForm from './payHiddenForm';
import PayPromisePanel from '../PanelPromisePay';
import { getStyle, getStdNotifyStruct, phoneFormatForInput } from '../../tools';
import * as CONST from '../../const';
import PanelAutoPayments from '../PanelAutoPayments';
import * as PanelActions from './actions';
import 'moment/locale/ru'
import moment from 'moment';
import {API} from "../../const";
import PopUp from "../../Components/Panel/PopUp";
import PaymentInformation from "./payInformation";

moment.locale('ru');

export const PAYMENTS = {
    "card": "card",
    "sber": "sber",
    "qiwi": "qiwi",
    "yandex": "yandex",
    "wm": "wm",
    "sms": "sms",
    "check": "check",
    "google": "google",
    "apple": "apple",
};

const RFI_PAY_TYPES = {
    "qiwi": "qiwi",
    "yandex": "ym",
    "wm": "wm",
    "sms": "mc"
};

// запрашивать ли контактную информацию для высылки чека
const RequireContactInfo = false;

const fakeState = {
    contracts: {}
};

class PanelPay extends React.Component {
    constructor(props) {
        super(props);

        this.inputAmountChange = this.inputAmountChange.bind(this);
        this.inputValueChange = this.inputValueChange.bind(this);
        this.makePayment = this.makePayment.bind(this);
        this.inputPhoneChange = this.inputPhoneChange.bind(this);
        this.autoPaymentsClick =this.autoPaymentsClick.bind(this);
        this.autoPaymentsCloseClick = this.autoPaymentsCloseClick.bind(this);
        this.makePaymentWrapper = this.makePaymentWrapper.bind(this);
        this.makeTrialPayClick = this.makeTrialPayClick.bind(this);
        this.clearNotify = this.clearNotify.bind(this);
        this.updateStateValues = this.updateStateValues.bind(this);
        this.makePaymentWrapperQIWI = this.makePaymentWrapperQIWI.bind(this);

        const authData = props.authData
            ? props.authData.activeContract
                ? props.authData
                :  this.props.forceState
                    ? this.props.forceState.authenticate
                    : null
            : null;

        const activeContract = authData ? authData.activeContract : null;

        this.state = {
            inputAmount: null,
            phoneNumber: authData && authData.ai_phones ? (authData.ai_phones[0] ||'') : '',
            cards: undefined,
            displayAutoPayments: false,
            hiddenFormData: null,
            loadingState: false,
            notifyText: null,
            trialPayDialog: false,
            frameContractNumber: ''+(props.prioritetContract || (activeContract ? activeContract : '')),
            checkMask: 0,
            checkPhone: phoneFormatForInput(authData && authData.ai_phones ? authData.ai_phones[0] : ''),
            checkMail: authData && authData.ai_mails ? authData.ai_mails[0] : '',
            activeContract: activeContract,
            hasErrors: [],
            showNotification: false,
        };

        this.isFrame = props.isFrame || false;
    }

    componentDidMount() {
        const authData = this.props.forceState ? this.props.forceState.authenticate : this.props.authData;
        const state = this.props.forceState ? this.props.forceState.panelPay : this.props.state;

        if(this.props.smallLink) {
            return;
        }

        if(authData) {
            const abRecommend = this.props.panelServices.recommendMoney[authData.activeContract];
            if (!state.financeDataLoaded || state.dataContract != authData.activeContract) {
                this.props.elementActions.getFinanceDetails(authData.activeContract, abRecommend);
            }
            this.props.elementActions.getContractBalanceMonitors(authData.activeContract)
        }
    }

    componentWillReceiveProps(nextProps) {
        if(nextProps.smallLink) return;

        if(nextProps.authData.activeContract !== this.state.activeContract) {
            const abRecommend = this.props.panelServices.recommendMoney[nextProps.authData.activeContract];
            // смена договора
            this.setState({activeContract: nextProps.authData.activeContract, checkPhone: nextProps.authData.ai_phones[0] || '', checkMail: nextProps.authData.ai_mails[0] || '' });
            this.props.elementActions.getFinanceDetails(nextProps.authData.activeContract, abRecommend);
            this.props.elementActions.getContractBalanceMonitors(nextProps.authData.activeContract)
        }
    }

    inputAmountChange(event) {
        this.setState({ inputAmount: event.target.value });
    }

    updateStateValues(values) {
        this.setState({...this.state, ...values});
    };

    inputPhoneChange(number) {
        this.setState({phoneNumber: number});
    }

    inputValueChange(event) {
        const element = event.target;
        const elementName = element.getAttribute('name');

        let stateUpdate = {};
        stateUpdate[elementName] = element.value;

        this.setState(stateUpdate);
    }

    makePaymentWrapperQIWI(event){
        this.setState({showNotification: true})
    }

    makePaymentWrapper(event, ignoreWarning, extendData) {
        if(!ignoreWarning && CONST.PAY.QIWI.SHOW_NOTIFICATION_COMMISSION && this.paymentSelected() === PAYMENTS.qiwi) return this.makePaymentWrapperQIWI(event);
        const self = this;
        const authData = (this.props.forceState ? this.props.forceState.authenticate : this.props.authData) || fakeState;

        const activeContract = this.props.smallLink ? authData.smallLinkActiveContract : authData.activeContract;
        const balance =
            this.props.prioritetBalance ?
                this.props.prioritetBalance
                :
                this.props.smallLink ?
                    this.props.state.balance
                    :
                    authData.contracts[authData.activeContract];

        let hasErrors = [];

        if(this.state.checkMask & 1 && !this.state.checkPhone.replace(/\D/g, '').match(/7?\d{10}$/)) hasErrors.push('checkPhone');
        if(this.state.checkMask & 2 && !this.state.checkMail.match(/^.+@.+\..{2,}$/)) hasErrors.push('checkMail');

        if(hasErrors.count) {
            this.setState({ hasErrors: hasErrors });
            return false;
        }

        if(balance <= 0 && !this.isFrame) {
            self.setState({loadingState: true, notifyText: null});

            axios.get(CONST.API + '/payments/openInternet', {
                withCredentials: true,
                params: {
                    'contract': activeContract,
			        '.rnd': Math.ceil(Math.random()*1000000)
                }
            }).then((answer) => {
                //const data = answer.data;
                // if(data.result === 'success') {
                    self.setState({loadingState: false, notifyText: null});
                    self.makePayment(event, extendData);
                // } else {
                //     self.state({
                //         loadingState: false,
                //         notifyText: data.text
                //     });
                // }
            }).catch(() => {
                self.setState({loadingState: false, notifyText: null});
                self.makePayment(event, extendData);
            });
        } else self.makePayment(event, extendData);
    }

    makeTrialPayClick(event) {
        this.setState({trialPayDialog: true})
    }

    logging = (data) => {
        let sendData = new FormData();

        sendData.append('contract', data.activeContract);
        sendData.append('recommendMoney', data.recommendMoney);
        sendData.append('inputAmount', data.inputAmount);
        sendData.append('payPerMonth', data.payPerMonth);
        sendData.append('balance', data.balance);


        axios.post(API + '/metrics/logger', sendData, {withCredentials: true});


    };

    getActiveContract = () => {
        const inputValues = this.state;
        const authData = this.props.forceState
            ? (this.props.forceState.authenticate?  this.props.forceState.authenticate:this.props.authData)
            : this.props.authData;

        return this.isFrame ?
            inputValues.frameContractNumber
            :
            this.props.smallLink ? authData.smallLinkActiveContract : authData.activeContract;
    };

    makePayment(event, extendData) {
        const inputValues = this.state;
        const state = this.props.forceState ? (this.props.forceState.panelPay ? this.props.forceState.panelPay:this.props.state) : this.props.state;

        const authData = this.props.forceState
            ? (this.props.forceState.authenticate?  this.props.forceState.authenticate:this.props.authData)
            : this.props.authData;
        const paymentSelected = this.paymentSelected();
        const activeContract = this.getActiveContract();

        if(!(this.state.checkMail) || !(this.state.checkPhone)) this.setState({
            checkMail:authData.ai_mails[0],
            checkPhone:authData.ai_phones[0]
        });

        let forceRecommend = inputValues.inputAmount === null
            ? state.recommendMoney
                ? state.recommendMoney
                : this.props.forceState.topAmount ? this.props.forceState.topAmount:this.props.panelServices.forceRecommendMoney[activeContract]
            : inputValues.inputAmount;

        let query = {
            amount: forceRecommend,
            contract: activeContract,
            type: paymentSelected,
            checkMask: inputValues.checkMask,
            checkPhone: inputValues.checkPhone,
            checkMail: inputValues.checkMail
        };

        let registerPaymentData = {
            contract: activeContract,
            mask: this.state.checkMask,
            phone: this.state.checkPhone,
            email: this.state.checkMail,
            sys: null
        };

        const domain = window.location.href.replace(/^(https?:\/\/[^\/]+).*/, '$1');
        let amount = (inputValues.inputAmount === null ? forceRecommend : inputValues.inputAmount).toString();
        if(!amount.match(/^[\d\.,]+$/)) return false;

        let url = "";
        const params = [];

        let __balance = this.props.prioritetBalance
            ? this.props.prioritetBalance
            :authData.contracts[authData.activeContract] === null ? 0 : authData.contracts[authData.activeContract];


        let _state = this.props.forceState ? (this.props.forceState.panelPay ? this.props.forceState.panelPay:this.props.state) : this.props.state;

        this.logging({
            activeContract: activeContract,
            recommendMoney: state.recommendMoney,
            inputAmount: amount,
            payPerMonth: _state.payPerMonth,
            balance:__balance
        });

        // Проверим наличие правильных данных
        switch(paymentSelected) {
            case PAYMENTS.google:
                if(!amount.match(/^\d+[\.,]\d+$/) && !amount.match(/^\d+$/)) return false;
                amount = Math.ceil(amount.replace(",", ".") * 100);

                url = CONST.API + '/sberbank/registerGoogle';

                params.push({name: "back_url_s", value: domain + CONST.ROOT_PATH + '/billing-pay.success.html'});
                params.push({name: "back_url_f", value: domain + CONST.ROOT_PATH + '/billing-pay.fail.html'});
                params.push({name: "account", value: activeContract});
                params.push({name: "amount", value: amount});
                params.push({name: "ts", value: moment().format('YYYY-MM-DD HH:mm:ss')});
                params.push({name: "trx_id", value: Math.floor(Math.random() * (9999999 - 1000000)) + 1000000});

                Object.keys(extendData).map((itemKey) => {
                    params.push({ name: itemKey, value: extendData[itemKey]})
                });

                if(query['checkPhone']) params.push({name: "checkPhone", value: query['checkPhone']});
                if(query['checkMail']) params.push({name: "checkMail", value: query['checkMail']});

                registerPaymentData.sys = 'sbergoogle';

                this.setState({hiddenFormData: {
                        "params": params,
                        "url": url,
                        "method": 'get',
                        "registerData": registerPaymentData,
                        "contract": activeContract
                    }, loadingState: true});

                break;
            case PAYMENTS.sber:
                if(!amount.match(/^\d+[\.,]\d+$/) && !amount.match(/^\d+$/)) return false;
                amount = Math.ceil(amount.replace(",", ".") * 100);

                url = CONST.API + '/sberbank/registerPayment';
                params.push({name: "back_url_s", value: domain + CONST.ROOT_PATH + '/billing-pay.success.html'});
                params.push({name: "back_url_f", value: domain + CONST.ROOT_PATH + '/billing-pay.fail.html'});
                params.push({name: "account", value: activeContract});
                params.push({name: "amount", value: amount});
                params.push({name: "ts", value: moment().format('YYYY-MM-DD HH:mm:ss')});
                params.push({name: "trx_id", value: Math.floor(Math.random() * (9999999 - 1000000)) + 1000000});

                if(query['checkPhone']) params.push({name: "checkPhone", value: query['checkPhone']});
                if(query['checkMail']) params.push({name: "checkMail", value: query['checkMail']});

                registerPaymentData.sys = 'sbercard';

                this.setState({hiddenFormData: {
                        "params": params,
                        "url": url,
                        "method": 'get',
                        "registerData": registerPaymentData,
                        "contract": activeContract
                    }, loadingState: true});
                break;
            case PAYMENTS.card:
                if(!amount.match(/^\d+[\.,]\d+$/) && !amount.match(/^\d+$/)) return false;
                amount = Math.ceil(amount.replace(",", ".") * 100);

                url = 'https://www.pps.gazprombank.ru/payment/start.wsm';
                params.push({name: "lang", value: "RU"});
                params.push({name: "merch_id", value: "6C30BFCF03BA59AEE729AC2C6260CCE8"});
                params.push({name: "back_url_s", value: domain + CONST.ROOT_PATH + '/billing-pay.success.html'});
                params.push({name: "back_url_f", value: domain + CONST.ROOT_PATH + '/billing-pay.fail.html'});
                params.push({name: "o.account", value: activeContract});
                params.push({name: "o.amount", value: amount});
                params.push({name: "amount", value: amount});
                params.push({name: "ts", value: moment().format('YYYY-MM-DD HH:mm:ss')});
                params.push({name: "trx_id", value: Math.floor(Math.random() * (9999999 - 1000000)) + 1000000});
                params.push({name: "o.from", value: "site"});
                if(query['checkPhone']) params.push({name: "o.checkPhone", value: query['checkPhone']});
                if(query['checkMail']) params.push({name: "o.checkMail", value: query['checkMail']});

                if(inputValues.cards) query['card'] = inputValues.cards;
                else if(state.cards.length && inputValues.cards === undefined) query['card'] = state.cards[0].cardID;
                else query['card'] = null;

                if(query['card']) {
                    params.push({name: "o.usecard", value: "1"});
                    params.push({name: "o.carduid", value: query['card']});
                } else {
                    params.push({name: "o.usecard", value: "0"});
                }

                registerPaymentData.sys = 'gpb';

                this.setState({hiddenFormData: {
                        "params": params,
                        "url": url,
                        "method": 'get',
                        "registerData": registerPaymentData,
                        "contract": activeContract
                }, loadingState: true});

                break;
            case PAYMENTS.qiwi:
            case PAYMENTS.sms: query['phone'] = inputValues.phoneNumber;
            case PAYMENTS.wm:
            case PAYMENTS.yandex:
                url = 'https://partner.rficb.ru/alba/input/';

                params.push({name: "key", value: "1JhKHGHQYpIOjcmaX9Srrpq6cT5u/tQmEO5Eiuuv/pM="});
                params.push({name: "payment_type", value: RFI_PAY_TYPES[paymentSelected]});
                params.push({name: "cost", value: amount});
                params.push({name: "name", value: "Оплата услуг сети интернет по договору "+activeContract});
                params.push({name: "default_email", value: ""});
                params.push({name: "order_id", value: "0"});
                params.push({name: "comment", value: activeContract});
                params.push({name: "version", value: "2.0"});
                params.push({name: "phone_number", value: (query['phone'] || '').replace(/[^\d]/, '').replace(/^.*(\d{10})$/, '$1') });
                params.push({name: "email", value: ""});
                params.push({name: "verbose", value: "0"});

                registerPaymentData.sys = 'rfibank';

                this.setState({hiddenFormData: {
                        "params": params,
                        "url": url,
                        "method": 'post',
                        "registerData": registerPaymentData,
                        "contract": activeContract
                }, loadingState: true});

                break;
            case PAYMENTS.check:
                url = CONST.API + '/payments/getSberbankCheck';
                params.push({name: 'lastName', value: inputValues.lastName});
                params.push({name: 'firstName', value: inputValues.firstName});
                params.push({name: 'middleName', value: inputValues.middleName});
                params.push({name: 'address', value: inputValues.address});
                params.push({name: 'amount', value: amount});
                params.push({name: 'contract', value: activeContract});

                registerPaymentData.sys = 'sberbank-check';

                this.setState({
                    hiddenFormData: {
                        "params": params,
                        "url": url,
                        "method": 'post',
                        "registerData": registerPaymentData
                    },
                    loadingState: true
                });

                break;
        }


    }

    autoPaymentsClick(event) {
        if(event) event.preventDefault();
        this.setState({displayAutoPayments: true});
    }

    autoPaymentsCloseClick(event, params) {
        if(event) event.preventDefault();

        this.setState({displayAutoPayments: false});

        const authData = this.props.authData;

        if(params) {
            if(params['refresh']) this.props.elementActions.getFinanceDetails(authData.activeContract);
            if(params['appendCard']) {
                const location = window.location.href;
                const account = this.props.smallLink ? authData.smallLinkActiveContract : authData.activeContract;
                const amount = Math.floor(Math.random() * (900 - 200)) + 200;
                const ts = moment().format('YYYY-MM-DD HH:mm:ss');
                const trxid = Math.floor(Math.random() * (9999999 - 1000000)) + 1000000;
                const url = "https://www.pps.gazprombank.ru/payment/start.wsm?lang=RU&merch_id=6C30BFCF03BA59AEE729AC2C6260CCE8";

                let dest = url + '&back_url_s=' + encodeURIComponent(location);
                dest += '&back_url_f=' + encodeURIComponent(location);
                dest += '&o.account=' + encodeURIComponent(account);
                dest += '&amount=' + amount;
                dest += '&ts=' + encodeURIComponent(ts);
                dest += '&trx_id=' + trxid;
                dest += '&account=' + encodeURIComponent(account);
                dest += '&o.amount=' + amount;
                dest += '&o.from=subscribe';

                window.location.href = dest;
            }
        }
    }

    hasAutoPay() {
        const autoPays = this.props.state.autoPays || [];

        let activePay = null;
        autoPays.forEach(function(autoPay) {
            if(autoPay.StopDate) return true;               // continue
            activePay = autoPay;
            return false;                                   // break
        });


        return activePay;
    }

    clearNotify(event) {
        event.preventDefault();
        this.setState({notifyText: null});
    }

    // Возвращает название текущей платёжной системы
    paymentSelected = () => {
        const { paymentSelected } = this.props;

        if(!paymentSelected) {
            if(this.paymentByGoogleAllowed()) return PAYMENTS.google;
            return this.cardPaymentSystem();
        }
        return paymentSelected;
    };

    // Возвращает возможность оплаты через Google Pay
    paymentByGoogleAllowed = () => {
        const { GooglePay } = this.props;
        const contract = this.getActiveContract();

        if(contract === '88000000') return !!GooglePay.paymentClient;
        if(contract === '88099635') return !!GooglePay.paymentClient;

        return false;
//        return !!GooglePay.paymentClient;
    };

    // Возвращаем через какую платёжную систему работаем с банковскими картами
    cardPaymentSystem = () => {
        //const contract = this.getActiveContract() + "";

        if(this.props.isFrame) return PAYMENTS.sber;

        if(this.props.state.forceCardSystem && PAYMENTS[this.props.state.forceCardSystem]) return PAYMENTS[this.props.state.forceCardSystem];

        //if(this.hasAutoPay()) return PAYMENTS.card;

        // const sberContracts = {
        //     88164703: 1, 88164415: 1, 88164212: 1,
        //     88164075: 1,
        //     88163722: 1,
        //     88163468: 1,
        //     88163240: 1,
        //     88161745: 1,
        //     88161485: 1,
        //     88161194: 1,
        //     88159278: 1,
        //     88158554: 1,
        //     88157241: 1,
        //     88151853: 1,
        //     88149350: 1,
        //     88149340: 1,
        //     88149007: 1,
        //     88147189: 1,
        //     88146361: 1,
        //     88146061: 1,
        //     88146043: 1,
        //     88145426: 1,
        //     88144892: 1,
        //     88142449: 1,
        //     88140412: 1,
        //     88138333: 1,
        //     88138226: 1,
        //     88135737: 1,
        //     88135396: 1,
        //     88135065: 1,
        //     88129036: 1,
        //     88128888: 1,
        //     88128405: 1,
        //     88127886: 1,
        //     88125531: 1,
        //     88125301: 1,
        //     88125113: 1,
        //     88125064: 1,
        //     88124972: 1,
        //     88124637: 1,
        //     88124032: 1,
        //     88123616: 1,
        //     88123150: 1,
        //     88122249: 1,
        //     88122225: 1,
        //     88122100: 1,
        //     88121820: 1,
        //     88121755: 1,
        //     88120642: 1,
        //     88120640: 1,
        //     88120639: 1,
        //     88120184: 1,
        //     88119190: 1,
        //     88118829: 1,
        //     88118782: 1,
        //     88118446: 1,
        //     88117914: 1,
        //     88117687: 1,
        //     88115212: 1,
        //     88115192: 1,
        //     88113484: 1,
        //     88111094: 1,
        //     88110373: 1,
        //     88109859: 1,
        //     88108516: 1,
        //     88108253: 1,
        //     88107323: 1,
        //     88107120: 1,
        //     88106167: 1,
        //     88103525: 1,
        //     88099699: 1,
        //     88099635: 1,
        //     88099367: 1,
        //     88097969: 1,
        //     88097829: 1,
        //     88096118: 1,
        //     88095437: 1,
        //     88094455: 1,
        //     88094299: 1,
        //     88093629: 1,
        //     88092806: 1,
        //     88092097: 1,
        //     88091013: 1,
        //     88090900: 1,
        //     88090634: 1,
        //     88090534: 1,
        //     88090308: 1,
        //     88088958: 1,
        //     88087788: 1,
        //     88087593: 1,
        //     88086406: 1,
        //     88086048: 1,
        //     88079909: 1,
        //     88079013: 1,
        //     88076265: 1,
        //     88076261: 1,
        //     88076256: 1,
        //     88076254: 1,
        //     88076236: 1,
        //     88076226: 1,
        //     88075369: 1,
        //     88074435: 1,
        //     88073547: 1,
        //     88073318: 1,
        //     88071097: 1,
        //     88070302: 1,
        //     88070145: 1,
        //     88069485: 1,
        //     88066702: 1,
        //     88066653: 1,
        //     88066431: 1,
        //     88066428: 1,
        //     88066338: 1,
        //     88066143: 1,
        //     88060527: 1,
        //     88060283: 1,
        //     88050978: 1,
        //     88050860: 1,
        //     88030593: 1,
        //     88010415: 1,
        // };
        // if(sberContracts[contract]) return PAYMENTS.sber;

        // if(contract == '88099635') return PAYMENTS.sber;
        // if(contract == '88079909') return PAYMENTS.sber;
        // if(contract == '88125064') return PAYMENTS.sber;
        // if(contract == '88144892') return PAYMENTS.sber;
        //if(contract == '88000000') return PAYMENTS.sber;

        return PAYMENTS.sber;
    };

    render() {
        let panelTitle = this.props.title || "Пополнение счёта";
        let panelTitleIcon = getStyle('icon-rub');
        const state = this.props.forceState ? (this.props.forceState.panelPay ? this.props.forceState.panelPay:this.props.state) : this.props.state;

        const authData = this.props.forceState
            ? (this.props.forceState.authenticate?  this.props.forceState.authenticate:this.props.authData)
            : this.props.authData;
        const activeContract = authData.activeContract;
        const paymentSelected = this.paymentSelected();
        const forceRecommend = (this.props.forceState  && this.props.forceState.topAmount)
            ? this.props.forceState.topAmount
            :this.props.panelServices.forceRecommendMoney[activeContract];

        const displayBalance = this.props.prioritetBalance
            ? this.props.prioritetBalance
            : authData.contracts[authData.activeContract] === null ? 0 : authData.contracts[authData.activeContract];
        const loadingState = state.panelLoadingState | this.state.loadingState;
        const hasAutoPayment = this.hasAutoPay();
        const linkPrefix = this.props.smallLink ? (this.props.noPrefixLinks ? '' : '/' + this.props.smallLink) : '';
        const hasPopUp = this.state.displayAutoPayments && !this.props.smallLink;
        if(this.props.smallLink) {
            panelTitle = "Пополнение договора "+authData.smallLinkActiveContract;
        }
        if(this.isFrame) {
            panelTitle = null;
            panelTitleIcon = null;
        }

        const notifyData = getStdNotifyStruct(this.state.notifyText, this.clearNotify);
        let Amount = 0;

        let payButtonDisabled = this.isFrame ?
            !this.state.frameContractNumber.match(/^(88\d{6}|[Фф]\-\d+\-\d+)$/i)
            : false;

        if(!loadingState) {
            Amount = this.props.prioritetTopAmount
                ? this.props.prioritetTopAmount
                : this.state.inputAmount === null ? (forceRecommend ? forceRecommend : state.recommendMoney) : this.state.inputAmount;
//            const Amount = this.state.inputAmount === null ? state.recommendMoney : this.state.inputAmount;
            if(!Amount) payButtonDisabled = true;
        }

        return (
            <Panel title={panelTitle} icon={panelTitleIcon} isLoading={loadingState} notify={notifyData}
                   className={hasPopUp ? myStyle['hasPopUp'] :
                       this.isFrame ? myStyle['iframe-panel-block']
                   : null}
                   rowClassName={this.isFrame ? myStyle['iframe-row'] : null}
                   asFrame={this.isFrame}
            >
                {this.state.hiddenFormData &&
                    <HiddenForm data={this.state.hiddenFormData.params} method={this.state.hiddenFormData.method} url={this.state.hiddenFormData.url}
                                isFrame={this.isFrame && paymentSelected === PAYMENTS.yandex}    // когда оплата через яндек деньги в iFrame нужно открывать новое окно, ибо CrossOrigin яндекс запретил
                                registerData={this.state.hiddenFormData.registerData} contract={this.state.hiddenFormData.contract}
                    />
                }
                {(this.state.showNotification && paymentSelected === PAYMENTS.qiwi && CONST.PAY.QIWI.SHOW_NOTIFICATION_COMMISSION) &&
                    <PopUp  title='Внимание! Возможно взимание дополнительной комиссии! Предлагаем воспользоваться другим способом оплаты!' >
                        <button  type="button" className={getStyle("btn-major")}  onClick={()=> this.setState({showNotification:false}) }>Выбрать другой способ</button>
                        <button  type="button" onClick={()=> {this.setState({showNotification:false});this.makePaymentWrapper(null, true); }}>Оплатить QIWI</button>
                    </PopUp>
                }
                {this.state.displayAutoPayments && !this.props.smallLink && <PanelAutoPayments onClose={this.autoPaymentsCloseClick} hasAutoPayment={hasAutoPayment} /> }
                {this.state.trialPayDialog && <PayPromisePanel onCancel={() => this.setState({trialPayDialog: false}) }  /> }
                { !this.props.smallLink ?
                    (!this.isFrame && <PaymentInformation state={state} displayBalance={this.props.prioritetBalance || state.balance || displayBalance} />)
                    :
                    <div className={getStyle('b-row gray b-border')}>
                        Баланс: <strong>{this.props.prioritetBalance || state.balance || displayBalance}</strong> руб.
                    </div>
                }
                <div className={getStyle('b-row circle-nav')}>
                    {this.paymentByGoogleAllowed() && <Link to={CONST.ROOT_PATH + linkPrefix + "/billing.google.html"} className={[getStyle('circle-nav-item'), paymentSelected==PAYMENTS.google?getStyle('active'):null].join(' ')}><i className={getStyle('l-icon icon-card')} /><div className={getStyle('title')}>Google Pay</div></Link>}
                    {this.cardPaymentSystem() === PAYMENTS.card && <Link to={CONST.ROOT_PATH + linkPrefix + "/billing.card.html"} className={[getStyle('circle-nav-item'), paymentSelected==PAYMENTS.card?getStyle('active'):null].join(' ')}><i className={getStyle('l-icon icon-card')} /><div className={getStyle('title')}>Карта</div></Link>}
                    {this.cardPaymentSystem() === PAYMENTS.sber && <Link to={CONST.ROOT_PATH + linkPrefix + "/billing.sber.html"} className={[getStyle('circle-nav-item'), paymentSelected==PAYMENTS.sber?getStyle('active'):null].join(' ')}><i className={getStyle('l-icon icon-card')} /><div className={getStyle('title')}>Карта</div></Link>}
                    <Link to={CONST.ROOT_PATH + linkPrefix + "/billing.qiwi.html"} className={[getStyle('circle-nav-item'), paymentSelected==PAYMENTS.qiwi?getStyle('active'):null].join(' ')}><i className={getStyle('l-icon icon-qiwi')} /><div className={getStyle('title')}>QIWI</div></Link>
                    <Link to={CONST.ROOT_PATH + linkPrefix + "/billing.yandex.html"} className={[getStyle('circle-nav-item'), paymentSelected==PAYMENTS.yandex?getStyle('active'):null].join(' ')}><i className={getStyle('l-icon icon-yandex')} /><div className={getStyle('title')}>Яндекс</div></Link>
                    <Link to={CONST.ROOT_PATH + linkPrefix + "/billing.wm.html"} className={[getStyle('circle-nav-item'), paymentSelected==PAYMENTS.wm?getStyle('active'):null].join(' ')}><i className={getStyle('l-icon icon-wm')} /><div className={getStyle('title')}>WebMoney</div></Link>
                    {!this.isFrame ? <Link to={CONST.ROOT_PATH + linkPrefix + "/billing.sms.html"} className={[getStyle('circle-nav-item'), paymentSelected==PAYMENTS.sms?getStyle('active'):null].join(' ')}><i className={getStyle('l-icon icon-sms')} /><div className={getStyle('title')}>SMS</div></Link> : null }
                    {!this.isFrame ? <Link to={CONST.ROOT_PATH + linkPrefix + "/billing.check.html"} className={[getStyle('circle-nav-item'), paymentSelected==PAYMENTS.check?getStyle('active'):null].join(' ')}><i className={getStyle('l-icon icon-check')} /><div className={getStyle('title')}>Квитанция</div></Link> : null }
                </div>
                {!loadingState &&
                    <PaymentInterface paymentSelected={paymentSelected} cardList={state.cards} recommendMoney={Amount}
                                             amountChange={this.inputAmountChange} inputValueChange={this.inputValueChange} smallLink={this.props.smallLink}
                                             inputValues={this.state} makePayment={this.makePaymentWrapper} inputPhoneChange={this.inputPhoneChange}
                                             autoPayments={this.autoPaymentsClick} hasAutoPayment={hasAutoPayment}
                                             isFrame={this.isFrame} payButtonDisabled={payButtonDisabled}
                                             requireContacts={this.isFrame || this.props.smallLink ? true : RequireContactInfo} updateStateValues={this.updateStateValues} _state={this.state}
                                             makeTrialPayClick={this.makeTrialPayClick}
                    />
                }
            </Panel>
        );
    }
}

function mapStateToProps(state) {
    return {
        authData: state.authenticate,
        state: state.panelPay,
        panelServices: state.panelServices,
        GooglePay: state.GooglePay,
//        servicesData: state.panelServices
    }
}

function mapDispatchToProps(dispatch) {
    return {
        elementActions: bindActionCreators(PanelActions, dispatch)
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(PanelPay);
