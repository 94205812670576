/**
 * Created by ponomarev on 17.02.2020.
 */
import React from 'react';
import { connect } from 'react-redux';
import { getStyle } from "../../tools";

import Panel from '../../Components/Panel';

import 'moment/locale/ru';
import moment from 'moment';
moment.locale('ru');

class PanelInactiveContract extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            contract: this.props.authData.activeContract
        }
    }

    render() {
        const closeDate = moment(this.props.authData['statuses'][this.state.contract]['closeDate']).format('L');
        const panelTitle = (<strong>Статус договора №<span className={'color-main'}>{this.state.contract}</span>: закрыт с {closeDate}</strong>);

        return (
            <Panel title={panelTitle} icon={getStyle('icon-chat')} >
                <table className={getStyle('line v-mid left-pull')}>
                    <tbody><tr><td>По всем вопросам обращаться по телефону  +7 812 622-0000</td></tr></tbody></table>
            </Panel>
        );
    }
}

function mapStateToProps(state) {
    return {
        authData: state.authenticate
    }
}



export default connect(mapStateToProps)(PanelInactiveContract);