/**
 * Created by dpopov on 14.09.2017.
 */
import axios from 'axios';
import * as CONST from '../../const';

export function requestNotifySettings(contract) {
    return (dispatch, getState, args) => {
        dispatch({ type: CONST.NOTIFY_SETTINGS.NOTIFY_REQUEST, payload: true });

        axios.get(CONST.API + '/notify/getSettings', {
            withCredentials: true,
            params: {
                contract: contract,
		        '.rnd': Math.ceil(Math.random()*1000000)
            }
        }).then(function(answer) {
            const data = answer.data;
            if(data.result === 'success') {
                dispatch({ type: CONST.NOTIFY_SETTINGS.NOTIFY_SET_DATA, payload: { error: null, data: data.data }});
            } else {
                dispatch({ type: CONST.NOTIFY_SETTINGS.NOTIFY_SET_DATA, payload: { error: data.text, data: {} }});
            }
        })
    }
}

export function updateNotifySetting(contract, phones, emails, limit, mask, successHendler = false) {
    return (dispatch, getState, args) => {
        dispatch({ type: CONST.NOTIFY_SETTINGS.NOTIFY_REQUEST, payload: true });

        axios.get(CONST.API + '/notify/setSettings', {
            withCredentials: true,
            params: {
                contract: contract,
                phone: phones.shift(),
                email: emails.shift(),
                limit: limit,
                mask: mask,
		'.rnd': Math.ceil(Math.random()*1000000)
            }
        }).then(function(answer) {
            const data = answer.data;
            if(data.result === 'success') {
                //dispatch({ type: CONST.NOTIFY_SETTINGS.NOTIFY_SET_DATA, payload: { error: null, data: data.data }});
                dispatch({ type: CONST.NOTIFY_SETTINGS.NOTIFY_REQUEST, payload: false });
            } else {
                dispatch({ type: CONST.NOTIFY_SETTINGS.NOTIFY_SET_DATA, payload: { error: data.text, data: {} }});
            }

            if(typeof successHendler === 'function') successHendler();
        });
    }
}