import 'moment/locale/ru'
import moment from 'moment';
moment.locale('ru');

// eslint-disable-next-line
//if(typeof STATIC_CONF === "undefined") STATIC_CONF = {};

// eslint-disable-next-line
const _STATIC_CONF = typeof STATIC_CONF === 'undefined' ? {} : STATIC_CONF;

export const REDUX_LOGGER_ENABLE = _STATIC_CONF['LOGGER'] || 1;

export const SUSPEND_DATE_END_FIRST_HCR = moment('2031-01-01 00:00:00.000');

// Адрес доступа к АПИ
export const API = _STATIC_CONF['API_URL'] || 'http://127.0.0.1:8811/api/lk';
//export const API = 'https://my.obit.ru/api/lk';
// eslint-disable-next-line
export const API_HOTEL = API.replace(/^(.+)\/[^\/]+$/, '$1/hotel');
//export const LINK_TYPE_API = 'https://my.obit.ru/api/lk';
export const LINK_TYPE_API = API
export const ROOT_PATH = _STATIC_CONF['ROOT_PATH'] || '';
export const DEFAULT_TARIFF_END_DATE=moment('2035-01-01 00:00:00');

export const ADV_DELIMITER_IMAGE='#@#';


export const HOTELS = {
    MACAUTH_LINK: 'https://s-macauth.obit.ru'
};


// Константы доступа. Должны совпадать с api/lk/auth.php
export const ACCESS = {
    READ: 1,
    WRITE: 2,
    FINANCE: 4,
    PRIVATE: 8,
    PAY_SMALL_LINK: 16
};

// Константы телефонов регионов
export const REGION_DATA = {
    default: {
        phone: '+7(812)622-00-00',
        link_phone: '+7812622-00-00'
    },
    'Москва' : {
        phone: '+7(495)647-50-00 ',
        link_phone: '+7495647-50-00 '
    },
};

// Константы типа услуг. Должны совпадать с api/lk/services.php
export const SERVICE_TYPES = {
    INET: 1,
    PHONE: 2,
    TV: 4,
    SUBTV: 8
};

export const EMPTY_REDUCER = 'EMPTY_REDUCER';

// Аутентификация
export const AUTHENTICATE = {
    IN_PROCESS: 'AUTHENTICATE.IN_PROCESS',
    SET_USER_INFO: 'AUTHENTICATE.SET_USER_INFOR',
    FORM_IN_PROCESS: 'AUTHENTICATE.FORM_IN_PROCESS',
    LOGOUT_IN_PROCESS: 'AUTHENTICATE.LOGOUT_IN_PROCESS',
    SET_BILLING_INFO: 'AUTHENTICATE.SET_BILLING_INFO',
    UPDATE_BALANCE: 'AUTHENTICATE.UPDATE_BALANCE',
    SWITCH_ACTIVE_CONTRACT: 'AUTHENTICATE.SWITCH_ACTIVE_CONTRACT',
    EMPTY_ACTIVE_CONTRACT: 'AUTHENTICATE.EMPTY_ACTIVE_CONTRACT',
    ERROR: 'AUTHENTICATE.ERROR'
};

//  WEB-интерфейс для Клиентов (Гостиницы) для авторизации в WiFi
export const HOTEL_SERVER_URL= 'https://s-macauth.obit.ru/';
export const HOTEL = {
    HOTEL_REQUEST_LIST_SET_DATA: 'HOTEL_REQUEST_LIST_SET_DATA',
    HOTEL_SET_LIMIT_COUNT: 'HOTEL_SET_LIMIT_COUNT',
    HOTEL_SET_DISPLAY_PAGE: 'HOTEL_SET_DISPLAY_PAGE',
    HOTEL_SET_LIST_ROOMS: 'HOTEL_SET_LIST_ROOMS',
};

// Услуги
export const SERVICES = {
    PANEL_SERVICES_REQUEST_IN_PROCESS: 'SERVICES.PANEL_SERVICES_REQUEST_IN_PROCESS',
    PANEL_SERVICES_SET_DATA: 'SERVICES.PANEL_SERVICES_SET_DATA',

    PANEL_SERVICES_INET_REQUEST_HISTORY: 'SERVICES.PANEL_SERVICES_INET_REQUEST_HISTORY',
    PANEL_SERVICES_INET_SET_HISTORY: 'SERVICES.PANEL_SERVICES_INET_SET_HISTORY',

    PANEL_SERVICES_IPTV_REQUEST_DETAILS: 'SERVICES.PANEL_SERVICES_IPTV_REQUEST_DETAILS',
    PANEL_SERVICES_IPTV_DETAILS_SET: 'SERVICES.PANEL_SERVICES_IPTV_DETAILS_SET',

    PANEL_SERVICES_REQUEST_EXTRAS: 'SERVICES.PANEL_SERVICES_REQUEST_EXTRAS',
    PANEL_SERVICES_EXTRAS_SET: 'SERVICES.PANEL_SERVICES_EXTRAS_SET',

    SET_RECOMMEND_PAYMENT: 'SERVICES.SET_RECOMMEND_PAYMENT'
};

// Новости
export const NEWS = {
    PANEL_NEWS_REQUEST_LIST_IN_PROCESS: 'PANEL_NEWS_REQUEST_LIST_IN_PROCESS',
    PANEL_NEWS_REQUEST_LIST_SET_DATA: 'PANEL_NEWS_REQUEST_LIST_SET_DATA',
    PANEL_NEWS_REQUEST_ITEM_IN_PROCESS: 'PANEL_NEWS_REQUEST_ITEM_IN_PROCESS',
    PANEL_NEWS_REQUEST_ITEM_SET_DATA: 'PANEL_NEWS_REQUEST_ITEM_SET_DATA',
    PANEL_NEWS_SET_LIMIT_COUNT: 'PANEL_NEWS_SET_LIMIT_COUNT'
};

// История платежей
export const PAYS_HISTORY = {
    PANEL_PAY_HISTORY_IN_PROCESS: 'PANEL_PAY_HISTORY_IN_PROCESS',
    PANEL_PAY_HISTORY_SET_DATA: 'PANEL_PAY_HISTORY_SET_DATA',
    PANEL_PAY_HISTORY_SET_ERROR: 'PANEL_PAY_HISTORY_SET_ERROR'
};

// Панел оплаты
export const PAY = {
    FINANCE_DETAIL_REQUEST_IN_PROCESS: 'FINANCE_DETAIL_REQUEST_IN_PROCESS',
    FINANCE_DETAIL_SET_DETAIL: 'FINANCE_DETAIL_SET_DETAIL',
    FINANCE_DETAIL_SET_ERROR: 'FINANCE_DETAIL_SET_ERROR',
    FINANCE_DETAIL_SET_BM: 'FINANCE_DETAIL_SET_BM',

    QIWI: {
        SHOW_NOTIFICATION_COMMISSION: true
    }
};

// Панель уведомлений
export const NOTIFY = {
    NOTIFY_SET_DATA: 'NOTIFY_SET_DATA',
    NOTIFY_APPEND_ERROR: 'NOTIFY_APPEND_ERROR',
    NOTIFY_POP_ERROR: 'NOTIFY_POP_ERROR',
    NOTIFY_SUCCESS_FORGOT_PASSWORD: 'NOTIFY_SUCCESS_FORGOT_PASSWORD'
};

// Проистановки
export const SUSPEND = {
    SUSPEND_REQUEST_DATA: 'SUSPEND_REQUEST_DATA',
    SUSPEND_UPDATE_DATA: 'SUSPEND_UPDATE_DATA',
    SUSPEND_REQUEST_HISTORY: 'SUSPEND_REQUEST_HISTORY',

    SUSPEND_SET_DATA: 'SUSPEND_SET_DATA',
    SUSPEND_SET_HISTORY: 'SUSPEND_SET_HISTORY'
};

// Настройка уведомлений
export const NOTIFY_SETTINGS = {
    NOTIFY_REQUEST: 'NOTIFY_SETTINGS.REQUEST',
    NOTIFY_SET_DATA: 'NOTIFY_SETTINGS.SET_DATA',
    NOTIFY_SET_VALUE: 'NOTIFY_SETTINGS.SET_VALUE'
};

export const NOTIFY_TYPES = {
    BALANCE_SMS: 1,
    BALANCE_EMAIL: 2,
    NEWS_SMS: 4,
    NEWS_EMAIL: 8,
    OFFER_SMS: 16,
    OFFER_EMAIL: 32
};

// Личные настройки
export const PERSONAL_SETTINGS = {
    REQUEST_DATA: 'PERSONAL_SETTINGS_REQUEST_DATA',
    SET_DATA: 'PERSONAL_SETTINGS_SET_DATA'
};

// FAQ
export const FAQ = {
    REQUEST_CHAPTERS: 'FAQ_REQUEST_CHAPTERS',
    SET_CHAPTERS: 'FAQ_SET_CHAPTERS',
    REQUEST_CHAPTER_DATA: 'FAQ_REQUEST_CHAPTER_DATA',
    SET_CHAPTER_DATA: 'FAQ_SET_CHAPTER_DATA',
    SWITCH_CHAPTER: 'FAQ_SWITCH_CHAPTER'
};

// Support
export const SUPPORT = {
    REQUEST_ADDRESSES: 'SUPPORT_REQUEST_ADDRESSES',
    SET_ADDRESSES: 'SUPPORT_SET_ADDRESSES',
    REQUEST_TICKETS_LIST: 'SUPPORT_REQUEST_TICKETS_LIST',
    SET_TICKETS_LIST: 'SUPPORT_SET_TICKETS_LIST'
};
