/**
 * Created by dpopov on 16.08.2017.
 */
import * as CONST from '../../const';
import axios from 'axios';

export function getPayments(contract, periodStart, periodEnd, limit, from) {
    return (dispatch, getState, args) => {
        dispatch({type: CONST.PAYS_HISTORY.PANEL_PAY_HISTORY_IN_PROCESS, payload: true });

        axios.get(CONST.API + '/payments/getPaymentsList', {
            withCredentials: true,
            params: {
                'contract': contract,
                'periodStart': periodStart,
                'periodEnd': periodEnd,
                'limitFrom': from || 0,
                'limitCount': limit || 10,
		'.rnd': Math.ceil(Math.random()*1000000)
            }
        }).then(function(result) {
            if(result.data.result === 'success') {
                dispatch({type: CONST.PAYS_HISTORY.PANEL_PAY_HISTORY_SET_DATA, payload: result.data.data })
            } else {
                dispatch({type: CONST.PAYS_HISTORY.PANEL_PAY_HISTORY_SET_ERROR, payload: result.data.text });
            }
        })
    }
}