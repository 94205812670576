/**
 * Created by dpopov on 19.07.2017.
 */
import React from 'react';
import ChapterServices from './index'

import PanelBalance from '../../Panels/PanelBalance';
import PanelServices from '../../Panels/PanelServices';
import PanelTariffs from '../../Panels/PanelTariffs';

import CheckRoster from '../../Panels/PanelServices/CheckRoster';

import { getStyle } from '../../tools';

export default function (props) {
    return (
        <CheckRoster from={"pageServices"}>
            {PageService(props)}
        </CheckRoster>
    );
}

const PageService = (props) => {
    const servicesFilter = props.match.params.serviceType;

    let title;
    let icon;
    let needRefresh = false;

    switch(servicesFilter) {
        case 'inet':
            title = "Услуги интернет";
            icon = getStyle('icon-wifi');

            break;
        case 'phone':
            title = "Услуги телефонии";
            icon = getStyle('icon-phone');

            break;
        case 'tv':
            title = "Услуги ТВ";
            icon = getStyle('icon-tv');

            break;
        default:
            title = "Подключенные услуги";
            icon = getStyle('icon-list');
            //needRefresh = true;

            break;
    }

    return (
        <ChapterServices>
            <PanelServices title={title} servicesFilter={servicesFilter} icon={icon} showServiceTypeIcon={servicesFilter ? false: true} forceReload={needRefresh} />
            { !servicesFilter ?
                <PanelBalance />
            : <PanelTariffs chapter={servicesFilter} /> }
        </ChapterServices>
    )
};