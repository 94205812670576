/**
 * Created by dpopov on 12.07.2017.
 */
import React from 'react';
import Row from '../Row';
import { getStyle } from "../../tools";

import stylePanel from './style.module.css';
import AjaxLoadImage from '../../../assets/images/ajax_loader_orange_64.gif';

import defaultPromoImage from '../../../assets/images/card-img-3_big.png';

const Panel = (props) => {
    const blockStyles = [ getStyle('block') ].concat(props.className);
    const blockHeaderStyles = [ getStyle('promo-header') ].concat(props.headerClassName);
    const blockPromoImage = props.promoImage || defaultPromoImage;
    const promoImageStyle = props.promoImageStyle || {};

    const panelTitle = props.title || 'новость';

    const rowClassName = [].concat(props.rowClassName || []);

    const PanelIsLoading = (
        <div className={[stylePanel['inProcessContainer'], stylePanel['promo-content']].join(' ')}>
            <div className={[ getStyle('block'), stylePanel['InProcess'] ].join(' ')} />
            <div className={stylePanel['InProcessImage']}><img src={AjaxLoadImage} alt={""} /></div>
        </div>
    );

    return (
        <Row className={rowClassName}>
            <div className={blockStyles.join(' ')}>
                <div className={blockHeaderStyles.join(' ')}>
                    <div className={[ getStyle('promo-title'), 'promo-title-special-fix' ].join(' ')}><h1>{panelTitle}</h1></div>
                    {blockPromoImage ? <div className={getStyle('promo-img')} style={promoImageStyle}><img src={blockPromoImage} alt={""} /></div> : null }
                </div>
                <div className={getStyle('promo-content')}>
                    {props.isLoading ? PanelIsLoading : null}
                    {props.children}
                </div>
            </div>
        </Row>
    )
};

export default Panel;