import React from 'react';
import configureStore, { configureHistory } from './code/.redux/controlStore.pay-frame';
import { Route, Switch } from 'react-router-dom';
import { Provider } from 'react-redux';
import { ConnectedRouter } from 'connected-react-router'

import * as CONST from './code/const';

import { framePay as FrameBilling } from "./code/Chapters/ChapterBilling/pageBilling";
import PageStdBilling from './code/Chapters/ChapterBilling/pageBilling';

import Page404 from './code/Pages/Page404';

import './assets/css/pay-frame.css'

let store = configureStore();
let history = configureHistory(store);

export default (props) => {
    const isPaymentBySmallLink = props && props.smallLink;

    const PageBilling = isPaymentBySmallLink ?
        (routeProps) => <PageStdBilling {...routeProps} {...props} forceNonRouteData={true} />
        :
        FrameBilling;

    return (
    <Provider store={store}>
        <ConnectedRouter history={history}>
            <Switch>
                <Route exact path="/" component={PageBilling}/>
                <Route exact path="/billing.:payType.html" component={PageBilling}/>
                <Route exact path={CONST.ROOT_PATH + "/billing.:payType.html"} component={PageBilling}/>
                <Route component={Page404}/>
            </Switch>
        </ConnectedRouter>
    </Provider>
    );
};