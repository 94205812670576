/**
 * Created by dpopov on 14.08.2017.
 */
import * as CONST from '../../const';

const initialState = {
    panelLoadingState: false,
    newsList: {},
    newsData: {},
    error: false,
    errorText: "",
    totalNews: 0,
    displayCount: 10,
    displayFrom: 0,
    itemLoadingState: {}
};

export default function PanelNewsReducer(state = initialState, action) {
    switch(action.type) {
        case CONST.EMPTY_REDUCER: return {...initialState};
        case CONST.NEWS.PANEL_NEWS_REQUEST_LIST_IN_PROCESS: {
            return {...state, panelLoadingState: true }
        }
        case CONST.NEWS.PANEL_NEWS_REQUEST_LIST_SET_DATA: {
            let newState = {...state,
                panelLoadingState: false,
                newsList: action.payload.items,
                totalNews: action.payload.total,
                displayFrom: action.payload.from
            };
            return newState;
        }
        case CONST.NEWS.PANEL_NEWS_REQUEST_ITEM_IN_PROCESS: {
            let itemLoadingState = state.itemLoadingState;
            itemLoadingState[action.payload.id] = true;
            return {...state, itemLoadingState: itemLoadingState };
        }
        case CONST.NEWS.PANEL_NEWS_REQUEST_ITEM_SET_DATA: {
            let itemLoadingState = state.itemLoadingState;
            itemLoadingState[action.payload.id] = false;

            let newsData = state.newsData;
            newsData[action.payload.id] = action.payload.data;

            let newState = {...state, itemLoadingState: itemLoadingState, newsData: newsData };

            return newState;
        }
        case CONST.NEWS.PANEL_NEWS_SET_LIMIT_COUNT: {
            return {...state, displayCount: action.payload };
        }
        default: return state;
    }
}