/**
 * Created by dpopov on 17.10.2017.
 */
/*eslint eqeqeq: ["off", "smart"]*/
/*eslint jsx-a11y/anchor-is-valid: ["off", "smart"]*/
/*eslint array-callback-return: ["off"]*/

import React from 'react';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import {withRouter} from 'react-router-dom';

import axios from 'axios';

import * as CONST from '../../const';
import * as PanelActions from './actions';

import {getStyle} from '../../tools';

import myStyle from './style.module.css';

import Panel from '../../Components/Panel';
//import PopUp from '../../Components/Panel/PopUp';
import Notify from '../../Components/Panel/innerNotify';
import notifyStyle from '../../Components/Panel/innerNotify.module.css';

import FileUpload from '../../Components/FileUpload';

import 'moment/locale/ru';
import moment from 'moment';

moment.locale('ru');

const initialState = {
    loadingState: false,
    uploaded: {},
    chatMessage: '',
    chat: [],
    errorString: null,
    address: 'неизвестный адрес'
};

class PanelChat extends React.Component {
    constructor(props) {
        super(props);
        this.state = {...initialState};

        this.onCancelTicketClick = this.onCancelTicketClick.bind(this);
        this.requestChatMessages = this.requestChatMessages.bind(this);

        this.onUploadError = this.onUploadError.bind(this);
        this.onFileUpload = this.onFileUpload.bind(this);
        this.onDeleteFileClick = this.onDeleteFileClick.bind(this);

        this.onAddCommentClick = this.onAddCommentClick.bind(this);
        this.onCommentMessageInput = this.onCommentMessageInput.bind(this);
        this.onClearErrorClick = this.onClearErrorClick.bind(this);
        this.onGetAttachmentClick = this.onGetAttachmentClick.bind(this);
    }

    componentWillMount() {
        const contract = this.props.authData.activeContract;

        if (typeof this.props.state.tickets[contract] == 'undefined') {
            this.props.elementActions.getTicketsList(contract);
        } else {
            this.requestChatMessages();
        }
    }

    componentWillReceiveProps(nextProps) {
        const contract = nextProps.authData.activeContract;
        if (typeof nextProps.state.tickets[contract] !== 'undefined' && this.state.chat.length === 0) {
            this.requestChatMessages();
        }
    }

    requestChatMessages() {
        const self = this;
        const contract = self.props.authData.activeContract;

        self.setState({loadingState: true});
        if (!this.props.crm) {
            axios.get(CONST.API + '/support/rtchat', {
                withCredentials: true,
                params: {
                    contract: contract,
                    ticket: self.props.ticket,
                    '.rnd': Math.ceil(Math.random() * 1000000)
                }
            }).then((answer) => {
                const data = answer.data;

                if (data.result === 'success') {
                    self.setState({chat: data.data.messages, address: data.data.Address, loadingState: false})
                } else {
                    self.setState({errorString: data.text, loadingState: false});
                }
            });
        } else {
            axios.get(CONST.API + "/support/crmChat", {
                withCredentials: true,
                params: {
                    contract: contract,
                    ticket: self.props.ticket,
                    '.rnd': Math.ceil(Math.random() * 1000000)
                }
            })
                .then(resp => {
                    const data = resp.data;

                    if (data.result === 'success') {
                        self.setState({chat: data.data.messages, address: data.data.Address, loadingState: false})
                    } else {
                        self.setState({errorString: data.text, loadingState: false});
                    }
                })
                .catch(err => {
                    console.log(err);
                })
        }
    }

    onCancelTicketClick(event, history) {
        event.preventDefault();

        const self = this;
        const contract = this.props.authData.activeContract;

        this.setState({loadingState: true});
        axios.get(CONST.API + '/support/cancel', {
            withCredentials: true,
            params: {
                contract: contract,
                ticket: self.props.ticket,
                '.rnd': Math.ceil(Math.random() * 1000000)
            }
        }).then((answer) => {
            const data = answer.data;

            if (data.result === 'success') {
                self.setState({loadingState: false, chat: data.data});
                self.props.elementActions.getTicketsList(contract);
                history.push(CONST.ROOT_PATH + '/support.html');
            } else {
                self.setState({loadingState: false, errorString: data.text});
            }
        });
    }

    onUploadError() {
        this.setState({errorString: 'При загрузке файла произошла ошибка'});
    }

    onFileUpload(data) {
        if (data.result === 'success') {
            let uploaded = this.state.uploaded;
            uploaded[data.data.guid] = data.data.filename;

            this.setState({uploaded: uploaded});
        }
    }

    onDeleteFileClick(event) {
        event.preventDefault();
        const guid = event.target.getAttribute('data-guid');
        let uploaded = this.state.uploaded;
        delete uploaded[guid];
        this.setState({uploaded: uploaded});

    }

    onCommentMessageInput(event) {
        this.setState({chatMessage: event.target.value});
    }

    onAddCommentClick(event) {
        event.preventDefault();

        if (!this.state.chatMessage) {
            // Сообщение пустое
            this.setState({errorString: 'Нужно ввести сообщение'});
            return false;
        }

        const self = this;
        const contract = this.props.authData.activeContract;

        let params = new FormData();
        params.append('contract', contract);
        params.append("message", this.state.chatMessage);
        params.append("files", Object.keys(this.state.uploaded).join(','));
        params.append("ticket", this.props.ticket);
        if(this.props.crm){
            params.append("uid",this.state.chat[0].uid);
        }
        this.setState({loadingState: true});

        axios.post(CONST.API + (!this.props.crm?'/support/comment':'/support/commentCrm'), params, {
            withCredentials: true
        }).then((answer) => {
            const data = answer.data;

            if (data.result === 'success') {
                self.setState({loadingState: false, chat: data.data, chatMessage: ''});
            } else {
                self.setState({loadingState: false, errorString: data.text});
            }
        });

    }

    onClearErrorClick(event) {
        event.preventDefault();
        this.setState({errorString: null});
    }

    getTicketData() {
        const ticket = this.props.ticket;
        const ticketsData = this.props.state.tickets;
        const contract = this.props.authData.activeContract;

        if (ticketsData[contract]) {
            for (let i = 0; i < ticketsData[contract].items.length; i++) {
                if (ticketsData[contract].items[i].id == ticket) return ticketsData[contract].items[i];
            }
        }

        return null;
    }

    onGetAttachmentClick(event) {
        event.preventDefault();
        const contract = this.props.authData.activeContract;
        const attachment = event.currentTarget.getAttribute('data-attachment');
        const ticket = this.props.ticket;
        const name = event.currentTarget.getAttribute('data-name');

        const self = this;

        self.setState({loadingState: true});

        axios.get(CONST.API + '/support/getattachment', {
            withCredentials: true,
            responseType: 'blob',
            params: {
                contract: contract,
                ticket: ticket,
                attachment: attachment,
                '.rnd': Math.ceil(Math.random() * 1000000)
            }
        }).then((answer) => {
            self.setState({loadingState: false});
            if (answer.data.result === 'error') {
                self.setState({errorString: answer.data.text});
            } else {
                const FileDownload = require('react-file-download');
                FileDownload(answer.data, name);
            }
            //console.log(answer);
        });
    }

    render() {
        const ticket = this.props.ticket;
        const loadingState = this.state.loadingState;
        const contract = this.props.authData.activeContract;

        const clientName = this.props.authData.clientFirstName;

        const ticketData = this.getTicketData();

        const ticketDate = ticketData ? moment(ticketData.Created, 'YYYY-MM-DD hh:mm:ss').format('DD.MM.YYYY') : 'неизвестная дата';
        const ticketAddress = this.state.address;

        const CancelButton = withRouter(({history}) => (
            <button type='button' onClick={(event) => {
                this.onCancelTicketClick(event, history)
            }} className={getStyle("btn-major btn-big f-right")}>
                Отменить вопрос
            </button>
        ))

        const title = [
            "Вопрос №" + ticket,
            !this.props.crm?<CancelButton key="2"/>:null
        ];

        const uploadParams = {
            contract: contract
        };

        const errNotify = {
            text: this.state.errorString,
            className: [getStyle('top-notify'), notifyStyle['center'], myStyle['support_chat_error_notify']].join(' '),
            notifyTextClassName: [getStyle('notify-message'), notifyStyle['error'], notifyStyle['inline']].join(' '),
            closeClick: this.onClearErrorClick,
            notifyCloseClassName: [getStyle("button btn-close inv"), notifyStyle['btn-close']].join(' ')
        };

        return (
            <Panel title={title} icon={getStyle('icon-chat')} isLoading={loadingState}>
                <div className={getStyle("b-row b-border small")}>
                    <div className={getStyle("t-smaller")}>
                        Дата обращения: {ticketDate}<br/>
                        Тема обращения: {this.props.crm ? 'Прочие вопросы' : 'Технические вопросы'}<br/>
                        Адрес обслуживания: {ticketAddress}<br/>
                    </div>
                </div>
                <div className={getStyle('chat-box')}>
                    <div className={getStyle('chat-messages')}>
                        {this.state.chat.map((element, _idx) => {
                            const elementDate = moment(element.Created, 'YYYY-MM-DD hh:mm:ss').format('DD.MM.YYYY, HH:mm');
                            // let display = {};
                            if (element.Type.match(/create/i)) {
                                return (<div className={getStyle("message-item message-right")} key={_idx}>
                                    <div className={getStyle("t-date")}>{'Система, ' + elementDate}</div>
                                    <div className={getStyle("message-text")}>{!this.props.crm?'Создана заявка №' + ticket:element.Description}</div>

                                </div>);
                            } else if (element.Type.match(/(comment|correspond)/i)) {

                                const userContent = element.UserContent;
                                const itemStyle = userContent ? getStyle("message-item message-right") : getStyle("message-item message-left");
                                const attachmentTitle = userContent ? clientName + ', ' + elementDate :
                                    element.CreatorTitle ? element.CreatorTitle + ', ' + elementDate : null;

                                let answer = [];

                                const AttachmentIds = Object.keys(element.Attachments);
                                for (let i = 0; i < AttachmentIds.length; i++) {
                                    const attachment = element.Attachments[AttachmentIds[i]];
                                    if (attachment.type && attachment.type.match(/text/i)) {

                                        if (userContent && !attachment.UserContent) continue;

                                        let content = attachment.content;
                                        if (!attachment.type.match(/html/i)) content = content.replace(/\n/g, '<br>');
                                        answer.push(
                                            <div className={itemStyle} key={_idx + '_' + i}>
                                                {attachmentTitle ?
                                                    <div className={getStyle("t-date")}>{attachmentTitle}</div> : null}
                                                <div className={getStyle("message-text")}
                                                     dangerouslySetInnerHTML={{__html: content}}></div>
                                            </div>
                                        );
                                    } else if (attachment.type && attachment.type.match(/image/i) && attachment.preview) {
                                        answer.push(
                                            <div className={itemStyle} key={_idx + '_' + i}>
                                                {attachmentTitle ?
                                                    <div className={getStyle("t-date")}>{attachmentTitle}</div> : null}
                                                <div className={getStyle("message-text")}>
                                                    <a href="#" data-attachment={AttachmentIds[i]}
                                                       data-name={attachment.name}
                                                       onClick={this.onGetAttachmentClick}><img src={attachment.preview}
                                                                                                alt={""}/></a>
                                                </div>
                                            </div>
                                        );
                                    } else if (attachment.type && attachment.name) {
                                        answer.push(
                                            <div className={itemStyle} key={_idx + '_' + i}>
                                                {attachmentTitle ?
                                                    <div className={getStyle("t-date")}>{attachmentTitle}</div> : null}
                                                <div className={getStyle("message-text")}>
                                                    <a href="#" data-attachment={AttachmentIds[i]}
                                                       data-name={attachment.name}
                                                       onClick={this.onGetAttachmentClick}>{attachment.name}</a>
                                                </div>
                                            </div>
                                        );
                                    }
                                }

                                return answer;
                            }
                        })}
                    </div>
                    <div className={getStyle('chat-form')}>
                        {errNotify.text ?
                            <Notify className={errNotify.className} notifyTextClassName={errNotify.notifyTextClassName}
                                    closeClick={errNotify.closeClick}
                                    notifyCloseClassName={errNotify.notifyCloseClassName}>
                                {errNotify.text}
                            </Notify>
                            : null}
                        <div className={getStyle('chat-input-row')}>
                            <div className={getStyle('input')}>
                                <input type="text" className={getStyle("input-big wide")}
                                       placeholder="Введите сообщение.." onChange={this.onCommentMessageInput}
                                       value={this.state.chatMessage}/>
                            </div>
                            <div className={getStyle('send')}>
                                <button type="submit" className={getStyle("btn-big btn-major")}
                                        onClick={this.onAddCommentClick}>Отправить
                                </button>
                            </div>
                        </div>
                        <div className={getStyle('chat-file-row')}>
                            {!this.props.crm ? <FileUpload onUpload={this.onFileUpload} onError={this.onUploadError}
                                                           onUploadAbort={null} api="/support/upload"
                                                           buttonTitle="Приложить файл" className={getStyle('f-left')}
                                                           params={uploadParams}/> : null}
                            <div className={getStyle('file-box f-auto')}>
                                <div className={getStyle("file-item i-b")}/>
                                {Object.keys(this.state.uploaded).map((fileGuid, _idx) => {
                                    return <div className={getStyle('file-item i-b')} key={_idx}>
                                        {this.state.uploaded[fileGuid]} &nbsp;
                                        <button className={getStyle('btn-close')} data-guid={fileGuid}
                                                onClick={this.onDeleteFileClick}>&times;</button>
                                    </div>;
                                })}
                            </div>
                        </div>
                    </div>
                </div>
            </Panel>
        );
    }
}

function mapStateToProps(state) {
    return {
        authData: state.authenticate,
        state: state.support
    }
}

function mapDispatchToProps(dispatch) {
    return {
        elementActions: bindActionCreators(PanelActions, dispatch)
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(PanelChat);