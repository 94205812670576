/**
 * Created by dpopov on 31.08.2017.
 */
import * as CONST from '../../const';

const initialState = {
    loadingState: false,
    updatingState: false,
    loadingHistoryState: false,
    services: {},
    history: []
};

export default function PanelNewsReducer(state = initialState, action) {
    switch (action.type) {
        case CONST.EMPTY_REDUCER: return {...initialState};
        case CONST.SUSPEND.SUSPEND_REQUEST_DATA: {
            return {...state, loadingState: true, updatingState: true }
        }
        case CONST.SUSPEND.SUSPEND_UPDATE_DATA: {
            return {...state, updatingState: true }
        }
        case CONST.SUSPEND.SUSPEND_REQUEST_HISTORY: {
            return {...state, loadingHistoryState: true }
        }
        case CONST.SUSPEND.SUSPEND_SET_DATA: {
            return {...state, loadingState: false, updatingState: false, services: action.payload }
        }
        case CONST.SUSPEND.SUSPEND_SET_HISTORY: {
            return {...state, loadingHistoryState: false, history: action.payload }
        }
        default: return state;
    }
}