import React from 'react';
import { Provider } from "react-redux";
import { Route, Switch } from 'react-router-dom';
import { ConnectedRouter } from 'connected-react-router'

import configureStore, { configureHistory } from './code/.redux/controlStore';

import * as CONST from './code/const';

import LKInterface from './LKInterface';
import CheckAccess from './code/Authenticate';
import Dashboard from './code/Pages/PageDashboard';
import Page404 from './code/Pages/Page404';
import PanelPromoAdv from './code/Panels/PanelPromos/itemDisplay';
import PageContract from './code/Pages/PageContract';

import GooglePayContext from "./code/Components/GooglePay";

// Hotel Section
import HotelSection from './HotelSection';
// Services pages
import { ServicesRoutes } from './code/Chapters/ChapterServices';

// Billing pages
import { BillingRoutes } from './code/Chapters/ChapterBilling';

// News pages
import { NewsRoutes } from './code/Chapters/ChapterNews';

// User Settings
import { UserRoutes } from './code/Chapters/ChapterUser';

// Help Chpater
import { HelpRoutes } from './code/Chapters/ChapterHelp';

// Small Links
import { SmallLinksRoutes } from './code/Chapters/ChapterSmallLinks';

// Promo Pages
import { PromoRoutes } from './code/Chapters/ChapterPromo';

// PayFrame
import PayFrame from './pay-frame';

let store = configureStore();
let history = configureHistory(store);

export default () => (
    <Provider store={store}>
        <GooglePayContext>
        <ConnectedRouter history={history}>
                <Switch>

                    <Route exact path={CONST.ROOT_PATH + '/pay-frame.html'} component={PayFrame} />
                    <Route exact path={CONST.ROOT_PATH + "/hotel-auth-wifi.html"} component={HotelSection} />

                    {SmallLinksRoutes}

                    <LKInterface>
                        <Switch>
                            <Route exact path={CONST.ROOT_PATH + '/'} component={CheckAccess(Dashboard, CONST.ACCESS.READ, true)} />
                            <Route exact path={CONST.ROOT_PATH + '/index.html'} component={CheckAccess(Dashboard, CONST.ACCESS.READ, true)} />
                            <Route exact path={CONST.ROOT_PATH + '/dogovor.html'} component={CheckAccess(PageContract, CONST.ACCESS.READ, true)} />
                            <Route exact path={CONST.ROOT_PATH + '/promo.:promoName.html'} component={CheckAccess(PanelPromoAdv, CONST.ACCESS.READ, true)} />

                            {UserRoutes}
                            {ServicesRoutes}
                            {BillingRoutes}
                            {HelpRoutes}
                            {NewsRoutes}
                            {PromoRoutes}

                            <Route component={Page404}/>
                        </Switch>
                    </LKInterface>

                </Switch>
        </ConnectedRouter>
        </GooglePayContext>
    </Provider>
)