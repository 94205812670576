import React from 'react';
import {getStyle} from "../../../../tools";
import moment from "moment";
import myStyle from '../../style.module.css';
import DatePicker from "react-datepicker/es";
import Select from 'react-select';

export const PopUpEditDataUser = (props) => {
    return (
        <form onSubmit={props.onSubmit}>
            <table>
                <tbody>
                <tr>
                    <td>Фамилия гостя</td>
                    <td><input type="text" required name='guestName' value={props.data.guestName} onChange={(e) => {
                        props.onChange(e.target.name, e.target.value)
                    }} className={getStyle('g8-2')}/></td>
                </tr>
                <tr>
                    <td>Номер брони</td>
                    <td><input type="text" required name='pmsRegNum' value={props.data.pmsRegNum} onChange={(e) => {
                        props.onChange(e.target.name, e.target.value)
                    }} className={getStyle('g8-2')}/></td>
                </tr>
                <tr>
                    <td>Номер комнаты</td>
                    <td><input type="text" required name='roomNumber' value={props.data.roomNumber} onChange={(e) => {
                        props.onChange(e.target.name, e.target.value)
                    }} className={getStyle('g8-2')}/></td>
                </tr>
                </tbody>
            </table>
            <div className={getStyle('t-center')}>
                <input type="submit" className={getStyle('btn-major')} value="Сохранить"/>
            </div>
        </form>
    )
};

export const PopUpOutFromHotel = (props) => {
    return (
        <form onSubmit={props.onSubmit}>
            <table>
                <tbody>
                <tr>
                    <td>Время</td>
                    <td>
                        <DatePicker
                            selected={props.timeOutFromHotel.toDate()}
                            onChange={(d) => props.onChange(moment(d))}
                            dateFormat="dd.MM.yyyy HH:mm"
                            locale={'ru-RU'}
                            timeFormat="HH:mm"
                            timeCaption="Время"
                            showTimeSelect
                        />
                    </td>
                </tr>

                </tbody>
            </table>
            <div className={getStyle('t-center')}>
                <input type="submit" className={getStyle('btn-major')} value="Сохранить"/>
            </div>
        </form>
    )
};


export const PopUpRemoveUser = (props) => {
    return (
        <form>
            <table className={getStyle('t-center')}>
                <tbody>
                <tr>
                    <td>Вы точно хотите удалить?</td>
                </tr>

                </tbody>
            </table>
            <div className={getStyle('t-center')}>
                <button onClick={props.onConfirmYes} className={getStyle('g8-2')}>Да</button>
                <button onClick={props.onConfirmNo} className={getStyle('g8-2')}>Нет</button>
            </div>
        </form>
    )
};

export class PopUpLoadNewListRoom extends React.Component {
    state = {
        file: null
    };

    render() {
        return (
            <form onSubmit={(e) => {
                e.preventDefault();
                this.props.onSubmit(this.state.file);
            }
            }>
                <table className={getStyle('t-center')}>
                    <tbody>
                    <tr>
                        <td><input type="file" name='listRoom' onChange={(e) => {

                            e.preventDefault();


                            this.setState({file: e.target.files[0],});

                        }}/></td>
                    </tr>

                    </tbody>
                </table>
                <div className={getStyle('t-center')}>
                    <button type='submit' className={getStyle('g8-2')}>Загрузить</button>
                    <button onClick={this.props.onCancel} className={getStyle('g8-2')}>Отмена</button>
                </div>
            </form>
        )
    }
}

export class PopUpAddNewRoom extends React.Component {
    state = {
        pmsRegNum: null
    };

    render() {
        return (
            <form onSubmit={(e) => {
                e.preventDefault();
                this.props.onSubmit(this.state.pmsRegNum);
            }}>
                <table className={getStyle('t-center')}>
                    <tbody>
                    <tr>
                        <td><input type="text" name='pmsRegNum' value={this.state.pmsRegNum} onChange={(e) => {
                            this.setState({pmsRegNum: e.target.value})
                        }}/></td>
                    </tr>

                    </tbody>
                </table>
                <div className={getStyle('t-center')}>
                    <button type='submit' className={getStyle('g8-2')}>Добавить</button>
                    <button onClick={this.props.onCancel} className={getStyle('g8-2')}>Отмена</button>
                </div>
            </form>
        )
    }
}

export const PopUpListRoom = (props) => {

    let bookedRooms = [];
    if (props.booked.ALL) {
        for (let i = 0; i < props.booked.ALL; ++i) {
            bookedRooms[props.booked.data[i]['roomNumber']] = props.booked.data[i];
        }

    }

    return (
        <div>
            <div className={['container', myStyle['scroll-list-room']].join(' ')}>
                <table className={[myStyle['rm-z-index'], myStyle['height-80']].join(' ')}>
                    <tbody>
                    {props.listRoom.map(function (room, key) {
                            return (
                                <tr key={key}>
                                    <td>{room.value}</td>
                                    <td>{bookedRooms[room.value]
                                        ? 'Забронирована ' +
                                        moment(bookedRooms[room.value]['timeIn'] * 1000).format('L') +
                                        ' ' + moment(bookedRooms[room.value]['timeIn'] * 1000).format('LT') +
                                        ' - ' + moment(bookedRooms[room.value]['timeOut'] * 1000).format('L') +
                                        ' ' + moment(bookedRooms[room.value]['timeOut'] * 1000).format('LT')
                                        : <button type="button" className={getStyle('f-right btn-popup')} onClick={() => {
                                            props.onClick(room.value)
                                        }}>Зарегистрировать гостя</button>
                                    }</td>
                                </tr>)
                        }
                    )}
                    </tbody>
                </table>
            </div>
            <div className={getStyle('b-row')}>
                <button type="button" className={getStyle('f-left btn-major')}
                        onClick={props.onClickBtnAddRoom}>Добавить комнату
                </button>
                <button type="button" className={getStyle('f-left btn-popup')}
                        onClick={props.onClickBtnLoadNewListRoom}>Загрузить новый список комнат
                </button>
            </div>
        </div>
    )
};

export default class PopUpAddUser extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            selectedOption: this.props.data.roomNumber
                ? {value: this.props.data.roomNumber, label: this.props.data.roomNumber}
                : null
        };
    }

    render() {

        const {selectedOption} = this.state;
        return (
            <form onSubmit={this.props.onSubmit}>
                <table className={myStyle['rm-z-index']}>
                    <tbody>
                    <tr>
                        <td>Фамилия гостя</td>
                        <td><input type="text" required name='guestName' value={this.props.data.guestName}
                                   onChange={(e) => {
                                       this.props.onChange(e.target.name, e.target.value)
                                   }}/></td>
                    </tr>
                    <tr>
                        <td>Номер брони</td>
                        <td><input type="text" required name='pmsRegNum' value={this.props.data.pmsRegNum}
                                   onChange={(e) => {
                                       this.props.onChange(e.target.name, e.target.value)
                                   }}/></td>
                    </tr>
                    <tr>
                        <td>Номер комнаты</td>
                        <td>
                            {this.props.listRoom.length ?
                                <Select
                                    value={selectedOption}
                                    className={myStyle['w-100']}
                                    placeholder=' '
                                    name='roomNumber'
                                    onChange={selectedOption => {
                                        this.setState({selectedOption});
                                        this.props.onChange('roomNumber', selectedOption.value)
                                    }}
                                    options={this.props.listRoom}
                                />
                                : <input type="text" required name='roomNumber' value={this.props.data.roomNumber}
                                         onChange={(e) => {
                                             this.props.onChange(e.target.name, e.target.value)
                                         }}/>
                            }
                        </td>

                    </tr>
                    <tr>
                        <td>Дата заезда</td>
                        <td>
                            <DatePicker
                                selected={this.props.data.timeIn.toDate()}
                                onChange={(d) => this.props.onChange('timeIn', moment(d))}
                                dateFormat="dd.MM.yyyy HH:mm"
                                locale={'ru-RU'}
                                timeFormat="HH:mm"
                                timeCaption="Время"
                                showTimeSelect
                                className='timeIn'


                            />
                        </td>
                    </tr>
                    <tr>
                        <td>Дата выезда</td>
                        <td>
                            <DatePicker
                                selected={this.props.data.timeOut.toDate()}
                                onChange={(d) => this.props.onChange('timeOut', moment(d))}
                                dateFormat="dd.MM.yyyy HH:mm"
                                locale={'ru-RU'}
                                timeFormat="HH:mm"
                                timeCaption="Время"
                                className='timeOut'
                                showTimeSelect
                                minDate={this.props.data.timeOut.toDate()}
                            />
                        </td>
                    </tr>
                    </tbody>
                </table>
                <div className={getStyle('t-center')}>
                    <input type="submit" className={getStyle('btn-major')} value="Добавить"/>
                </div>
            </form>
        )
    };
}

