/**
 * Created by dpopov on 01.11.2017.
 */
import React from 'react';

import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

// import { getServiceData, requestServiceWays, findParentService } from './tools';
import { getStyle, getStdNotifyStruct } from '../../tools';

import axios from 'axios';

import * as authenticateActions from '../../Authenticate/actions';
import * as panelServicesActions from './actions';
import * as CONST from '../../const';

import elStyle from './style.module.css';

import Panel from '../../Components/Panel';
//import { PanelIsLoading } from '../../Components/Panel';

import 'moment/locale/ru';
import moment from 'moment';
moment.locale('ru');

const initialState = {
    loadingState: false,
    serviceId: null,
    activeContract: null,
    errorString: null,
    directionsCost: []
};

class PanelPhoneDirectionList extends React.Component {
    constructor(props) {
        super(props);

        this.state = {...initialState,
            activeContract: props.authData.activeContract,
            serviceId: props.serviceId
        };

        this.onErrorCloseClick = this.onErrorCloseClick.bind(this);
    }

    componentWillMount() {
        this.refreshDirections();
    }

    refreshDirections() {
        this.setState({ loadingState: true});

        axios.get(CONST.API + '/phone/maindirectionscost', {
            withCredentials: true,
            params: {
                contract: this.props.authData.activeContract,
		'.rnd': Math.ceil(Math.random()*1000000)
            }
        }).then((answer) => {
            const data = answer.data;

            if(data.result === 'success') {
                this.setState({ loadingState: false, directionsCost: data.data })
            } else {
                this.setState({ loadingState: false, errorString: data.text});
            }
        });
    }

    onErrorCloseClick(event) {
        event.preventDefault();

        this.setState({errorString: null});
    }

    render() {
        const title = this.props.title || 'Основные направления';
        const icon = this.props.icon || getStyle('icon-list');
        const loadingState = this.state.loadingState;

        return (
            <Panel title={title} icon={icon} notify={getStdNotifyStruct(this.state.errorString, this.onErrorCloseClick)} isLoading={loadingState}>
                <table className={getStyle('line v-mid left-pull')}>
                    <tbody>
                    {this.state.directionsCost.map((direction, _idx) => {
                        return (
                            <tr key={_idx}>
                                <td>{direction.DirectionName}</td>
                                <td className={elStyle['td-nowrap']}><strong>{direction.Price}</strong> руб/мин</td>
                            </tr>
                        );
                    })}
                    </tbody>
                </table>
            </Panel>
        );
    }
}

function mapStateToProps(state) {
    return {
        authData: state.authenticate,
        servicesData: state.panelServices
    }
}

function mapDispatchToProps(dispatch) {
    return {
        elementActions: bindActionCreators(panelServicesActions, dispatch),
        authenticate: bindActionCreators(authenticateActions, dispatch)
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(PanelPhoneDirectionList);