import { createStore, applyMiddleware, compose, combineReducers } from 'redux';
import { connectRouter, routerMiddleware } from 'connected-react-router'

import thunk from './enhancers/enhancer';
import RootReducer from '../reducer.HotelSection';
import * as CONST from '../const';

import createHistory from 'history/createHashHistory';

export let history = createHistory({
//    hashType: 'slash'
});

export default function configureStore(initialStore = {}) {
    const middlewares = [];

    middlewares.push(thunk.withExtraArgument({ api: CONST.API }));
    middlewares.push(routerMiddleware(history));

    // if (process.env.NODE_ENV === 'development') {
    const { logger } = require('redux-logger');
    middlewares.push(logger);
    // }

    const rootReducer = (_history) => combineReducers({
        ...RootReducer,
        router: connectRouter(_history)
    });

    return createStore(
        rootReducer(history), // root reducer with router state
        initialStore,
        compose(
            applyMiddleware(...middlewares),
        ),
    );
}

export function configureHistory(store) {
    return history;
}