/**
 * Created by dpopov on 23.08.2017.
 */
/* eslint eqeqeq: ["off", "smart"] */
import React from 'react';

import { connect } from 'react-redux';
import axios from 'axios';

import Panel from '.';
import Oops from '../Oops';
import CheckRoster from '../../Panels/PanelServices/CheckRoster';

import * as CONST from '../../const';
import { getStyle, processInputHtmlStaticPage, StaticPageDisplayContainer } from '../../tools';

class StaticPage extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            htmlData: null,
            query: null,
            errorText: null,
            isLoading: false,
            compare: props.compareValue,
            title: null
        };

        this.processInputHtml = this.processInputHtml.bind(this);
    }

    componentWillReceiveProps(nextProps) {
        if(nextProps.compareValue != this.state.compare) {
            this.setState({ compare: nextProps.compareValue });
            this.requestPage(nextProps.queryParams);
        }
    }

    processInputHtml(html) {
        const currentRegion = CONST.REGION_DATA[this.props.authData.region] ? CONST.REGION_DATA[this.props.authData.region] : CONST.REGION_DATA.default;

        const replacements = {
            'PHONE': currentRegion.phone,
            'LINK_PHONE': currentRegion.link_phone
        };
        const servicesTree = this.props.servicesData.servicesTree;
        return processInputHtmlStaticPage(html, servicesTree, this.props.authData, replacements );
    }

    componentDidMount() {
        this.requestPage(this.props.queryParams);
    }

    requestPage(params) {
        const page = this.props.pageName;
        //const params = this.props.queryParams;
        let self = this;

        self.setState({ isLoading: true });

        axios.get(CONST.API + '/staticPages/'+page, {
            withCredentials: true,
            params: {...params, '.rnd': Math.ceil(Math.random()*1000000)}
        }).then(function(data) {
            if(data.data.result == "error") {
                self.setState({errorText: data.data.text, isLoading: false});
            } else {
                self.setState({
                    errorText: null,
                    isLoading: false,
                    htmlData: data.data.data.html,
                    title: data.data.data.title
                });
            }
        })
    }

    render() {
        const panelTitle = this.props.title || this.state.title || "Статичная страница";
        const icon = this.props.icon || getStyle('icon-star');
        const bodyStyle = this.props.bodyStyle;
        const containerStyle = this.props.containerStyle;

        const content = this.state.htmlData;
        const isLoading = this.state.isLoading;
        //if(!content) isLoading = true;

        // console.log(this.props.authData);

        let DivContainer = this.state.errorString ? null : StaticPageDisplayContainer(this.processInputHtml(content), containerStyle);

        return (
            <CheckRoster from={"StaticPage"}>
                <Panel title={panelTitle} icon={icon} isLoading={isLoading}>
                    <div className={bodyStyle}>
                        {content ?
                            <DivContainer/>
                            :
                            <Oops>
                                <h3>{this.state.errorText}</h3>
                            </Oops>
                        }
                    </div>
                </Panel>
            </CheckRoster>
        );
    }


}

function mapStateToProps(state) {
    return {
        authData: state.authenticate,
//      state: state.panelPay
        servicesData: state.panelServices
    }
}

export default connect(mapStateToProps)(StaticPage);