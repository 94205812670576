import * as CONST from './const';

export function loadSDK() {
    return (dispatch) => {
        dispatch({ type: CONST.REDUX.INITIALISE_PROCESS, payload: true });

        const script = document.createElement('script');
        script.src = CONST.GOOGLE_PAY_SDK;
        script.onload = () => {
            let options = {
                environment: CONST.GOOGLE_PAY_ENVIRONMENT,
            };
            const paymentsClient = new window.google.payments.api.PaymentsClient(options);

            const isReadyToPayRequest = Object.assign({}, CONST.baseRequest);
            isReadyToPayRequest.allowedPaymentMethods = [ CONST.baseCardPaymentMethod ];

            paymentsClient.isReadyToPay(isReadyToPayRequest)
                .then((response) => {
                    if(response.result) {
                        dispatch({ type: CONST.REDUX.INITIALISE_RESULT, payload: paymentsClient })
                    } else {
                        dispatch({ type: CONST.REDUX.INITIALISE_ERROR, payload: response })
                    }
                })
                .catch((err) => {
                    dispatch({ type: CONST.REDUX.INITIALISE_ERROR, payload: err })
                });
        };
        script.async = true;
        script.defer = true;
        document.body.appendChild(script);
    }
}
