/**
 * Created by dpopov on 19.07.2017.
 */
/*eslint eqeqeq: ["off", "smart"]*/
/*eslint no-loop-func: ["off"]*/
import React from 'react';

import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Link } from 'react-router-dom';
import { getStyle } from "../../../tools";

import Panel from '../../../Components/Panel';

import * as suspendActions from '../actions';

import SuspendRules from '../rules';

import 'react-datepicker/dist/react-datepicker.css';
import '../../../styles-fix.css';
import styleEl from '../style.module.css';

import DatePicker from 'react-datepicker';

import 'moment/locale/ru';
import moment from 'moment';
moment.locale('ru');

class PanelSuspendServices extends React.Component {
    constructor(props) {
        super(props);

        this.changePeriodFrom = this.changePeriodFrom.bind(this);
        this.changePeriodTo = this.changePeriodTo.bind(this);
        this.refreshSuspendServices = this.refreshSuspendServices.bind(this);
        this.displayRules = this.displayRules.bind(this);
        this.hideRules = this.hideRules.bind(this);
        this.onCheckAgree = this.onCheckAgree.bind(this);
        this.onSuspendButtonClick = this.onSuspendButtonClick.bind(this);

        this.minimalDate = this.getMinimalSuspendDate();

        this.state = {
            periodStart: moment(this.minimalDate),
            periodEnd: moment(this.minimalDate).add(1, 'month'),
            displayRules: false,
            agreeChecked: false,
            activeContract: props.authData.activeContract
        };

    }

    componentWillMount() {
        this.minimalDate = this.getMinimalSuspendDate();
        let contract = this.props.authData.activeContract;
        this.props.elementActions.requestSuspendData(contract, this.state.periodStart, this.state.periodEnd);
    }

    componentDidMount() {
    }

    componentWillReceiveProps(nextProps) {
        let minimalDate = this.getMinimalSuspendDate(nextProps);

        if(nextProps.authData.activeContract !== this.state.activeContract) {
            const pStart = moment(minimalDate);
            const pEnd = moment(minimalDate).add(1, 'month');
            this.setState({
                activeContract: nextProps.authData.activeContract,
                periodStart: pStart,
                periodEnd: pEnd,
                displayRules: false,
                agreeChecked: false
            });
            this.props.elementActions.requestSuspendData(nextProps.authData.activeContract, pStart, pEnd);
            return;
        }

        if(minimalDate.isAfter(this.state.periodStart)) {
            const newPeriodStart = minimalDate;
            const newPeriodEnd = this.state.periodEnd.isBefore(newPeriodStart) ? moment(newPeriodStart).add(1, 'day') : this.state.periodEnd;

            this.setState({
                periodStart: newPeriodStart,
                periodEnd: newPeriodEnd
            });
        }

        //this.setState({
        //    periodStart: minimalDate,
        //    periodEnd: moment(minimalDate).add(1, 'month')
        //});
    }

    changePeriodFrom(chosenDate) {
        if(!(chosenDate instanceof moment)) chosenDate = moment(chosenDate);
        const periodStart = moment(chosenDate);
        const periodEnd = chosenDate.isAfter(this.state.periodEnd)?chosenDate:this.state.periodEnd;
//        const periodEnd = this.state.periodEnd;
        this.setState({ periodStart: periodStart, periodEnd: periodEnd});
//        this.setState({ periodStart: periodStart});

        //this.refreshSuspendServices(periodStart, periodEnd);
    }

    changePeriodTo(chosenDate) {
        const periodEnd = moment(chosenDate);
        //const periodStart = chosenDate.isAfter(this.state.periodStart)?this.state.periodStart:choosedDate;
        //const periodStart = this.state.periodStart;
        //this.setState({ periodStart: periodStart, periodEnd: periodEnd});
        this.setState({ periodEnd: periodEnd});

        //this.refreshSuspendServices(periodStart, periodEnd);
    }

    refreshSuspendServices(periodStart, periodEnd) {
        let contract = this.props.authData.activeContract;
        this.props.elementActions.updateSuspendData(contract, periodStart?periodStart:this.state.periodStart, periodEnd?periodEnd:this.state.periodEnd);
    }

    displayRules(event) {
        event.preventDefault();
        this.setState({displayRules: true});
    }

    hideRules(event) {
        this.setState({displayRules: false});
    }

    onCheckAgree(event) {
        this.setState({agreeChecked: !this.state.agreeChecked });
    }

    onSuspendButtonClick(event) {
        event.preventDefault();

        const periodStart = this.state.periodStart;
        const periodEnd = this.state.periodEnd;

        if(periodEnd.diff(periodStart, 'months') > 12) {
            // разница между датами больше года.
            return false;
        }

        this.props.elementActions.makeSuspend(this.props.authData.activeContract, periodStart, periodEnd);
    }

    getMinimalSuspendDate(props) {
        const state = props ? props.state : this.props.state;

        let minimalDate = moment().startOf('day');

        if(!state.services) return minimalDate;

//        console.log(state.services);

        for(let specNum in state.services) {
            if(state.services.hasOwnProperty(specNum)) {
                const specServices = state.services[specNum];

                for(let specServiceId in specServices) {
                    if(specServices.hasOwnProperty(specServiceId) && specServices[specServiceId].length) {
                        specServices[specServiceId].forEach(function(hcrItem, hcrIndex) {
                            if(!hcrItem.canSuspend) {
                                let dateEnd = moment(hcrItem.DateEnd);
                                if(dateEnd.isAfter(minimalDate)) minimalDate = dateEnd;
                            };
                        })
                    }
                }
            }
        }

        return minimalDate;
    }

    render() {
        const panelTitle = this.props.title || "Приостановка обслуживания";
        const loadingState = this.props.state.loadingState || this.props.state.updatingState;

        const state = this.props.state;

        const periodStart = this.state.periodStart;
        const periodEnd = this.state.periodEnd;

        const minimalDate = this.getMinimalSuspendDate();
//        const minimalDate = this.minimalDate;
//        console.log('minimalDate', minimalDate);

        if(state.history.length) {
            // Есть история приостановок. Пройдёмся по ней, и если есть активная приостанвка, то ограничим минимальную дату создания новой приостанвки
            let activeEndDate = null;
            state.history.forEach(function(historyItem) {
                if(historyItem.active) {
                    const endDate = moment(historyItem.DateEnd);
                    if(!activeEndDate) activeEndDate = endDate;
                    else if(endDate.isAfter(activeEndDate)) activeEndDate = endDate;
                }
            });

            if(activeEndDate) {
                if(activeEndDate.isAfter(this.state.periodStart)) this.changePeriodFrom(activeEndDate);
            }
        }

        let btnSuspendStyle = [getStyle('btn-major')];
        if(!this.state.agreeChecked) btnSuspendStyle.push(styleEl['suspend-btn-disabled']);

        let services = [];
        let canStop = true;
        let monthCost = 0;

        let nowMoment = moment();

        for(let specNum in state.services) {
            if( state.services.hasOwnProperty(specNum)) {
                const specServices = state.services[specNum];

                for(let specServiceId in specServices) {
                    if(specServices.hasOwnProperty(specServiceId) && specServices[specServiceId].length) {

                        let hcrList = [];
                        let cost = null;

                        const hcrCount = specServices[specServiceId].length;

                        let canStopService = true;

                        specServices[specServiceId].forEach(function(hcrItem, hcrIndex) {
                            if(!hcrItem.canSuspend) {
                                canStop = false;
                                canStopService = false;
                            }

//                            if(hcrIndex == 0) {
//                                cost = hcrItem.isAbonement ? 0 : hcrItem.Amount;
//                                monthCost += cost;
//                            }

                            let pushItem = true;
                            let itemEndMoment = moment(hcrItem.DateEnd);

                            if(canStopService === false) pushItem = true;
                            else if(itemEndMoment.isBefore(nowMoment)) pushItem = false;

                            if(pushItem && cost === null) {
                                cost = hcrItem.isAbonement ? 0 : hcrItem.Amount;
                                monthCost += cost;
                            }

                            if(pushItem) hcrList.push(<div key={hcrIndex}>
                                {hcrItem.Tariff}
                                { hcrCount >= 1 ?
                                    <span> c <strong>{moment(hcrItem.DateBegin).format('DD.MM.YYYY')}</strong> по <strong>{moment(hcrItem.DateEnd).format('DD.MM.YYYY')}</strong></span>
                                    :
                                    null}
                                {!hcrItem.canSuspend ? <div className={styleEl['forbidden']}>Приостановка услуги невозможна</div> : null}
                            </div>);
                        });

                        services.push(<tr key={specServiceId}>
                            <td className={styleEl['suspend-item']}>{hcrList}</td>
                            {cost ? (<td><strong>{cost}</strong> руб/мес</td>) : <td>&nbsp;</td>}
                        </tr>);

                    }
                }
            }
        }

        canStop = this.state.periodStart.isAfter(minimalDate) || this.state.periodStart.isSame(minimalDate);
        // console.log('minimal', minimalDate.format('YYYY-MM-DD hh:mm:ss'));
        // console.log('periodStart', this.state.periodStart.format('YYYY-MM-DD hh:mm:ss'));
        // console.log('canStop', canStop);
//        console.log(minimalDate, this.state.periodStart, this.getMinimalSuspendDate());

        return (
            <Panel title={panelTitle} icon={getStyle('icon-pause')} isLoading={loadingState}>
                { this.state.displayRules ?
                    <SuspendRules onClose={this.hideRules} />
                : null}
                <div className={[getStyle('b-row'), 'z1000', 'react-datepicker-root-div'].join(' ')}>
                    Дата приостановки&nbsp;
                    <DatePicker className={getStyle('forceInputStyle input-x')}  placeholder="дд.мм.гггг" dateFormat="dd.MM.yyyy" onChange={this.changePeriodFrom} selected={periodStart.toDate()} minDate={minimalDate.toDate()} />
                </div>
                <div className={[getStyle('b-row'), 'z1000', 'react-datepicker-root-div'].join(' ')}>
                    Дата возобновления&nbsp;
                    <DatePicker className={getStyle('forceInputStyle input-x')}  placeholder="дд.мм.гггг" dateFormat="dd.MM.yyyy" onChange={this.changePeriodTo} selected={periodEnd.toDate()} minDate={moment(periodStart).add(1, 'day').toDate()} maxDate={moment(periodStart).add(1, 'year').toDate()} />
                </div>
                <table className={getStyle('line v-mid gray left-pull')}>
                    <tbody>
                        <tr><td colSpan="2">Действующие услуги</td></tr>
                        {services}
                    </tbody>
                    <tfoot>
                        <tr>
                            <td>Итого</td>
                            <td><strong>{monthCost}</strong> руб/мес</td>
                        </tr>
                    </tfoot>
                </table>
                <div className={getStyle('b-row')}>
                    {canStop ?
                        <div>
                            <p>
                                <label>
                                    <input type="checkbox" ref="confirmCheck" onChange={this.onCheckAgree} checked={this.state.agreeChecked}  /> Я согласен с <Link to="#" className={getStyle('a-underline')} onClick={this.displayRules}>условиями приостановки обслуживания</Link>
                                </label>
                            </p>
                            <button type="button" className={btnSuspendStyle.join(' ')} ref="suspendButton" disabled={!this.state.agreeChecked} onClick={this.onSuspendButtonClick}>Приостановить за 100 руб.</button>
                        </div>
                        :
                        null }
                </div>
            </Panel>
        );
    }
}

function mapStateToProps(state) {
    return {
        state: state.suspendData,
        authData: state.authenticate
//        services: state.panelServices
//        servicesData: state.panelServices
    }
}

function mapDispatchToProps(dispatch) {
    return {
        elementActions: bindActionCreators(suspendActions, dispatch),
//        authenticate: bindActionCreators(SubNavBlock, dispatch)
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(PanelSuspendServices);