/**
 * небольшая панель с отображением текущего баланса и ссылкой на страницу оплаты
 *
 * Created by dpopov on 12.07.2017.
 */
/*eslint eqeqeq: ["off", "smart"]*/
import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Link } from 'react-router-dom';
import * as authenticateActions from '../../Authenticate/actions';
import Panel from '../../Components/Panel';

import PayPromisePanel from '../PanelPromisePay';

import * as CONST from '../../const';
import { getStyle } from '../../tools';
import style from './style.module.css';

class PanelBalance extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            displayPromisePay: false
        }
    }

    render() {
        const authData = this.props.state.authenticate;

        const contracts = authData.contracts;
        const contract = authData.activeContract;
        const balance = contracts[contract];

        const status = authData.statuses ? authData.statuses[contract] : {};
//        console.log(status);

        const headerContent = (
            <span className={style['container']}>
                Баланс: <strong>{balance ? balance : 0}</strong> руб.

                <div className={getStyle('f-right')}>
                    {status && status.delayAvailable ? //Доступен на статусах 3,4,6,8
                        <button onClick={() => this.setState({ displayPromisePay: true })} className={getStyle('button')}>Отложенный платёж</button>
                    :null}
                    <Link to={CONST.ROOT_PATH + "/billing.html"} className={getStyle('button btn-major')}>Пополнить баланс</Link>&nbsp;
                </div>
            </span>
        );

        return (
            <Panel title={headerContent} icon={getStyle('icon-rub')} headerClassName={getStyle('bottom_line')} key="1">
                {this.state.displayPromisePay ?
                    <PayPromisePanel onCancel={() => this.setState({ displayPromisePay: false })} />
                :null}
            </Panel>
        )
    }
}

function mapStateToProps(state) {
    return {
        state: state
    }
}

function mapDispatchToProps(dispatch) {
    return {
        elementActions: null,
        authenticate: bindActionCreators(authenticateActions, dispatch)
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(PanelBalance);