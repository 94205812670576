/**
 * Created by dpopov on 11.10.2017.
 */

import * as CONST from '../../const';

const initialState = {
    error: null,
    addressesLoading: false,
    addresses: {},
    ticketListLoading: false,
    ticketsError: null,
    tickets: {},
    chatLoadingState: false
};

export default function PanelSupportReducer(state = initialState, action) {
    switch(action.type) {
        case CONST.EMPTY_REDUCER: return {...initialState};
        case CONST.SUPPORT.REQUEST_ADDRESSES: return {...state, addressesLoading: action.payload };
        case CONST.SUPPORT.SET_ADDRESSES: {
            let addresses = state.addresses;
            if(!action.payload.error) {
                addresses[action.payload.contract] = action.payload.addresses;
                return {...state, addresses: addresses, addressesLoading: false, error: null }
            }  else {
                addresses[action.payload.contract] = null;
                return {...state, addresses: addresses, addressesLoading: false, error: action.payload.error }
            }
        }
        case CONST.SUPPORT.REQUEST_TICKETS_LIST: return {...state, ticketListLoading: action.payload };
        case CONST.SUPPORT.SET_TICKETS_LIST: {
            const list = action.payload.list;
            const error = action.payload.error;
            const contract = action.payload.contract;

            let tickets = state.tickets;

            if(error) {
                tickets[contract] = null;
                return {...state, tickets: tickets, ticketsError: error }
            } else {
                tickets[contract] = list;
                return {...state, tickets: tickets, ticketsError: null }
            }
        }
        default: return state;
    }
}