/*eslint no-useless-escape: ["off", "smart"]*/

import React from 'react';
//import { connect } from 'react-redux';

import axios from 'axios';

import Panel from '../../Components/Panel';
import Panel404 from '../../Pages/Page404/panel404';

import PageRate from '../../Pages/PageRate';

import * as CONST from '../../const';

export default class Rate_2Week extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            loadingState: false,
            code: props.match.params.code,
            url: props.match.url,
            unknownLink: false
        }
    }

    componentDidMount() {
        this.getLinkInfo(this.state.url, this.state.code);
    }

    componentWillReceiveProps(nextProps) {
        if(nextProps.match.url !== this.state.url) this.getLinkInfo(nextProps.match.url, nextProps.props.match.params.code);
    }

    getLinkInfo(url, code) {
        // const type = url.replace(code, '').replace(/^.+\/([^\/]+)$/, '$1');

        this.setState({loadingState: true});

//        axios.get(CONST.API + '/smalllinks/checkLink', {
        axios.get(CONST.LINK_TYPE_API + '/smalllinks/checkLink', {
            withCredentials: true,
            params: {
                type: '2week',
                code: code
            }
        }).then((answer) => {
            const data = answer.data;

            if(data.result === 'success') {
                this.setState({loadingState: false });
            } else {
                this.setState({loadingState: false, unknownLink: true });
            }
        });
    }

    render() {
        const code = this.state.code;

        return (
            <div>
                {this.state.unknownLink ?
                    <Panel404/>
                    :
                    this.state.loadingState ?
                        <Panel title="Проверяем" isLoading={this.state.loadingState}/>
                        :
                        <PageRate ticket={null} linkSrc={'mobile'} smallLink={code} rateType="2week"/>
                }
            </div>
        );
    }
}



// export default function(props) {
//     console.log(props);
//
//     const code = props.match.params.code;
//     const type = props.match.url.replace(code, '').replace(/^.+\/([^\/]+)$/, '$1');
//
//     console.log(type, code);
//
//     return (<div>
//         <PageRate ticket={null} linkSrc={'mobile'} smallLink={code} rateType="2week">
//
//         </PageRate>
//     </div>)
// }