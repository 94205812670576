/**
 * Created by dpopov on 20.12.2017.
 */
import React from 'react';

export default class OldLkLoginForm extends React.Component {
    componentDidMount() {
        this.postForm.submit();
    }

    render() {
        return (
            <form method="post" action="https://info-svc-012.obit.ru/ObitINFO/j_spring_security_check" ref={(input) => this.postForm = input}>
                <input type="hidden" name="j_username" value={this.props.login} />
                <input type="hidden" name="j_password" value={this.props.password} />
            </form>
        );
    }
}