import React from 'react';
import { getStyle } from "../../tools";

export default function(props) {
    return props.isFrame ?
        <tr>
            <td>Номер договора</td>
            <td>
                <input type="text" name="frameContractNumber" value={props.inputValues.frameContractNumber} className={getStyle('input-x')} onChange={props.inputValueChange} placeholder={'88******'} />
            </td>
        </tr>
        : null
}