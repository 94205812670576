/**
 * Created by dpopov on 12.07.2017.
 */
import React from 'react';
import Row from '../Row';
import moment from "moment";
import { getStyle } from "../../tools";

const Footer = (props) => {
    return (
        <Row className={getStyle('footer')}>
            <div className={getStyle('b')}>2017-{moment().format('YYYY')} &copy; ОБИТ</div>
            <div className={getStyle('b')}>&nbsp;Личный кабинет v1.003</div>
        </Row>
    )
};

export default Footer;