import React from 'react';
import ChapterBilling from './index';

import PanelPayAndChargesLog from '../../Panels/PanelPayAndChargesLog';

export default (props) => {
    return (
        <ChapterBilling>
            <PanelPayAndChargesLog/>
        </ChapterBilling>
    );
}