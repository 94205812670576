/**
 * Created by dpopov on 04.09.2017.
 */
import React from 'react';
import PopUp from '../../Components/Panel/PopUp';
import { getStyle } from "../../tools";

import styleEl from './style.module.css';

export default (props) => {
    const iconStyles = getStyle('m-icon icon-rub border');

    return (
        <PopUp title="Условия приостановки" onCancel={props.onClose} iconStyle={iconStyles} blockStyle={styleEl['rules-popup-block']} blockRowStyle={styleEl['rules-popup-data']}>
            <div>
                <p>Дополнительная услуга «Приостановка» позволяет временно отключить все подключенные абоненту услуги связи на выбранный им период времени, а также приостановить списание абонентских платежей.</p>
                <p>Услуги можно приостановить на срок от 1 дня до 1 года.</p>
                <p>Стоимость «Приостановки» не зависит от выбранного срока и составляет 100 рублей, которые списываются при заказе услуги.</p>
                <p>Абонент может досрочно прекратить действие «Приостановки». Средства за Приостановку не возвращаются.</p>
                <p>При подключенном «Абонементе» «Приостановка» невозможна.</p>
                <p>«Приостановка» доступна абонентам (кроме обладателей «Абонемента»), у которых на личном счете достаточно средств для оплаты услуги.</p>
                <p>Абонент может отменить Приостановку до момента ее активации. Списанные за Приостановку средства будут возвращены на личный счет.</p>
            </div>
        </PopUp>
    );
}