/**
 * Created by dpopov export const REGION_DATA = {on 11.10.2017.
 */
/*eslint eqeqeq: ["off", "smart"]*/
import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import axios from 'axios';
import * as CONST from '../../const';
import * as PanelActions from './actions';
import Panel from '../../Components/Panel';
import {getStyle, StaticPageDisplayContainer} from '../../tools';

class PanelFAQContent extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            content: {},
            chapter: this.props.chapter,
            chapterTitle: '',
            loadingState: false,
            error: null
        };
    }

    requestChapterData(chapter) {
        if(!chapter) return;
        const self = this;

        self.setState({loadingState: true, error: null});

        axios.get(CONST.API + '/faq/getContent', {
            withCredentials: true,
            params: {
                chapter: chapter,
		'.rnd': Math.ceil(Math.random()*1000000)
            }
        }).then((answer) => {
            const data = answer.data;
            if(data.result === 'success') {
                let content = self.state.content;
                content[chapter] = data.data;
                self.setState({
                    content: content,
                    loadingState: false,
                    error: null
                });
            } else {
                self.setState({
                    loadingState: false,
                    error: data.text
                })
            }
        })
    }

    getChapterTitle(myChapter) {
        const chapters = this.props.state.chapters;
        for(let i=0; i < chapters.length; i++) {
            if(chapters[i].chapter == myChapter) return chapters[i].title;
        }
    }

    componentWillMount() {
        const chapter = this.props.chapter ? this.props.chapter : this.props.state.activeChapter;
        if(!this.state.content[chapter]) this.requestChapterData(chapter);

        if(chapter != this.props.state.activeChapter) this.props.elementActions.switchActiveChapter(chapter);
    }

    componentWillReceiveProps(nextProps) {
        const chapter = nextProps.chapter ? nextProps.chapter : nextProps.state.activeChapter;
        if(!this.state.content[chapter]) this.requestChapterData(chapter);

        if(chapter != nextProps.state.activeChapter) this.props.elementActions.switchActiveChapter(chapter);

        if(chapter != this.props.chapter) this.setState({ error: null });
    }

    render() {

        let title = this.props.title || "Вопросы и ответы: ";


        const chapter = this.props.chapter ? this.props.chapter : this.props.state.activeChapter;
        // const content = this.state.content[];
        const content = this.state.loadingState ? [] : this.state.content[chapter] ? this.state.content[chapter] : [];
        const lineStyle = getStyle('b-row b-border');

        const currentRegion = CONST.REGION_DATA[this.props.authenticate.region] ? CONST.REGION_DATA[this.props.authenticate.region] : CONST.REGION_DATA.default;

        const replacements = {
            'PHONE': currentRegion.phone,
            'LINK_PHONE': currentRegion.link_phone
        };

        return (
            <Panel title={title + ' ' + this.getChapterTitle(chapter)} rowClassName={getStyle('block f-auto')} noRow={true} icon={getStyle('icon-manual')}>
                { this.state.error ?
                    <div className={getStyle('b-row')}>{this.state.error}</div>
                : null }
                {content.map((htmlData, _i) => {
                    const Element = StaticPageDisplayContainer(htmlData.content, getStyle('faq'), replacements);
                    return (
                        <div className={lineStyle} key={_i}>
                            <h5>{htmlData.title}</h5>
                            <Element />
                        </div>
                    );
                })}
            </Panel>
        );
    }
}

function mapStateToProps(state) {
    return {
        state: state.FAQ,
        authenticate: state.authenticate
    }
}

function mapDispatchToProps(dispatch) {
    return {
        elementActions: bindActionCreators(PanelActions, dispatch)
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(PanelFAQContent);