/**
 * Created by dpopov on 23.08.2017.
 */

import React from 'react';
import axios from 'axios';

import Panel from '.';
import Oops from '../Oops';

import * as CONST from '../../const';
import { getStyle } from '../../tools';

class TariffPage extends React.Component {
    constructor(props) {
        super(props);


        this.state = {
            htmlData: null,
            query: null,
            errorText: null,
            isLoading: false,
            compare: props.compareValue
        }
    }

    componentWillReceiveProps(nextProps) {
        if(nextProps.compareValue !== this.state.compare) {
            this.setState({ compare: nextProps.compareValue });
            this.requestPage(nextProps.queryParams);
        }
    }

    componentDidMount() {
        this.requestPage(this.props.queryParams);
    }

    requestPage(params) {

        // const page = this.props.pageName;
        // const params = this.props.queryParams;
        let self = this;

        self.setState({ isLoading: true });

        axios.get(CONST.API + '/staticPages/tariffDesc', {
            withCredentials: true,
            params: {...params, '.rnd': Math.ceil(Math.random()*1000000)}
        }).then(function(data) {
            if(data.data.result === "error") {
                self.setState({errorText: data.data.text, isLoading: false});
            } else {
                self.setState({
                    errorText: null,
                    isLoading: false,
                    title: data.data.data.title,
                    htmlData: data.data.data.html
                });
            }
        })
    }

    render() {
        const panelTitle = this.state.title;
        const icon = this.props.icon || getStyle('icon-star');
        const bodyStyle = this.props.bodyStyle;
        const containerStyle = this.props.containerStyle;

        const content = this.state.htmlData;
        const isLoading = this.state.isLoading;
        //if(!content) isLoading = true;

        return (
            <Panel title={panelTitle} icon={icon} isLoading={isLoading}>
                <div className={bodyStyle}>
                    {content ?
                        <div className={containerStyle} dangerouslySetInnerHTML={{__html: content}}/>
                        :
                        <Oops>
                            <h3>{this.state.errorText}</h3>
                        </Oops>
                    }
                </div>
            </Panel>
        );
    }


}

export default TariffPage;