/**
 * Created by dpopov on 13.07.2017.
 */
import React from 'react';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import * as authenticateActions from './actions';
import InputMask from 'react-input-mask';
import Panel from '../Components/Panel';
import OldLKRedirect from './oldLkLoginForm';

import styleAuth from './style.module.css';
//import AjaxLoadImage from '../../../assets/images/ajax_loader_orange_64.gif';
import SeasicknessImage from '../Components/SeasicknessImage';

import {getStyle} from "../tools";
import * as CONST from '../const';

import logoImage from '../../assets/images/obit_logo_login.png';
import {Link} from 'react-router-dom';
import axios from "axios/index";


class AuthenticateForm extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            ...this.state,
            login: '',
            password: '',
            openOld: false,
            loadingState: false,
            forgotPassword: false,
            linkVK: false,
            typeAlert: 'phone',
        };

        this.handlerChange = this.handlerChange.bind(this);
        this.handlerSubmit = this.handlerSubmit.bind(this);
    }

    handlerChange(event) {
        if (event.target.tagName === 'INPUT') {
            const newState = {};
            newState[event.target.getAttribute('name')] = event.target.value;
            this.setState(newState);
        }
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.state.redirect) {
            if (nextProps.state.redirect === 'old') this.setState({openOld: true});
            else {
                this.setState({loadingState: true});
                window.location.href = nextProps.state.redirect;
            }
        }
    }

    handlerSubmit(event) {
        event.preventDefault();

//        const login = this.state.login;
//        const password = this.state.password;

        const login = this.inputUser.value;
        const password = this.inputPassword.value;

        this.props.authenticate.AuthenticateUser(login, password);
    }

    clickForgotPassword = (event) => {
        event.preventDefault();

        this.setState({forgotPassword: true});
    };

    handlerForgotPasswordSubmit = (event) => {
        event.preventDefault();

        const contract = this.contract.value;
        const typeAlert = this.state.typeAlert;
        let valueTypeAlert = this.valueTypeAlert.value.replace(/[- )(]/g, '');

        this.props.authenticate.forgotPassword(contract, typeAlert, valueTypeAlert);
    };

    resetFirstVKLogin = () => {
        let postParams = new FormData();
        postParams.append('contract', this.props.state.activeContract);
        axios.post(CONST.API+"/vk/resetFirstVKLogin", postParams, {
            withCredentials: true,
        })
    };

    linkVK = () => {
        const activeContract = this.props.state.activeContract;
        // eslint-disable-next-line
        VK.Auth.login(function(response) {
            if (response.session) {// Пользователь успешно авторизовался
                // eslint-disable-next-line
                VK.Api.call('users.get', {user_ids: response.session.user.id, fields: self.fieldsVK, v:"5.73"}, function(r) {
                    if(r.response) {

                        let vkData = new FormData();

                        vkData.append('vkData', JSON.stringify(r.response[0]));
                        vkData.append('contract', activeContract);

                        axios.post(CONST.API+'/vk/saveData', vkData, {
                            withCredentials: true,
                        }).then((result) => {
                        });
                    }
                });
            }
        });
    };

    render() {

        // console.log('!!!', this.props.state.forgotPassword,this.state.forgotPassword);
        //const formTitle = this.props.title || 'Вход в личный кабинет';
        const formTitle = <img src={logoImage} alt={""}/>;
        const authInProcess = this.props.state.formInProcess || this.state.loadingState;

        const loginScreenImage = this.props.state.loginScreenImage;
        //const loginScreenImage = '9584.mp4';
        const loginScreenType = loginScreenImage && loginScreenImage.match(/\.mp4/i) ? 'video' : 'image';

        const placeholdersTypeAlert = {phone: 'Введите ваш номер телефона', email: 'Введите e-mail, указанный в профиле'};

        if (this.state.openOld) return <OldLKRedirect login={this.inputUser.value}
                                                      password={this.inputPassword.value}/>;

//        console.log('!!!', loginScreenImage, loginScreenType);

        //             <div className={styleAuth['authContainer']}></div>
        /*

         <div className={getStyle('b-row')}>
         <ul>
         <li>
         <label htmlFor="login">Имя пользователя</label>
         <input type="text" name="login" className={[style['wide']].join(' ')} ref={(input) => this.inputUser = input }/>
         </li>
         <li>
         <label htmlFor="passowrd">Пароль</label>
         <input type="password" name="password" className={[style['wide']].join(' ')} ref={(input) => this.inputPassword = input }/>
         </li>
         </ul>
         </div
         */

        const Input = () => (
            <InputMask mask="7(999)999-99-99"
                       className={getStyle('wide')}
                       placeholder={placeholdersTypeAlert[this.state.typeAlert]}
                       ref = {(input) => this.valueTypeAlert = input}
            >
            </InputMask>
        );
        return (
            <div>
                {loginScreenImage ?
                    <SeasicknessImage src={CONST.API + '/auth/getLoginScreenImage?image=' + loginScreenImage}
                                      srcType={loginScreenType}/>
                    : null}
                <Panel title={formTitle} rowClassName={styleAuth['authForm']} className={styleAuth['authForm-block']}
                       headerClassName={styleAuth['authForm-header']} isLoading={authInProcess}>

                    {this.state.forgotPassword
                        ?

                        this.props.state.forgotPassword
                            ?
                            <form onSubmit={() => document.location.href= '/'}>
                                <div className={styleAuth['authForm-row']}>
                                    <p className={'t-center'}>
                                        Новый пароль успешно создан и отправлен вам.</p>
                                </div>
                                <div className={styleAuth['authForm-row']}>
                                    <ul>
                                        <li><input type="submit" className={getStyle('btn-major btn-big')}
                                                   value="Авторизоваться"/>
                                        </li>
                                    </ul>

                                </div>
                            </form>
                            :
                            <form onSubmit={this.handlerForgotPasswordSubmit}>
                                <div className={styleAuth['authForm-row']}>
                                    <ul>
                                        <li>Номер договора:
                                            <input type="text" name="login" className={getStyle('wide')}
                                                   ref={(input) => this.contract = input} />
                                        </li>
                                        <li>
                                            <label className={styleAuth['labelRadio']}>
                                                <input type='radio' id='radio-phone' name='typeAlert' value='phone'
                                                       className={styleAuth['radioInput']}
                                                       checked={this.state.typeAlert === 'phone'}
                                                       onChange={(e) => this.setState({typeAlert: e.target.value})}/>
                                                <span className={styleAuth['textRadio']}>Отправить код подтверждения по телефону</span>
                                            </label>
                                            <br/>
                                            <label className={styleAuth['labelRadio']}>
                                                <input type='radio' id='radio-email' name='typeAlert' value='email'
                                                       className={styleAuth['radioInput']}
                                                       checked={this.state.typeAlert === 'email'}
                                                       onChange={(e) => this.setState({typeAlert: e.target.value})}/>
                                                <span className={styleAuth['textRadio']}>Отправить ссылку для изменения пароля по почте</span>
                                            </label>


                                        </li>
                                        <li>
                                            {this.state.typeAlert === 'phone' ? <Input/> :
                                                <input type="text"
                                                       className={getStyle('wide')}
                                                       placeholder={placeholdersTypeAlert[this.state.typeAlert]}
                                                       ref = {(input) => this.valueTypeAlert = input}

                                                />}
                                        </li>

                                    </ul>
                                </div>
                                <div className={styleAuth['authForm-row']}>
                                    <ul>
                                        <li><input type="submit" className={getStyle('btn-major btn-big')}
                                                   value="Восстановить"/>
                                        </li>
                                    </ul>
                                </div>
                            </form>

                        :
                         this.props.state.firstVKLogin ?
                            <React.Fragment>
                                <div className={styleAuth['form-linked-vk']}>
                                    <p>Персонифицируйте свой личный кабинет</p> <p>- привяжите ваш профиль Вконтакте.</p>
                                </div>
                                <div className={styleAuth['authForm-row']}>
                                    <ul>
                                        <li><input type="submit" className={getStyle('btn-major btn-big')}
                                                   value="Привязать профиль VK" onClick={this.linkVK}/>
                                        </li>
                                        <li><input type="submit" className={getStyle('btn btn-big')}
                                                   value="В личный кабинет" onClick={this.resetFirstVKLogin} />
                                        </li>
                                    </ul>
                                </div>
                            </React.Fragment>
                            :
                        <form onSubmit={this.handlerSubmit}>
                            <div className={styleAuth['authForm-row']}>
                                <ul>
                                    <li>
                                        <input type="text" name="login" className={getStyle('wide')}
                                               ref={(input) => this.inputUser = input} placeholder="Имя пользователя"/>
                                    </li>
                                    <li>
                                        <input type="password" name="password" className={getStyle('wide')}
                                               ref={(input) => this.inputPassword = input} placeholder="Пароль"/>
                                    </li>
                                </ul>
                            </div>
                            <div className={styleAuth['authForm-row']}>
                                <ul>
                                    <li><input type="submit" className={getStyle('btn-major btn-big')}
                                               value="Войти"/>
                                    </li>
                                </ul>
                                <div className={styleAuth['authForm-links']}>
                                    <a href="https://info-svc-012.obit.ru/ObitINFORegistration/registration.cgi"
                                       className={styleAuth['lostPassword']}>Регистрация для юр.лиц</a>
                                    <Link to={CONST.ROOT_PATH + "/forgot-password"} onClick={this.clickForgotPassword}>Забыли
                                        пароль?</Link>
                                </div>
                                {this.props.children}
                            </div>
                        </form>

                    }
                </Panel>
            </div>
        )
    }
}

function mapStateToProps(state) {
    return {
        state: state.authenticate
    }
}

function mapDispatchToProps(dispatch) {
    return {
        elementActions: null,
        authenticate: bindActionCreators(authenticateActions, dispatch)
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(AuthenticateForm);


//<table
//    className={[style['v-top'], style['left-pull'], style['form-table'], style['b-border']].join(' ')}>
//    <tbody>
//    <tr>
//        <td>Имя пользователя</td>
//        <td><input type="text" name="login" className={[style['wide']].join(' ')}
//                   ref={(input) => this.inputUser = input }/></td>
//        <td></td>
//    </tr>
//    <tr>
//        <td>Пароль</td>
//        <td><input type="password" name="password" className={[style['wide']].join(' ')}
//                   ref={(input) => this.inputPassword = input }/></td>
//        <td></td>
//    </tr>
//    </tbody>
//</table>
