/**
 * Created by dpopov on 19.07.2017.
 */
/* eslint eqeqeq: ["off", "smart"] */
import React from 'react';

import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import * as CONST from '../../../const';


import Panel from '../../../Components/Panel';
import Paginator from '../../../Components/Panel/paginator';

import * as suspendActions from '../actions';

import { getStyle } from '../../../tools';

import 'moment/locale/ru';
import moment from 'moment';
moment.locale('ru');

class PanelSuspendHistory extends React.Component {
    constructor(props) {
        super(props);

        this.state ={
            skip: 0,
            limit: 5
        };

        this.breakSuspend = this.breakSuspend.bind(this);
        this.changeDisplayPage = this.changeDisplayPage.bind(this);
        this.changeLimitClick = this.changeLimitClick.bind(this);
    }

    componentWillMount() {
        this.props.elementActions.requestSuspendHistory(this.props.authData.activeContract, this.state.skip, this.state.limit);
    }

    breakSuspend(event) {
        event.preventDefault();
        const hcrRule = event.target.getAttribute('data-hcr');
        const contract = this.props.authData.activeContract;

        this.props.elementActions.breakSuspend(contract, hcrRule);
    }

    changeDisplayPage(event) {
        event.preventDefault();

        const newSkip = event.currentTarget.getAttribute('data-from');
        if(newSkip != this.state.skip) return false;

        this.setState({ skip: newSkip});
        this.props.elementActions.requestSuspendHistory(this.props.authData.activeContract, newSkip, this.state.limit);
    }

    changeLimitClick(event) {
        event.preventDefault();

        const newLimit = event.currentTarget.getAttribute('data-count');
        if(newLimit == this.state.limit) return false;
        this.setState({ limit: newLimit });
        this.props.elementActions.requestSuspendHistory(this.props.authData.activeContract, this.state.skip, newLimit);
    }

    render() {
        const panelTitle = this.props.title || "История приостановок";
        const state = this.props.state;

        if(!state.history) return null;
        if(!state.history.items) return null;
        if(!state.history.items.length) return null;
        let i = 0;

        return (
            <Panel title={panelTitle} icon={getStyle('icon-list')} isLoading={state.loadingHistoryState}>
                <table className={getStyle('line v-mid left-pull')}>
                    <tbody>
                    {state.history.items.map((item) => {
                        return (<tr key={i++}>
                            <td>с <strong>{moment(item.DateBegin).format('DD.MM.YYYY')}</strong> по <strong>{moment(item.DateEnd).format('DD.MM.YYYY')}</strong></td>
                            <td className={getStyle('t-right')}>
                                {(((item.active || item.planned) && item.PayHCR) || CONST.SUSPEND_DATE_END_FIRST_HCR.format('DD.MM.YYYY') === moment(item.DateEnd).format('DD.MM.YYYY')) ?
                                    <button type="button" onClick={this.breakSuspend} data-hcr={item.PayHCR}>Возобновить обслуживание</button>
                                : null}
                            </td>
                        </tr>)
                    })}
                    </tbody>
                </table>
                <Paginator totalCount={state.history.total} displayFrom={state.history.skip} currentLimit={state.history.count} limits={[5, 10, 15]}
                           changeLimitClick={this.changeLimitClick} changeDisplayPage={this.changeDisplayPage}
                />
            </Panel>
        );
    }
}

function mapStateToProps(state) {
    return {
        authData: state.authenticate,
        state: state.suspendData
//        services: state.panelServices
//        servicesData: state.panelServices
    }
}

function mapDispatchToProps(dispatch) {
    return {
        elementActions: bindActionCreators(suspendActions, dispatch)
//        authenticate: bindActionCreators(SubNavBlock, dispatch)
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(PanelSuspendHistory);