/**
 * Created by dpopov on 31.08.2017.
 */
import * as CONST from '../../const';
import axios from 'axios';

import 'moment/locale/ru'
import moment from 'moment';
moment.locale('ru');

export function requestSuspendData(contract, from, to, update) {
    return (dispatch, getState, args) => {
        dispatch({ type: update?CONST.SUSPEND.SUSPEND_UPDATE_DATA:CONST.SUSPEND.SUSPEND_REQUEST_DATA, payload: true });

        axios.get(CONST.API + '/contract/checkSuspend', {
            withCredentials: true,
            params: {
                'DogovorNum': contract,
                'periodStart': from.format('YYYY-MM-DD 00:00:00'),
                'periodEnd': to.format('YYYY-MM-DD 00:00:00'),
                '.rnd': Math.ceil(Math.random()*1000000)
            }
        }).then(function(result) {
            if(result.data.result === 'success') {
                dispatch({ type: CONST.SUSPEND.SUSPEND_SET_DATA, payload: result.data.data });
            } else {
                dispatch({ type: CONST.NOTIFY.NOTIFY_APPEND_ERROR, payload: result.data.text});
            }
        })

    }
}

// Обновляем список услуг, которые будут затронуты новым периодом приостановки
export function updateSuspendData(contract, from, to) {
    return requestSuspendData(contract, from, to, true);
}

// Получение истории приостановок
export function requestSuspendHistory(contract, skip, limit) {
    return (dispatch, getState, args) => {
        dispatch({ type: CONST.SUSPEND.SUSPEND_REQUEST_HISTORY, payload: true });

        axios.get(CONST.API + '/contract/suspendHistoryEx', {
            withCredentials: true,
            params: {
                'DogovorNum': contract,
                'skip': skip,
                'count': limit,
                '.rnd': Math.ceil(Math.random()*1000000)
            }
        }).then(function(result) {
            if(result.data.result === 'success') {
                dispatch({ type: CONST.SUSPEND.SUSPEND_SET_HISTORY, payload: result.data.data });
            } else {
                dispatch({ type: CONST.NOTIFY.NOTIFY_APPEND_ERROR, payload: result.data.text});
            }
        });
    }
}

// возобнавление
export function breakSuspend(contract, rule) {
    return (dispatch, getState, args) => {
        dispatch({type: CONST.SUSPEND.SUSPEND_REQUEST_HISTORY, payload: true });

        axios.get(CONST.API + '/contract/unSuspend', {
            withCredentials: true,
            params: {
                'DogovorNum': contract,
                hcr: rule,
                '.rnd': Math.ceil(Math.random()*1000000)
            }
        }).then(function(result) {
            if(result.data.result === 'success') {
                updateSuspendData(contract, moment(), moment().add('1', 'month'))(dispatch, getState, args);
                requestSuspendHistory(contract)(dispatch, getState, args);
            } else {
                dispatch({ type: CONST.NOTIFY.NOTIFY_APPEND_ERROR, payload: result.data.text});
            }
        });
    }
}

// выполнение приостановки
export function makeSuspend(contract, periodStart, periodEnd) {
    return (dispatch, getState, args) => {
        dispatch({ type: CONST.SUSPEND.SUSPEND_REQUEST_DATA, payload: true});

        axios.get(CONST.API + '/contract/suspend', {
            withCredentials: true,
            params: {
                'DogovorNum': contract,
                'periodStart': periodStart.format('YYYY-MM-DD 00:00:00'),
                'periodEnd': periodEnd.format('YYYY-MM-DD 00:00:00'),
                '.rnd': Math.ceil(Math.random()*1000000)
            }
        }).then(function(result) {
            if(result.data.result === 'success') {
                updateSuspendData(contract, periodStart, periodEnd)(dispatch, getState, args);
                requestSuspendHistory(contract)(dispatch, getState, args);
            } else {
                dispatch({ type: CONST.NOTIFY.NOTIFY_APPEND_ERROR, payload: result.data.text});
            }
        });
    }
}