import React from 'react';
import { Route } from 'react-router-dom';

//import CheckAccess from "../../Authenticate";
import * as CONST from "../../const";

import Rate2Week from './rate_2week';

export const SmallLinksRoutes = [
    <Route key="SmallLinksRoutes1" exact path={CONST.ROOT_PATH + "/c/2:code"} component={Rate2Week} />,
];
