/**
 *
 * панель отображения списка новостей
 *
 * Created by dpopov on 14.08.2017.
 */
/*eslint eqeqeq: ["off", "smart"]*/

import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Link } from 'react-router-dom';
import * as PanelNewsActions from './actions';
import Panel from '../../Components/Panel';

import * as CONST from '../../const';
import { getStyle } from '../../tools';

class PanelNews extends React.Component {
    constructor(props) {
        super(props);

        this.changeLimitClick = this.changeLimitClick.bind(this);
        this.changeDisplayPage = this.changeDisplayPage.bind(this);
    }

    componentWillMount() {
        this.props.elementActions.getNewsTitle(this.props.limit || this.props.state.displayCount);
    }

    changeLimitClick(event) {
        event.preventDefault();
        const newCountValue = event.target.getAttribute('data-count');

        if(newCountValue != this.props.state.displayCount) this.props.elementActions.getNewsTitle(newCountValue);
    }

    changeDisplayPage(event) {
        event.preventDefault();

        const currentLimit = this.props.state.displayCount;
        const currentFrom = this.props.state.displayFrom;
        const newFrom = event.target.getAttribute('data-from');

        if(currentFrom != newFrom) this.props.elementActions.getNewsTitle(currentLimit, newFrom);
    }

    render() {
        const Title = this.props.title || "Новости «ОБИТ»";
        const loadingState = this.props.state.panelLoadingState;
        const isWidget = this.props.widget || false;

        let content = null;
        let bottom = null;

        if(!loadingState) {
            const newsTitleItems = this.props.state.newsList || [];
            let items = [];

            if(isWidget) {
                bottom = (<div className={getStyle('b-row')}><Link to={CONST.ROOT_PATH + "/news.html"} className={getStyle('button f-right')}>Посмотреть все новости</Link></div>);
            } else {
                const currentLimit = this.props.state.displayCount;
                const currentFrom = this.props.state.displayFrom;
                const newsCount = this.props.state.totalNews;

                const pagesCount = Math.ceil(newsCount / currentLimit);
                const currentPage = Math.floor(currentFrom / currentLimit) + 1;

                let paginator = [];

                if(currentPage != 1) { paginator.push(<button className={getStyle('button')} onClick={this.changeDisplayPage} data-from={0}>В начало</button>) }

                for(let p=currentPage - 1; p <= currentPage + 1; p++) {
                    if(p == 0) continue;
                    if(p > pagesCount) break;

                    paginator.push(<button className={[getStyle('button'), p==currentPage?getStyle('active'):null].join(' ')} onClick={this.changeDisplayPage} data-from={(p-1)*currentLimit}>{p}</button>);
                }

                if(currentPage != pagesCount) { paginator.push(<button className={getStyle('button')} onClick={this.changeDisplayPage} data-from={(pagesCount-1)*currentLimit}>В конец</button>)}

                bottom = (
                    <div className={getStyle('b-row')}>
                        <div className={getStyle('f-left')}>
                            {paginator}
                        </div>
                        <div className={getStyle('f-right')}>
                            Показать по&emsp;
                            <button type="button" className={currentLimit == 10 ? getStyle('active') : null} onClick={this.changeLimitClick} data-count={10}>10</button>
                            <button type="button" className={currentLimit == 20 ? getStyle('active') : null} onClick={this.changeLimitClick} data-count={20}>20</button>
                            <button type="button" className={currentLimit == 30 ? getStyle('active') : null} onClick={this.changeLimitClick} data-count={30}>30</button>
                        </div>
                    </div>);
            }

            for(let i=0; i<newsTitleItems.length;i++) {
                items.push(
                    <tr key={i}>
                        <td className={getStyle('t-date')}>{newsTitleItems[i]['DisplayDate']}</td>
                        <td><Link to={CONST.ROOT_PATH + "/news-"+newsTitleItems[i]['Id']+".html"}>{newsTitleItems[i]['Title']}</Link></td>
                    </tr>
                );
            }

            content = (<div>
                <table className={getStyle('line v-mid left-pull')}>
                    <tbody>
                    {items}
                    </tbody>
                </table>
                {bottom}
            </div>);
        }

        return (
            <Panel title={Title} icon={getStyle('icon-docs')} headerClassName={getStyle('small b-header-z-0')} isLoading={loadingState}>
                {content}
            </Panel>
        )
    }
}

function mapStateToProps(state) {
    return {
        state: state.panelNews
    }
}

function mapDispatchToProps(dispatch) {
    return {
        elementActions: bindActionCreators(PanelNewsActions, dispatch)
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(PanelNews);