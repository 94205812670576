/**
 * Created by dpopov on 10.10.2017.
 */
import React from 'react';

import ChapterHelp from './index';

import { getStyle } from '../../tools';

import Chapters from '../../Panels/PanelFAQ/chapters';
import ChapterContent from '../../Panels/PanelFAQ/content';

export default (props) => {
    const chapter = props.match.params.chapter;

    return (
        <ChapterHelp>
            <div className={getStyle('row')}><div className={getStyle('wrap')}>
                <div className={getStyle('block side-block')}>
                    <Chapters />
                </div>

                <ChapterContent chapter={chapter} />
            </div></div>
        </ChapterHelp>
    )
}