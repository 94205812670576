import React from 'react';
import {bindActionCreators} from "redux";
import {connect} from 'react-redux';

import * as ACTIONS from './actions';

class GooglePayContext extends React.Component {

    componentDidMount() {
        const { GooglePay, GoogleActions } = this.props;
        if(!GooglePay.contextInitialised && !GooglePay.contextInitialiseProcess) {
            GoogleActions.loadSDK();
        }
    }

    render() {
        return this.props.children;
    }
}

function mapStateToProps(state) {
    return {
        GooglePay: state.GooglePay
    }
}

function mapDispatchToProps(dispatch) {
    return {
        GoogleActions: bindActionCreators(ACTIONS, dispatch)
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(GooglePayContext);