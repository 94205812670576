/**
 * Created by dpopov on 08.09.2017.
 */
/*eslint eqeqeq: ["off", "smart"]*/

import axios from 'axios';
import * as CONST from '../../const';

import 'moment/locale/ru';
import moment from 'moment';
moment.locale('ru');

export function getServiceDataFromTree(servicesTree, serviceId) {
    let answer = null;

    if(!servicesTree || !serviceId) return answer;

    for(let i=0; i<servicesTree.length; i++) {
        const App = servicesTree[i];
        const AppServicesIDs = Object.keys(App.services);
//        const AppStopDate = App.dateStop ? moment(App.dateStop) : null;
//        const AppStartDate = App.dateStart ? moment(App.dateStart) : null;
        // Идём по услугам
        for(let appServiceID_i = 0; appServiceID_i < AppServicesIDs.length; appServiceID_i++) {
            const AppServiceID = AppServicesIDs[appServiceID_i];
            const hcrList = App.services[AppServiceID];

            let hcrItem = hcrList;

            while (hcrItem) {
                if(hcrItem.id == serviceId) return hcrItem;
                hcrItem = hcrItem.__next;
            }
        }
    }

    return answer;
}

export function getServiceData(servicesData, serviceId) {
    let answer = null;

    if(!servicesData || !serviceId) return answer;
    if(typeof servicesData.forEach !== 'function') return answer;

    let appChargeRules = null;

    servicesData.forEach(function(app) {
        if(app.services && !answer) Object.keys(app.services).forEach(function(appServiceId) {
            const chargeRules = app.services[appServiceId];
            if(!answer) chargeRules.forEach(function(chargeRuleData) {
                if(chargeRuleData.id == serviceId) {
                    appChargeRules = chargeRules;
                    answer = chargeRuleData;
                    answer.AppServiceID = appServiceId;
                }
            });
        });
    });

    if(answer && appChargeRules) {
        answer.nextRule = null;
        answer.momentStart = moment(answer.dateStart);
        answer.momentStop = moment(answer.dateStop);

        appChargeRules.forEach((chargeRuleData) => {
            chargeRuleData.momentStart = moment(chargeRuleData.dateStart);
            chargeRuleData.momentStop = moment(chargeRuleData.dateStop);
            if(chargeRuleData.momentStart.isBefore(answer.momentStop)) return;

            if(!answer.nextRule && chargeRuleData.id != answer.id) answer.nextRule = chargeRuleData;
            else if(answer.nextRule && chargeRuleData.momentStart.isBefore(answer.nextRule.momentStart)) answer.nextRule = chargeRuleData
        });
    }

    return answer;
}

export function getServiceDataByAppServiceID(servicesData, serviceId) {
    let answer = null;

    if(!servicesData || !serviceId) return answer;
    if(typeof servicesData.forEach !== 'function') return answer;

    let appChargeRules = null;

    servicesData.forEach(function(app) {
        if(app.services && !answer) Object.keys(app.services).forEach(function(appServiceId) {
            const chargeRules = app.services[appServiceId];
            if(!answer) chargeRules.forEach(function(chargeRuleData) {
                if(chargeRuleData.id == serviceId) {
                    appChargeRules = chargeRules;
                    answer = chargeRuleData;
                }
            });
        });
    });

    if(answer && appChargeRules) {
        answer.nextRule = null;
        answer.momentStart = moment(answer.dateStart);
        answer.momentStop = moment(answer.dateStop);

        appChargeRules.forEach((chargeRuleData) => {
            chargeRuleData.momentStart = moment(chargeRuleData.dateStart);
            chargeRuleData.momentStop = moment(chargeRuleData.dateStop);
            if(chargeRuleData.momentStart.isBefore(answer.momentStop)) return;

            if(!answer.nextRule && chargeRuleData.id != answer.id) answer.nextRule = chargeRuleData;
            else if(chargeRuleData.momentStart.isBefore(answer.nextRule.momentStart)) answer.nextRule = chargeRuleData
        });
    }

    return answer;
}

export function requestServiceWays(contract, hcr, ab, onSuccess, onError) {
    axios.get(CONST.API + '/services/possibleTariffs', {
        withCredentials: true,
        params: {
            contract: contract,
            hcr: hcr,
            ab: ab || 0,
		    '.rnd': Math.ceil(Math.random()*1000000)
        }
    }).then(function(answer) {
        const data = answer.data;
        if(data.result === 'success') {
            if(typeof onSuccess == 'function') onSuccess(data);
        } else {
            if(typeof onError == 'function') onError(data);
        }
    });
}

export function findParentService(servicesData, AppServiceID) {
    let answer = null;

    if(!servicesData || !AppServiceID) return answer;

    servicesData.forEach(function(app) {
        if(app.services && !answer) Object.keys(app.services).forEach(function(srcAppServiceID) {
            if(srcAppServiceID == AppServiceID) {
                const chargeRules = app.services[AppServiceID];
                const today = moment();

                chargeRules.forEach(function(chargeRuleData) {
                    const hcrBegin = moment(chargeRuleData['dateStart']);
                    const hcrEnd = moment(chargeRuleData['dateStop']);

                    if(today.isBetween(hcrBegin, hcrEnd)) {
                        answer = chargeRuleData;
                    }
                });
            }
        });
    });

    return answer;
}