import React from 'react';
import { Link } from 'react-router-dom';

import { getStyle } from '../../../tools';
import myStyle from '../style.module.css';
import * as CONST from "../../../const";

import 'moment/locale/ru';
import moment from 'moment';
moment.locale('ru');

export default (props) => {
    const bonusSpeed = props.bonusSpeed;
    const ruleId = props.ruleId;

    const linkHref = CONST.ROOT_PATH + (ruleId?'/bonus-speed.'+ruleId+'.html':'/bonus-speed.html');

    const beforeText = typeof props.beforeText === 'undefined' ? 'Бонусная скорость' : props.beforeText;
    const afterText = typeof props.afterText === 'undefined' ? '' : props.afterText;

    const aboutText = props.aboutText || 'Оплачивайте Интернет вовремя, не уходите "в минус" - и получайте +1 Мбит к скорости каждый месяц.';
    const aboutLink = typeof props.aboutLink === 'undefined' ? linkHref : props.aboutLink;
    const aboutExternalLink = props.aboutExternalLink;
    const hideLink = typeof props.hideLink === 'undefined' ? false : props.hideLink;

    if(!bonusSpeed) return null;

    return (
        <div className={myStyle['rule-stopped']}>
            {beforeText} <b>+{bonusSpeed} Мбит/с</b>&nbsp;{afterText}
            {moment().isBefore('2018-10-01 00:00:00') ? <small>(c 1 октября 2018 года)</small> : null}
            <div className={myStyle['bonus-speed-about-block']}>
                <div className={myStyle['bonus-speed-about-block-dropdown']}>
                    <span className={[getStyle('button a-color'), myStyle['help-me']].join(' ')}>?</span>
                    <div className={myStyle['bonus-speed-about']}>
                        {aboutText}&nbsp;
                        {!hideLink ?
                            aboutExternalLink ?
                                <a href={aboutExternalLink} target={"_blank"} className={getStyle('a-color')}>Подробнее...</a>
                                :
                                <Link to={aboutLink} className={getStyle('a-color')}>Подробнее...</Link>
                        : null}

                    </div>
                </div>
            </div>

        </div>
    );
}

