import React from 'react';

import { getStyle } from '../../../tools';
import style from './style.module.css';

import 'moment/locale/ru';
import moment from 'moment';
moment.locale('ru');

export default function(props) {
    if (!props.items) return null;

    let total = {
        cost: 0, inCount: 0, inDuration: 0, outCount: 0, outDuration: 0
    };

    return (
        <table className={[getStyle('line v-top left-pull'), style['ph-stat-v4-table']].join(' ')}>
            <thead>
            <tr>
                <th rowSpan={2}>День</th>
                <th rowSpan={2}>Номер</th>
                <th rowSpan={2}>Стоимость</th>
                <th colSpan={2}>Входящие вызовы</th>
                <th colSpan={2}>Исходящие вызовы</th>
            </tr>
            <tr>
                <th>Количество</th>
                <th>Время (минут)</th>
                <th>Количество</th>
                <th>Время (минут)</th>
            </tr>
            </thead>
            <tbody>
            {props.items.map((item, _i) => {
                total.cost += item.CalcPrice;
                total.inCount += item.callsCountIN;
                total.inDuration += item.callsDurationIN;
                total.outCount += item.callsCountOut;
                total.outDuration += item.callsDurationOut;

                return (<tr key={_i}>
                    <td>{moment(item.dateDay).format('DD.MM.YYYY')}</td>
                    <td className={getStyle('t-center')}>{item.UserIDNumber ?
                            item.UserIDNumber.match(/\d{10}#/) ?
                                item.UserIDNumber.replace(/^.*(\d{3})(\d{7})#(\d+)/, '+7 ($1) $2 ext. $3')
                            : item.UserIDNumber
                        : item.Number}</td>
                    <td className={getStyle('t-right')}>{item.CalcPrice}</td>
                    <td className={getStyle('t-right')}>{item.callsCountIN}</td>
                    <td className={getStyle('t-right')}>{item.callsDurationIN}</td>
                    <td className={getStyle('t-right')}>{item.callsCountOut}</td>
                    <td className={getStyle('t-right')}>{item.callsDurationOut}</td>
                </tr>);
            })}
            </tbody>
            <tfoot>
            <tr>
                <td>ИТОГО</td>
                <td />
                <td className={getStyle('t-right')}>{total.cost}</td>
                <td className={getStyle('t-right')}>{total.inCount}</td>
                <td className={getStyle('t-right')}>{total.inDuration}</td>
                <td className={getStyle('t-right')}>{total.outCount}</td>
                <td className={getStyle('t-right')}>{total.outDuration}</td>
            </tr>
            </tfoot>
        </table>
    );
}