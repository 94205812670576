/*eslint eqeqeq: ["off", "smart"]*/
import React from 'react';
import {connect} from "react-redux";
import axios from 'axios';

import myStyle from './style.module.css';
import { getStyle } from '../../tools';

import InputContractNumber from './inputContractNumber';
import ContactsForCheck from './contactsForCheck';

import * as CONST from '../../Components/GooglePay/const';

class PayByGoogle extends React.Component {

    getClient = () => {
        const { GooglePay } = this.props;
        return GooglePay.paymentClient;
    };

    processPayment = (paymentData) => {
        const { recommendMoney } = this.props;
        console.log(paymentData);
        // @todo pass payment token to your gateway to process payment
        const paymentToken = btoa(paymentData.paymentMethodData.tokenizationData.token);

        console.log(paymentToken);

        this.props.makePayment(null, false, {
            paymentToken: paymentToken,
            description: paymentData.description,
            type: paymentData.type
        });
    };

    makePayment = (event) => {
        const { recommendMoney } = this.props;
        event && event.preventDefault();

        const paymentDataRequest = {...CONST.baseRequest};
        paymentDataRequest.allowedPaymentMethods = [CONST.cardPaymentMethod];

        paymentDataRequest.transactionInfo = {
            totalPriceStatus: 'FINAL',
            totalPrice: recommendMoney,
            currencyCode: 'RUB',
            countryCode: 'RU'
        };

        paymentDataRequest.merchantInfo = CONST.merchantInfo;
//        paymentDataRequest.callbackIntents = ["PAYMENT_AUTHORIZATION"];

        console.log(paymentDataRequest);
//        return;
        this.getClient().loadPaymentData(paymentDataRequest)
            .then((paymentData) => {
                this.processPayment(paymentData)
            })
            .catch((err) => {
                console.log(err);
            });
    };

    render() {
        const { recommendMoney,  isFrame, amountChange, payButtonDisabled, requireContacts } = this.props;

        let subPanelHeaderClassName = null;
        let subPanelBodyClassName = null;

        if(isFrame) {
            subPanelHeaderClassName = myStyle['iframe-subpanel-header'];
            subPanelBodyClassName = myStyle['iframe-subpanel-body'];
        }

        return (
            <div>
                <div className={getStyle('b-row doble-col')} key="2">
                    <div className={getStyle('col-main')}>
                        <table className={getStyle('form-table')}><tbody>
                        {isFrame && <InputContractNumber {...this.props} />}
                        <tr>
                            <td>Сумма платежа</td>
                            <td>
                                <input type="text" value={recommendMoney} className={getStyle('input-x')} onChange={amountChange} />
                                <button type="button" className={getStyle('btn-major f-right')} onClick={this.makePayment} disabled={payButtonDisabled}>Оплатить</button>
                            </td>
                        </tr>
                        </tbody></table>
                        {requireContacts ? <ContactsForCheck {...this.props} /> : null }
                    </div>

                    <div className={getStyle('col-side')}>
                        <div className={getStyle('b-header')}><h4 className={subPanelHeaderClassName}><i className={getStyle('m-icon icon-star border')} /> Условия оплаты</h4></div>
                        <ul className={[getStyle('dash t-small'), subPanelBodyClassName].join(' ')}>
                            <li>Комиссия за проведение операции - 0%</li>
                            <li>«ОБИТ» не имеет доступа к вводимой информации</li>
                            <li>«ОБИТ» не хранит данные банковских карт на своих серверах</li>
                        </ul>
                    </div>
                </div>

            </div>
        )
    }
}

function mapStateToProps(state) {
    return {
        GooglePay: state.GooglePay
    }
}

export default connect(mapStateToProps)(PayByGoogle);
