/**
 * Created by dpopov on 07.11.2017.
 */
import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import axios from 'axios';

import Panel from '../../Components/Panel/promoPanel';
import CheckRoster from '../PanelServices/CheckRoster';

import { getStyle, processInputHtmlStaticPage, StaticPageDisplayContainer } from '../../tools';
import style from './style.module.css';

import * as PanelActions from './actions';
import * as CONST from '../../const';

import defaultImage from '../../../assets/images/card-img-3_big.png';

class PanelItem extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            loadingState: false,
            title: null,
            content: null,
            css: [],
            errorString: null
        };

        this.requestItem = this.requestItem.bind(this);
        this.onInnerClick = this.onInnerClick.bind(this);
//        this.extractServicesData = this.extractServicesData.bind(this);
        this.processInputHtml = this.processInputHtml.bind(this);
    }

    componentWillMount() {
        this.requestItem();
    }

    requestItem() {
        const promoName = this.props.match.params.promoName;
        const contract = this.props.authData.activeContract;
        const self = this;

        self.setState({loadingState: true});

        axios.get(CONST.API + '/adv/getPromoItem', {
            withCredentials: true,
            params: {
                contract: contract,
                promo: promoName,
		'.rnd': Math.ceil(Math.random()*1000000)
            }
        }).then((answer) => {
            const data = answer.data;
            if(data.result === 'success') {
                self.setState({
                    loadingState: false,
                    css: data.data.showitemimagestyle || {},
                    content: data.data.content,
                    title: data.data.title
                });
            } else {
                self.setState({ loadingState: false, errorString: data.text, title: 'Что-то пошло не так' });
            }
        });
    }

    onInnerClick(history) {
        return (event) => {
            const target = event.target;
            const href = target.getAttribute('href');

            if (href) {
                // Клик по ссылке, посмотрим, не является ли она внутренним переходом
                let matches = href.match(/^internal:(.+)$/);
                if (matches) {
                    event.preventDefault();
                    const targetLink = CONST.ROOT_PATH + matches[1];
                    history.push(targetLink);
                    return;
                }
            }
        }
    }

    processInputHtml(html) {
        const servicesTree = this.props.servicesData.servicesTree;
        return processInputHtmlStaticPage(html, servicesTree);
    }

    render() {
        const loadingState = this.state.loadingState;
        const title = this.state.title || 'Получаем данные';
        const promoName = this.props.match.params.promoName;
        const promoImage = this.state.errorString ? defaultImage : CONST.API + '/adv/getPromoImage?promo=' + encodeURIComponent(promoName) + '&contract=' + encodeURIComponent(this.props.authData.activeContract) + '&big=1';

        let DivContainer = this.state.errorString ? null : StaticPageDisplayContainer(this.processInputHtml(this.state.content));

        return (
            <CheckRoster from={"PanelPromos:itemDisplay"}>
                <Panel title={title} headerClassName={getStyle('promo-header')} isLoading={loadingState}
                       className={[getStyle('promo-block'), style['promo-body']].join(' ')} promoImage={promoImage}
                       promoImageStyle={this.state.css}>
                    {this.state.errorString ?
                        <h3>{this.state.errorString}</h3>
                        :
                        <DivContainer />
                    }
                </Panel>
            </CheckRoster>
        );
    }
}

function mapStateToProps(state) {
    return {
        authData: state.authenticate,
//      state: state.panelPay
        servicesData: state.panelServices
    }
}

function mapDispatchToProps(dispatch) {
    return {
        elementActions: bindActionCreators(PanelActions, dispatch)
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(PanelItem);
