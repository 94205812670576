/**
 * Created by dpopov on 17.08.2017.
 */
import React from 'react';
import myStyle from './style.module.css';

import InputContractNumber from './inputContractNumber';
import { getStyle } from "../../tools";

export default (props) => {
    const recommendMoney = props.recommendMoney;

    let subPanelHeaderClassName = null;
    let subPanelBodyClassName = null;

    if(props.isFrame) {
        subPanelHeaderClassName = myStyle['iframe-subpanel-header'];
        subPanelBodyClassName = myStyle['iframe-subpanel-body'];
    }


    return (
        <div className={getStyle('b-row doble-col')}>
            <div className={getStyle('col-main')}>
                <table className={getStyle('form-table')}><tbody>
                <InputContractNumber {...props} />
                <tr>
                    <td>Фамилия</td>
                    <td><input type="text" className={getStyle('wide')} value={props.inputValues.lastName || ''} name="lastName" onChange={props.inputValueChange} /></td>
                </tr>
                <tr>
                    <td>Имя</td>
                    <td><input type="text" className={getStyle('wide')} value={props.inputValues.firstName || ''} name="firstName" onChange={props.inputValueChange} /></td>
                </tr>
                <tr>
                    <td>Отчество</td>
                    <td><input type="text" className={getStyle('wide')} value={props.inputValues.middleName || ''} name="middleName" onChange={props.inputValueChange} /></td>
                </tr>
                <tr>
                    <td>Адрес прописки</td>
                    <td><textarea rows="4" className={getStyle('wide')} value={props.inputValues.address || ''} name="address" onChange={props.inputValueChange} /></td>
                </tr>
                <tr>
                    <td>Сумма платежа</td>
                    <td>
                        <input type="text" value={recommendMoney} className={getStyle('input-x')} onChange={props.amountChange} name="amount" />
                        <button type="button" className={getStyle('btn-major f-right')} onClick={props.makePayment} disabled={props.payButtonDisabled}>Оплатить</button>
                    </td>
                </tr>
                </tbody></table>
            </div>

            <div className={getStyle('col-side')}>
                <div className={getStyle('b-header')}><h4 className={subPanelHeaderClassName}><i className={getStyle('m-icon icon-star border')} /> Условия оплаты</h4></div>
                <ul className={[getStyle('dash t-small'), subPanelBodyClassName].join(' ')}>
                    <li>Комиссия за проведение операции - 0%</li>
                    <li>Оплату можно произвести в любом отделении <a href="https://www.sberbank.ru/ru/about/today/oib" target="_blank" rel="noopener noreferrer">Сбербанка</a></li>
                </ul>
            </div>

        </div>
    )
}