/**
 * Created by dpopov on 01.11.2017.
 */
/*eslint default-case: ["off"]*/
import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import * as authenticateActions from '../../../Authenticate/actions';
import * as panelServicesActions from '../actions';
import * as CONST from '../../../const';

import Panel from '../../../Components/Panel';
import Paginator from '../../../Components/Panel/paginator';
import Chooser from '../InternetStatistics/chooser';

import axios from 'axios';

import { getStyle, getStdNotifyStruct } from '../../../tools';

import V1 from './v1Details';
import V2 from './v2Directions';
import V3 from './v3Calls';
import V4 from './v4PerDay';

import '../../../../assets/css/obit.module.css';
import '../../../styles-fix.css';
import styles from './style.module.css';

import 'moment/locale/ru';
import moment from 'moment';
moment.locale('ru');

const initialState = {
    loadingState: false,
    errorString: null,
    checkboxes: {
        incoming: true,
        outgoing: true,
        e164: false,
        payed: false
    },
    period: {
        from: moment().startOf('month'),
        to: moment().endOf('month')
    },
    btnClicked: 'thisMonth',
    numberFilter: '',
    limitFrom: 0,
    limitCount: 50,
    variant: 2,
    contract: null,
    serviceId: null,
    itemsTotalCount: 0,
    items: []
};

class PanelPhoneStatistics extends React.Component {
    constructor(props) {
        super(props);

        this.state = {...initialState,
            contract: props.authData.activeContract,
            serviceId: props.serviceId
        };

        this.onCloseErrorClick = this.onCloseErrorClick.bind(this);
        this.onCheckBoxChange = this.onCheckBoxChange.bind(this);
        this.onVariantChange = this.onVariantChange.bind(this);
        this.onTextInputChange = this.onTextInputChange.bind(this);
        this.periodSetFrom = this.periodSetFrom.bind(this);
        this.periodSetTo = this.periodSetTo.bind(this);
        this.btnPreConfigureClick = this.btnPreConfigureClick.bind(this);
        this.changeDisplayPage = this.changeDisplayPage.bind(this);
        this.changeLimitClick = this.changeLimitClick.bind(this);

        this.refreshTimer = null;
    }

    componentDidMount() {
        this.refreshTableView(this.state);
    }

    componentWillReceiveProps(nextState) {
        if(nextState.authData.activeContract !== this.state.contract || nextState.serviceId !== this.state.serviceId) {
            let state = {...initialState,
                contract: nextState.authData.activeContract,
                serviceId: nextState.serviceId
            };

            this.refreshTableView(state);
        }
    }

    refreshTableView(newState) {
        newState.loadingState = true;
        this.setState(newState);

        if(this.refreshTimer) clearTimeout(this.refreshTimer);

        this.refreshTimer = setTimeout(() => {
            this.setState({ loadingState: true, errorString: null });
            const flags = {
                incoming: newState.checkboxes.incoming,
                outgoing: newState.checkboxes.outgoing,
                e164: newState.checkboxes.e164,
                payed: newState.checkboxes.payed
            };
            axios.get(CONST.API + '/services/history', {
                withCredentials: true,
                params: {
                    contract: newState.contract,
                    hcr: newState.serviceId,
                    type: 'phone',
                    periodStart: newState.period.from.format('YYYY-MM-DD'),
                    periodEnd: newState.period.to.format('YYYY-MM-DD'),
                    from: newState.limitFrom,
                    count: newState.limitCount,
                    numberFilter: newState.numberFilter,
                    flags: flags,
                    variant: newState.variant
                }
            }).then((answer) => {
                const data = answer.data;
                if(data.result === 'success') {
                    this.setState({
                        loadingState: false, errorString: null,
                        limitFrom: data.data.skip,
                        limitCount: data.data.count,
                        variant: data.data.variant,
                        itemsTotalCount: data.data.total,
                        items: data.data.items
                    });
                } else {
                    this.setState({ loadingState: false, errorString: data.text });
                }
            });
        }, 200);
    }

    onCloseErrorClick(event) {
        event.preventDefault();
        this.setState({errorString: null});
    }

    // +-*+-*+-*+-*-+*-+*-+*-+*-+*-+*-+*-+*-+*-+*-+*-+*-+*-+*-+*-+*-+*-+*-+*-+*-+*-+*-+*-+*-+*-+*-+*-+*-+*-+*-+*-+*-+*-+
    onCheckBoxChange(event) {
        let thisState = this.state;
        const checkboxName = event.currentTarget.getAttribute('name');

        thisState.checkboxes[checkboxName] = !thisState.checkboxes[checkboxName];
        this.refreshTableView(thisState);
    }
    onVariantChange(event) {
        this.refreshTableView({...this.state, variant: parseInt(event.currentTarget.getAttribute('value')), limitFrom: 0});
    }

    onTextInputChange(event) {
        const inputName = event.currentTarget.getAttribute('name');
        let thisState = this.state;

        thisState[inputName] = event.currentTarget.value;
        this.refreshTableView(thisState);
    }

    checkIsPeriodLikeBtnClicked(state) {
        if(moment().startOf('month').isSame(state.period.from) && moment().endOf('month').endOf('day').isSame(state.period.to.endOf('day'))) return 'thisMonth';
        if(moment().add(-1, 'month').startOf('month').isSame(state.period.from) && moment().add(-1, 'month').endOf('month').endOf('day').isSame(state.period.to.endOf('day'))) return 'prevMonth';
        if(moment().startOf('year').isSame(state.period.from) && moment().endOf('year').endOf('day').isSame(state.period.to.endOf('day'))) return 'thisYear';

        return null;
    }
    periodSetFrom(date) {
        date = moment(date);
        let state = this.state;
        if(state.period.to.isBefore(date)) state.period.to = moment(date);
        state.period.from = date;
        state.btnClicked = this.checkIsPeriodLikeBtnClicked(state);
        this.refreshTableView(state);
    }
    periodSetTo(date) {
        date = moment(date);
        let state = this.state;
        if(state.period.from.isAfter(date)) state.period.from = moment(date);
        state.period.to = date;
        state.btnClicked = this.checkIsPeriodLikeBtnClicked(state);
        this.refreshTableView(state);
    }
    btnPreConfigureClick(event, history) {
        event.preventDefault();
        const btnId = event.target.getAttribute('id');

        let state = this.state;
        state.btnClicked = btnId;

        switch(btnId) {
            case 'thisMonth':
                state.period = { from: moment().startOf('month'), to: moment().endOf('month') };
                break;
            case 'prevMonth':
                state.period = { from: moment().add(-1, 'month').startOf('month'), to: moment().add(-1, 'month').endOf('month') };
                break;
            case 'thisYear':
                state.period = { from: moment().startOf('year'), to: moment().endOf('year')};
                break;
        }

        this.refreshTableView(state);
    }
    changeDisplayPage(event) {
        event.preventDefault();
        this.refreshTableView({...this.state, limitFrom: event.target.getAttribute('data-from')})
    }
    changeLimitClick(event) {
        event.preventDefault();
        this.refreshTableView({...this.state, limitCount: event.target.getAttribute('data-count'), limitFrom: 0})
    }
    // +-*+-*+-*+-*-+*-+*-+*-+*-+*-+*-+*-+*-+*-+*-+*-+*-+*-+*-+*-+*-+*-+*-+*-+*-+*-+*-+*-+*-+*-+*-+*-+*-+*-+*-+*-+*-+*-+

    render() {
        const title = this.props.title || 'Статистика';
        const loadingState = this.props.loadingState || this.state.loadingState;
        const icon = this.props.icon || getStyle('icon-chart');

        const periodOptions = { from: this.state.period.from, to: this.state.period.to, fromChange: this.periodSetFrom, toChange: this.periodSetTo };

        const btnThisMonth = { onClick: this.btnPreConfigureClick, className: this.state.btnClicked === 'thisMonth'? getStyle('active') : null};
        const btnPrevMonth = { onClick: this.btnPreConfigureClick, className: this.state.btnClicked === 'prevMonth'? getStyle('active') : null};
        const btnThisYear  = { onClick: this.btnPreConfigureClick, className: this.state.btnClicked === 'thisYear'? getStyle('active') : null};


        return (
            <Panel title={title} icon={icon} isLoading={loadingState} notify={getStdNotifyStruct(this.state.errorString, this.onCloseErrorClick)}>
                <Chooser className={[getStyle('b-row gray'), 'z1000'].join(' ')} period={periodOptions}
                         btnThisMonth={btnThisMonth} btnPrevMonth={btnPrevMonth} btnThisYear={btnThisYear}
                >
                    <div className={[getStyle('clear'), styles['ph-stat-fix-labels']].join(' ')}>
                        <br />
                        <label><input type="radio" name="variant" value={1} checked={this.state.variant === 1} onChange={this.onVariantChange} /> Детализация</label>
                        <label><input type="radio" name="variant" value={2} checked={this.state.variant === 2} onChange={this.onVariantChange} /> По направлениям</label>
                        <label><input type="radio" name="variant" value={3} checked={this.state.variant === 3} onChange={this.onVariantChange} /> По вызовам</label>
                        <label><input type="radio" name="variant" value={4} checked={this.state.variant === 4} onChange={this.onVariantChange} /> По дням</label>

                    </div>
                    {this.state.variant === 1 ?
                    <div className={[getStyle('clear'), styles['ph-stat-fix-labels']].join(' ')}>
                        <br />
                        <label><input type="checkbox" name="incoming" onChange={this.onCheckBoxChange} checked={this.state.checkboxes.incoming} /> Входящие</label>
                        <label><input type="checkbox" name="outgoing" onChange={this.onCheckBoxChange} checked={this.state.checkboxes.outgoing} /> Исходящие</label>
                        {/*<label><input type="checkbox" name="e164" onChange={this.onCheckBoxChange} checked={this.state.checkboxes.e164} /> Номера E164</label>*/}
                        <label><input type="checkbox" name="payed" onChange={this.onCheckBoxChange} checked={this.state.checkboxes.payed} /> Только платные вызовы</label>
                    </div>
                    : null }
                </Chooser>
                <div>
                    {this.state.variant === 1 ?
                    <div className={getStyle('b-row b-border')}>
                        <div className={getStyle('f-left')}>
                            Быстрая фильтрация по номеру
                            <input type="text" name="numberFilter" value={this.state.numberFilter} onChange={this.onTextInputChange} />
                        </div>
                    </div>
                    : null }

                    {/*<div className={getStyle('b-row b-border')}>*/}
                        {this.state.variant === 1 ? <V1 items={loadingState? [] : this.state.items} /> : null }
                        {this.state.variant === 2 ? <V2 items={loadingState? [] : this.state.items} /> : null }
                        {this.state.variant === 3 ? <V3 items={loadingState? [] : this.state.items} /> : null }
                        {this.state.variant === 4 ? <V4 items={loadingState? [] : this.state.items} /> : null }
                    {/*</div>*/}
                    <Paginator totalCount={this.state.itemsTotalCount} currentLimit={this.state.limitCount}
                               displayFrom={this.state.limitFrom} limits={[25, 50, 100, 150]}
                               changeDisplayPage={this.changeDisplayPage} changeLimitClick={this.changeLimitClick} />

                </div>
            </Panel>
        );
    }
}

function mapStateToProps(state) {
    return {
        authData: state.authenticate,
        servicesData: state.panelServices
    }
}

function mapDispatchToProps(dispatch) {
    return {
        serviceActions: bindActionCreators(panelServicesActions, dispatch),
        authenticate: bindActionCreators(authenticateActions, dispatch)
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(PanelPhoneStatistics);