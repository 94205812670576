/**
 * Created by dpopov on 01.11.2017.
 */
/*eslint eqeqeq: ["off", "smart"]*/
import React from 'react';

import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Link } from 'react-router-dom';

import { getServiceDataFromTree } from './tools';
import { getStyle, getStdNotifyStruct, declension } from '../../tools';

import axios from 'axios';

import * as authenticateActions from '../../Authenticate/actions';
import * as panelServicesActions from './actions';
import * as CONST from '../../const';

//import elStyle from './style.css';

import Panel from '../../Components/Panel';
// import { PanelIsLoading } from '../../Components/Panel';
import Oops from '../../Components/Oops';

import 'moment/locale/ru';
import moment from 'moment';
moment.locale('ru');

const initialState = {
    loadingState: false,
    serviceId: null,
    activeContract: null,
    errorString: null,
    spentMoney: null,
    spentMinutes: null,
    phoneNumbersCount: null,
    parsedPhoneNumbers: {},
    stat: {}
};

class PanelPhoneService extends React.Component {
    constructor(props) {
        super(props);

        this.state = {...initialState,
            activeContract: props.authData.activeContract,
            serviceId: props.serviceId
        };

        this.requestPhoneData = this.requestPhoneData.bind(this);
        this.onCloseErrorClick = this.onCloseErrorClick.bind(this);
    }

    requestPhoneData(contract, srvId) {
        const self = this;

        this.setState({
            loadingState: true,
            activeContract: contract,
            serviceId: srvId
        });

        axios.get(CONST.API + '/phone/spent', {
            withCredentials: true,
            params: {
                contract: contract,
                srvid: srvId,
		'.rnd': Math.ceil(Math.random()*1000000)
            }
        }).then((answer) => {
            const data = answer.data;

            if(data.result === 'success') {
                let spentMoney = 0;
                let spentMinutes = 0;

                let parsedPhoneNumbers = {};
                let phoneNumbersCount = 0;

                for(let UserID in data.data) {
                    if(!data.data.hasOwnProperty(UserID)) continue;

                    spentMoney += parseFloat(data.data[UserID].cost);
                    spentMinutes += parseFloat(data.data[UserID].duration);

                    if(data.data[UserID].numbers.length) {
                        phoneNumbersCount += data.data[UserID].numbers.length;
                        parsedPhoneNumbers[UserID] = data.data[UserID].numbersParsed;
                    }
                }

                self.setState({
                    loadingState: false,
                    errorString: null,
                    stat: data.data,
                    spentMoney: spentMoney,
                    spentMinutes: spentMinutes,
                    phoneNumbersCount: phoneNumbersCount,
                    parsedPhoneNumbers: parsedPhoneNumbers
                });
            } else {
                self.setState({
                    loadingState: false,
                    errorString: data.text
                });
            }
        });
    }

    getServiceData() {
        return getServiceDataFromTree(this.props.servicesData.servicesTree, this.state.serviceId);
    }

    componentWillMount() {
        this.requestPhoneData(this.props.authData.activeContract, this.props.serviceId);
    }

    componentWillReceiveProps(nextProps) {
        if(nextProps.serviceId !== this.state.serviceId) this.requestPhoneData(nextProps.authData.activeContract, nextProps.serviceId);
        else if(nextProps.authData.activeContract !== this.state.activeContract) this.requestPhoneData(nextProps.authData.activeContract, nextProps.serviceId);
    }

    onCloseErrorClick(event) {
        event.preventDefault();
        this.setState({ errorString: null });
    }

    render() {
        const title = this.props.title || 'Телефония';
        const icon = this.props.icon || getStyle('icon-phone');
        const loadingState = this.state.loadingState;

        const showStatButton = true;

        const spentMoney = this.state.spentMoney !== null ? this.state.spentMoney : 'н/д';
        const spentMinutes = this.state.spentMinutes !== null ? this.state.spentMinutes : 'н/д';

        const currentService = this.getServiceData();
        if(!currentService || this.state.errorString) return (
            <Panel title={title} icon={icon} isLoading={loadingState}>
                {!loadingState ?
                    <Oops />
                 : null }
            </Panel>
        );

        let prevTarif = null;

        if(currentService.isStop) {
            let iterator = currentService;
            // Пройдёмся в будущее
            while(iterator.__next) {
                iterator = iterator.__next;
                if(!iterator) break;
                if(iterator.isStop) continue;

                prevTarif = iterator;
                break;
            }
            if(!prevTarif) {
                iterator = currentService;
                while (iterator.__prev) {
                    iterator = iterator.__prev;
                    if(!iterator) break;
                    if(iterator.isStop) continue;

                    prevTarif = iterator;
                    break;
                }
            }
        }

        let phoneNumbers = this.state.parsedPhoneNumbers;
        let phoneNumbersCount = this.state.phoneNumbersCount;

        const displayCost = currentService.isStop && prevTarif ? prevTarif.cost : currentService.cost;
        const displayCostIsAB = currentService.isStop && prevTarif ? prevTarif.isAb : currentService.isAb;

        return (
            <Panel title={title} icon={icon} notify={getStdNotifyStruct(this.state.errorString, this.onCloseErrorClick)} isLoading={loadingState}>
                {loadingState ?
                    <div className={getStyle('b-row')}>

                    </div>
                    :
                    <table className={getStyle('line v-mid left-pull')}>
                        <tbody>
                        {!prevTarif ?
                            <tr>
                                <td>Дата активации</td>
                                <td><strong>{moment(currentService.dateStart).format('DD.MM.YYYY')}</strong></td>
                                <td className={getStyle('t-right')}></td>
                            </tr>
                        :   [
                                <tr key={1}>
                                    <td>Тариф</td>
                                    <td>{currentService.name}</td>
                                    <td />
                                </tr>,
                                <tr key={2}>
                                    <td>{prevTarif.dateStart.isBefore(currentService.dateStart) ? "Тариф до приостановки" : "Тариф после приостановки"}</td>
                                    <td>{prevTarif.name}</td>
                                    <td></td>
                                </tr>
                            ]
                        }
                        <tr>
                            <td>{phoneNumbersCount == 1 ? 'Номер телефона' : 'Номера телефонов'}</td>
                            <td>
                                { Object.keys(phoneNumbers).map((userId, _idx) => {
                                    let string = (_idx ? ", ": "");
                                    return string + phoneNumbers[userId].join(", ");
                                })}
                            </td>
                            <td className={getStyle('t-right')}></td>
                        </tr>
                        <tr>
                            <td>Стоимость вызовов за этот месяц</td>
                            <td><strong>{/^-?[0-9.,]+$/.test(''+spentMoney) ? spentMoney.toFixed(2) : spentMoney}</strong> руб за <strong>{spentMinutes}</strong> {declension(spentMinutes, 'минуту', 'минуты', 'минут')}</td>
                            <td className={getStyle('t-right')}>
                                {showStatButton ? <Link to={CONST.ROOT_PATH + '/statistics-phone.'+this.state.serviceId+''} className={getStyle('button')}>Смотреть отчет</Link> : null}
                            </td>
                        </tr>
                        {currentService.isAb ?
                            [
                                <tr key="0">
                                    <td rowSpan={4}>Тариф</td>
                                    <td className={ getStyle('no-pull')}>{currentService.name}</td>
                                    <td className={ getStyle('t-right') }></td>
                                </tr>,
                                <tr key="1">
                                    <td className={ getStyle('no-pull') }>Стоимость абонемента <strong>{currentService.cost}</strong> руб/мес </td>
                                    <td className={ getStyle('t-right') }></td>
                                </tr>,
                                <tr key="2">
                                    <td className={ getStyle('no-pull')}>Действует до <strong>{moment(currentService.dateStop).format('DD.MM.YYYY')}</strong></td>
                                </tr>,
                                currentService.nextRule ?
                                    <tr key="3">
                                        <td className={getStyle('no-pull')} colSpan="2">
                                            <p>Тариф по окончанию <strong>{currentService.nextRule.name}</strong></p>
                                            {currentService.nextRule.isAb ?
                                                null
                                                :
                                                <Link to={CONST.ROOT_PATH+"/service-applynextab."+currentService.nextRule.id+".html"} className={getStyle('button')}>Подключить новый абонемент после этого</Link>
                                            }
                                        </td>
                                    </tr>
                                    : <tr key="3"><td></td></tr>
                            ]
                            :
                            [
                                <tr key="1">
                                    {!currentService.isStop ?
                                        <td rowSpan={currentService.abways?2:1}>Стоимость тарифа в месяц</td>
                                        :
                                        <td>
                                            {prevTarif.dateStart.isBefore(currentService.dateStart) ? "Стоимость тариф до приостановки" : "Стоимость тарифа после приостановки"}
                                        </td>
                                    }
                                    {displayCostIsAB ?
                                        <td>Абонемент за <strong>{!displayCost ? '0' : displayCost}</strong> рублей в месяц</td>
                                    :
                                        <td><strong>{!displayCost ? '0' : displayCost}</strong> рублей при оплате ежемесячно</td>
                                    }
                                    <td></td>
                                </tr>,
                                currentService.abways ?
                                    <tr key="2">
                                        <td className={getStyle('no-pull')}>
                                            <span
                                                className={getStyle('t-mute')}>от <strong>{currentService.minabcost}</strong> рублей при подключении абонемента</span>
                                        </td>
                                        <td className={getStyle('t-right')}>
                                            <Link to={CONST.ROOT_PATH+"/service-applyab."+this.state.serviceId+".html"} className={getStyle('button')}>Подключить абонемент</Link>
                                        </td>
                                    </tr>
                                    : null
                            ]
                        }
                        </tbody>
                    </table>
                }
            </Panel>
        );
    }
}
function mapStateToProps(state) {
    return {
        authData: state.authenticate,
        servicesData: state.panelServices
    }
}

function mapDispatchToProps(dispatch) {
    return {
        elementActions: bindActionCreators(panelServicesActions, dispatch),
        authenticate: bindActionCreators(authenticateActions, dispatch)
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(PanelPhoneService);