/**
 * Created by dpopov on 29.11.2017.
 */
/* eslint eqeqeq: ["off", "smart"] */
/* eslint jsx-a11y/anchor-is-valid: ["off", "smart"] */

import React from 'react';
import axios from 'axios';

import Panel from '../../../Components/Panel';

import { getStyle, getStdNotifyStruct } from '../../../tools';
import style from './style.module.css';
import oStyle from '../../../../assets/css/obit.module.css';
import fStyle from '../../../../assets/fonts/fonts.css';

import * as CONST from '../../../const';

import logo from '../../../../assets/images/logo_sub.png';

const TEXTS = {
    'rt': {
        'ru': 'Пожалуйста, оцените нашу работу по Вашему обращению',
        'en': 'Please rate our work on your request'
    },
    'ht': {
        'ru': 'Пожалуйста, оцените нашу работу по Вашей заявке',
        'en': 'Please rate our work on your ticket'
    },
    'rt-new': {
        'ru': 'по вашему обращению',
        'en': 'on your request'
    },
    'ht-new': {
        'ru': 'по вашей заявке',
        'en': 'on your ticket'
    },
    '2week-new': {
        'ru': '',
        'en': ''
    },
    'employee-courtly': {
        'ru': 'был вежлив и внимателен',
        'en': 'polite and attentive?',
    },
    'issue-resolved': {
        'ru': 'по которому вы обращались?',
        'en': 'solved?'
    },
    'issue-caption-yes': {
        'ru': 'Да, с первого раза',
        'en': 'Yes',
    },
    'issue-caption-yes-long': {
        'ru': 'Да, но после нескольких обращений',
        'en': 'Yes, but not for the first try',
    },
    'issue-caption-no': {
        'ru': 'Нет',
        'en': 'No'
    }
};

const CommentText = {
    'ru': 'При желании Вы можете оставить для нас комментарий здесь:',
    'en': 'Optionally, you can leave your comment here:'
};

const RateButtonTitle = {
    'ru': 'оценить нашу работу',
    'en': 'rate us'
};

class PageRate extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            rate: null,
            comment: '',
            loadingState: false,
            showThx: false,
            notify: null,
            employeeRate: null,
            issueResolved: null
        };

        this.onCommentInput = this.onCommentInput.bind(this);
        this.onRateClick = this.onRateClick.bind(this);
        this.onLikeClick = this.onLikeClick.bind(this);
        this.onDislikeClick = this.onDislikeClick.bind(this);
        this.onCloseNotify = this.onCloseNotify.bind(this);
    }

    onCommentInput(event) {
        this.setState({comment: event.target.value});
    }


    onLikeDislike(param, value) {
        return (event) => {
            event.preventDefault();
            this.setState({...this.state, [param]: value });
        }
    }


    onRateClick(event) {
        event.preventDefault();

        const self = this;
        const link = this.props.link;

        self.setState({loadingState: true, notify: null});
        axios.get(CONST.API + '/smalllinks/ratert', {
            withCredentials: true,
            params: {
                link: link,
                rate: this.state.rate,
                employeeRate: this.state.employeeRate,
                issueResolved: this.state.issueResolved,
                comment: this.state.comment,
                '.rnd': Math.ceil(Math.random()*1000000)
            }
        }).then((answer) => {
            const data = answer.data;

            if(data.result === 'success') {
                self.setState({showThx: true, loadingState: false});
            } else {
                self.setState({ loadingState: false, notify: data.text });
            }
        });
    }

    onLikeClick(event) {
        event.preventDefault();
        this.setState({rate: 'like'});
    }

    onDislikeClick(event) {
        event.preventDefault();
        this.setState({rate: 'dislike'});
    }

    onCloseNotify(event) {
        event.preventDefault();
        this.setState({ notify: null });
    }

    render() {
        const ticket = this.props.rt_ticket;
        const rateType = this.props.rateType;
        const contract = this.props.contract;

        const userLang = navigator.language || navigator.userLanguage;
        const lang = userLang.match(/ru/i) ? 'ru' : 'en';

        const notify = this.state.notify ? getStdNotifyStruct(this.state.notify, this.onCloseNotify) : null;

        //<a href="#" onClick={this.onLikeClick} className={style['image']}><img src={this.state.rate == 'like' ? imgLikeBlue : imgLikeGrey}/></a>
        //<a href="#" onClick={this.onDislikeClick} className={style['image']}><img src={this.state.rate == 'dislike' ? imgDislikeBlue : imgDislikeGrey}/></a>

        //<div className={[getStyle('t-center'), style['panelrate-line']].join(' ')}>{TEXTS[this.props.rateType].ru} №{ticket}</div>
        //<div className={[getStyle('t-center'), style['panelrate-line']].join(' ')}>{TEXTS[this.props.rateType].en} №{ticket}</div>
        console.log(this.props);
        return (
            <Panel stripTitle={true} isLoading={this.state.loadingState} notify={notify}>
                <div className={getStyle('row')}>
                    {this.state.showThx ?
                        <div>
                            {/* ----- Для домашников и подключенцев через ht выведем ссылку на VK ----- */}
                            {(contract && contract.match(/^88/)) || rateType == 'ht' ?
                                <div className={[getStyle('t-center'), style['panelrate-line']].join(' ')}>
                                    Также вы можете оставить свое пожелания или предложение
                                    &nbsp;<a href="https://vk.com/topic-53642882_38810401" className={[getStyle('a-color'), style['vk-link']].join(' ')}>в нашей официальной группе ВКонтакте</a>
                                </div>
                            : null }
                            <div className={[getStyle('t-center'), style['panelrate-line']].join(' ')}>
                                <a href="https://www.obit.ru" className={getStyle('button')}>Спасибо</a>
                            </div>
                        </div>
                        :
                        <div>
                            <div className={[getStyle('t-center'), style['panelrate-line']].join(' ')}><img src={logo} alt="OBIT"/></div>
                            <div className={[getStyle('t-center'), style['panelrate-line']].join(' ')}>
                                <div className={[getStyle('t-center'), lang == 'ru' ? style['please-ru'] : style['please-en']].join(' ')}></div>
                                <div className={[lang=='ru' ? style['ticket'] : style['ticket-en'] , fStyle['roboto']].join(' ')}>{TEXTS[this.props.rateType+'-new'][lang]}{ticket ? <b>№{ticket}</b>:null}</div>
                            </div>
                            <div className={[getStyle('t-center'), style['panelrate-line']].join(' ')}>
                                <a href="#" onClick={this.onLikeClick} className={style['image']}><div className={oStyle['bird'] + ' ' + (this.state.rate == 'like' ? oStyle['bird-like-active'] : oStyle['bird-like'])}></div></a>
                                <a href="#" onClick={this.onDislikeClick} className={style['image']}><div className={oStyle['bird'] + ' ' + (this.state.rate == 'dislike' ? oStyle['bird-dislike-active'] : oStyle['bird-dislike'])}></div></a>
                            </div>
                            {rateType === 'rt' ?
                                <div>
                                    {/* -------------- Сотрудник был вежлив --------------- */}
                                    <div className={[getStyle('t-center'), style['panelrate-line']].join(' ')}>
                                        <div className={[getStyle('t-center'), lang == 'ru' ? style['employee-courtly-ru'] : style['employee-courtly-en']].join(' ')}></div>
                                        <div className={[getStyle('t-center'), style['employee-courtly-text-container'], fStyle['roboto']].join(' ')}>
                                            <div className={style['employee-courtly-text']}>{TEXTS['employee-courtly'][lang]}</div>
                                        </div>
                                    </div>
                                    <div className={[getStyle('t-center'), style['panelrate-line']].join(' ')}>
                                        <a href="#" onClick={this.onLikeDislike('employeeRate', 'like')} className={style['image']}>
                                            <div className={[oStyle['bird'],
                                                                this.state.employeeRate == 'like' ? oStyle['bird-employee-like-active'] : oStyle['bird-employee-like-inactive']
                                                            ].join(' ')} />
                                        </a>
                                        <a href="#" onClick={this.onLikeDislike('employeeRate', 'dislike')} className={style['image']}>
                                            <div className={[oStyle['bird'],
                                                this.state.employeeRate == 'dislike' ? oStyle['bird-employee-dislike-active'] : oStyle['bird-employee-dislike-inactive']
                                            ].join(' ')} />
                                        </a>
                                    </div>
                                    {/* --------------- */}
                                    <div className={[getStyle('t-center'), style['panelrate-line']].join(' ')}>
                                        <div className={[getStyle('t-center'), style['issue-resolved-container'],
                                                            (lang == 'ru' ? style['issue-resolved-container-ru'] : style['issue-resolved-container-en'])
                                                        ].join(' ')}>
                                            <div className={[style['issue-resolved-text']].join(' ')}>
                                                {lang == 'ru' ? TEXTS["issue-resolved"].ru : TEXTS["issue-resolved"].en}
                                            </div>
                                        </div>
                                    </div>
                                    <div className={getStyle('clear')} />
                                    <div className={[getStyle('t-center clearfix'), style['panelrate-line']].join(' ')}>
                                        <div className={style['bird3-item-container']}>
                                            <a href="#" onClick={this.onLikeDislike('issueResolved', 'yes')} className={style['image3']}>
                                                <div className={[oStyle['bird3'],
                                                    this.state.issueResolved == 'yes' ? oStyle['bird-issue-yes-active'] : oStyle['bird-issue-yes-inactive']].join(' ')} />
                                                <div className={style['subtext']}>{lang == 'ru' ? TEXTS['issue-caption-yes'].ru : TEXTS['issue-caption-yes'].en }</div>
                                            </a>
                                        </div>
                                        <div className={style['bird3-item-container']}>
                                            <a href="#" onClick={this.onLikeDislike('issueResolved', 'yes-long')} className={style['image3']}>
                                                <div className={[oStyle['bird3'],
                                                    this.state.issueResolved == 'yes-long' ? oStyle['bird-issue-yes-long-active'] : oStyle['bird-issue-yes-long-inactive']].join(' ')} />
                                                <div className={style['subtext']}>{lang=='ru' ? TEXTS['issue-caption-yes-long'].ru : TEXTS['issue-caption-yes-long'].en}</div>
                                            </a>
                                        </div>
                                        <div className={style['bird3-item-container']}>
                                            <a href="#" onClick={this.onLikeDislike('issueResolved', 'no')} className={style['image3']}>
                                                <div className={[oStyle['bird3'],
                                                    this.state.issueResolved == 'no' ? oStyle['bird-issue-no-active'] : oStyle['bird-issue-no-inactive']].join(' ')} />
                                                <div className={style['subtext']}>{lang=='ru' ? TEXTS['issue-caption-no'].ru : TEXTS['issue-caption-no'].en}</div>
                                            </a>
                                        </div>
                                    </div>
                                </div>
                            :null}
                            <div className={[getStyle('t-center'), style['panelrate-line'], fStyle['roboto']].join(' ')}>
                                {CommentText[lang]}
                            </div>
                            <div className={[getStyle('t-center'), style['panelrate-line']].join(' ')}><textarea name="comment" value={this.state.comment} onChange={this.onCommentInput} onBlur={this.onCommentInput}/></div>
                            {
                                (this.state.rate !== null && this.state.employeeRate !== null && this.state.issueResolved !==null && rateType === 'rt') ||
                                (this.state.rate !== null && rateType === 'ht') || (this.state.rate !== null && rateType === '2week')
                                ?
                                <div className={[getStyle('t-center'), style['panelrate-line']].join(' ')}>
                                    <button onClick={this.onRateClick}>{RateButtonTitle[lang]}</button>
                                </div>
                                : null }
                        </div>
                    }
                </div>
            </Panel>
        );
    }
}

export default PageRate;