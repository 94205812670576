/**
 * Created by dpopov on 21.02.2018.
 */
import React from 'react';
import ChapterBilling from './index';

import PanelWriteOffofFunds from '../../Panels/PanelWriteOffofFunds';

export default (props) => {
    return (
        <ChapterBilling>
            <PanelWriteOffofFunds />
        </ChapterBilling>
    );
}