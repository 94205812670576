/**
 * Created by dpopov on 14.09.2017.
 */
import React from 'react';

import ChapterUser from './index';
import PanelPassword from '../../Panels/PanelPassword';

export default (props) => {
    return (
        <ChapterUser>
            <PanelPassword />
        </ChapterUser>
    );
}