/**
 * Created by dpopov on 11.10.2017.
 */
/*eslint eqeqeq: ["off", "smart"]*/

import React from 'react';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';

import axios from 'axios';

import * as CONST from '../../const';
import * as PanelActions from './actions';

import TimePicker from 'rc-time-picker';
import 'rc-time-picker/assets/index.css';

import Panel from '../../Components/Panel';
import PopUp from '../../Components/Panel/PopUp';
import FileUpload from '../../Components/FileUpload';

import {getStyle} from '../../tools';

import myStyle from './style.module.css';
import notifyStyle from '../../Components/Panel/innerNotify.module.css';

import 'moment/locale/ru'
import moment from 'moment';

moment.locale('ru');

const appeals = [
    {type: 'tech', title: 'Технические вопросы'},
    {type: 'finance', title: 'Финансовые вопросы'},
    {type: 'other', title: 'Другое'}
];

const initialState = {
    subject: '',
    address: '',
    message: '',
    uploaded: {},
    hasError: {},
    errorString: '',
    formSend: false,
    popupMessage: '',
    rangeFrom: moment('10:00', 'HH:mm'),
    rangeTo: moment('21:00', 'HH:mm')
};

class PanelSupport extends React.Component {

    constructor(props) {
        super(props);

        this.state = initialState;

        this.onChangeValue = this.onChangeValue.bind(this);
        this.onFileUpload = this.onFileUpload.bind(this);
        this.onUploadError = this.onUploadError.bind(this);
        this.onDeleteFileClick = this.onDeleteFileClick.bind(this);
        this.onSendClick = this.onSendClick.bind(this);
        this.onClearErrorClick = this.onClearErrorClick.bind(this);
        this.onPopUpCloseClick = this.onPopUpCloseClick.bind(this);
        this.onPeriodFromChoose = this.onPeriodFromChoose.bind(this);
        this.onPeriodToChoose = this.onPeriodToChoose.bind(this);
        this.periodDisabledHours = this.periodDisabledHours.bind(this);
        this.periodDisabledMinutes = this.periodDisabledMinutes.bind(this);
    }

    componentWillMount() {
        const contract = this.props.authData.activeContract;
        if (!this.props.state.addresses[contract]) this.props.elementActions.getAddresses(contract);
    }

    componentWillReceiveProps(nextProps) {
        const contract = nextProps.authData.activeContract;
        if (!nextProps.state.addresses[contract] && nextProps.state.addressesLoading == false && nextProps.state.error == null) this.props.elementActions.getAddresses(contract);
    }

    onChangeValue(event) {
        const value = event.target.value;
        const element = event.target.getAttribute('name');

        let updateState = {};
        updateState[element] = value;

        this.setState(updateState);
    }

    onFileUpload(data) {
        if (data.result === 'success') {
            let uploaded = this.state.uploaded;
            uploaded[data.data.guid] = data.data.filename;

            this.setState({uploaded: uploaded});
        }
    }

    onDeleteFileClick(event) {
        event.preventDefault();

        const guid = event.target.getAttribute('data-guid');
        let uploaded = this.state.uploaded;
        delete uploaded[guid];
        this.setState({uploaded: uploaded});
    }

    onClearErrorClick(event) {
        event.preventDefault();

        this.setState({errorString: ''});
    }

    /**
     * Очистка формы после отправки
     * todo ref #23670
     */
    clearForm(data) {
        this.setState({
            formSend: false,
            popupMessage: data.data.message,
            subject: '',
            message: '',
            uploaded: {},
            hasError: {},
            errorString: ''
        });
        /**
         * Очищаем выпадающие списки
         */
        Array.from(document.querySelectorAll("select")).forEach(elem => elem.firstChild.setAttribute('selected', 'selected'));
        /**
         * Очищаем текстовые поля
         * Кроме скрытых
         */
        Array.from(document.querySelectorAll('input,textarea')).forEach(elem => elem.value = (elem.type !== 'hidden') ? '' : elem.value);
    }

    onSendClick(event) {
        event.preventDefault();

        let hasError = {};

        if (!this.state.subject) hasError['subject'] = 1;
        if (!this.state.message) hasError['message'] = 1;

        if (Object.keys(hasError).length) {
            this.setState({hasError: hasError, errorString: 'Нужно заполнить все поля'});

            return;
        }

        const self = this;
        const contract = this.props.authData.activeContract;
        const addresses = this.props.state.addresses[contract] ? this.props.state.addresses[contract] : {};

        let params = new FormData();

        params.append("subject", this.state.subject);
        params.append("message", this.state.message);
        params.append("address", this.state.address ? this.state.address : Object.keys(addresses)[0]);
        params.append("files", Object.keys(this.state.uploaded).join(','));
        params.append("contract", contract);
        params.append('periodFrom', this.state.rangeFrom.format('HH:mm'));
        params.append('periodTo', this.state.rangeTo.format('HH:mm'));

        this.setState({hasError: hasError, formSend: true});

        axios.post(CONST.API + '/support/ask', params, {
            withCredentials: true
        }).then((answer) => {
            const data = answer.data;
            if (data.result == 'success') {
                if (data.data['to'] == 'rt' || data.data['to'] == 'crm') {
                    self.props.elementActions.getTicketsList(contract);
                }
                self.clearForm(data);
            } else {
                self.setState({errorString: data.text, formSend: false});
            }
        });

        //  console.log(params);
    }

    onUploadError() {
        this.setState({errorString: 'При загрузке файла произошла ошибка'});
    }

    onPopUpCloseClick(event) {
        event.preventDefault();
        this.setState({popupMessage: ''});
    }

    onPeriodFromChoose(value) {
        this.setState({rangeFrom: value});
    }

    onPeriodToChoose(value) {
        this.setState({rangeTo: value});
    }

    periodDisabledHours(type) {
        const self = this;

        if (type === 'from') {
            return () => {
                const maxTime = self.state.rangeTo;

                let answer = [];

                for (let i = maxTime.hour(); i < 24; i++) {
                    answer.push(i);
                }

                return answer;
            }
        } else if (type === 'to') {
            return () => {
                const minTime = self.state.rangeFrom;

                let answer = [];
                for (let i = 0; i < minTime.hour() + 1; i++) {
                    answer.push(i);
                }

                return answer;
            }
        }
    }

    periodDisabledMinutes(type) {

    }

    render() {
        const contract = this.props.authData.activeContract;
        const title = this.props.title || 'Техподдержка: Задайте вопрос';
        const loadingState = this.props.state.addressesLoading || this.state.formSend;

        const addresses = this.props.state.addresses[contract] ? this.props.state.addresses[contract] : {};

        const params = {
            contract: contract
        };

        let notify = {};
        if (this.state.errorString) {
            notify = {
                text: this.state.errorString,
                style: [getStyle('top-notify'), notifyStyle['center']].join(' '),
                textStyle: [getStyle('notify-message'), notifyStyle['error'], notifyStyle['inline']].join(' '),
                closeClick: this.onClearErrorClick,
                closeStyle: [getStyle("button btn-close inv"), notifyStyle['btn-close']].join(' ')
            }
        }

        return (
            <Panel title={title} rowClassName={getStyle('block f-auto')} noRow={true} icon={getStyle('icon-call')}
                   isLoading={loadingState} notify={notify}>
                {this.state.popupMessage ?
                    <PopUp title="Обращение успешно отправлено" icon={getStyle('icon-call')}>
                        <div className={getStyle('t-centr')}>{this.state.popupMessage}</div>
                        <div className={[getStyle('t-center'), myStyle['support_popup_close_container']].join(' ')}>
                            <button type="button" onClick={this.onPopUpCloseClick}>Закрыть</button>
                        </div>
                    </PopUp>
                    : null
                }

                <table className={getStyle('v-top left-pull form-table b-border')}>
                    <tbody>
                    <tr>
                        <td>Тема обращения</td>
                        <td>
                            <select name="subject"
                                    className={getStyle('wide') + (this.state.hasError['subject'] ? ' ' + myStyle['support_error_border'] : "")}
                                    onChange={this.onChangeValue}>
                                <option value="">&nbsp;</option>
                                {appeals.map((item, _idx) => {
                                    return <option value={item.type} key={_idx}>{item.title}</option>
                                })}
                            </select>
                        </td>
                        <td/>
                    </tr>
                    <tr>
                        <td>Адрес обслуживания</td>
                        <td>
                            <select name="address" className={getStyle('wide')} onChange={this.onChangeValue}>
                                {Object.keys(addresses).map((address, _idx) => {
                                    return <option value={address} key={_idx}>{addresses[address]}</option>
                                })}
                            </select>
                        </td>
                        <td/>
                    </tr>
                    <tr>
                        <td>Желаемое время звонка</td>
                        <td>
                            c&nbsp;<TimePicker showSecond={false} value={this.state.rangeFrom}
                                               hideDisabledOptions={true} onChange={this.onPeriodFromChoose}
                                               disabledHours={this.periodDisabledHours('from')}/>&nbsp;
                            по&nbsp;<TimePicker showSecond={false} value={this.state.rangeTo} hideDisabledOptions={true}
                                                onChange={this.onPeriodToChoose}
                                                disabledHours={this.periodDisabledHours('to')}/>&nbsp;
                        </td>
                        <td/>
                    </tr>
                    <tr>
                        <td>Задайте ваш вопрос</td>
                        <td>
                            <textarea name="message" rows="5"
                                      className={getStyle('wide') + (this.state.hasError['message'] ? ' ' + myStyle['support_error_border'] : "")}
                                      onChange={this.onChangeValue}></textarea>
                        </td>
                        <td/>
                    </tr>
                    </tbody>
                </table>

                <div className={getStyle('b-row b-border')}>
                    <div className={getStyle('file-box')}>
                        {Object.keys(this.state.uploaded).map((fileGuid, _idx) => {
                            return <div className={getStyle('file-item')} key={_idx}>
                                {this.state.uploaded[fileGuid]} &nbsp;
                                <button className={getStyle('btn-close')} data-guid={fileGuid}
                                        onClick={this.onDeleteFileClick}>&times;</button>
                            </div>;
                        })}
                    </div>

                    <FileUpload onUpload={this.onFileUpload} onError={this.onUploadError} onUploadAbort={null}
                                api="/support/upload" buttonTitle="Приложить файл" className="" params={params}/>
                </div>

                <div className={getStyle('b-row')}>
                    <button type="button" className={getStyle("btn-major btn-big")}
                            onClick={this.onSendClick}>Отправить
                    </button>
                </div>
            </Panel>
        );
    }
}

function mapStateToProps(state) {
    return {
        authData: state.authenticate,
        state: state.support
    }
}

function mapDispatchToProps(dispatch) {
    return {
        elementActions: bindActionCreators(PanelActions, dispatch)
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(PanelSupport);