/**
 * Created by dpopov on 10.10.2017.
 */
import React from 'react';
import { connect } from 'react-redux';

import * as CONST from '../../const';

import SubNavBlock from '../../Components/SubNavBlock';

import { Route } from 'react-router-dom';
import CheckAccess from '../../Authenticate';

import PageQNA from './pageQNA';
import PageSupport from './pageSupport';
import PageBonusSpeed from './pageBonusSpeed';
import PageTariff from './pageTariff';

export const HelpRoutes = [
    <Route key="HelpRoutes1" exact path={CONST.ROOT_PATH + "/qna.html"} component={PageQNA} />,
    <Route key="HelpRoutes1.1" exact path={CONST.ROOT_PATH + "/qna/:chapter"} component={PageQNA} />,
    <Route key="HelpRoutes2" path={CONST.ROOT_PATH + "/support.html"} component={PageSupport} />,
    <Route key="HelpRoutes3" path={CONST.ROOT_PATH + "/support-:id.html"} component={PageSupport} />,
    // <Route key="HelpRoutes7" path={CONST.ROOT_PATH + "/support-:id(\\w+).html"} component={PageSupport} />,

    <Route key="HelpRoutes4" path={CONST.ROOT_PATH + "/bonus-speed.html"} component={CheckAccess(PageBonusSpeed, CONST.ACCESS.READ, true)} />,
    <Route key="HelpRoutes5" path={CONST.ROOT_PATH + "/bonus-speed.:id.html"} component={CheckAccess(PageBonusSpeed, CONST.ACCESS.READ, true)} />,
    <Route key="HelpRoutes5" path={CONST.ROOT_PATH + "/tariff.:id.html"} component={CheckAccess(PageTariff, CONST.ACCESS.READ, true)} />,

];

class ChapterHelp extends React.Component {

    render() {
        let menuItems = {};
        menuItems[CONST.ROOT_PATH + '/qna.html'] = 'Вопросы и ответы';
        menuItems[CONST.ROOT_PATH + '/support.html'] = 'Техническая поддержка';

        return (
            <div>
                <SubNavBlock title="Помощь" items={menuItems} />
                {this.props.children}
            </div>
        );
    }
}

function mapStateToProps(state) {
    return {};
}

function mapDispatchToProps(dispatch) {
    return {};
}

export default connect(mapStateToProps, mapDispatchToProps)(ChapterHelp);