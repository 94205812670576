/**
 * Created by dpopov on 11.09.2017.
 */
/*eslint eqeqeq: ["off", "smart"]*/
/*eslint array-callback-return: ["off"]*/
import React from 'react';

import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { getStyle } from '../../tools';

import * as authenticateActions from '../../Authenticate/actions';
import * as panelServicesActions from './actions';

import Panel from '../../Components/Panel';
import Oops from '../../Components/Oops';
import TariffDescriptionDisplay from './Components/tariffDescDisplay';

import 'moment/locale/ru';
import moment from 'moment';

moment.locale('ru');

//import { PanelIsLoading } from '../../Components/Panel';

class PanelTVExtras extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            serviceId: props.serviceId,
            activeContract: props.authData.activeContract
        };

        this.btnApplyClick = this.btnApplyClick.bind(this);
    }

    componentWillMount() {
        this.refreshExtras();
    }

    refreshExtras(force) {
        const ExtrasForHCR = this.props.servicesData.ExtrasForHCR;
        const Extras = this.props.servicesData.Extras;
        const ExtrasLoadingState = this.props.servicesData.loadingExtrasState;

        if((!Extras || ExtrasForHCR != this.state.serviceId) && !ExtrasLoadingState) this.props.elementActions.requestExtras(this.props.authData.activeContract, this.state.serviceId);
        else if(force) this.props.elementActions.requestExtras(this.props.authData.activeContract, this.state.serviceId);
    }

    btnApplyClick(event) {
        event.preventDefault();

        let currentExtras = this.props.servicesData.Extras.active;
        let newExtras = [];

        const applied = event.target.getAttribute('data-applied');
        const extraId = event.target.getAttribute('data-extra');

        if(applied == '1') {
            // отключаем
//            console.log(1);
            Object.keys(currentExtras).forEach(function(extraRuleId) {
                if(currentExtras[extraRuleId] != extraId) newExtras.push(currentExtras[extraRuleId]);
            });
        } else {
//            console.log(2);
            // подключаем
            Object.keys(currentExtras).forEach(function(extraRuleId) {
                newExtras.push(currentExtras[extraRuleId]);
            });
            if(newExtras.indexOf(extraId) == -1) newExtras.push(extraId);       // небольшая "защита" от дурака
        }

//        console.log(newExtras);

        this.props.elementActions.applyExtras(this.props.authData.activeContract, this.state.serviceId, newExtras.join(','));
    }


    render() {
        const loadingState = this.props.servicesData.loadingExtrasState;
        const title = this.props.title || "Дополнительные пакеты";
        const icon = this.props.icon || getStyle('icon-add');

        if(this.props.authData.activeContract != this.state.activeContract) return (
            <Panel title={title} icon={icon} isLoading={loadingState}>
                {!loadingState ? <Oops /> : null }
            </Panel>
        );


        const Extras = this.props.servicesData.Extras;

        let Possibles = [];
        if(Extras.possible) Extras.possible.map((item) => {
            if(!item.applied) Possibles.push(item);
        });

        if(Possibles.length) Possibles.sort((a, b) => {
            // item.options.DateTo

            const aDate = typeof a.options === 'object' ? a.options.DateTo : null;
            const bDate = typeof b.options === 'object' ? b.options.DateTo : null;

            if(aDate || bDate) {
                if(!aDate && bDate) return 1;
                if(!bDate && aDate) return -1;

                if(aDate !== bDate) return moment(aDate).isBefore(bDate) ? 1 : -1;
            }

            return a.name < b.name ? -1 : 1;
        });


        return (
            <Panel title={title} icon={icon} isLoading={loadingState}>
                { !loadingState ?
                    Possibles && Possibles.length ?
                        <div>
                            <table className={getStyle('line v-mid left-pull')}>
                                <tbody>
                                {Possibles.map((item) => (
                                    <tr key={item.id}>
                                        <td>{item.name}</td>
                                        <td>
                                            {item.amount ? <span><strong>{item.amount}</strong> руб/мес</span> : <strong>бесплатно</strong>}
                                            {typeof item.options === 'object' && item.options.Description && <TariffDescriptionDisplay anons={item.options.Description} hasExternalDesc={item.options.DescriptionLink} />}
                                        </td>
                                        <td>{typeof item.options === 'object' && item.options.DateTo && <span><strong>до {moment(item.options.DateTo).format('DD.MM.YYYY')}</strong></span>}</td>
                                        <td className={getStyle('t-right')}>
                                            {item.applied ?
                                                <button type="button" onClick={this.btnApplyClick} data-applied={item.applied?1:0} data-extra={item.id}>Отключить</button>
                                                :
                                                <button type="button" onClick={this.btnApplyClick} data-applied={item.applied?1:0} data-extra={item.id}>Подключить</button>
                                            }
                                        </td>
                                    </tr>
                                ))}
                                </tbody>
                            </table>
                        </div>
                        : <div><h3>нет доступных дополнительных пакетов</h3></div>
                : null }
            </Panel>
        );
    }
}

function mapStateToProps(state) {
    return {
        authData: state.authenticate,
        servicesData: state.panelServices
    }
}

function mapDispatchToProps(dispatch) {
    return {
        elementActions: bindActionCreators(panelServicesActions, dispatch),
        authenticate: bindActionCreators(authenticateActions, dispatch)
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(PanelTVExtras);