import React from 'react';
import configureStore, { configureHistory } from './code/.redux/controlStore.HotelsSection';

import { Route, Switch } from 'react-router-dom';
import { Provider } from 'react-redux';
import { ConnectedRouter } from 'connected-react-router';

import CheckAccess from './code/Authenticate';

import * as CONST from './code/const';

import Interface from './code/Pages/PageHotel/Components/Interface';

import PageHotelAuthWifi from './code/Pages/PageHotel'
import Page404 from './code/Pages/Page404';

let store = configureStore();
let history = configureHistory(store);

export default () => (
    <Provider store={store}>
        <ConnectedRouter history={history}>
            <Interface>
                <Switch>
                    <Route exact path="/" component={CheckAccess(PageHotelAuthWifi, CONST.ACCESS.READ, true)} />
                    <Route component={Page404} />
                </Switch>
            </Interface>
        </ConnectedRouter>
    </Provider>
)