import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import TariffPage from '../../Components/Panel/TariffPage';
import * as panelServicesActions from "../../Panels/PanelServices/actions";
import * as authenticateActions from "../../Authenticate/actions";

class PageTariff extends React.Component {
    // getAppServiceID() {
    //     if(!this.props.servicesData) return null;
    //     if(typeof this.props.servicesData.servicesTree === 'undefined') return null;
    //     if(typeof this.props.servicesData.servicesTree.forEach !== 'function') return null;
    //
    //     if(this.props.match.params.id) return this.props.match.params.id;
    //
    //     const today = moment();
    //
    //     if(this.props.servicesData && this.props.servicesData.servicesTree) {
    //         const servicesTree = this.props.servicesData.servicesTree;
    //
    //         let activeService;
    //
    //         servicesTree.forEach((App) => {
    //             if(activeService) return true;
    //             if(!App.services) return false;
    //
    //             Object.keys(App.services).forEach((srvId) => {
    //                 if(activeService) return true;
    //                 const serviceRoot = App.services[srvId];
    //                 let nextService = serviceRoot;
    //                 do {
    //                     if(nextService.Type & 1 && today.isAfter(nextService.dateStart) && today.isBefore(nextService.dateStop)) {
    //                         activeService = nextService;
    //                     }
    //                 } while (nextService = nextService.__next && !activeService);
    //             });
    //
    //             return true;
    //         });
    //         if(activeService) return activeService.id;
    //     }
    //
    //     return null;
    // }

    render() {
        // const queryParams = {tariffId: this.getAppServiceID()};
        const queryParams = {
            tariffId: this.props.match.params.id
        };

        return (

            <div>
                {/*{AppServiceID ? <FrameInternetSpeedBonus appServiceId={AppServiceID} stripTitle={true}/> : null}*/}
                <TariffPage queryParams={queryParams} />
            </div>
        );
    }
}

function mapStateToProps(state) {
    return {
        authData: state.authenticate,
        servicesData: state.panelServices
    }
}

function mapDispatchToProps(dispatch) {
    return {
        elementActions: bindActionCreators(panelServicesActions, dispatch),
        authenticate: bindActionCreators(authenticateActions, dispatch)
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(PageTariff);