/**
 * Created by dpopov on 08.09.2017.
 */
/*eslint eqeqeq: ["off", "smart"]*/

import React from 'react';

import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { withRouter } from 'react-router-dom';

import { getServiceData, requestServiceWays } from './tools';
import { getStyle } from '../../tools';

import axios from 'axios';

import * as authenticateActions from '../../Authenticate/actions';
import * as panelServicesActions from './actions';
import * as CONST from '../../const';

import elStyle from './style.module.css';
import notifyStyle from '../../Components/Panel/innerNotify.module.css';

import Panel from '../../Components/Panel';
import PanelHiSpeedCheck from '../PanelHiSpeedCheck';

import 'moment/locale/ru';
import moment from 'moment';
moment.locale('ru');

class PanelChangeTariff extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            serviceId: props.serviceId,
            loadingState: false,
            error: null,
            tariffs: [],
            selectedTariff: 0,
            selectedTariffIndex: 0,
            errorString: null,
            needHiSpeedTicket: false
        };

        this.requestServiceWays = this.requestServiceWays.bind(this);
        this.onSelectTariff = this.onSelectTariff.bind(this);
        this.onSwitchTariffClick = this.onSwitchTariffClick.bind(this);
        this.onClearErrorClick = this.onClearErrorClick.bind(this);
        this.onCheckHiSpeedCancel = this.onCheckHiSpeedCancel.bind(this);
        this.checkNeedHighSpeedTicket = this.checkNeedHighSpeedTicket.bind(this);

        this.CheckHiSpeed = withRouter(({history}) => (
            <PanelHiSpeedCheck AppServiceID={null} hcr={this.props.serviceId} autoClose={true} changeTariff={true} onSuccess={() => {
                this.makeTariffSwitch(history);
                //history.push(CONST.ROOT_PATH+'/services.html');
            }} onCancel={this.onCheckHiSpeedCancel} />
        ));

        this.BalanceLink = withRouter(({history}) => (
            <button type={'button'} className={'button'} onClick={event => {
                event.preventDefault();

                const contract = this.props.authData.activeContract;
                const tariffData = this.state.tariffs[this.state.selectedTariffIndex];
                const needMoney = tariffData.switch.surcharge;

                this.props.serviceActions.setRecommentPayment(needMoney, contract);

                if(this.checkNeedHighSpeedTicket()) return;

                history.push(CONST.ROOT_PATH + "/billing.html");
            }}>Пополнить баланс</button>
        ));
    }

    componentDidMount() {
        this.requestServiceWays();
    }

    // Получение списка возможных тарифов на которые можно перейти
    requestServiceWays() {
        const self = this;
        const contract = this.props.authData.activeContract;

        self.setState({ loadingState: true, error: null });

        requestServiceWays(contract, self.state.serviceId, 0,
            function(data) {
                let defaultChooseIndex = 0;
                let defaultChoose = 0;
                if(data.data.length) defaultChoose = data.data[defaultChooseIndex].id;
                self.setState({ loadingState: false, tariffs: data.data, selectedTariff: defaultChoose, selectedTariffIndex: defaultChooseIndex });
            },
            function(data) {
                self.setState({ loadingState: false, error: data.text });
            }
        );
    }

    onSelectTariff(event) {
        const selectedTariff = event.target.getAttribute('data-id');
        const selectedTariffIndex = event.target.getAttribute('data-index');

        this.setState({selectedTariff: selectedTariff, selectedTariffIndex: selectedTariffIndex });
    }

    checkNeedHighSpeedTicket() {
        const tariffData = this.state.tariffs[this.state.selectedTariffIndex];
        if(tariffData !== undefined && tariffData.speed > 100 && (!tariffData.hasHiSpeedMarker || !tariffData.hasHiSpeedRules)) {
            // Скоростные тарифы. Но у абонента нет правил-маркеров и правил со скоростями > 100 Мбит/с
            this.setState({ needHiSpeedTicket: true });
            return true;
        }

        return false;
    }

    onSwitchTariffClick(event, history) {
        if(this.checkNeedHighSpeedTicket()) return;
        this.makeTariffSwitch(history);
    }

    makeTariffSwitch(history) {
        const self = this;
        const contract = this.props.authData.activeContract;

        self.setState({ loadingState: true });

        axios.get(CONST.API + '/services/switchTariff', {
            withCredentials: true,
            params: {
                contract: contract,
                hcr: self.state.serviceId,
                ab: 0,
                tariff: self.state.selectedTariff,
                '.rnd': Math.ceil(Math.random()*1000000)
            }
        }).then(function(answer) {
            const data = answer.data;
            if(data.result == 'success') {
                self.props.serviceActions.requestServicesList(contract, 'changeTariff-makeTariffSwitch');
                history.push(CONST.ROOT_PATH+'/services.html');
            } else {
                self.setState({
                    loadingState: false,
                    errorString: data.text
                });
            }
        });
    }

    onClearErrorClick(event) {
        event.preventDefault();
        this.setState({errorString: null});
    }

    onCheckHiSpeedCancel(event) {
        if(event) event.preventDefault();
        this.setState({needHiSpeedTicket: false});
    }

    render() {
        const self = this;
        const ActiveTariff = getServiceData(this.props.servicesData.services, this.state.serviceId);
        const ActiveContract = this.props.authData.activeContract;
        const Balance = this.props.authData.contracts[ActiveContract];

        const selectedTariffData = this.state.tariffs[this.state.selectedTariffIndex];

        // console.log(this.props.authData.contracts);
        // console.log(ActiveTariff);
        // console.log(this.state, selectedTariffData);

        const surcharge = selectedTariffData && selectedTariffData.switch ? selectedTariffData.switch.surcharge : null;
        const afterBalance = selectedTariffData && selectedTariffData.switch ? selectedTariffData.switch.afterBalance : 0;

        const SwitchTariffButton = withRouter(({history}) => (
            <button type="button" className={getStyle('btn-big btn-major')}
                    onClick={(event) => self.onSwitchTariffClick(event, history)}>Сменить тариф</button>
        ));

        const BalanceLink = this.BalanceLink;
        const CheckHiSpeed = this.CheckHiSpeed;

        let notify = {};
        if (this.state.errorString) {
            notify = {
                text: this.state.errorString,
                style: [getStyle('top-notify'), notifyStyle['center']].join(' '),
                textStyle: [getStyle('notify-message'), notifyStyle['error'], notifyStyle['inline']].join(' '),
                closeClick: this.onClearErrorClick,
                closeStyle: [getStyle("button btn-close inv"), notifyStyle['btn-close']].join(' ')
            }
        }


        return (
                <Panel title={ActiveTariff.isAb ? 'Увеличение скорости в рамках абонемента' : 'Сменить тариф'}
                       icon={getStyle('icon-wifi')} isLoading={this.state.loadingState} notify={notify}>
                    {this.state.needHiSpeedTicket ?
                        <CheckHiSpeed />
                    : null }
                    {ActiveTariff ? [
                        <div className={getStyle('b-row gray')} key="1">
                            Ваш текущий
                            тариф: <strong>{ActiveTariff.name}</strong> за <strong>{ActiveTariff.cost}</strong>
                            руб/мес
                        </div>,
                        <table className={getStyle('line v-mid left-pull')} key="2">
                            <tbody>
                            {this.state.tariffs.map((item, _i) => (
                                <tr className={getStyle('interactive')} key={_i}>
                                    <td className={getStyle('td-null t-nowarp')}>
                                        <label className={getStyle('input-radio')}>
                                            <input type="radio" name="tarif"
                                                   checked={this.state.selectedTariff == item.id}
                                                   data-id={item.id} data-index={_i} onChange={this.onSelectTariff}/>
                                            <div className={getStyle('input')}></div>
                                        </label>
                                    </td>
                                    <td className={item.switch && item.switch.afterBalance < 0 ? getStyle('t-mute') : null}>{item.tariff}</td>
                                    <td>{item.speed ? <span><strong>{item.speed}</strong> Мбит/с</span> : null}</td>
                                    <td><strong>{item.cost}</strong> руб/мес</td>
                                </tr>
                            ))}
                            </tbody>
                            {ActiveTariff.isAb && surcharge ?
                                <tfoot>
                                <tr>
                                    <td><i className={getStyle('m-icon icon-rub border')}/></td>
                                    <td colSpan="3">
                                        <p>Текущий баланс: <strong>{Balance}</strong> руб.</p>
                                        <p>Доплата за увеличение скорости: <strong>{surcharge}</strong> руб.</p>
                                        <p>Баланс после смены абонемента: <strong>{afterBalance}</strong> руб.</p>
                                    </td>
                                </tr>
                                {afterBalance < 0 ?
                                    <tr>
                                        <td colSpan="4">
                                            <table className={getStyle('left-pull v-mid')}>
                                                <tbody>
                                                <tr className={getStyle('gray')}>
                                                    <td>К сожалению, у вас недостаточно средств на балансе (не
                                                        хватает <strong>{-afterBalance}</strong> руб)
                                                    </td>
                                                    <td className={getStyle('t-right')}>
                                                        <BalanceLink />
                                                    </td>
                                                </tr>
                                                </tbody>
                                            </table>
                                        </td>
                                    </tr>
                                    : null}
                                </tfoot>
                                : null}
                        </table>,
                        afterBalance >= 0 ?
                            <div className={getStyle('b-row')} key="3">
                                <div className={getStyle('f-left')}>
                                    <SwitchTariffButton/>
                                </div>
                                <div
                                    className={getStyle('f-auto t-small t-mute t-em') + ' ' + elStyle['tariff-info-text']}>Тариф
                                    обновится в течение 15 минут. С вашего баланса автоматически спишется разница с
                                    вашим предыдущим тарифом
                                </div>
                            </div>
                            : null
                    ] : [
                        <div key="1"><h3>Услуга не найдена</h3></div>
                    ]
                    }
                </Panel>
        );
    }
}

function mapStateToProps(state) {
    return {
        authData: state.authenticate,
        servicesData: state.panelServices
    }
}

function mapDispatchToProps(dispatch) {
    return {
        serviceActions: bindActionCreators(panelServicesActions, dispatch),
        authenticate: bindActionCreators(authenticateActions, dispatch)
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(PanelChangeTariff);
