/**
 * Created by dpopov on 09.10.2017.
 */
import React from 'react';
import axios from 'axios';

import PopUp from '../Panel/PopUp';
import InnerNotify from '../Panel/innerNotify';
import notifyStyle from '../Panel/innerNotify.module.css';

import { getStyle } from  '../../tools';

import * as CONST from '../../const';

import myStyle from './style.module.css';

class ConfirmationPhone extends React.Component {
    constructor(props){
        super(props);

        this.state = {
            code: '',
            codeEmail: '',
            error: null,
            inRequest: false
        };

        this.onCheckClick = this.onCheckClick.bind(this);
        this.submitEmailCode = this.submitEmailCode.bind(this);
        this.onInputValueChange = this.onInputValueChange.bind(this);
        this.onHideNotify = this.onHideNotify.bind(this);
        this.onCancel = this.onCancel.bind(this);
        this.onError = this.onError.bind(this);
        this.onSuccess = this.onSuccess.bind(this);
    }

    componentWillMount() {
        if(!this.props.noSendSMS && this.props.phoneNum && this.props.oldPhoneNum) this.sendCodeBySMS();
        if(!this.props.noSendEmail && this.props.newEmail && this.props.oldEmail) this.sendCodeByEmail();
    }

    sendCodeBySMS() {
        const self = this;
        const phone = this.props.phoneNum;
        const contract = this.props.contract;

        axios.get(CONST.API + '/homeuser/confirmPhone', {
            withCredentials: true,
            params: {
                DogovorNum: contract,
                phone: phone,
                '.rnd': Math.ceil(Math.random()*1000000)
            }
        }).then(function(answer) {
            const data = answer.data;
            if(data.result !== 'success') {
                self.setState({error: data.text});
            }
        });
    }

    sendCodeByEmail() {
        const self = this;
        const email = this.props.newEmail;
        const contract = this.props.contract;

        axios.get(CONST.API + '/homeuser/confirmEmail', {
            withCredentials: true,
            params: {
                DogovorNum: contract,
                email: email,
                '.rnd': Math.ceil(Math.random()*1000000)
            }
        }).then(function(answer) {
            const data = answer.data;
            if(data.result !== 'success') {
                self.setState({error: data.text});
            }
        });
    }

    checkSMSCode(code) {
        const contract = this.props.contract;

        if(typeof this.props.confirmHandler == 'function') this.props.confirmHandler(contract, this.props.oldPhoneNum, this.props.phoneNum, code, this.onError, this.onSuccess);
    }

    onError(errorString) {
        if(errorString) this.setState({ error: errorString, inRequest: false });
    }

    onInputValueChange(event) {
        let value = event.target.value;
        const name = event.target.getAttribute('name');

        let newState = {};
        newState[name] = value;

        this.setState(newState);
    }

    onCheckClick(event) {
        event.preventDefault();

        this.setState({ inRequest: true });
        this.checkSMSCode(this.state.code);
    }

    onSuccess() {
        this.setState({ inRequest: false });
    }

    submitEmailCode(event) {
        event.preventDefault();

        this.setState({ inRequest: true });

        const contract = this.props.contract;
        if(typeof this.props.confirmHandlerEmail == 'function') this.props.confirmHandlerEmail(contract, this.props.oldEmail, this.props.newEmail, this.state.codeEmail, this.onError, this.onSuccess);
    }

    onHideNotify(event) {
        event.preventDefault();
        this.setState({error: null});
    }

    onCancel(event) {
        event.preventDefault();
        if(typeof this.props.onCancel === 'function') this.props.onCancel(this.props.phoneNum, this.props.oldPhoneNum);
        if(typeof this.props.onCancelEmail === 'function') this.props.onCancelEmail(this.props.newEmail, this.props.oldEmail);
    }

    render() {
        const title = this.props.title || 'Подтверждение номера телефона';
        const phoneNum = this.props.phoneNum;
        const newEmail = this.props.newEmail;

        let notify = null;
        if(this.state.error) {
            const notifyClassName = [ getStyle('top-notify'), notifyStyle['center'], myStyle['confirmNotify'] ];
            const notifyTextClassName = [ getStyle('notify-message'), notifyStyle['inline'], notifyStyle['error'] ];
            const notifyCloseClassName = [ getStyle("button btn-close inv"), notifyStyle['btn-close'] ];

            notify = (
                <InnerNotify className={notifyClassName.join(' ')} notifyTextClassName={notifyTextClassName.join(' ')} closeClick={this.onHideNotify} notifyCloseClassName={notifyCloseClassName.join(' ')}>
                    {this.state.error}
                </InnerNotify>
            );
        }

      //  console.log('---', this.state);

        return(
            <PopUp title={title} onCancel={typeof this.props.onCancel === 'function' ? this.onCancel : null} isLoading={this.state.inRequest}>
                {notify}

                {phoneNum && this.props.oldPhoneNum ?
                    <div>
                        <div>
                            На номер {phoneNum} было выслано SMS-сообщение с кодом подтверждения.
                        </div>
                        <table className={getStyle('left-pull form-table')}>
                            <tbody>
                            <tr>
                                <td className={getStyle('t-nowrap td-null')}>Введите код</td>
                                <td>
                                    <input type="text" name="code" value={this.state.code} onChange={this.onInputValueChange} />
                                </td>
                            </tr>
                            <tr>
                                <td className={getStyle('t-nowrap td-null')} />
                                <td>
                                    <button className={getStyle('btn-major')} onClick={this.onCheckClick}>Проверить</button>
                                </td>
                            </tr>
                            </tbody>
                        </table>
                    </div>
                : null}


                {newEmail && this.props.oldEmail ?
                    <div>
                        <div>
                            На <b>{newEmail}</b> было выслано письмо с кодом подтверждения.
                        </div>
                        <table className={getStyle('left-pull form-table')}>
                            <tbody>
                            <tr>
                                <td className={getStyle('t-nowrap td-null')}>Введите код</td>
                                <td>
                                    <input type="text" name="codeEmail" value={this.state.codeEmail} onChange={this.onInputValueChange} />
                                </td>
                            </tr>
                            <tr>
                                <td className={getStyle('t-nowrap td-null')} />
                                <td>
                                    <button className={getStyle('btn-major')} onClick={this.submitEmailCode}>Проверить</button>
                                </td>
                            </tr>
                            </tbody>
                        </table>
                    </div>
                : null}

            </PopUp>
        );
    }
}

export default ConfirmationPhone;