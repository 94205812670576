import React from 'react';
import { connect } from 'react-redux';
// import { bindActionCreators } from 'redux';

import axios from 'axios';

import Panel from '../../Components/Panel';
import { processInputHtmlStaticPage, StaticPageDisplayContainer } from '../../tools';
import * as CONST from '../../const';

class PanelTariffs extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            chapter: props.chapter,
            chapterContent: {},
            loadingState: false,
            error: null
        }
    }

    requestChapterContent(chapter) {
        if(this.state.chapterContent[chapter]) return;

        this.setState({loadingState: true, error: null, chapter: chapter});

        axios.get(CONST.API + '/staticPages/tariffs', {
            withCredentials: true,
            params: {
                chapter: chapter,
                contract: this.props.authData.activeContract,
                '.rnd': Math.ceil(Math.random()*1000000)
            }
        }).then((response) => {
            const answer = response.data;
            if(answer.result === 'error') {
                this.setState({loadingState: false, error: answer.text });
            } else {
                let chapterContent = this.state.chapterContent;
                chapterContent[chapter] = answer.data;
                this.setState({loadingState: false, error: null, chapterContent: chapterContent});
            }
        });
    }

    componentWillMount() {
        this.requestChapterContent(this.props.chapter);
    }

    componentWillReceiveProps(nextProps) {
        if(nextProps.chapter !== this.state.chapter) this.requestChapterContent(nextProps.chapter);
    }

    processInputHtml(html) {
        const servicesTree = this.props.panelServices.servicesTree;
        return processInputHtmlStaticPage(html, servicesTree);
    }

    render() {
        const title = this.props.title || 'Тарифы';

        const content = this.state.chapterContent[this.state.chapter];
        const loadingState = this.state.loadingState;

        const DivContainer = StaticPageDisplayContainer(this.processInputHtml(content));

        return (
            this.state.error || (!content && !loadingState) ? null :
            <Panel title={title} isLoading={loadingState}>
                <div className='text-content__container'>
                {!this.state.loadingState ?
                    <DivContainer />
                :null}
                </div>
            </Panel>
        );
    }
}

function mapStateToProps(state) {
    return {
        authData: state.authenticate,
        panelServices: state.panelServices
//        servicesData: state.panelServices
    }
}

function mapDispatchToProps(dispatch) {
    return {
        // elementActions: bindActionCreators(PanelActions, dispatch)
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(PanelTariffs);