/**
 * Created by dpopov on 16.08.2017.
 */
/*eslint eqeqeq: ["off", "smart"]*/
import React from 'react';
import { getStyle } from "../../tools";

export default (props) => {
    let paginator = [];

    const currentLimit = props.currentLimit;
    const currentFrom = props.displayFrom;
    const totalCount = props.totalCount || 0;

    const pagesCount = Math.ceil(totalCount / currentLimit);
    const currentPage = Math.floor(currentFrom / currentLimit) + 1;

    const limits = props.limits || [ 10, 20, 30 ];

    const hideShowCountSelector = props.hideShowCountSelector;

    const toFirstCaption = props.toFirstCaption || 'В начало';
    const toEndCaption = props.toEndCaption || 'В конец';

    const buttonStyle = [ getStyle('button'), props.buttonStyle].join(' ');

    if(currentPage != 1) { paginator.push(<button key="fisrt" className={buttonStyle} onClick={props.changeDisplayPage} data-from={0}>{toFirstCaption}</button>) }

    for(let p=currentPage - 2; p <= currentPage + 2; p++) {
        if(p <= 0) continue;
        if(p > pagesCount) break;

        paginator.push(<button key={p} className={[buttonStyle, p==currentPage?getStyle('active'):null].join(' ')} onClick={props.changeDisplayPage} data-from={(p-1)*currentLimit}>{p}</button>);
    }

    if(currentPage != pagesCount && totalCount != 0) { paginator.push(<button key="last" className={buttonStyle} onClick={props.changeDisplayPage} data-from={(pagesCount-1)*currentLimit}>{toEndCaption}</button>)}

    return (
        <div className={getStyle('b-row')}>
            <div className={getStyle('f-left')}>
                {paginator}
            </div>
            {hideShowCountSelector ? null :
            <div className={getStyle('f-right')}>
                Показать по&emsp;
                {limits.map((limitItem, _i) => (<button key={_i} type="button" className={currentLimit == limitItem ? getStyle('active') : null} onClick={props.changeLimitClick} data-count={limitItem}>{limitItem}</button>))}
            </div>
            }
        </div>
    );
}