import * as CONST from '../../const';

const initialState = {
    panelLoadingState: false,
    usersList: {},
    totalUsers: 0,
    displayCount: 10,
    displayPage: 1,
    listRoom: [],
    booked: [],
};

export default function HotelReducer(state = initialState, action) {
    switch(action.type) {

        case CONST.HOTEL.HOTEL_REQUEST_LIST_SET_DATA: {
            return {...state,
                panelLoadingState: false,
                usersList: action.payload.items,
                totalUsers: action.payload.total,
                displayPage: action.payload.page
            }
        }

        case CONST.HOTEL.HOTEL_SET_DISPLAY_PAGE: {
            return {...state,
                displayPage: action.payload.page
            }
        }
        case CONST.HOTEL.HOTEL_SET_LIMIT_COUNT: {
            return {...state,
                displayCount: action.payload.limit
            }
        }
        case CONST.HOTEL.HOTEL_SET_LIST_ROOMS: {
            return {...state,
                listRoom: action.payload.listRoom,
                booked: action.payload.booked
            }
        }


        default: return state;
    }
}