/**
 * Created by dpopov on 14.12.2017.
 */
/* eslint eqeqeq: ["off", "smart"] */
import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import axios from 'axios';

import Panel from '../../Components/Panel';
import PanelPassword from '../PanelPassword';
import PanelConfirmContacts from '../PanelPersonalSettings';

import * as authenticateActions from '../../Authenticate/actions';
import * as CONST from '../../const';
import { getStyle, getStdNotifyStruct } from '../../tools';

const apiPasswordUpdate = '/auth/firstLoginPassword';
const apiPhoneConfirmed = '/auth/firstLoginPhoneConfirmed';

class PanelFirstLogin extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            loadingState: false,
            notifyText: null,
            step: this.getStepByProps(props),
            checkPhoneLoadingState: false
        };

        this.passwordChanged = this.passwordChanged.bind(this);
        this.onError = this.onError.bind(this);
        this.onNotifyClear = this.onNotifyClear.bind(this);
        this.onRequestBegin = this.onRequestBegin.bind(this);
        this.onPhoneConfirmSuccess = this.onPhoneConfirmSuccess.bind(this);
    }

    getStepByProps(props) {
        if(props.authState.changePassword) return 1;

        return 2;
    }

    componentWillReceiveProps(nextProps) {
        const nextStep = this.getStepByProps(nextProps);
        if(nextStep != this.state.step) this.setState({step: nextStep, loadingState: false});
    }

    changePassword() {
        return (
            <div className={getStyle('b-row doble-col')}>
                <div className={getStyle('col-main')}>
                    <PanelPassword asFrame={true} askOldPassword={false} apiPasswordUpdate={apiPasswordUpdate}
                                   onPasswordUpdated={this.passwordChanged} onError={this.onError} onRequestBegin={this.onRequestBegin} />
                </div>
                <div className={getStyle('col-side')}>
                    <div className={getStyle('b-header')}>
                        <h4>Смена пароля</h4>
                    </div>
                    <ul className={getStyle('t-small')}>
                        <li>Это ваш первый вход в личный кабинет. Пожалуйста, задайте новый пароль.</li>
                        <li>Новый пароль должен содержать не менее 6 символов.</li>
                    </ul>
                </div>
            </div>
        );
    }

    confirmPhone() {
        return (
            <PanelConfirmContacts asFrame={true} forceCheck={true} onConfirmPhone={this.onPhoneConfirmSuccess} loadingState={this.props.checkPhoneLoadingState} />
        )
    }

    onPhoneConfirmSuccess() {
        this.setState({checkPhoneLoadingState: true});  // Оставим форму заблокированной
        axios.get(CONST.API + apiPhoneConfirmed, {
            withCredentials: true,
            params: {
                contract: this.props.authState.activeContract,
		'.rnd': Math.ceil(Math.random()*1000000)
            }
        }).then((answer) => {
            const data = answer.data;
            if(data.result == 'success') {
                this.props.authActions.updateCurrentUser(data);
            }
        })
    }

    onError(data) {
        if(data.result === 'error') this.setState({notifyText: data.text, loadingState: false});
        else this.setState({loadingState: false})
    }

    passwordChanged(data) {
        if(data.result === 'success') {
            this.props.authActions.updateCurrentUser(data);
        }
    }

    onNotifyClear(event) {
        event.preventDefault();
        this.setState({notifyText: null});
    }

    onRequestBegin() {
        this.setState({loadingState: true});
    }

    render() {
        const title = 'Первый вход';
        const icon = this.props.icon || getStyle('icon-sett');

        const notify = getStdNotifyStruct(this.state.notifyText, this.onNotifyClear);

        return (
            <Panel icon={icon} title={title} notify={notify}>
                {this.state.step == 1 ? this.changePassword() : null}
                {this.state.step == 2 ? this.confirmPhone(): null}
            </Panel>
        );
    }
}

function mapStateToProps(state) {
    return {
        authState: state.authenticate
    }
}

function mapDispatchToProps(dispatch) {
    return {
        elementActions: null,
        authActions: bindActionCreators(authenticateActions, dispatch)
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(PanelFirstLogin);