/**
 * Created by dpopov on 19.07.2017.
 */
import React from 'react';
import ChapterServices from './index';

import SuspendService from '../../Panels/SuspendService';
import CheckRoster from '../../Panels/PanelServices/CheckRoster';

export default (props) => {
    return (
        <CheckRoster from={"pageServicesSettings"}>
            <ChapterServices>
                <SuspendService />
            </ChapterServices>
        </CheckRoster>
    );
}