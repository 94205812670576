import * as CONST from './const';

const initialState = {
    initialiseError: undefined,
    contextInitialiseProcess: false,
    contextInitialised: false,
    paymentClient: undefined
};

export default function (state = initialState, action) {
    switch (action.type) {
        case CONST.REDUX.INITIALISE_PROCESS: return {...state, contextInitialiseProcess: action.payload };
        case CONST.REDUX.INITIALISE_RESULT: return {...state,
            contextInitialiseProcess: false,
            contextInitialised: true,
            paymentClient: action.payload
        };
        case CONST.REDUX.INITIALISE_ERROR: return {
            contextInitialiseProcess: false,
            contextInitialised: true,
            initialiseError: action.payload
        };
        default: return state;
    }
}