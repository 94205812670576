import React from 'react';

import Panel from '../../Components/Panel';
import style from './style.module.css';
import {Link} from "react-router-dom";
import * as CONST from "../../const";

export default function(props) {



    return (
        <Panel  title="Ошибка 404" isLoading={props.isLoading}>
            <div className={style.notfound_panelContent}>
                <h1 className={style.notfound__header}>Страница не найдена</h1>
                <Link to={CONST.ROOT_PATH+"/"}>Вернуться на главную</Link>
            </div>
        </Panel>
    );
}