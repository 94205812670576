/**
 * Created by dpopov on 17.08.2017.
 */
/*eslint eqeqeq: ["off", "smart"]*/
import React from 'react';
import myStyle from './style.module.css';

import {getStyle} from '../../tools';

import InputContractNumber from './inputContractNumber';
import ContactsForCheck from './contactsForCheck';

const DaysOfWeek = [
    'понедельникам', 'вторникам', 'средам', 'четвергам', 'пятницам', 'субботам', 'воскресеньям'
];

function describeEvery(num) {
    num = ('' + num).replace(/.*(\d)$/, '$1');
    if (num == 1) return 'Каждый';
    return 'Каждые';
}

function describeDay(day) {
    day = ('' + day).replace(/.*(\d)$/, '$1');
    if (day == 1) return 'день';
    if (2 <= day && day <= 4) return 'дня';

    return 'дней';
}

export default (props) => {
    const cardList = props.cardList;
    const recommendMoney = props.recommendMoney;
    const hasAutoPayment = props.hasAutoPayment;
    const smallLink = props.smallLink;
    const isFrame = props.isFrame;

    let subPanelHeaderClassName = null;
    let subPanelBodyClassName = null;

    if (props.isFrame) {
        subPanelHeaderClassName = myStyle['iframe-subpanel-header'];
        subPanelBodyClassName = myStyle['iframe-subpanel-body'];
    }

    let cardsOptions = [];

    for (let c = 0; c < cardList.length; c++) {
        cardsOptions.push(<option value={cardList[c].cardID} key={c}>{cardList[c].card}</option>);
    }

    cardsOptions.push(<option value="" key="lastOption">новая банковская карта</option>);

    return (
        <div>

            {hasAutoPayment ?
                <div className={getStyle("b-row gray b-border")}>
                    <p>У вас включен автоплатёж.&nbsp;
                        {hasAutoPayment.AutoType == 1 ?
                            hasAutoPayment.shedulePeriodType == 1 ? "Каждую неделю по " + DaysOfWeek[hasAutoPayment.shedulePeriodParam] + ' счёт будет пополнен на сумму ' + (hasAutoPayment.AmountValue ? hasAutoPayment.AmountValue + ' руб.' : 'расчитанную автоматически') :
                                hasAutoPayment.shedulePeriodType == 2 ? 'Каждый месяц по ' + hasAutoPayment.shedulePeriodParam + ' числам счёт будет пополнен на сумму ' + (hasAutoPayment.AmountValue ? hasAutoPayment.AmountValue + ' руб.' : 'расчитанную автоматически') :
                                    hasAutoPayment.shedulePeriodType == 3 ? describeEvery(hasAutoPayment.shedulePeriodParam) + ' ' + hasAutoPayment.shedulePeriodParam + ' ' + describeDay(hasAutoPayment.shedulePeriodParam) + ' счёт будет пополнен на сумму ' + (hasAutoPayment.AmountValue ? hasAutoPayment.AmountValue + ' руб.' : 'расчитанную автоматически')
                                        : null
                            :
                            "При достиженнии баланса счёта в " + hasAutoPayment.balanceLimit + ' руб ' +
                            ' счёт будет пополнен на сумму ' + (hasAutoPayment.AmountValue ? hasAutoPayment.AmountValue + ' руб.' : 'расчитанную автоматически') +
                            (hasAutoPayment.monthLimit ? ' но не более ' + hasAutoPayment.monthLimit + ' руб в месяц' : '')
                        }
                    </p>
                    <div>
                        <div className={getStyle('f-right')}>
                            <button type="button" onClick={props.autoPayments}>Отключить или изменить автоплатёж
                            </button>
                        </div>
                    </div>
                </div>
                : null}

            <div className={getStyle('b-row doble-col')} key="2">
                <div className={getStyle('col-main')}>
                    <table className={getStyle('form-table')}>
                        <tbody>
                        {isFrame ?
                            <InputContractNumber {...props} />
                            :
                            <tr>
                                <td>Банковская карта</td>
                                <td>
                                    <select name="cards" className={getStyle('wide')} onChange={props.inputValueChange}
                                            value={props.inputValues.cards}>{cardsOptions}</select>
                                </td>
                            </tr>
                        }
                        <tr>
                            <td>Сумма платежа</td>
                            <td>
                                <input type="text" value={recommendMoney} className={getStyle('input-x')}
                                       onChange={props.amountChange}/>
                                <button type="button" className={getStyle('btn-major f-right')}
                                        onClick={props.makePayment} disabled={props.payButtonDisabled}>Оплатить
                                </button>
                            </td>
                        </tr>
                        {smallLink || isFrame ? null :
                            <tr>
                                <td colSpan={2}>
                                    <button type="button" className={getStyle('btn-popup')}
                                            onClick={props.autoPayments}>
                                        {hasAutoPayment ? "Изменить автоплатёж" : "Включить автоплатеж"}
                                    </button>
                                </td>
                            </tr>
                        }
                        </tbody>
                    </table>
                    {props.requireContacts ? <ContactsForCheck {...props} /> : null}
                </div>

                <div className={getStyle('col-side')}>
                    <div className={getStyle('b-header')}><h4 className={subPanelHeaderClassName}><i
                        className={getStyle('m-icon icon-star border')}/> Условия оплаты</h4></div>
                    <ul className={[getStyle('dash t-small'), subPanelBodyClassName].join(' ')}>
                        <li>Комиссия за проведение операции - 0%</li>
                        <li>Вы будете перенаправлены на сайт ПАО Газпромбанк с применением сертифицированных протоколов
                            безопасности
                        </li>
                        <li>«ОБИТ» не имеет доступа к вводимой информации</li>
                        <li>«ОБИТ» не хранит данные банковских карт на своих серверах</li>
                    </ul>
                </div>
            </div>

        </div>
    )
}