/**
 * Created by dpopov on 11.10.2017.
 */
import * as CONST from '../../const';

const initialState = {
    chapterLoading: false,
    chapters: [],
    contentLoading: false,
    content: {},
    activeChapter: ''
};

export default function PanelFAQReducer(state = initialState, action) {
    switch(action.type) {
        case CONST.EMPTY_REDUCER: return {...initialState};
        case CONST.FAQ.REQUEST_CHAPTERS: return {...state, chapterLoading: action.payload };
        case CONST.FAQ.REQUEST_CHAPTER_DATA: return {...state, contentLoading: action.payload };
        case CONST.FAQ.SET_CHAPTERS: {
            let currentChapter = state.activeChapter;
            const chapters = action.payload;
            let chapterPresent = false;
            let defaultChapter = '';

            for(let i = 0; i < chapters.length; i++) {
                if(currentChapter === chapters[i].chapter) chapterPresent = true;
                if(chapters[i].options.default || i === 0) defaultChapter = chapters[i].chapter;
            }

            if(!currentChapter || !chapterPresent) currentChapter = defaultChapter;

            return {...state, chapters: chapters, chapterLoading: false, activeChapter: currentChapter };
        }
        case CONST.FAQ.SET_CHAPTER_DATA: {
            let currentContent = state.content;
            currentContent[action.payload.chapter] = action.payload.data;

            return {...state, contentLoading: false, content: currentContent };
        }
        case CONST.FAQ.SWITCH_CHAPTER: {
            return {...state, activeChapter: action.payload }
        }
        default: return state;
    }
}