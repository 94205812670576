/**
 * Created by dpopov on 07.09.2017.
 */
import React from 'react';
import ChapterServices from './index'

import PanelInternetStatistics from '../../Panels/PanelServices/InternetStatistics';
import PanelPhoneStatistics from '../../Panels/PanelServices/PhoneStatistics';
import CheckRoster from '../../Panels/PanelServices/CheckRoster';

export default (props) => {
    const serviceType = props.match.params.serviceType;
    const serviceId = props.match.params.serviceId;
    const dateFrom = props.match.params.dateFrom;
    const dateTo = props.match.params.dateTo;
    const chooserBtn = props.match.params.chooserBtn;

    return (
        <CheckRoster from={"pageStatistics"}>
            <ChapterServices>
                { serviceType === 'inet' ? <PanelInternetStatistics serviceId={serviceId} dateFrom={dateFrom} dateTo={dateTo} chooserBtn={chooserBtn} /> : null }
                { serviceType === 'phone' ? <PanelPhoneStatistics serviceId={serviceId} dateFrom={dateFrom} dateTo={dateTo} chooserBtn={chooserBtn} /> : null }
            </ChapterServices>
        </CheckRoster>
    );
}