/**
 * Created by dpopov on 06.09.2017.
 */
/*eslint eqeqeq: ["off", "smart"]*/
/*eslint default-case: ["off"]*/

import React from 'react';
import ReactDOM from 'react-dom';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import * as authenticateActions from './../../../Authenticate/actions';
import * as panelServicesActions from '../actions';
import * as CONST from '../../../const';

import Panel from '../../../Components/Panel';
import Paginator from '../../../Components/Panel/paginator';

import '../../../../assets/css/obit.module.css';
import '../../../styles-fix.css';

import Chooser from './chooser';
import { getStyle } from "../../../tools";

import 'moment/locale/ru';
import moment from 'moment';
moment.locale('ru');

class PanelInternetStatistics extends React.Component {
    constructor(props) {
        super(props);

        let propsDateFrom = moment(props.dateFrom, 'YYYY-MM-DD');
        let propsDateTo = moment(props.dateTo, 'YYYY-MM-DD');
        let btnClicked = null;

        switch(props.chooserBtn) {
            case '1': btnClicked = 'thisMonth'; break;
            case '2': btnClicked = 'prevMonth'; break;
            case '3': btnClicked = 'thisYear'; break;
        };

        if (!propsDateFrom.isValid()) propsDateFrom = moment().startOf('month');
        if (!propsDateTo.isValid()) propsDateTo = moment().endOf('month');

        if (propsDateFrom.isAfter(propsDateTo)) propsDateTo = moment(propsDateFrom).add(1, 'day');

        this.state = {
            serviceId: this.props.serviceId,
            period: {
                from: propsDateFrom,
                to: propsDateTo
            },
            btnClicked: btnClicked,
            historyItemsLimit: 50,
            historyItemsFrom: 1,
            displayVariant: 1,
            _history: undefined
        };

        this.btnPreConfigureClick = this.btnPreConfigureClick.bind(this);
        this.periodSetFrom = this.periodSetFrom.bind(this);
        this.periodSetTo = this.periodSetTo.bind(this);
        this.periodDisplayClick = this.periodDisplayClick.bind(this);
        this.requestHistoryData = this.requestHistoryData.bind(this);
        this.changeLimitClick = this.changeLimitClick.bind(this);
        this.changeDisplayPage = this.changeDisplayPage.bind(this);
        this.changeDisplayVariantClick = this.changeDisplayVariantClick.bind(this);
        this.moveToStatPage = this.moveToStatPage.bind(this);
    }

    componentWillMount() {
    }

    moveToStatPage(history) {
        const date1 = this.state.period.from.format('YYYY-MM-DD');
        const date2 = this.state.period.to.format('YYYY-MM-DD');
        let btnClicked = null;
        switch(this.state.btnClicked) {
            case 'thisMonth': btnClicked = 1; break;
            case 'prevMonth': btnClicked = 2; break;
            case 'thisYear': btnClicked = 3; break;
        }
        const url = CONST.ROOT_PATH+"/statistics-inet."+this.state.serviceId+"/"+date1+"/"+date2+(btnClicked?"/"+btnClicked:"");

        history.push(url);

        this.requestHistoryData();
    }

    requestHistoryData() {
        const contract = this.props.authData.activeContract;
        this.props.serviceActions.requestServiceHistoryData(contract, this.state.serviceId, 'inet', this.state.period.from,
            this.state.period.to, this.state.historyItemsFrom, this.state.historyItemsLimit, this.state.displayVariant,
            () => { ReactDOM.findDOMNode(this).scrollIntoView(); }
        );
    }

    btnPreConfigureClick(event, history) {
        event.preventDefault();

        const btnId = event.target.getAttribute('id');
        switch(btnId) {
            case 'thisMonth':
                this.setState({
                    _history: history,
                    period: { from: moment().startOf('month'), to: moment().endOf('month') },
                    btnClicked: btnId
                }); break;
            case 'prevMonth':
                this.setState({
                    _history: history,
                    period: { from: moment().add(-1, 'month').startOf('month'), to: moment().add(-1, 'month').endOf('month') },
                    btnClicked: btnId
                }); break;
            case 'thisYear':
                this.setState({
                    _history: history,
                    period: { from: moment().startOf('year'), to: moment().endOf('year')},
                    btnClicked: btnId
                }); break;
        }
    }

    periodSetFrom(date) {
        date = moment(date);
//        console.log(date);
        let to = this.state.period.to;
        if(to.isBefore(date)) to = moment(date);
        this.setState({ period: { from: date, to: to }, btnClicked: null});
    }

    periodSetTo(date) {
        date = moment(date);
        let from = this.state.period.from;
        if(from.isAfter(date)) from = moment(date);
        this.setState({ period: { from: from, to: date }, btnClicked: null});
    }

    periodDisplayClick(event, history) {
        event.preventDefault();
        this.moveToStatPage(history);
    }

    componentDidUpdate(prevProps, prevState) {
        if(this.state.historyItemsFrom != prevState.historyItemsFrom) this.requestHistoryData();
        else if(this.state.historyItemsLimit != prevState.historyItemsLimit) this.requestHistoryData();
        else if(this.state.displayVariant != prevState.displayVariant) this.requestHistoryData();

        if(this.state._history) {
            if(prevState.btnClicked != this.state.btnClicked) this.moveToStatPage(this.state._history);
        }
    }

    changeLimitClick(event) {
        event.preventDefault();
        let limit = event.target.getAttribute('data-count');
        let from = this.state.historyItemsFrom;

        from = 0;           // пока делаем так, что при изменении количества отображаемых элементов, отображаем всё с самого начала

        this.setState({ historyItemsLimit: limit, historyItemsFrom: from });
    }

    changeDisplayPage(event) {
        event.preventDefault();
        this.setState({ historyItemsFrom: event.target.getAttribute('data-from') });
    }

    changeDisplayVariantClick(event) {
        event.preventDefault();
        this.setState({ displayVariant: event.target.getAttribute('data-variant') });
    }

    render() {
        const title = this.props.title || "Статистика";
        const icon = this.props.icon || getStyle('icon-chart');

        const loadingState = this.props.servicesData.historyRequestState;

        const chooserOnly = this.props.chooserOnly || false;

        const btnThisMonth = { onClick: this.btnPreConfigureClick, className: this.state.btnClicked == 'thisMonth'? getStyle('active') : null};
        const btnPrevMonth = { onClick: this.btnPreConfigureClick, className: this.state.btnClicked == 'prevMonth'? getStyle('active') : null};
        const btnThisYear  = { onClick: this.btnPreConfigureClick, className: this.state.btnClicked == 'thisYear'? getStyle('active') : null};

        const periodOptions = { from: this.state.period.from, to: this.state.period.to, fromChange: this.periodSetFrom, toChange: this.periodSetTo, onClick: this.periodDisplayClick };

        const historyItems = this.props.servicesData.historyData.items || [];
        const historyTotalItems = this.props.servicesData.historyData.total || 0;

        const displayVariant = this.state.displayVariant;

        return (
            <Panel title={title} icon={icon} isLoading={loadingState} headerClassName={getStyle('b-header-z-0')}>
                <Chooser btnThisMonth={btnThisMonth} btnPrevMonth={btnPrevMonth} btnThisYear={btnThisYear}
                         period={periodOptions} serviceId={this.state.serviceId} className={ chooserOnly ? [getStyle('b-row'), 'z1000'].join(' ') : [getStyle('b-row gray'), 'z1000'].join(' ')} chooserOnly={chooserOnly} />
                {chooserOnly ? null :
                    <div>
                        <div className={getStyle('b-row b-border')}>
                            <div className={getStyle('f-left')}>
                                <button type="button" data-variant={1} onClick={this.changeDisplayVariantClick} className={displayVariant == 1 ? getStyle('active') : null}>По дням</button>
                                <button type="button" data-variant={2} onClick={this.changeDisplayVariantClick} className={displayVariant == 2 ? getStyle('active') : null}>По IP адресам</button>
                            </div>
                        </div>
                        <table className={getStyle('line v-top left-pull')}>
                            <thead>
                            <tr>
                                <th>{displayVariant == 1 ? "Дата" : "IP адрес" }</th>
                                <th>Входящий трафик, Мбайт</th>
                                <th>Исходящий трафик, Мбайт</th>
                            </tr>
                            </thead>
                            <tbody>
                            { historyItems.map(((item, _i) => (<tr key={_i}>
                                <td>{displayVariant == 1 ? moment(item.StartDate).format('DD.MM.YYYY') : item.IPAddr }</td>
                                <td>{Math.ceil((item.ToBytes / 1024 / 1024))}</td>
                                <td>{Math.ceil((item.FromBytes / 1024 / 1024))}</td>
                            </tr>)))}
                            </tbody>
                        </table>
                        <Paginator totalCount={historyTotalItems} currentLimit={this.state.historyItemsLimit}
                                   displayFrom={this.state.historyItemsFrom} limits={[25, 50, 100, 150]}
                                   changeDisplayPage={this.changeDisplayPage} changeLimitClick={this.changeLimitClick} />
                    </div>
                }
            </Panel>
        );
    }
}

function mapStateToProps(state) {
    return {
        authData: state.authenticate,
        servicesData: state.panelServices
    }
}

function mapDispatchToProps(dispatch) {
    return {
        serviceActions: bindActionCreators(panelServicesActions, dispatch),
        authenticate: bindActionCreators(authenticateActions, dispatch)
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(PanelInternetStatistics);