/*eslint no-unused-vars: ["off", "smart"]*/

import React from 'react';

import { getStyle } from '../../../tools';
import style from './style.module.css';

export default function(props) {
    if(!props.items) return null;

    let totalDuration = 0;
    let totalCount = 0;
    let totalPrice = 0;

    return (
        <table className={[getStyle('line v-top left-pull'), style['ph-stat-v2-table']].join(' ')}>
            <thead>
            <tr>
                <th>Направление</th>
                <th className={getStyle('t-right')}>Длительность</th>
                <th className={getStyle('t-right')}>Кол-во вызовов</th>
                <th className={getStyle('t-right')}>Стоимость</th>
            </tr>
            </thead>
            <tbody>
            {props.items.map((item, _i) => {
                totalDuration += item.CalcDuration;
                totalCount += item.callsCount;
                totalPrice += item.CalcPrice;
                return (
                    <tr key={_i}>
                        <td>{item.DirectionName}</td>
                        <td className={getStyle('t-right')}>{item.CalcDuration}</td>
                        <td className={getStyle('t-right')}>{item.callsCount}</td>
                        <td className={getStyle('t-right')}>{item.CalcPrice}</td>
                    </tr>
                );
            })}
            </tbody>
            <tfoot>
            <tr>
                <td>ИТОГО</td>
                <td className={getStyle('t-right')} />
                <td className={getStyle('t-right')} />
                <td className={getStyle('t-right')}>{totalPrice}</td>
            </tr>
            </tfoot>
        </table>
    );
}