/**
 * Created by dpopov on 19.07.2017.
 */
/*eslint eqeqeq: ["off", "smart"]*/
import React from 'react';
import { connect } from 'react-redux';
import { getStyle } from "../../tools";

import Panel from '../../Components/Panel/StaticPage';
import myStyle from './style.module.css';

class PanelPayBonus extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            contract: this.props.authData.activeContract
        }
    }

    componentWillReceiveProps(nextProps) {
        if(nextProps.authData.activeContract != this.state.contract) {
            this.setState({ contract: nextProps.authData.activeContract});
        }
    }

    render() {
        const panelTitle = this.props.title || "Бонус за друзей";
        //const queryParams = { contract: this.props.authData.activeContract };
        const queryParams = { contract: this.state.contract };

        return (
            <Panel title={panelTitle} icon={getStyle('icon-star')} pageName="internetForFriends" queryParams={queryParams} bodyStyle={myStyle['panelBody']} containerStyle={myStyle['container']} compareValue={this.state.contract}>

            </Panel>
        );
    }
}

function mapStateToProps(state) {
    return {
//        services: state.panelServices
//        servicesData: state.panelServices
        authData: state.authenticate
    }
}

function mapDispatchToProps(dispatch) {
    return {
//        elementActions: bindActionCreators(SubNavBlock, dispatch),
//        authenticate: bindActionCreators(SubNavBlock, dispatch)
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(PanelPayBonus);