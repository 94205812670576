import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Link } from 'react-router-dom';

import axios from 'axios';

import * as CONST from '../../const';
import { getStyle } from '../../tools';
import style from './style.module.css';

// import * as panelServicesActions from "../PanelServices/actions";
import * as authenticateActions from "../../Authenticate/actions";

import Panel from '../../Components/Panel/PopUp';
import Oops from '../../Components/Oops';

//  Параметры:
//      AppServiceID    int
//      hcr             int
//      autoClose       boolean
//      onSuccess       function
//      onTicketCreate  function
//      changeTariff    boolean
class PanelHiSpeedCheck extends React.Component {
   constructor(props) {
       super(props);

       this.state = {
           inProcess: true,
           allowHiSpeed: false,
           ticketProcess: false,
           ticketCreated: null,
           error: false
       };

       this.btnMakeTicketClick = this.btnMakeTicketClick.bind(this);
   }

   componentDidMount() {
       this.checkPortOperSpeed();
   }

   checkPortOperSpeed() {
       const contract = this.props.authData.activeContract;

       axios.get(CONST.API + '/services/getCurrentPortSpeed', {
           withCredentials: true,
           params: {
               contract: contract,
               AppServiceID: this.props.AppServiceID,
               hcr: this.props.hcr
           }
       }).then((answer) => {
           const data = answer.data;
           if(data.result === 'error') {
               this.setState({error: true, inProcess: false});
//               console.log('checkPortOperSpeed.1', data);
           } else {
               if(data.data.operSpeed > 100) {
                   this.setState({inProcess: false, allowHiSpeed: true });
                   if(typeof this.props.onSuccess === 'function' && this.props.autoClose) this.props.onSuccess();
               } else {
                   this.setState({inProcess: false, allowHiSpeed: false });
               }
           }
       }).catch((error => {
//           console.log('checkPortOperSpeed.2', error);
           this.setState({error: true, inProcess: false });
       }));
   }

   btnMakeTicketClick(event) {
       event.preventDefault();
       this.setState({inProcess: true, ticketProcess: true });

       const contract = this.props.authData.activeContract;

       axios.get(CONST.API + '/services/makeHiSpeedTicket', {
           withCredentials: true,
           params: {
               contract: contract,
               changeTariff: this.props.changeTariff ? 1:0,
               targetTariff: this.props.targetTariff
           }
       }).then((answer) => {
           const response = answer.data;
           if(response.result === 'success') {
               const ticketNumber = typeof response.data === 'object' ? response.data.Code : response.data;
               this.setState({ inProcess: false, ticketCreated: ticketNumber });
           } else {
//               console.log('btnMakeTicketClick.1', response);
               this.setState({ error: true, inProcess: false});
           }
       }).catch(error => {
//           console.log('btnMakeTicketClick.2', error);
           this.setState({error: true, inProcess: false });
       })
   }

   render() {
       let content = null;

       if(this.state.inProcess) {
           if(this.state.ticketProcess) content = <div className={getStyle('b-row')}>Создаём заявку</div>;
           else content = <div className={getStyle('b-row')}>Мы проверяем техническую возможность подключения высокоскоростных тарифов</div>;
       } else {
           if (this.state.allowHiSpeed) content =
               <div>
                   <div className={getStyle('b-row b-border')}>Проверка прошла успешно! Вам доступны высокоскоростные тарифы</div>
                   <div className={getStyle('b-row')}>
                       {typeof this.props.onSuccess === 'function' ?
                           <button typeof='button' className={getStyle('btn-major f-left')} onClick={event => {event.preventDefault(); this.props.onSuccess();}}>Закрыть</button>
                       : <Link to={CONST.ROOT_PATH} className={getStyle('button')}>На главную страницу</Link>}
                   </div>
               </div>;
           else if(this.state.ticketCreated) content =
               <div>
                   <div className={getStyle('b-row b-border')}>Заявка №{this.state.ticketCreated} успешно создана</div>
                   <div className={getStyle('b-row')}>
                       {typeof this.props.onTicketCreate === 'function' ?
                           <button typeof='button' className={getStyle('btn-major f-left')} onClick={event => {
                               event.preventDefault();
                               this.props.onTicketCreate();
                           }}>
                               Закрыть
                           </button>
                           :
                           <Link to={CONST.ROOT_PATH} className={getStyle('button')}>На главную страницу</Link>
                       }
                   </div>
               </div>;
           else content = (
               <div>
                   <div className={getStyle('b-row gray b-border')}>
                       <b>Возможно, Ваше оборудование не поддерживает высокоскоростное соединение.</b><br/>
                       <br/>
                       <b>Оставьте заявку и наш менеджер позвонит Вам для решения проблемы.</b>
                   </div>
                   <div className={getStyle('b-row')}>
                       <button type={'button'} className={getStyle('btn-major f-left')}
                               onClick={this.btnMakeTicketClick}>Оставить заявку
                       </button>
                       {typeof this.props.onCancel === 'function' ?
                           <button onClick={this.props.onCancel} type={'button'} className={getStyle('button f-right')}>Закрыть</button>
                       :null}
                   </div>
               </div>
           )

       }

       return (
                <Panel title={"Проверяем"} blockRowStyle={style['hiSpeedCheckFixPadding']} onCancel={this.state.inProcess ? null : this.props.onCancel}>
                    {this.state.error ? <Oops/> : content}
                </Panel>
       );
   };
}

function mapStateToProps(state) {
    return {
        authData: state.authenticate,
        servicesData: state.panelServices
    }
}

function mapDispatchToProps(dispatch) {
    return {
//        serviceActions: bindActionCreators(panelServicesActions, dispatch),
        authenticate: bindActionCreators(authenticateActions, dispatch)
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(PanelHiSpeedCheck);
