/**
 * Created by dpopov on 11.10.2017.
 */
import * as CONST from '../../const';
import axios from 'axios';

export function getChapters() {
    return (dispatch, getState, args) => {
        dispatch({ type: CONST.FAQ.REQUEST_CHAPTERS, payload: true });

        axios.get(CONST.API + '/faq/getChapters', {
            withCredentials: true,
            params: { 
		'.rnd': Math.ceil(Math.random()*1000000)
	    }
        }).then((answer) => {
            const data = answer.data;

            if(data.result === 'success') {
                dispatch({ type: CONST.FAQ.SET_CHAPTERS, payload: data.data });
            } else {
                dispatch({ type: CONST.FAQ.REQUEST_CHAPTERS, payload: false });
                dispatch({ type: CONST.NOTIFY.NOTIFY_APPEND_ERROR, lastError: data.text })
            }
        });
    }
}

export function requestContent(chapter) {
    return (dispatch, getState, args) => {
        dispatch({ type: CONST.FAQ.REQUEST_CHAPTER_DATA, payload: true });

        axios.get(CONST.API + '/faq/getContent', {
            withCredentials: true,
            params: {
                chapter: chapter,
		'.rnd': Math.ceil(Math.random()*1000000)
            }
        }).then((answer) => {
            const data = answer.data;
            if(data.result === 'success') {
                dispatch({ type: CONST.FAQ.SET_CHAPTER_DATA, payload: { chapter: chapter, data: data.data }});
            } else {
                dispatch({ type: CONST.FAQ.REQUEST_CHAPTER_DATA, payload: false });
                dispatch({ type: CONST.NOTIFY.NOTIFY_APPEND_ERROR, lastError: data.text })
            }
        });
    }
}

export function switchActiveChapter(chapter) {
    return (dispatch, getState, args) => {
        dispatch({ type: CONST.FAQ.SWITCH_CHAPTER, payload: chapter});
    }
}