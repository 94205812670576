import React from 'react';

import Variant1 from './variant1';
import Variant2 from './variant2';

export default function(props) {
    const variant = props.variant ? props.variant : 2;

    if(variant === 1) return <Variant1 {...props} />;
    if(variant === 2) return <Variant2 {...props} />;
    return null;
}