/**
 * Created by dpopov on 06.12.2017.
 */
/*eslint array-callback-return: ["off"]*/
import React from 'react';
import axios from 'axios';
import { connect } from 'react-redux';

import Panel from '../../Components/Panel';

import {getStyle, StaticPageDisplayContainer} from '../../tools';
import * as CONST from '../../const';

import style from './style.module.css';

class PanelPersonalPromo extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            promos: [],
            activeContract: null,
            loadingState: false
        }
    }

    componentWillReceiveProps(nextProps) {
        if(nextProps.authData.activeContract !== this.state.activeContract && !this.state.loadingState) {
            this.requestPersonalPromo(nextProps.authData.activeContract);
        }
    }

    componentDidMount() {
        const contract = this.props.authData.activeContract;
        this.requestPersonalPromo(contract);
    }

    requestPersonalPromo(contract) {
        this.setState({ loadingState: true, activeContract: contract});
        axios.get(CONST.API + '/adv/getPersonalPromo', {
            withCredentials: true,
            params: {
                contract: contract,
                '.rnd': Math.ceil(Math.random()*1000000)
            }
        }).then((answer) => {
            const data = answer.data;
            if(data.result === 'success') {
                this.setState({promos: data.data, loadingState: false });
            } else {
                this.setState({ loadingState: false });
            }
        });
    }

    render() {
        const promos = this.state.promos;
        const promoKeys = Object.keys(promos);

        if(!promoKeys.length) return null;

        let NumericPromos = [];

        promoKeys.map((key, _idx) => {
            if (key.match(/^\d+$/))
                NumericPromos.push(
                    <tr key={_idx}>
                        <td dangerouslySetInnerHTML={{__html: promos[key]["body"]}}/>
                    </tr>
                );
        });

        return (
            <div>
                {NumericPromos.length ?
            <Panel title="Специальное предложение" icon={getStyle('icon-star')}>
                <div className="position-relative">
                    <table className={getStyle('line v-mid left-pull')}>
                        <tbody>
                        {NumericPromos}
                        </tbody>
                    </table>
                </div>
            </Panel>
                : null }

                {promoKeys.map((key, _idx) => {
                    if(!key.match(/^\d+$/)){
                        const StaticDesc = StaticPageDisplayContainer(promos[key]["body"], "personalPromoCustomContent");

                        return (
                          <Panel className={style['zindex9']} title={key} icon={getStyle('icon-star')} key={_idx}>
                              <StaticDesc />
                          </Panel>
                        );
                    }
                })}

            </div>
        );
    }
}

function mapStateToProps(state) {
    return {
        authData: state.authenticate,
//        servicesData: state.panelServices
    }
}

function mapDispatchToProps(dispatch) {
    return {
//        elementActions: bindActionCreators(panelServicesActions, dispatch),
//        authenticate: bindActionCreators(authenticateActions, dispatch)
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(PanelPersonalPromo);