import React from 'react';

import PhoneConfirmation from '../../../Panels/PanelChoosePrimaryPhone';

import axios from 'axios';
import * as CONST from "../../../const";
import Thanks from "./thanks";

class SLink_PhoneChooser extends React.Component {

    constructor(init) {
        super(init);

        this.state = {
            loadingState: false,
            Numbers: [],
            thanks: false,
            chooseNumber: null
        }
    }

    componentDidMount() {
        if(this.props.phoneIndex !== null && this.props.phones && this.props.phones[this.props.phoneIndex]) {
            const autoChooseNumber = this.props.phones[this.props.phoneIndex];
            this.onConfirmButtonClick(autoChooseNumber)();
        }
    }

    // requestPhoneNumbers() {
    //     this.setState({ loadingState: true });
    //     axios.get(CONST.API + '/smalllinks/getPhoneAutoInformerNumbers', {
    //         withCredentials: true,
    //         params: {
    //             contract: this.props.contract,
    //             link: this.props.link,
    //             '.rnd': Math.ceil(Math.random()*1000000)
    //         }
    //     }).then((answer) => {
    //         const data = answer.data;
    //
    //         if(data.result === 'success') {
    //             let Numbers = data.data.ai || [];
    //             if(data.data.ph) {
    //                 for(let i=0;i<data.data.ph.length; i++) {
    //                     if(Numbers.indexOf(data.data.ph[i]) === -1) Numbers.push(data.data.ph[i]);
    //                 }
    //             }
    //             this.setState({loadingState: false, Numbers: data.data});
    //         } else {
    //             this.setState({loadingState: false, saveErrorText: data.text});
    //         }
    //     })
    //
    // }

    onConfirmButtonClick = (phoneNum) => {
        return (event) => {
            if(event) event.preventDefault();

            this.setState({loadingState: true});

            // this.setState({ thanks: true, chooseNumber: phoneNum }); return null;
            // if(typeof this.props.onSuccess === 'function') return null; // this.props.onSuccess(this.props);

            const _phoneNum = phoneNum.replace(/[^\d]+/g, '');

            let sendData = new FormData();
            sendData.append('contract', this.props.contract);
            sendData.append('mainPhone', _phoneNum);
            sendData.append('_pid', this.props._pid);
            sendData.append('slink', this.props.link);

            axios.post(CONST.API + '/notify/updateMainNumber', sendData, {
                withCredentials: true
            }).then((answer) => {
                const data = answer.data;

                if(data.result === 'success') {
                    this.setState({ thanks: true, chooseNumber: phoneNum });
                    //if(typeof this.props.onSuccess === 'function') this.props.onSuccess(this.props);
                } else {
                    this.setState({loadingState: false, saveErrorText: data.text});
                }
            });

            // console.log(phoneNum);
        }
    };

    render() {
        const formLoadingState = this.state.loadingState;

        return (
            <div>
                {this.state.thanks ?
                    <Thanks phoneNumber={this.state.chooseNumber} />
                    :
                    <PhoneConfirmation variant={2} isLoading={formLoadingState} forceContract={this.props.contract}
                                       Numbers={this.props.phones}
                                       onConfirmButtonClick={this.onConfirmButtonClick}
                                       appendRequestsData={{slink: this.props.link, _pid: this.props._pid }}
                    />
                }
            </div>
        )
    }
}

export default SLink_PhoneChooser;