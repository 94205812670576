/**
 * Created by dpopov on 11.07.2017.
 */

import * as CONST from '../const';
import axios from 'axios';

export function createPayloadFromAnswer(answer) {
    let payload = {
        access: 0,
        contracts: []
    };

    if(answer.result === 'success') {
        payload = {
            auth_type: answer.data.auth_type,
            authVK: answer.data.authVK,
            vkUserPhoto: answer.data.vkUserPhoto,
            access: answer.data.access,
            clientFirstName: answer.data.clientFirstName,
            clientMiddleName: answer.data.clientMiddleName,
            clientLastName: answer.data.clientLastName,
            sex: answer.data.sex,
            contracts: answer.data.contracts || [],
            helpAsks: answer.data.helpAsks,
            guid: answer.data.guid,
            code: answer.data.code,
            ai_phones: answer.data.ai_phones || [],
            ai_mails: answer.data.ai_mails || [],
            other_phones: answer.data.other_phones || [],
            promoBackground: answer.data.promoBackground,
            statuses: answer.data.statuses,
            firstLogin: answer.data.firstLogin,
            firstVKLogin: answer.data.firstVKLogin,
            changePassword: answer.data.changePassword,
            redirect: answer.data.redirect,
            region: answer.data.region || 'Санкт-Петербург',
            rosterLastChange: answer.data.rosterLastChange,
            loginScreenImage: answer.data.loginScreenImage,
            defaultContract: answer.data.defaultContract,
            hotels: answer.data.hotels
        };
    }

    return payload;
}

export function updateCurrentUser(statusPayload) {
    return (dispatch, getState, args) => {
        const payload = createPayloadFromAnswer(statusPayload);
        dispatch({type: CONST.AUTHENTICATE.SET_USER_INFO, payload: payload});
    }
}

export function requestCurrentUser(noInProcess, params) {
    return (dispatch, getState, args) => {
        if(!noInProcess) dispatch({ type: CONST.AUTHENTICATE.IN_PROCESS, payload: true });

        if(params) params['.rnd'] = Math.ceil(Math.random()*1000000);
        else params = { '.rnd': Math.ceil(Math.random()*1000000) };

        axios.get(CONST.API+"/auth/status", {
            withCredentials: true,
            credentials: 'same-origin',
            crossDomain: true,
            params: params
        }).then(function(result) {
            const payload = createPayloadFromAnswer(result.data || {});
            dispatch({type: CONST.AUTHENTICATE.SET_USER_INFO, payload: payload});
            if(!payload.access) dispatch({type: CONST.AUTHENTICATE.EMPTY_ACTIVE_CONTRACT, payload: null });
        }).catch(function (error) {
            dispatch({type: CONST.AUTHENTICATE.ERROR, payload: {
                    title: 'Ошибка #RCU82462',
                    text: 'Ошибка получения данных с сервера'
                }
            });
            // Произошла обшибка. Повторим запрос спустя некоторое время
            // console.log('error', error);
            // setTimeout(function() {
            //     requestCurrentUser()(dispatch, getState, args);
            // }, 3000);
        });
    }
}

export function AuthenticateUser(user, password) {
    return (dispatch, getState, args) => {
        dispatch({ type: CONST.AUTHENTICATE.FORM_IN_PROCESS, payload: true });

//        console.log(user, password);

        let postParams = new FormData();
        postParams.append('user', user);
        postParams.append('password', password);

        axios.post(CONST.API+"/auth/login", postParams, {
            withCredentials: true
        }).then(function(result) {
            if(result.data.result === 'success') {
                if(result.data.data.redirect) result.data.data.auth_type = 'lk2';
                if(result.data.data.auth_type === 'lk2') {
                    const payload = createPayloadFromAnswer(result.data || {});
                    dispatch({type: CONST.NOTIFY.NOTIFY_POP_ERROR, payload: null});
                    dispatch({type: CONST.AUTHENTICATE.SET_USER_INFO, payload: payload});
                } else if(result.data.data.auth_type === 'hotels') {
                    dispatch({type: CONST.AUTHENTICATE.SET_USER_INFO, payload: result.data.data});
                }
            } else {
                dispatch({type: CONST.AUTHENTICATE.SET_USER_INFO, payload: createPayloadFromAnswer({})});
                dispatch({type: CONST.NOTIFY.NOTIFY_APPEND_ERROR, payload: result.data.text });
            }
        })
    }
}

export function forgotPassword(contract, typeAlert, valueTypeAlert) {
    return (dispatch, getState, args) => {
        dispatch({ type: CONST.AUTHENTICATE.FORM_IN_PROCESS, payload: true });

        let postParams = new FormData();
        postParams.append('contract', contract);
        postParams.append('typeAlert', typeAlert);
        postParams.append('valueTypeAlert', valueTypeAlert);

        axios.post(CONST.API+"/auth/forgotPassword", postParams, {
            withCredentials: true
        }).then(function(result) {

            dispatch({ type: CONST.AUTHENTICATE.FORM_IN_PROCESS, payload: false });
            if(result.data.result === 'success') {
                dispatch({type: CONST.NOTIFY.NOTIFY_SUCCESS_FORGOT_PASSWORD, payload: true });
            } else {
                dispatch({type: CONST.NOTIFY.NOTIFY_APPEND_ERROR, payload: result.data.text });
            }
        })
    }
}

export function Logout() {
    return (dispatch, getState, args) => {
        dispatch({ type: CONST.AUTHENTICATE.LOGOUT_IN_PROCESS, payload: null});
        dispatch({ type: CONST.EMPTY_REDUCER, payload: null });
        dispatch({type: CONST.AUTHENTICATE.EMPTY_ACTIVE_CONTRACT, payload: null });

        axios.get(CONST.API+"/auth/logout", {
            withCredentials: true,
            params: {
                '.rnd': Math.ceil(Math.random()*1000000)
            }
        }).then(function(result) {
            //const payload = createPayloadFromAnswer({});
            //dispatch({ type: CONST.AUTHENTICATE.SET_USER_INFO, payload: payload});
            requestCurrentUser()(dispatch, getState, args);
        }).catch(() => {
            dispatch({ type: CONST.EMPTY_REDUCER, payload: null });
        });
    }
}

export function authFromSmallLink(contract, finance, contacts) {
    return (dispatch, getState, args) => {
        //dispatch({ type: CONST.AUTHENTICATE.IN_PROCESS, payload: null});
        dispatch({ type: CONST.AUTHENTICATE.SET_BILLING_INFO, payload: {
            contract: contract,
            auth_type: 'lk2',
            ai_phones: contacts.phones,
            ai_mails: contacts.emails
        }});
        dispatch({type: CONST.PAY.FINANCE_DETAIL_SET_DETAIL, payload: {...finance, contract: contract} });
    }
}

export function updateBalance(contract) {
    return (dispatch, getState, args) => {
        axios.get(CONST.API+"/payments/getFinanceDetails", {
            withCredentials: true,
            params: {
                contract: contract,
                balanceOnly: 1,
                '.rnd': Math.ceil(Math.random()*1000000)
            }
        }).then((answer) => {
            const data = answer.data;
            if(data.result === 'success') {
                dispatch({ type: CONST.AUTHENTICATE.UPDATE_BALANCE, payload: {
                    contract: contract,
                    balance: data.data.balance
                }});
            };
        })
    }
}

export function switchActiveContract(contract) {
    return (dispatch, getState, args) => {
        dispatch({type: CONST.SERVICES.PANEL_SERVICES_SET_DATA, payload: [] });
        dispatch({type: CONST.AUTHENTICATE.SWITCH_ACTIVE_CONTRACT, payload: contract});
    }
}