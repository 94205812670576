/**
 * Created by dpopov on 14.07.2017.
 */
import * as CONST from '../../const';
import axios from 'axios';

export function requestServicesList(contract, from) {
    return (dispatch, getState, args) => {
        dispatch({ type: CONST.SERVICES.PANEL_SERVICES_REQUEST_IN_PROCESS, payload: true });

        axios.get(CONST.API+'/services/roster', {
            withCredentials: true,
            params: {
                contract: contract,
//                '.requestFrom': from,
                '.rnd': Math.ceil(Math.random()*1000000)
            }
        }).then(function(result) {
//            console.log(result.data);
            if(result.data.result === 'success') {
                let cumulativeServiceTypes = 0;
                for(let i=0; i<result.data.data.length; i++) {
                    cumulativeServiceTypes = cumulativeServiceTypes | result.data.data[i].Type;
                }
                dispatch({type: CONST.SERVICES.PANEL_SERVICES_SET_DATA, payload: { error: false, errorText: "", services: result.data.data, cumulativeServiceTypes: cumulativeServiceTypes } });
            } else {
                dispatch({type: CONST.SERVICES.PANEL_SERVICES_SET_DATA, payload: { error: true, errorText: result.data.text, services: {} } });
            }
        })
    }
}

export function requestServiceHistoryData(contract, serviceId, serviceType, dateFrom, dateTo, from, count, variant, successfn) {
    return (dispatch, getState, args) => {
        dispatch({ type: CONST.SERVICES.PANEL_SERVICES_INET_REQUEST_HISTORY, payload: true });

        axios.get(CONST.API+'/services/history', {
            withCredentials: true,
            params: {
                contract: contract,
                hcr: serviceId,
                type: serviceType,
                periodStart: dateFrom.format('YYYY-MM-DD'),
                periodEnd: dateTo.format('YYYY-MM-DD'),
                from: from || 1,
                count: count || 50,
                variant: variant || 1,
                '.rnd': Math.ceil(Math.random()*1000000)
            }
        }).then(function(result) {
            const data = result.data;
            if(data.result === 'success') {
                dispatch({ type: CONST.SERVICES.PANEL_SERVICES_INET_SET_HISTORY, payload: data.data });
                if(typeof successfn === 'function') successfn();
            } else {
                dispatch({ type: CONST.SERVICES.PANEL_SERVICES_INET_REQUEST_HISTORY, payload: false });
                dispatch({ type: CONST.NOTIFY.NOTIFY_APPEND_ERROR, payload: data.text});
            }
        });
    }
}

export function requestIPTVParams(contract, hcr) {
    return (dispatch, getState, args) => {
        dispatch({ type: CONST.SERVICES.PANEL_SERVICES_IPTV_REQUEST_DETAILS, payload: true });

        axios.get(CONST.API + '/services/ipTVDetails', {
            withCredentials: true,
            params: {
                contract: contract,
                hcr: hcr,
                '.rnd': Math.ceil(Math.random()*1000000)
            }
        }).then(function(answer) {
            const data = answer.data;

            if(data.result === 'success') {
                dispatch({ type: CONST.SERVICES.PANEL_SERVICES_IPTV_DETAILS_SET, payload: { data: data.data, hcr: hcr }});
            } else {
                dispatch({ type: CONST.SERVICES.PANEL_SERVICES_IPTV_REQUEST_DETAILS, payload: false });
                dispatch({ type: CONST.NOTIFY.NOTIFY_APPEND_ERROR, payload: data.text });
            }
        });

    }
}

export function requestExtras(contract, hcr) {
    return (dispatch, getState, args) => {
        dispatch({ type: CONST.SERVICES.PANEL_SERVICES_REQUEST_EXTRAS, payload: true });

        axios.get(CONST.API + '/services/getExtras', {
            withCredentials: true,
            params: {
                contract: contract,
                hcr: hcr,
                '.rnd': Math.ceil(Math.random()*1000000)
            }
        }).then(function(answer) {
            const data = answer.data;

            if(data.result === 'success') {
                dispatch({ type: CONST.SERVICES.PANEL_SERVICES_EXTRAS_SET, payload: { data: data.data, hcr: hcr } });
            } else {
                dispatch({ type: CONST.SERVICES.PANEL_SERVICES_REQUEST_EXTRAS, payload: false });
                dispatch({ type: CONST.NOTIFY.NOTIFY_APPEND_ERROR, payload: data.text });
            }
        })
    }
}

export function applyExtras(contract, hcr, extras) {
    return (dispatch, getState, args) => {
        dispatch({ type: CONST.SERVICES.PANEL_SERVICES_REQUEST_EXTRAS, payload: true});

        axios.get(CONST.API + '/services/applyExtras', {
            withCredentials: true,
            params: {
                contract: contract,
                hcr: hcr,
                extras: extras,
                '.rnd': Math.ceil(Math.random()*1000000)
            }
        }).then(function(answer) {
            const data = answer.data;
            if(data.result === 'success') {
                dispatch({ type: CONST.SERVICES.PANEL_SERVICES_EXTRAS_SET, payload: { data: data.data, hcr: hcr } });
            } else {
                dispatch({ type: CONST.SERVICES.PANEL_SERVICES_REQUEST_EXTRAS, payload: false });
                dispatch({ type: CONST.NOTIFY.NOTIFY_APPEND_ERROR, payload: data.text });
            }
        })
    }
}

export function setRecommentPayment(sum, contract, force) {
    return (dispatch, getState, args) => {
        dispatch({ type: CONST.SERVICES.SET_RECOMMEND_PAYMENT, payload: { contract, sum, force } });
    }
}