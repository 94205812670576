/**
 * Created by dpopov on 06.09.2017.
 */
import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Link } from 'react-router-dom';

//import ScrollLock from 'react-scrolllock';

import * as authenticateActions from '../../Authenticate/actions';
import * as panelServicesActions from './actions';
import * as CONST from '../../const';

import { getServiceDataFromTree } from './tools';
import { getStyle } from '../../tools';

import Panel, {PanelIsLoading} from '../../Components/Panel';
import Oops from '../../Components/Oops';
import BonusSpeedDisplay from './Components/bonusSpeedDisplay';

//import styleObit from '../../../assets/css/obit.css';
import myStyle from './style.module.css';
import TariffDescDisplay from "./Components/tariffDescDisplay";

import 'moment/locale/ru';
import moment from 'moment';
moment.locale('ru');

class PanelInternetService extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            serviceId: this.props.serviceId,
            internetSettings: false,
            activeContract: this.props.authData.activeContract
        };
    }

    // Получение тарифа
    getServiceData() {
        return getServiceDataFromTree(this.props.servicesData.servicesTree, this.state.serviceId);
        // return getServiceData(this.props.servicesData.services, this.state.serviceId);
    }

    componentWillReceiveProps(nextProps) {
        if(nextProps.authData.activeContract !== this.state.activeContract) {
            this.setState({activeContract: nextProps.authData.activeContract});
            this.props.elementActions.requestServicesList(nextProps.authData.activeContract, 'internetService-ReceiveProps');
        }
    }

    componentDidMount() {
        if(!this.getServiceData()) {
            const activeContract = this.props.authData.activeContract;
            this.setState({activeContract: activeContract});
            this.props.elementActions.requestServicesList(activeContract, 'internetService-DidMount');
        }
    }

    displayInternetParameters = (event) => {
        event.preventDefault();

      /*  const ipTVParams = this.props.servicesData.ipTVParams;
        const ParamsForHCR = this.props.servicesData.ParamsForHCR;

        if(!ipTVParams || ParamsForHCR != this.state.serviceId) this.props.elementActions.requestIPTVParams(this.props.authData.activeContract, this.state.serviceId);*/

        this.setState({ internetSettings: !this.state.internetSettings });
    }

    render() {
        const PanelTitle = this.props.title || "Интернет";
        const loadingState = this.props.servicesData.panelLoadingState;
        const icon = this.props.icon || getStyle('icon-wifi');
        const currentService = this.getServiceData();
        const isPanelForNextRule = this.props.nextRule;

        let
            prevTarif = null,
            self = this;

        if(currentService.isStop) {
            let iterator = currentService;
            // Пройдёмся в будущее
            while(iterator.__next) {
                iterator = iterator.__next;
                if(!iterator) break;
                if(iterator.isStop) continue;

                prevTarif = iterator;
                break;
            }
            if(!prevTarif) {
                iterator = currentService;
                while (iterator.__prev) {
                    iterator = iterator.__prev;
                    if(!iterator) break;
                    if(iterator.isStop) continue;

                    prevTarif = iterator;
                    break;
                }
            }
        }

        // console.log(prevTarif);
//        console.log('!!!', currentService);

        const active2035 = currentService ? moment(currentService.dateStop).diff(CONST.DEFAULT_TARIFF_END_DATE, 'days') === 0 : false;
        const serviceDateForm = currentService ? moment(currentService.dateStart).format('DD.MM.YYYY') : '00.00.0000';
        const serviceDateTo = currentService ? moment(currentService.dateStop).format('DD.MM.YYYY') : '00.00.0000';

        const bonusSpeedApplied = moment().isAfter(moment('2018-10-01'));

        return (
            <div>
                {!currentService ?
                    <Panel title={PanelTitle} icon={icon} isLoading={loadingState}>
                        {!loadingState ? <Oops /> : null }
                    </Panel>
                    :
                    <Panel title={isPanelForNextRule? PanelTitle + ' c ' + serviceDateForm : PanelTitle} icon={icon} isLoading={loadingState}
                           className={[myStyle['force-overflow-y-visible']].join(' ')} headerClassName={getStyle('b-header-z-0')}>
                        <table className={
                            currentService.isAb ? getStyle('line v-top left-pull')
                                : getStyle('line v-mid left-pull')}>
                            <tbody>
                            <tr>
                                <td rowSpan={currentService.isAb?2:null}>{isPanelForNextRule ? "Следующий тариф" : "Текущий тариф"}</td>
                                <td>
                                    {currentService.name}
                                    { !currentService.isStop && (currentService.Type & CONST.SERVICE_TYPES.INET) && currentService.anons ?
                                        <TariffDescDisplay anons={currentService.anons} ruleId={currentService.tariff} hasDesc={currentService.hasDesc} />
                                    : null }
                                </td>
                                <td className={getStyle('t-right')}>
                                    {currentService.ways
                                        //<Link to={CONST.ROOT_PATH+"/service-changetariff."+this.state.serviceId+".html"} className={style["button"]}>Аннулировать</Link>
                                        ? currentService.isAb
                                            ? (currentService.abways ? <Link to={CONST.ROOT_PATH+"/service-changetariff."+this.state.serviceId+".html"} className={getStyle('button')}>Повысить скорость</Link> : null)
                                            : <Link to={CONST.ROOT_PATH+"/service-changetariff."+this.state.serviceId+".html"} className={getStyle('button')}>Сменить тариф</Link>
                                        : null
                                    }
                                </td>
                            </tr>
                            {prevTarif ?
                                <tr>
                                    <td>
                                        {prevTarif.dateStart.isBefore(currentService.dateStart) ? "Тариф до приостановки" : "Тариф после приостановки"}
                                    </td>
                                    <td>
                                        {prevTarif.name}
                                    </td>
                                    <td></td>
                                </tr>
                            :null}
                            {currentService.isAb ?
                                [
                                    <tr key="1">
                                        <td className={ getStyle('no-pull') }>Стоимость абонемента <strong>{Math.ceil(currentService.cost * 100) / 100}</strong> руб/мес </td>
                                        <td className={ getStyle('t-right') }></td>
                                    </tr>,
                                    //<tr key="2">
                                    //    <td className={ getStyle('no-pull')}>Действует до <strong>{moment(currentService.dateStop).format('DD.MM.YYYY')}</strong></td>
                                    //</tr>,
                                    //currentService.nextRule ?
                                    //    <tr key="3">
                                    //        <td className={getStyle('no-pull')} colSpan="2">
                                    //            <p>Тариф по окончанию <strong>{currentService.nextRule.name}</strong></p>
                                    //            {currentService.nextRule.isAb ?
                                    //                <Link to={CONST.ROOT_PATH+"/service-changenexttariff."+currentService.nextRule.id+".html"} className={getStyle('button')}>Сменить тариф</Link>
                                    //                :
                                    //                <Link to={CONST.ROOT_PATH+"/service-applynextab."+currentService.nextRule.id+".html"} className={getStyle('button')}>Подключить новый абонемент после этого</Link>
                                    //            }
                                    //        </td>
                                    //    </tr>
                                    // : <tr key="3"><td></td></tr>
                                ]
                                :
                                [
                                <tr key="1">
                                    {prevTarif ?
                                        (
                                            prevTarif.dateStart.isBefore(currentService.dateStart) ?
                                                <td rowSpan={prevTarif.abways?2:1}>Стоимость тарифа до приостановки</td>
                                                :
                                                <td rowSpan={prevTarif.abways?2:1}>Стоимость тарифа после приостановки</td>
                                        )
                                        : <td rowSpan={currentService.abways?2:1}>Стоимость тарифа в месяц</td>
                                    }
                                    {prevTarif ?
                                        (prevTarif.isAb ?
                                                <td className={ getStyle('no-pull') }>Абонемент <strong>{Math.ceil(prevTarif.cost * 100) / 100}</strong> руб/мес </td>
                                                :
                                                <td><strong>{Math.ceil(prevTarif.cost*100)/100}</strong> рублей при оплате ежемесячно</td>
                                        )
                                        : <td><strong>{Math.ceil(currentService.cost*100)/100}</strong> рублей при оплате ежемесячно</td>
                                    }
                                    <td></td>
                                </tr>,
                                currentService.abways ?
                                    <tr key="2">
                                        <td className={getStyle('no-pull')}>
                                            <span
                                                className={getStyle('t-mute')}>от <strong>{currentService.minabcost}</strong> рублей при подключении абонемента</span>
                                        </td>
                                        <td className={getStyle('t-right')}>
                                            <Link to={CONST.ROOT_PATH+"/service-applyab."+this.state.serviceId+".html"} className={getStyle('button')}>Подключить
                                                абонемент</Link>
                                        </td>
                                    </tr>
                                    : null,

                                    prevTarif && prevTarif.abways ?
                                        <tr key="2.prev">
                                            <td className={getStyle('no-pull')}>
                                            <span
                                                className={getStyle('t-mute')}>от <strong>{prevTarif.minabcost}</strong> рублей при подключении абонемента</span>
                                            </td>
                                            <td className={getStyle('t-right')}>
                                                <Link to={CONST.ROOT_PATH+"/service-applyab."+prevTarif.id+".html"} className={getStyle('button')}>Подключить
                                                    абонемент</Link>
                                            </td>
                                        </tr>
                                        : null

                                ]}
                            {!isPanelForNextRule && currentService.speed ?
                                [<tr key={1}>
                                    <td rowSpan={2} className={getStyle('v-mid-force') }>Скорость интернет</td>
                                    {bonusSpeedApplied ?
                                    <td>
                                        {currentService.speed + currentService.bonusSpeed} Мбит/с
                                    </td>
                                    :
                                    <td>
                                        {currentService.speed} Мбит/с, c 1 октября {currentService.speed + currentService.bonusSpeed} Мбит/с
                                    </td>
                                    }
                                    <td className={getStyle('t-right')} />
                                </tr>,
                                <tr key={2}>
                                    {/*<td className={getStyle('no-pull')}>Тарифная {currentService.speed} Мбит/с, бонусная {currentService.bonusSpeed} Мбит/с <Link to={CONST.ROOT_PATH + '/bonus-speed.' + currentService['id'] + '.html'} className={[getStyle('button'), myStyle['help-me']].join(' ')}>?</Link></td>*/}
                                    <td className={getStyle('no-pull')}>
                                        Тарифная {currentService.speed} Мбит/с
                                        <BonusSpeedDisplay bonusSpeed={currentService.bonusSpeed} ruleId={currentService.id} />
                                    </td>
                                    <td className={getStyle('t-right')} />
                                </tr>
                                ]
                                : null}

                            {active2035 ?
                                <tr>
                                    <td>Действует с</td>
                                    <td>
                                        <strong>{serviceDateForm}</strong>
                                    </td>
                                    <td className={getStyle('t-right')}></td>
                                </tr>
                                :
                                <tr>
                                    <td>Период действия</td>
                                    <td>
                                        c <strong>{serviceDateForm}</strong>
                                        &nbsp;до <strong>{serviceDateTo}</strong>
                                    </td>
                                </tr>
                            }
                            </tbody>
                        </table>
                        {!isPanelForNextRule && currentService['settings'] && currentService['settings']['ip'] && currentService['settings']['ip'][0] ?
                            <React.Fragment>
                                <div className={[getStyle('b-row'), self.state.internetSettings?getStyle('gray b-border'):null].join(' ')} key="2">
                                    <button type="button" className={[getStyle('btn-dropdown'), self.state.internetSettings?getStyle('active'):null].join(' ')}
                                            onClick={self.displayInternetParameters}>Настройки</button>
                                </div>

                                <div className={[getStyle('dropdonw-block gray'), self.state.internetSettings?null:getStyle('hide')].join(' ')} key="3">
                                        <table className={getStyle('line v-top left-pull')}>
                                            <tbody>
                                            <tr>
                                                <td>Настройки</td>
                                            </tr>
                                            <tr>
                                                <td>IP</td>
                                                <td>MASK</td>
                                                <td>Gateway</td>
                                                <td>DNS1</td>
                                                <td>DNS2</td>
                                            </tr>
                                            <tr>
                                                <td>{currentService['settings']['ip'][0]['ip']}</td>
                                                <td>{currentService['settings']['ip'][0]['mask']}</td>
                                                <td>{currentService['settings']['ip'][0]['gw']}</td>
                                                <td>{currentService['settings']['ip'][0]['dns'][0]}</td>
                                                <td>{currentService['settings']['ip'][0]['dns'][1]}</td>
                                            </tr>
                                            </tbody>
                                        </table>
                                </div>
                            </React.Fragment>
                            : null}
                    </Panel>
                }
                {this.props.children}
            </div>
        );
    }
}

function mapStateToProps(state) {
    return {
        authData: state.authenticate,
        servicesData: state.panelServices
    }
}

function mapDispatchToProps(dispatch) {
    return {
        elementActions: bindActionCreators(panelServicesActions, dispatch),
        authenticate: bindActionCreators(authenticateActions, dispatch)
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(PanelInternetService);