/**
 * Created by dpopov on 11.10.2017.
 */
import axios from 'axios';

import * as CONST from '../../const';

export function getAddresses(contract) {
    return (dispatch, getState, args) => {
        dispatch({ type: CONST.SUPPORT.REQUEST_ADDRESSES, payload: true });

        axios.get(CONST.API+"/lkcontract/addresses", {
            withCredentials: true,
            params: {
                contract: contract,
                '.rnd': Math.ceil(Math.random()*1000000)
            }
        }).then((result) => {
            const data = result.data;

            if(data.result === 'success') {
                dispatch({ type: CONST.SUPPORT.SET_ADDRESSES, payload: { contract: contract, addresses: data.data, error: null }});
            } else {
                dispatch({ type: CONST.SUPPORT.SET_ADDRESSES, payload: { contract: contract, addresses: {}, error: data.text } });
            }
        })
    }
}

export function getTicketsList(contract, skip) {
    return (dispatch, getState, args) => {
        dispatch({ type: CONST.SUPPORT.REQUEST_TICKETS_LIST, payload: true });

        axios.get(CONST.API+"/support/getList", {
            withCredentials: true,
            params: {
                contract: contract,
                skip: skip,
                '.rnd': Math.ceil(Math.random()*1000000)
            }
        }).then((result) => {
            const data = result.data;

            if(data.result === 'success') {
                dispatch({ type: CONST.SUPPORT.SET_TICKETS_LIST, payload: { contract: contract, list: data.data, error: null}});
            } else {
                dispatch({ type: CONST.SUPPORT.SET_TICKETS_LIST, payload: { contract: contract, list: null, error: data.text }});
            }
        });
    }
}