import * as CONST from '../../const';
import axios from 'axios';

export function request(type, limit, data, callback, filter) {
    return (dispatch, getState, args) => {

        let postParams = new FormData();

        postParams.append('type', type);
        postParams.append('limit', JSON.stringify(limit));
        postParams.append('data', JSON.stringify(data));
        postParams.append('filter', JSON.stringify(filter));
        axios
            .post(CONST.API_HOTEL + '/hotel/doRequest', postParams, {withCredentials: true,})
            .then((response) => {

                if (response.data.result === 'success') {

                    let data = JSON.parse(response.data.data);
                    let args;

                    if (type === 5) {
                        args = data ? true : false;
                    }
                    dispatch({
                        type: CONST.HOTEL.HOTEL_REQUEST_LIST_SET_DATA, payload: {
                            items: data.data,
                            total: data['ALL'],
                        }
                    });

                    if (typeof callback === 'function') callback(args);
                }
            });
    }
}

export function setNewPage(page) {
    return (dispatch, getState, args) => {
        dispatch({type: CONST.HOTEL.HOTEL_SET_DISPLAY_PAGE, payload: {page: page}});
    }
}

export function setNewLimit(limit) {
    return (dispatch, getState, args) => {
        dispatch({type: CONST.HOTEL.HOTEL_SET_LIMIT_COUNT, payload: {limit: limit}});
    }
}

export function getListRoom() {
    return (dispatch, getState, args) => {

        let postParams = new FormData();

        axios
            .post(CONST.API_HOTEL + '/hotel/getListRoom', postParams, {withCredentials: true,})
            .then((response) => {

                if (response.data.result === 'success') {

                    let rooms = JSON.parse(response.data.data['list']);
                    let booked = JSON.parse(response.data.data['booked']);
                    rooms = rooms.map(function (room) {
                        return {value: room, label: room};
                    });

                    dispatch({type: CONST.HOTEL.HOTEL_SET_LIST_ROOMS, payload: {listRoom: rooms, booked: booked}});
                }
            });
    }
}

export function addRoom(room, callback) {
    return (dispatch, getState, args) => {

        let postParams = new FormData();

        postParams.append('room', room);

        axios
            .post(CONST.API_HOTEL + '/hotel/addRoom', postParams, {withCredentials: true,})
            .then((response) => {

                if (response.data.result === 'success') {

                    if (typeof callback === 'function') callback(args);
                }
            });
    }
}

export function loadNewListRoom(fileListRoom, callback) {
    return (dispatch, getState, args) => {

        let postParams = new FormData();

        postParams.append('fileListRoom', fileListRoom);

        axios
            .post(CONST.API_HOTEL + '/hotel/loadNewListRoom', postParams, {withCredentials: true,})
            .then((response) => {

                if (response.data.result === 'success') {

                    if (typeof callback === 'function') callback(args);
                }
            });
    }
}