/**
 * Created by dpopov on 15.08.2017.
 */
import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as PanelNewsActions from './actions';
import Panel from '../../Components/Panel/promoPanel';
import { getStyle } from "../../tools";

import PanelPromos from '../PanelPromos';

import newsStyle from './style.module.css';

class viewItem extends React.Component {
    componentWillMount() {
        const newsItems = this.props.state.newsData;
        const newsId = this.props.newsId;

        if(!newsItems[newsId]) this.props.elementActions.getNewsItem(newsId);
    }

    render() {
        const newsId = this.props.newsId;
        const newsData = this.props.state.newsData;
        const loadingState = this.props.state.itemLoadingState[newsId];
        let Title = "";

        // console.log(this.props.state.itemLoadingState[newsId]);

        let content = null;

        if(!loadingState) {
            const itemData = newsData[newsId];

            Title = itemData['Title'];
            content = <div dangerouslySetInnerHTML={{ __html: itemData['Body'] }}></div>;
           // console.log(itemData);
        }

        return (
            <div>
                <Panel title={Title} headerClassName={getStyle('small')} isLoading={loadingState} key="1" className={newsStyle['newsFixLinks']}>
                    {content}
                </Panel>
                <PanelPromos key="2" />
            </div>
        );
    }
}

function mapStateToProps(state) {
    return {
        state: state.panelNews
    }
}

function mapDispatchToProps(dispatch) {
    return {
        elementActions: bindActionCreators(PanelNewsActions, dispatch)
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(viewItem);