/**
 * Created by dpopov on 07.11.2017.
 */
import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import axios from 'axios';

import Row from '../../Components/Row';

import {getStyle, processInputHtmlStaticPage} from '../../tools';

import * as PanelActions from './actions';
import * as CONST from '../../const';

import Card from './card';

class PanelPromos extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            loadingState: false,
            cards: [],
            activeContract: null
        }
    }

    componentDidMount() {
        this.requestPromoCards(this.props.authData.activeContract);
    }

    componentWillReceiveProps(nextProps) {
        if(nextProps.authData.activeContract !== this.state.activeContract && this.state.loadingState === false) this.requestPromoCards(nextProps.authData.activeContract);
    }

    requestPromoCards(contract) {
        const self = this;
        //const contract = this.props.authData.activeContract;

        this.setState({loadingState: true, activeContract: contract});

        axios.get(CONST.API+'/adv/getActivePromo', {
            withCredentials: true,
            params: {
                contract: contract,
		        '.rnd': Math.ceil(Math.random()*1000000)
            }
        }).then((result) => {
            const data = result.data;
            if(data.result === 'success') {
                self.setState({loadingState: false, cards: data.data });
            } else {
                self.setState({loadingState: false, cards: [] });
            }
        });
    }

    render() {
        // const loadingState = this.state.loadingState;
        const cards = this.state.cards;
        const servicesTree = this.props.servicesData.servicesTree;

        if(cards.length === 0) return null;

        return (
            <Row className={getStyle('cards-row')}>
                <div className={getStyle('card-box')}>
                    {cards.map((card, _idx) => {
                        return <Card key={_idx} title={card.card_title ? card.card_title : card.title} name={card.name}
                                     redirect_int={processInputHtmlStaticPage(card.redirect_int, servicesTree, this.props.authData, {})}
                                     redirect_ext={card.redirect_ext} titleStyle={card.titlestyle} />
                    })}
                </div>
            </Row>
        );
    }
}

function mapStateToProps(state) {
    return {
        authData: state.authenticate,
//      state: state.panelPay
        servicesData: state.panelServices
    }
}

function mapDispatchToProps(dispatch) {
    return {
        elementActions: bindActionCreators(PanelActions, dispatch)
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(PanelPromos);
