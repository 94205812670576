/**
 * Created by dpopov on 24.08.2017.
 */
import * as CONST from '../../const';

export function clearLastNotify(notify) {
    return (dispatch, getState, args) => {
        if(notify.length) notify = notify.slice(-1, 1);

        dispatch({type: CONST.NOTIFY.NOTIFY_SET_DATA, payload: notify});
    }
}

export function clearLastError() {
    return { type: CONST.NOTIFY.NOTIFY_POP_ERROR, payload: null }
}