import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as authenticateActions from "./authenticate.actions";

class Interface extends React.Component {

    componentWillMount() {
        this.props.authenticate.checkStatus();
    }

    render() {

        // console.log(this.props);

        return <div>
            {this.props.children}
        </div>;
    }
}

function mapStateToProps(state) {
    return {
        state: state
    }
}

function mapDispatchToProps(dispatch) {
    return {
        elementActions: null,
        authenticate: bindActionCreators(authenticateActions, dispatch)
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Interface);