import React from 'react';

import { getStyle } from '../../../tools';
import style from './style.module.css';

import 'moment/locale/ru';
import moment from 'moment';
moment.locale('ru');

export default function(props) {
    if (!props.items) return null;
    return (
        <table className={[getStyle('line v-top left-pull'), style['ph-stat-v1-table']].join(' ')}>
            <thead>
            <tr>
                <th>Дата</th>
                <th>Вызов</th>
                <th>C/На номер</th>
                <th>Длительность (мин)</th>
                <th>Стоимость</th>
                <th>Направление</th>
            </tr>
            </thead>
            <tbody>
            {props.items.map((item, _i) => {
                const Number = item.CallType === 'IN' ? item.FromNumber : item.ToNumber;
                return (<tr key={_i}>
                    <td>{moment(item.StartDate).format('DD.MM.YYYY hh:mm:ss')}</td>
                    <td>{item.CallType === 'IN' ? 'Входящий' : 'Исходящий'}</td>
                    <td>{Number.match(/^[78]?\d{10}$/) ?
                                Number.replace(/^.*(\d{3})(\d{7})$/, '+7 ($1) $2')
                        : Number}</td>
                    <td>{item.CalcDuration}</td>
                    <td>{item.CalcPrice}</td>
                    <td>{item.DirectionGroupName}</td>
                </tr>);
            })}
            </tbody>
        </table>
    );
}