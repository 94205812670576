/**
 * Created by dpopov on 19.07.2017.
 */
import React from 'react';
import ChapterBilling from './index';

import PanelPayBonus from '../../Panels/PanelPayBonus';

export default (props) => {
    return (
        <ChapterBilling>
            <PanelPayBonus />
        </ChapterBilling>
    );
}