/**
 * Created by dpopov on 14.11.2017.
 */
/* eslint no-useless-escape: ["off"] */
/* eslint default-case: ["off"] */
import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import * as AuthActions from './actions';

import PayFrame from '../../pay-frame';
import PageRate from '../Pages/PageRate';
import ChapterSLink from '../Chapters/ChapterSLink';

import Panel from '../Components/Panel';

import * as CONST from '../const';
import axios from 'axios';
import {getStyle} from "../tools";

class CheckSmallLink extends React.Component {
    constructor(props, context) {
        super(props, context);

        this.state = {
            loadingState: false,
            linkType: 'unknown',
            linkData: null,
            context: context,
            path: null,
            error: null,
            balance:null
        };
    }

    componentDidMount() {
        this.getLinkType();
    }

    getLinkType() {
        const self = this;
        const path = this.props.location ?
            this.props.location.pathname.replace(CONST.ROOT_PATH, '').replace(/^([^\/]+).*/, '-$1-')
            :
            this.props.state.router.location.pathname.replace(CONST.ROOT_PATH, '').replace(/^([^\/]+).*/, '-$1-');
        if(!path) return;

//        console.log(path.replace(/^(\/[^\/]+).*/, '$1'));

        this.setState({loadingState: true, linkType: 'unknown', path: null});
        
//        axios.get(CONST.API + '/smalllinks/getLinkType', {
        axios.get(CONST.LINK_TYPE_API + '/smalllinks/getLinkType', {
            withCredentials: true,
            credentials: 'same-origin',
            crossDomain: true,
            params: {
                link: path,
		        '.rnd': Math.ceil(Math.random()*1000000)
            }
        }).then((answer) => {
            const data = answer.data;
            if(data.result === 'success') {
                if(data.data.linkType === 'pay' && data.data.pay.actual) {
                    let contacts = data.data.phone && data.data.email ? {phones:data.data.phone, emails:data.data.email} : [];
                    self.props.authActions.authFromSmallLink(data.data.pay.contract, data.data.pay.finance, contacts);
                    self.setState({
                        loadingState: false,
                        balance:data.data.pay.balance
                    });
                    console.log(this.state);

                }

                let linkData = {};
                switch(data.data.linkType) {
                    case 'pay': linkData = data.data.pay; break;
                    case 'rate': linkData = data.data.rate; break;
                    case 'slink': linkData = data.data.slink; break;
                }

                self.setState({
                    loadingState: false, linkType: data.data.linkType,
                    linkData: linkData,
                    path: path.replace(/\/(.+)/, '$1')
                })
            } else {
                self.setState({loadingState: false, linkType: 'unknown'})
            }
        }).catch((error) => {
            this.setState({loadingState: false, error: 'Ошибка получения данных с сервера'})
            //self.setState({loadingState: false, linkType: 'unknown'})
        });
//        console.log(path);

    }

    render() {
        let content = null;

        switch(this.state.linkType) {
            case 'pay':
                let title = 'Пополнениние договора по короткой ссылке';
                content = <PayFrame smallLink={this.state.path} title={title} prioritetBalance={this.state.balance} originalState={this.props.state} />;

                break;
            case 'rate':
                if(this.state.linkData.rateType === 'rt') {
                    content = <PageRate ticket={this.state.linkData.ticket} linkSrc={this.state.linkData.linkSrc} smallLink={this.state.path} rateType="rt" contract={this.state.linkData.contract} />
                } else if(this.state.linkData.rateType === 'connect') {
                    content = <PageRate ticket={this.state.linkData.ticket} linkSrc={this.state.linkData.linkSrc} smallLink={this.state.path} rateType="ht" />
                }

                break;
            case 'slink':
                content = <ChapterSLink slinkData={this.state.linkData} />;
                break;
        }

        if(this.state.error) {
            return (
                <Panel title={"Ошибка #CSL12956"}>
                    <div className={getStyle('b-row')}>
                        {this.state.error}
                    </div>
                </Panel>
            )
        }

        return (
            <div>
                { content ? content :
                    this.state.loadingState ? <Panel stripTitle={true}><h3>Проверяем...</h3></Panel> :
                    this.props.children
                }
            </div>
        );
    }
}

function mapStateToProps(state) {
    return {
        state: state
    }
}

function mapDispatchToProps(dispatch) {
    return {
        authActions: bindActionCreators(AuthActions, dispatch)
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(CheckSmallLink);