/**
 * Created by dpopov on 19.07.2017.
 */
/* eslint eqeqeq: ["off", "smart"] */
import React from 'react';

import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { getStyle } from "../../tools";

import Panel from '../../Components/Panel';
import Paginator from '../../Components/Panel/paginator';

import * as PanelActions from './actions';

import 'moment/locale/ru';
import moment from 'moment';
moment.locale('ru');

const PERIODS = {
    CURRENT_MONTH: 1,
    PREV_MONTH: 2,
    CURRENT_YEAR: 3,
    ALL_TIME: 4
};

class PanelPayHistory extends React.Component {
    constructor(props) {
        super(props);

        this.switchPeriod = this.switchPeriod.bind(this);
        this.changeDisplayLimit = this.changeDisplayLimit.bind(this);
        this.changeDisplayPage = this.changeDisplayPage.bind(this);
    }

    _displayPeriodType(date) {

        if(date.startOf('month').format() == moment().startOf('month').format()) return PERIODS.CURRENT_MONTH;
        if(date.startOf('month').format() == moment().subtract(1, 'months').startOf('month').format()) return PERIODS.PREV_MONTH;
        if(date.startOf('day').format('YYYY MM') == moment().subtract(1, 'year').startOf('day').format('YYYY MM')) return PERIODS.CURRENT_YEAR;

        return PERIODS.ALL_TIME;
    }

    switchPeriod(event) {
        event.preventDefault();

        const authData = this.props.authData;
        const state = this.props.state;
        const activePeriod = this._displayPeriodType(state.periodStart);

        const periodType = parseInt(event.target.getAttribute('data-type'));

        if(activePeriod == periodType) return;

        let periodStart = null;
        let periodEnd = null;

        switch(periodType) {
            case PERIODS.CURRENT_MONTH:
                periodStart = moment().startOf('month').format();
                periodEnd = moment().endOf('month').format();

                break;
            case PERIODS.PREV_MONTH:
                periodStart = moment().subtract(1, 'months').startOf('month').format();
                periodEnd = moment().subtract(1, 'months').endOf('month').format();

                break;
            case PERIODS.CURRENT_YEAR:
               // console.log(1);
                periodStart = moment().subtract(1, 'year').startOf('day').format();
                periodEnd = moment().endOf('year').format();

                break;
            default:
                periodStart = moment().subtract(100, 'year').startOf('day').format();
                periodEnd = moment().endOf('year').format();

                break;
        }

        this.props.elementActions.getPayments(authData.activeContract,
            periodStart, periodEnd, state.limitCount, state.limitFrom
        );
    }

    componentWillMount() {
        const state = this.props.state;
        const authData = this.props.authData;

        if(state.payments.length == 0) {
            this.props.elementActions.getPayments(
                authData.activeContract,
                moment().startOf('month').format(), moment().endOf('month').format(),
                state.limitCount, state.limitFrom
            );
        }
    }

    changeDisplayPage(event) {
        event.preventDefault();

        const authData = this.props.authData;
        const state = this.props.state;

        const newFrom = event.target.getAttribute('data-from');

        if(state.limitFrom == newFrom) return;

        this.props.elementActions.getPayments(
            authData.activeContract,
            state.periodStart.format(), state.periodEnd.format(),
            state.limitCount, newFrom
        );
    }

    changeDisplayLimit(event) {
        event.preventDefault();

        const authData = this.props.authData;
        const state = this.props.state;

        const newLimit = event.target.getAttribute('data-count');

        if(newLimit == state.limitCount) return;

        let newFrom = 0;

        this.props.elementActions.getPayments(
            authData.activeContract,
            state.periodStart.format(), state.periodEnd.format(),
            newLimit, newFrom
        );
    }

    render() {
        const panelTitle = this.props.title || "История платежей";
        const state = this.props.state;

        const loadingState = state.panelLoadingState;

        let content = null;

        if(!loadingState) {
            const activePeriod = this._displayPeriodType(state.periodStart);

            const periodRow = (
                <div className={getStyle('b-row gray')}>
                    <div className={getStyle('f-left')}>
                        <button type="button" className={activePeriod==PERIODS.CURRENT_MONTH?getStyle('active'):null} data-type={PERIODS.CURRENT_MONTH} onClick={this.switchPeriod}>За этот месяц</button>
                        <button type="button" className={activePeriod==PERIODS.PREV_MONTH?getStyle('active'):null} data-type={PERIODS.PREV_MONTH} onClick={this.switchPeriod}>Предыдущий месяц</button>
                        <button type="button" className={activePeriod==PERIODS.CURRENT_YEAR?getStyle('active'):null} data-type={PERIODS.CURRENT_YEAR} onClick={this.switchPeriod}>Этот год</button>
                    </div>
                </div>
            );

            const payments = [];

            for(var p=0;p<state.payments.length;p++) {
                payments.push(<tr key={"td-payment-"+p}>
                    <td>{state.payments[p].displayDate}</td>
                    <td>{state.payments[p].Amount}</td>
                    <td>{state.payments[p].DocumentType}</td>
                    <td>Пополнение баланса по договору {state.payments[p].ContractNumber}</td>
                </tr>);
            }

            if(!payments.length) payments.push(<tr key="td-1"><td colSpan="4">За выбранный период платежей не совершалось</td></tr>);

            content = (<div>
                {periodRow}
                <table className={getStyle('line v-top left-pull')}>
                    <thead>
                        <tr>
                            <th>Дата</th>
                            <th>Сумма</th>
                            <th>Метод оплаты</th>
                            <th>Комментарий</th>
                        </tr>
                    </thead>
                    <tbody>{payments}</tbody>
                </table>
                <Paginator currentLimit={state.limitCount} displayFrom={state.limitFrom} totalCount={state.total} changeDisplayPage={this.changeDisplayPage} changeLimitClick={this.changeDisplayLimit} />
            </div>);
        }

        return (
            <Panel title={panelTitle} icon={getStyle('icon-rub')} isLoading={loadingState}>
                {content}
            </Panel>
        );
    }
}

function mapStateToProps(state) {
    return {
        state: state.panelPayHistory,
        authData: state.authenticate
//        services: state.panelServices
//        servicesData: state.panelServices
    }
}

function mapDispatchToProps(dispatch) {
    return {
        elementActions: bindActionCreators(PanelActions, dispatch)
//        authenticate: bindActionCreators(SubNavBlock, dispatch)
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(PanelPayHistory);