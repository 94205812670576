/**
 * Created by dpopov on 06.09.2017.
 */
/*eslint default-case: ["off"]*/

import React from 'react';

import ChapterServices from './index'

import Panel from '../../Components/Panel';
import Oops from '../../Components/Oops';

import PanelInternetService from '../../Panels/PanelServices/internetService';
import PanelInternetStatistics from '../../Panels/PanelServices/InternetStatistics';

import PanelChangeTariff from '../../Panels/PanelServices/changeTariff';
import PanelApplyAbonement from '../../Panels/PanelServices/applyAbonement';

import PanelTVService from '../../Panels/PanelServices/tv';
import PanelTVInstructions from '../../Panels/PanelServices/tvInstructions';
import PanelTVExtras from '../../Panels/PanelServices/tvExtras';

import PanelPhone from '../../Panels/PanelServices/phone';
import PanelPhoneDirectionCost from '../../Panels/PanelServices/phoneDirectionCost';
import PanelPhoneDirectionList from '../../Panels/PanelServices/phoneDirectionList';

import CheckRoster from '../../Panels/PanelServices/CheckRoster';

import { getStyle } from '../../tools';
import { getServiceData } from '../../Panels/PanelServices/tools';

import 'moment/locale/ru';
import moment from 'moment';
moment.locale('ru');

export default function(props) {
        return (
            <CheckRoster from={"pageService"}>
                <PageService {...props} />
            </CheckRoster>
        );
}

class PageService extends React.Component {

    getActiveChargeRule(AppServiceID) {
        const servicesTree = this.props._state.panelServices.servicesTree;
        const today = moment();

        for(let app_i = 0; app_i < servicesTree.length; app_i++) {
            const App = servicesTree[app_i];
            if(App.services && App.services[AppServiceID]) {
                const AppServiceRules = App.services[AppServiceID];

                let Rule = AppServiceRules;
                while(Rule) {
                    if(today.isBetween(Rule.dateStart, Rule.dateStop)) return Rule;
                    Rule = Rule.__next;
                }
            }
        }

        return null;
    }

    render() {
        const props = this.props;
        const serviceType = props.match.params.serviceType;
        const serviceId = props.match.params.serviceId;

//    console.log(serviceType);
        let content = null;

        switch (serviceType) {
            case 'inet': {
//                console.log(props._state.panelServices);
                const servicesTree = props._state.panelServices.servicesTree;
                const today = moment();
                let activeChargeRuleID = null;

                // в serviceId у нас AppServiceID. найдём услугу.
                for(let app_i = 0; app_i < servicesTree.length; app_i++) {
                    const App = servicesTree[app_i];
                    if(App.services && App.services[serviceId]) {
                        const appServiceRules = App.services[serviceId];
                        content = [];

                        // appServiceRules у нас правила последовательно их действию
                        let Rule = appServiceRules;
                        while(Rule) {
                            if(Rule.dateStop.isAfter(today)) {
                                const isNextRule = !today.isBetween(Rule.dateStart, Rule.dateStop);
                                if(!isNextRule) activeChargeRuleID = Rule.id;
                                content.push(
                                    <PanelInternetService serviceId={Rule.id} key={Rule.id} nextRule={isNextRule}
                                                          title={isNextRule?"Запланированный тариф":null}
                                                          icon={isNextRule?getStyle('calendar'):null}
                                    />
                                );
                            }
                            Rule = Rule.__next;
                        }
                        break;
                    }
                }
                if(activeChargeRuleID) content.push(<PanelInternetStatistics serviceId={activeChargeRuleID} chooserOnly={true} key={'internetStatistics'} />);
            } break;
            case 'inet1' :
            {
               // console.log(props._state.panelServices);
                const services = props._state.panelServices.services;
                const serviceData = getServiceData(services, serviceId);
                let keyIndex = 0;

                content = [
                    <PanelInternetService serviceId={serviceId} key={keyIndex++} />];

                let nextRule = serviceData ? serviceData.nextRule : null;
                while (nextRule) {
                    content.push(<PanelInternetService serviceId={nextRule.id} key={keyIndex++} nextRule={true}
                                                       title="Запланированный тариф" icon={getStyle('calendar')}/>);
                    nextRule = getServiceData(services, nextRule.id);
                    if (!nextRule.nextRule) break;
                    nextRule = nextRule.nextRule;
                }

                content.push(<PanelInternetStatistics serviceId={serviceId} chooserOnly={true} key={keyIndex++} />);
            }
                break;
            case 'changetariff':
                content = (<PanelChangeTariff serviceId={serviceId} serviceIdIs="serviceId"/>);
                break;
            case 'applyab':
                content = (<PanelApplyAbonement serviceId={serviceId} serviceIdIs="serviceId"/>);
                break;
            case 'changenexttariff':
                content = (<PanelChangeTariff serviceId={serviceId} serviceIdIs="serviceId"/>);
                break;
            case 'applynextab':
                content = (<PanelApplyAbonement serviceId={serviceId} serviceIdIs="serviceId"/>);
                break;
            case 'tv': {
                const HCR = this.getActiveChargeRule(serviceId);
                if(HCR) content = [
                    <PanelTVService serviceId={HCR.id} key="1"/>,
                    <PanelTVInstructions serviceId={HCR.id} key="2"/>,
                    <PanelTVExtras serviceId={HCR.id} key="3"/>
                ];
            } break;
            case 'phone': {
                const servicesTree = props._state.panelServices.servicesTree;
                const today = moment();
                let activeChargeRuleID = null;

                for(let app_i = 0; app_i < servicesTree.length; app_i++) {
                    const App = servicesTree[app_i];
                    if(App.services && App.services[serviceId]) {
                        const appServiceRules = App.services[serviceId];
                        content = [];

                        // appServiceRules у нас правила последовательно их действию
                        let Rule = appServiceRules;
                        while(Rule) {
                            if(Rule.dateStop.isAfter(today)) {
                                const isNextRule = !today.isBetween(Rule.dateStart, Rule.dateStop);
                                if(!isNextRule) activeChargeRuleID = Rule.id;
                                content.push(
                                    <PanelPhone serviceId={Rule.id} key={Rule.id} nextRule={isNextRule}
                                                          title={isNextRule?"Запланированный тариф":null}
                                                          icon={isNextRule?getStyle('calendar'):null}
                                    />
                                );
                            }
                            Rule = Rule.__next;
                        }
                        break;
                    }
                }

                if(content) {
                    content.push(<PanelPhoneDirectionCost serviceId={activeChargeRuleID} key={'PanelPhoneDirectionCost'}/>);
                    content.push(<PanelPhoneDirectionList serviceId={activeChargeRuleID} key={'PanelPhoneDirectionList'}/>);
                }
            } break;
            case 'phone1': {
                const HCR = this.getActiveChargeRule(serviceId);
                if(HCR) content = [
                    <PanelPhone serviceId={HCR.id} key="1"/>,
                    <PanelPhoneDirectionCost serviceId={HCR.id} key="2"/>,
                    <PanelPhoneDirectionList serviceId={HCR.id} key="3"/>
                ];
            } break;
        }

        if(!content) return <Panel stripTitle={true} title={null} icon={null}><Oops/></Panel>;

        return (
            <ChapterServices>
                {content}
            </ChapterServices>
        )
    }
}