import * as CONST from '../../../const';

const initialState = {
    allowed: 0,
    clientFirstName: '',
    hotels: [],
    formInProcess: false
};

export default function AuthenticateReducer(state = initialState, action) {
    switch (action.type) {
        case CONST.EMPTY_REDUCER: return {...initialState};
        case CONST.AUTHENTICATE.IN_PROCESS: {
            return {...state,  process: action.payload }
        }
        case CONST.AUTHENTICATE.FORM_IN_PROCESS: {
            return {...state, formInProcess: action.payload }
        }
        case CONST.AUTHENTICATE.SET_USER_INFO: {
            if(action.payload.redirect) {
                window.location.href = action.payload.redirect;
                return initialState;
            }
            if(action.payload.auth_type !== 'hotels') {
                window.location.href = CONST.ROOT_PATH || '/';
                return {...initialState};
            }
            return {...state,
                allowed: action.payload.access,
                clientFirstName: action.payload.clientFirstName,
                hotels: action.payload.hotels
            }
        }
        default: return state;
    }
}