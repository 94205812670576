/**
 * Created by dpopov on 19.07.2017.
 */
/*eslint eqeqeq: ["off", "smart"]*/
import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';

import Row from '../Row';
import styleObit from '../../../assets/css/obit.module.css';

import { getStyle } from '../../tools';

class SubNavBlock extends React.Component {
    render() {
        const title = this.props.title;
        const items = this.props.items || {};
        const active = this.props.location.pathname;

        const hasPromoImage = this.props.authData.promoBackground && this.props.authData.promoBackground.image ? true : false;

        let menuItems = [];

        for(const key in items) {
            let styles = [ getStyle('button link') ];
            if(key == active) styles.push(getStyle('active'));

            menuItems.push(<Link to={key} className={styles.join(' ')} key={key}>{items[key]}</Link>);
        }

        const rowStyle = 'sub-nav';

        return (
            <Row className={getStyle(rowStyle)}>
                <div className={[getStyle('nav-block'), hasPromoImage ? styleObit['fixSubNavPromo'] : ''].join(' ')}>
                    {title ?
                        <div className={getStyle('title')}>{title}</div>
                        : null
                    }
                    {menuItems}
                </div>
            </Row>
        )
    }
}

function mapStateToProps(state) {
    return {
        location: state.router.location,
        authData: state.authenticate
//        servicesData: state.panelServices
    }
}

function mapDispatchToProps(dispatch) {
    return {
//        elementActions: bindActionCreators(SubNavBlock, dispatch),
//        authenticate: bindActionCreators(SubNavBlock, dispatch)
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(SubNavBlock);