/**
 * Created by dpopov on 19.02.2018.
 */
/* eslint eqeqeq: ["off", "smart"] */
import React from 'react';
import style from './style.module.css';

export default class SeasicknessImage extends React.Component {
    constructor(params) {
        super(params);

        this.lastMouseMoveAction = 0;
        this.lastOrientationAction = 0;

        this.orientation = {
            alpha: null,
            beta: null,
            gamma: null
        };

        this.state = {
            orientation: {...this.orientation}
        };

        this._applyImageInitialStyle = this._applyImageInitialStyle.bind(this);
        this._moveImage = this._moveImage.bind(this);
        this.onMouseMove = this.onMouseMove.bind(this);
        this.onDeviceOrientation = this.onDeviceOrientation.bind(this);
        this.onVideoLoadedMetaData = this.onVideoLoadedMetaData.bind(this);
    }

    _applyImageInitialStyle() {
        if(this.refs.image || this.refs.video) {

            const wh = document.documentElement.clientHeight;
            const ww = document.documentElement.clientWidth;

            //let a = window.innerWidth / window.innerHeight;
            let a =  ww / wh;
            let b = (this.props.srcType !== 'video') ? this.refs.image.naturalWidth / this.refs.image.naturalHeight : this.refs.video.videoWidth / this.refs.video.videoHeight;

            var w = (wh + 100) * b;
            var h = wh + 100;

            let element = this.props.srcType === 'video' ? this.refs.video : this.refs.image;

            if (a >= b) {w = ww + 100;h = (ww + 100) / b;}
            var t = 0, l = 0;
            if (h > wh) t = (wh - h) / 2;
            if (w > ww) l = (ww - w) / 2;

            if(this.props.srcType === 'video') {
                w = w * 1.1;
                h = h * 1.1;
            }

            element.style.width = w + 'px';
            element.style.height = h + 'px';
            element.style.marginTop = t + 'px';
            element.style.marginLeft = l + 'px';

            if(element.style.opacity != 1) {
                let image = element;
                setTimeout(() => {
                    var p="150ms ease-out,opacity .5s ease-in";
                    image.style.webkitTransition=p;
                    image.style.MozTransition=p;
                    image.style.MsTransition=p;
                    image.style.OTransition=p;
                    image.style.transition=p;
                }, 50);
            }

            element.style.opacity = 1;
            this._moveImage(0,0);
        }
    }

    _moveImage(x, y) {
        const wh = document.documentElement.clientHeight;
        const ww = document.documentElement.clientWidth;

        let image = this.props.srcType === 'video' ? this.refs.video : this.refs.image;
        if(!image) return;

        let _x = 0, _y = 0;

        x *= ww / wh * 3;
        y *= wh / ww * 3;

        if ((90 === window.orientation) || (-90 === window.orientation)) {
            [_x, _y] = [ -x, -y ];
        } else {
            [ _x, _y ] = [ -y, -x ];
        }

        var a = parseInt(image.style.marginTop, 10);
        var o = -a;

        if(_y > o) _y = o;
        if(a > _y) _y = a;
        let s = parseInt(image.style.marginLeft, 10);
        let c = -s;

        if(_x > c) _x = c;
        if(s > _x) _x = s;

        image.style.transform = 'translate3d(' + _x + 'px, ' + _y + 'px, 0)';
    }

    onMouseMove(event) {
        const wh = document.documentElement.clientHeight;
        const ww = document.documentElement.clientWidth;

        if(Math.abs(event.timeStamp - this.lastMouseMoveAction) < 50) return;
        this.lastMouseMoveAction = event.timeStamp;
        this._moveImage((event.pageY - wh / 2) / 30, (event.pageX - ww / 2) / 30);
    }

    onDeviceOrientation(event) {
        let now = Date.now();
        if(now - this.lastOrientationAction >= 50) {
            let beta = Math.round(event.beta);
            let gamma = Math.round(event.gamma);
            if(this.orientation.beta === null) {
                this.orientation.beta = beta;
                this.orientation.gamma = gamma;
            }

            beta = this.orientation.beta - beta;
            gamma = this.orientation.gamma - gamma;

            //this.setState({
            //    orientation: {
            //        alpha: event.alpha,
            //        beta: event.beta,
            //        gamma: event.gamma
            //    }
            //});

            this._moveImage(beta, gamma);
        }
    }

    onVideoLoadedMetaData(event) {
        this._applyImageInitialStyle();
        //this.refs.video.play();
    }

    componentDidMount() {
        this._applyImageInitialStyle();
        window.addEventListener('resize', this._applyImageInitialStyle);
        //document.addEventListener('mousemove', this.onMouseMove);
        if(window.DeviceOrientationEvent) {
            window.addEventListener('deviceorientation', this.onDeviceOrientation, true);
        }
        if(this.props.srcType === 'video') {
            this.refs.video.addEventListener('loadedmetadata', this.onVideoLoadedMetaData);
            //const vp = this.refs.video;
            //vp.play().catch(() => {
            //    setTimeout(() => { vp.play(); }, 1000);
            //});
        }
    }


    componentWillUnmount() {
        if(this.refs.image) {
            this.refs.image.style.opacity = 0;
        }

        window.removeEventListener('resize', this._applyImageInitialStyle);
        document.removeEventListener('mousemove', this.onMouseMove);
        if(window.DeviceOrientationEvent) {
            window.removeEventListener('deviceorientation', this.onDeviceOrientation);
        }
        if(this.props.srcType === 'video') {
            this.refs.video.removeEventListener('loadedmetadata', this.onVideoLoadedMetaData);
        }
    }

    render() {
        const srcUrl = this.props.src;
        const srcType = this.props.srcType || 'video';

        return (
            <div>
                {srcType === 'video' ?
                    <video ref="video" autoPlay muted loop className={style['SeasicknessImage']} controls="true">
                        <source src={srcUrl} type="video/mp4" />
                    </video>
                    :
                    <img ref="image" src={srcUrl} className={style['SeasicknessImage']} onLoad={this._applyImageInitialStyle} alt={""}/>
                }

            </div>
        )
    }
}