/**
 * История списаний и начислений в одной табличке
 */
/*eslint eqeqeq: ["off", "smart"]*/
/*eslint default-case: ["off"]*/
import React from 'react';
import { connect } from 'react-redux';
import DatePicker from 'react-datepicker';

import moment from 'moment';
import axios from 'axios';

import Panel from '../../Components/Panel';
import Paginator from '../../Components/Panel/paginator';

import * as CONST from '../../const';
import { getStyle, getStdNotifyStruct, addZeroes } from '../../tools';

import style from './style.module.css';

const PERIODS = {
    CURRENT_MONTH: 1,
    PREV_MONTH: 2,
    CURRENT_YEAR: 3,
    ALL_TIME: 4
};

const TYPE_OPERATION = {
    ALL: 1,
    ONLY_PAY: 2,
    ONLY_CHARGES: 3,
};

class PanelPayAndChargesLog extends React.Component {
    constructor(props) {
        super(props);

        moment.locale('ru');

        this.state = {
            loadingState: false,
            errorText: null,

            data: [],
            limitFrom: 0,
            limitCount: 10,
            total: 0,
            periodStart: moment().startOf('month'),
            periodEnd: moment().endOf('month'),
            periodPayments: 0,
            periodCharges: 0,
            typeOperation: 1,

            activeContract: null
        };

        this.switchPeriod = this.switchPeriod.bind(this);
        this.switchTypeOperation = this.switchTypeOperation.bind(this);
        this.changeDisplayPage = this.changeDisplayPage.bind(this);
        this.changeDisplayLimit = this.changeDisplayLimit.bind(this);
        this.onClearErrorClick = this.onClearErrorClick.bind(this);
        this.onDateChange = this.onDateChange.bind(this);
        this.onBtnDisplayClick = this.onBtnDisplayClick.bind(this);
    }

    _displayPeriodType(date, dateEnd) {
        if(date.format() === moment().startOf('month').format() && dateEnd.format() === moment().endOf('month').format()) return PERIODS.CURRENT_MONTH;
        if(date.format() === moment().subtract(1, 'months').startOf('month').format() && dateEnd.format() === moment().subtract(1, 'months').endOf('month').format()) return PERIODS.PREV_MONTH;
        if(date.format('YYYY MM') === moment().subtract(1, 'year').startOf('day').format('YYYY MM') && dateEnd.startOf('day').format() === moment().startOf('day').format()) return PERIODS.CURRENT_YEAR;

        return PERIODS.ALL_TIME;
    }

    componentWillMount() {
        this.requestData(this.props.authData.activeContract, this.state.periodStart, this.state.periodEnd, this.state.limitFrom, this.state.limitCount, this.state.typeOperation);
    }

    componentWillReceiveProps(nextProps) {
        if(nextProps.authData.activeContract !== this.state.activeContract) {
            this.requestData(nextProps.authData.activeContract, this.state.periodStart, this.state.periodEnd, this.state.limitFrom, this.state.limitCount, this.state.typeOperation);
        }
    }

    requestData(contract, periodStart, periodEnd, limitFrom, limitCount, typeOperation) {
        this.setState({
            loadingState: true,
            errorText: null
        });

        axios.get(CONST.API + '/finance/getOperationsLog', {
            withCredentials: true,
            params: {
                contract: contract,
                periodStart: periodStart.format(),
                periodEnd: periodEnd.format(),
                limitFrom: limitFrom,
                limitCount: limitCount,
                typeOperation: typeOperation
            }
        }).then((answer) => {
            if(this.refs.dataTable) this.refs.dataTable.scrollIntoView(true);
            const data = answer.data;
            if(data.result === 'success') {

                this.setState({
                    loadingState: false,
                    activeContract: contract,
                    periodStart: moment(data.data.periodStart),
                    periodEnd: moment(data.data.periodEnd),
                    total: data.data.total,
                    limitFrom: data.data.limitFrom,
                    limitCount: data.data.limitCount,
                    typeOperation: data.data.typeOperation,
                    data: data.data.items.map((item) => {
                        return {...item, Date: moment(item.Date)}
                    }),
                    errorText: null,
                    periodPayments: addZeroes(parseFloat(data.data.periodPayments)),
                    periodCharges: addZeroes(parseFloat(data.data.periodCharges))
                });

            } else {
                this.setState({ loadingState: false, errorText: data.text });
            }
        });
    }

    switchTypeOperation(event) {
        event.preventDefault();
        const newTypeOperation = parseInt(event.target.getAttribute('data-type'));
        this.requestData(this.state.activeContract, this.state.periodStart, this.state.periodEnd, this.state.limitFrom, this.state.limitCount, newTypeOperation);
    }

    switchPeriod(event) {
        const periodType = parseInt(event.target.getAttribute('data-type'));
        const activePeriod = this._displayPeriodType(moment(this.state.periodStart), moment(this.state.periodEnd));
        if(activePeriod == periodType) return;

        let periodStart = null;
        let periodEnd = null;
        switch(periodType) {
            case PERIODS.CURRENT_MONTH:
                periodStart = moment().startOf('month');
                periodEnd = moment().endOf('month');
                break;
            case PERIODS.PREV_MONTH:
                periodStart = moment().subtract(1, 'months').startOf('month');
                periodEnd = moment().subtract(1, 'months').endOf('month');
                break;
            case PERIODS.CURRENT_YEAR:
              //  console.log(1);
                periodStart = moment().subtract(1, 'year').startOf('day');
                periodEnd = moment(); //.endOf('year');
                break;
            default:
                periodStart = moment().subtract(100, 'year').startOf('day');
                periodEnd = moment().endOf('year');
                break;
        }

        //this.requestData(this.props.authData.activeContract, periodStart, periodEnd, this.state.limitFrom, this.state.limitCount);
        // При смене периода, отображение делаем с первой страницы
        this.requestData(this.props.authData.activeContract, periodStart, periodEnd, 0, this.state.limitCount, this.state.typeOperation);
    }
    changeDisplayPage(event) {
        event.preventDefault();

        const newFrom = event.target.getAttribute('data-from');
        this.requestData(this.state.activeContract, this.state.periodStart, this.state.periodEnd, newFrom, this.state.limitCount, this.state.typeOperation);
    }
    changeDisplayLimit(event) {
        event.preventDefault();

        const newLimit = event.target.getAttribute('data-count');
        this.requestData(this.state.activeContract, this.state.periodStart, this.state.periodEnd, this.state.limitFrom, newLimit, this.state.typeOperation);
    }
    onClearErrorClick(event) {
        if(event) event.preventDefault();
        this.setState({errorText: null});
    }
    onDateChange(field, date) {
        let periodStart = this.state.periodStart;
        let periodEnd = this.state.periodEnd;

        switch (field) {
            case 'start': periodStart = date; break;
            case 'end': periodEnd = date; break;
        }

        this.setState({periodStart: periodStart, periodEnd: periodEnd});

        this.requestData(this.props.authData.activeContract, periodStart, periodEnd, 0, this.state.limitCount, this.state.typeOperation);
    }
    onBtnDisplayClick(event) {
        event.preventDefault();
        this.requestData(this.props.authData.activeContract, this.state.periodStart, this.state.periodEnd, this.state.limitFrom, this.state.limitCount, this.state.typeOperation);
        return false;
    }

    render() {
        const title = this.props.title || 'История операций';
        const loadingState = this.state.loadingState;
        const activePeriod = this._displayPeriodType(moment(this.state.periodStart), moment(this.state.periodEnd));
        const activeTypeOperation = this.state.typeOperation;
        const notify = getStdNotifyStruct(this.state.errorText, this.onClearErrorClick);

        return (
            <Panel title={title} icon={getStyle('icon-rub')} isLoading={loadingState} notify={notify}>
                <div>
                    <div className={[getStyle('b-row gray'), style['pay-and-charges-gray']].join(' ')}>
                        <div className={getStyle('f-left')}>
                            <button type="button" className={activePeriod==PERIODS.CURRENT_MONTH?getStyle('active'):null} data-type={PERIODS.CURRENT_MONTH} onClick={this.switchPeriod}>За этот месяц</button>
                            <button type="button" className={activePeriod==PERIODS.PREV_MONTH?getStyle('active'):null} data-type={PERIODS.PREV_MONTH} onClick={this.switchPeriod}>Предыдущий месяц</button>
                            <button type="button" className={activePeriod==PERIODS.CURRENT_YEAR?getStyle('active'):null} data-type={PERIODS.CURRENT_YEAR} onClick={this.switchPeriod}>За год</button>
                        </div>
                        <div className={[getStyle('f-right'), style['pay-and-charges-gray-date-container']].join(' ')}>
                            За период с&nbsp;
                            <DatePicker className={getStyle('input-x')} placeholder="дд.мм.гггг" dateFormat="dd.MM.yyyy" onChange={(d) => this.onDateChange('start', moment(d))} selected={this.state.periodStart.toDate()} />
                            &nbsp;по&nbsp;
                            <DatePicker className={getStyle('input-x')} placeholder="дд.мм.гггг" dateFormat="dd.MM.yyyy" onChange={(d) => this.onDateChange('end', moment(d))} selected={this.state.periodEnd.toDate()} minDate={this.state.periodStart.toDate()}/>
                            &nbsp;
                            <button onClick={this.onBtnDisplayClick}>Показать</button>
                        </div>
                    </div>

                    <div className={[getStyle('b-row gray')].join(' ')}>
                        <div className={getStyle('f-left')}>
                            <button type="button" className={activeTypeOperation===TYPE_OPERATION.ALL?getStyle('active'):null} data-type={TYPE_OPERATION.ALL} onClick={this.switchTypeOperation}>Все</button>
                            <button type="button" className={activeTypeOperation===TYPE_OPERATION.ONLY_PAY?getStyle('active'):null} data-type={TYPE_OPERATION.ONLY_PAY} onClick={this.switchTypeOperation}>Только оплаты</button>
                            <button type="button" className={activeTypeOperation===TYPE_OPERATION.ONLY_CHARGES?getStyle('active'):null} data-type={TYPE_OPERATION.ONLY_CHARGES} onClick={this.switchTypeOperation}>Только начисления</button>
                        </div>
                    </div>

                    <table className={getStyle('line v-top left-pull')} ref="dataTable">
                            <thead>
                            <tr>
                                <th>Дата</th>
                                <th>Тип операции</th>
                                <th>Поступление</th>
                                <th>Списание</th>
                            </tr>
                            </thead>
                            <tbody>
                            {this.state.data.length ?
                                this.state.data.map((item, _index) => {
                                    let description = item.Description;

                                    if(item._itemType === 0) {
                                        if(!item.isAbonenemt && item.Mask) {
                                            if(item.Mask & 1) description = 'Абонентская плата за доступ в Интернет';
                                            if(item.Mask & 2) description = 'Абонентская плата за цифровое ТВ';
                                            if(item.Mask & 4) description = 'Абонентская плата за телефонную связь';

                                            if(item.Mask & 1 && item.Mask & 2) description = 'Абонентская плата за пакет (интернет + ТВ)';
                                            if(item.Mask & 1 && item.Mask & 4) description = 'Абонентская плата за пакет (интернет + телефон)';
                                            if(item.Mask & 1 && item.Mask & 2 && item.Mask & 4) description = 'Абонентская плата за пакет (интернет + ТВ + телефон)';
                                        }
                                    }

                                    return (
                                        <tr key={_index}>
                                            <td>{item.Date.format('DD.MM.YYYY')}</td>
                                            <td>{description.split("\n").map((text, textIndex) => {
                                                return <div key={textIndex}>{text} {item.fiscalDocument? <a target={'_blank'} title='Показать чек' href={item.fiscalDocument}><i className={[['m-icon icon-docs'], style['pay-icon-check']].join(' ')}/></a> : null }</div>
                                            })}</td>
                                            <td >{item._itemType === 1 ? item.Amount : null}</td>
                                            <td >{item._itemType === 0 ? item.Amount : null}</td>
                                        </tr>
                                    );
                                })
                                :
                                <tr>
                                    <td colSpan="4">За выбранный период начислений и платежей не совершалось</td>
                                </tr>
                            }
                            </tbody>
                            {this.state.data.length ?
                                <tfoot>
                                <tr>
                                    <td colSpan={2}>
                                        <div className={getStyle('f-right')}>
                                            Итого за период
                                            с {this.state.periodStart.format('DD.MM.YYYY')} по {this.state.periodEnd.format('DD.MM.YYYY')}
                                        </div>
                                    </td>
                                    <td>{this.state.periodPayments}</td>
                                    <td>{this.state.periodCharges}</td>
                                </tr>
                                </tfoot>
                            : null }
                        </table>
                        <Paginator currentLimit={this.state.limitCount} displayFrom={this.state.limitFrom} totalCount={this.state.total} changeDisplayPage={this.changeDisplayPage} changeLimitClick={this.changeDisplayLimit} />
                </div>
            </Panel>
        );
    }

}

function mapStateToProps(state) {
    return {
        authData: state.authenticate
    }
}

function mapDispatchToProps(dispatch) {
    return {

    }
}

export default connect(mapStateToProps, mapDispatchToProps)(PanelPayAndChargesLog);