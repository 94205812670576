import React from 'react'
import { connect } from 'react-redux';

import Page from '../../Components/Panel/StaticPage'

import { getStyle } from "../../tools";

class PromoPage extends React.Component {
    render () {

        const page = this.props.match.params.name;

        return (
            <Page pageName={"staticPage"} compareValue={page} queryParams={{pageName: page}} bodyStyle={getStyle('b-row')} />
        );
    }
}

function mapStateToProps(state) {
    return {
        state: state
    }
}

export default connect(mapStateToProps, null)(PromoPage)