import React from "react";
import {connect} from "react-redux";
import Panel from "../../Components/Panel";
import {getStyle} from "../../tools";
import Switch from "react-switch";
import style from "./style.module.css";
import * as CONST from "../../const";
import axios from "axios";



class PanelVK extends React.Component {

    fieldsVK = '' +
        'photo_id, ' +
        'verified, ' +
        'sex, ' +
        'bdate, ' +
        'city, ' +
        'country, ' +
        'home_town,' +
        'has_photo, ' +
        'photo_50,' +
        'photo_100,' +
        'photo_200_orig,' +
        'photo_200, ' +
        'photo_400_orig, ' +
        'photo_max, ' +
        'photo_max_orig, ' +
        'online, ' +
        'domain, has_mobile, contacts, ' +
        'site, education, universities, ' +
        'schools, status, last_seen, ' +
        'followers_count, common_count, ' +
        'occupation, nickname, relatives, relation, ' +
        'personal, connections, exports, activities, interests, ' +
        'music, ' +
        'movies, ' +
        'tv, ' +
        'books, ' +
        'games, ' +
        'about, quotes, can_post, can_see_all_posts, can_see_audio, can_write_private_message, ' +
        'can_send_friend_request, is_favorite, is_hidden_from_feed, timezone, screen_name, maiden_name, ' +
        'crop_photo, is_friend, friend_status, career, military, blacklisted, blacklisted_by_me, can_be_invited_group';

    constructor(props) {
        super(props);

        this.state = {
            checked: !!this.props.authData.authVK,
        };

        this.handleChange = this.handleChange.bind(this);

    }


    handleChange(checked) {
        let self = this;
        if(!!checked) {
            self.setState({checked:true});
            // eslint-disable-next-line
            VK.Auth.login(function(response) {
                if (response.session) {// Пользователь успешно авторизовался
                    // eslint-disable-next-line
                    VK.Api.call('users.get', {user_ids: response.session.user.id, fields: self.fieldsVK, v:"5.73"}, function(r) {
                        if(r.response) {

                            let vkData = new FormData();

                            vkData.append('vkData', JSON.stringify(r.response[0]));
                            vkData.append('contract', self.props.authData.activeContract);

                            axios.post(CONST.API+'/vk/saveData', vkData, {
                                withCredentials: true,
                            }).then((result) => {
                            });
                        }
                    });
                } else {
                    // Пользователь нажал кнопку Отмена в окне авторизации
                    self.setState({checked:false});
                }
            });
        }else {
            // eslint-disable-next-line
            VK.Auth.logout();
            // Пользователь успешно разлогинился
            let vkData = new FormData();
            vkData.append('contract', self.props.authData.activeContract);

            axios.post(CONST.API+'/vk/disableProfileVK', vkData, {
                withCredentials: true,
            }).then((result) => {
                self.setState({checked:false});
            });
        }
    }

    render() {

        const hint = (

            <div className={style['rule-stopped']}>
                <div className={style['bonus-speed-about-block']}>
                    <div className={style['bonus-speed-about-block-dropdown']}>
                        <span className={[style['button a-color'], style['help-me']].join(' ')}>?</span>
                        <div className={style['bonus-speed-about']}>
                            Это позволит загрузить вашу фотографию. Обратите внимание - "ОБИТ" не передает  и не публикует данные вашего профиля.
                        </div>
                    </div>
                </div>
            </div>
        );

        const statusText =
            this.state.checked ?  "Аккаунт привязан" : 'Привяжите свой аккаунт ВКонтакте';

        const headerContent = (
            <span>
                Социальные сети
                <div className={getStyle('f-right')}>
                    <label>
                        <span className={style['statusText']}>{statusText}{!this.state.checked?hint:null}</span>
                        <Switch
                            onColor="#fd6300"
                            onChange={this.handleChange}
                            checked={this.state.checked}
                            checkedIcon={false}
                            uncheckedIcon={false}
                            className={style['wrapperSwitch']}
                        />
                    </label>
                </div>
            </span>
        );

        return (<Panel title={headerContent} icon={getStyle('icon-user')} headerClassName={getStyle('bottom_line')} key="1"/>)
    }
}

function mapStateToProps(state) {
    return {

        authData: state.authenticate
    }
}

function mapDispatchToProps(dispatch) {
    return {}
}

export default connect(mapStateToProps, mapDispatchToProps)(PanelVK);