/**
 * Created by dpopov on 11.09.2017.
 */
import React from 'react';
import { Link } from 'react-router-dom';

import Panel from '../../Components/Panel';
import * as CONST from '../../const';

import { getStyle } from '../../tools';

export default(props) => {

    const headerContent = (
        <span>
            <Link to={CONST.ROOT_PATH + "/qna/tv"} className={getStyle('a-color')}>Инструкции по настройке приставки и работе со Смарт-ТВ</Link>
        </span>
    );

    return (
        <Panel title={headerContent} icon={getStyle('icon-sett')} />
    )
}