/**
 * Created by dpopov on 16.08.2017.
 */
import * as CONST from '../../const';
import axios from 'axios';

export function getFinanceDetails(contract, abRecommend) {
    return (dispatch, getState, args) => {
        dispatch({type: CONST.PAY.FINANCE_DETAIL_REQUEST_IN_PROCESS, payload: true });

        axios.get(CONST.API + '/payments/getFinanceDetails', {
            withCredentials: true,
            params: {
                'contract': contract,
		        '.rnd': Math.ceil(Math.random()*1000000)
            }
        }).then(function(result) {
            if (result.data.result === 'success') {
                const apiRecommend = result.data.data.recommendMoney;
                dispatch({type: CONST.PAY.FINANCE_DETAIL_SET_DETAIL, payload: {...result.data.data,
                        contract: contract,
                        recommendMoney: apiRecommend > (abRecommend | 0) ? apiRecommend : abRecommend }
                });
                dispatch({type: CONST.SERVICES.SET_RECOMMEND_PAYMENT, payload: { contract: contract, sum: null }})
            } else {
                dispatch({type: CONST.PAY.FINANCE_DETAIL_SET_ERROR, payload: result.data.text });
            }
        })
    }
}

export function getContractBalanceMonitors(contract) {
    return (dispatch, getState, args) => {
        axios.get(CONST.API + '/payments/getBMRules', {
            withCredentials: true,
            params: {
                'contract': contract,
                '.rnd': Math.ceil(Math.random()*1000000)
            }
        }).then(function(result) {
            const answer = result.data;
            if(answer.result === 'success') {
                dispatch({type: CONST.PAY.FINANCE_DETAIL_SET_BM, payload: answer.data});
            } else {
                dispatch({type: CONST.PAY.FINANCE_DETAIL_SET_ERROR, payload: answer.text });
            }
        })
    }
}