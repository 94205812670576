/**
 * Created by dpopov on 17.08.2017.
 */
import React from 'react';
import { getStyle } from "../../tools";

import myStyle from './style.module.css';

import Phone from 'react-phone-number-input'
import InputContractNumber from './inputContractNumber';
import ContactsForCheck from './contactsForCheck';

// import 'react-phone-number-input/rrui.css'
import 'react-phone-number-input/style.css'

export default (props) => {
    const recommendMoney = props.recommendMoney;

    let subPanelHeaderClassName = null;
    let subPanelBodyClassName = null;

    if(props.isFrame) {
        subPanelHeaderClassName = myStyle['iframe-subpanel-header'];
        subPanelBodyClassName = myStyle['iframe-subpanel-body'];
    }

    return (
        <div className={getStyle('b-row doble-col')}>
            <div className={getStyle('col-main')}>
                <table className={getStyle('form-table')}><tbody>
                <InputContractNumber {...props} />
                <tr>
                    <td>Номер телефона</td>
                    <td><Phone country="RU" countries={["RU"]} type="text" className={getStyle('wide')} value={props.inputValues.phoneNumber || ''} name="phoneNumber" onChange={props.inputPhoneChange} /></td>
                </tr>
                <tr>
                    <td>Сумма платежа</td>
                    <td>
                        <input type="text" value={recommendMoney} className={getStyle('input-x')} onChange={props.amountChange} name="amount" />
                        <button type="button" className={getStyle('btn-major f-right')} onClick={props.makePayment} disabled={props.payButtonDisabled}>Оплатить</button>
                    </td>
                </tr>
                </tbody></table>
                {props.requireContacts ? <ContactsForCheck {...props} /> : null }
            </div>

            <div className={getStyle('col-side')}>
                <div className={getStyle('b-header')}><h4 className={subPanelHeaderClassName}><i className={getStyle('m-icon icon-star border')} /> Условия оплаты</h4></div>
                <ul className={[getStyle('dash t-small'), subPanelBodyClassName].join(' ')}>
                    <li>Возможна комиссия.</li>
                    <li>Для оплаты у вас должен быть открыт счёт в платёжной системе QIWI.</li>
                </ul>
            </div>

        </div>
    )
}