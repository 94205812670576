/**
 * Created by dpopov on 12.07.2017.
 */
import React from 'react';
import { connect } from 'react-redux';

import PanelBalance from '../../Panels/PanelBalance';
import PanelServices from '../../Panels/PanelServices';
import PanelNews from '../../Panels/PanelNews';
import PanelInactiveContract from '../../Panels/PanelInactiveContract';
import PanelPromos from '../../Panels/PanelPromos';
import PanelPersonalPromo from '../../Panels/PanelPersonalPromo';
import PanelVK from '../../Panels/PanelVK';

import CheckRoster from "../../Panels/PanelServices/CheckRoster";

class Dasboard extends React.Component {
    render() {

        const authData = this.props.state.authenticate;

        return (
            <div>
                <CheckRoster from={"dashBoard"}>
                    <PanelPromos />
                    <PanelBalance />
                    {!!this.props.state.authenticate.authVK ? null : <PanelVK />}
                    <PanelPersonalPromo />
                    {(authData.activeContract && authData.statuses[authData.activeContract] && authData.statuses[authData.activeContract]['status']) === 1?
                        <PanelInactiveContract/>
                        :
                        <PanelServices showServiceTypeIcon={true} forceReload={false} title="Ваши услуги" />
                    }
                    <PanelNews title='Новости «ОБИТ»' widget={true} limit={5} />
                </CheckRoster>
            </div>
        )
    }
}

function mapStateToProps(state) {
    return {
        state: state
    }
}

function mapDispatchToProps(dispatch) {
    return {
        elementActions: null
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Dasboard);