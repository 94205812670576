/**
 * Created by dpopov on 19.07.2017.
 */
import React from 'react';
import { connect } from 'react-redux';
// import ScrollLock from 'react-scrolllock';

import * as CONST from '../../const';

import SubNavBlock from '../../Components/SubNavBlock';

import { Route } from 'react-router-dom';
import CheckAccess from '../../Authenticate';

import PageServices from './pageServices';
import PageService from './pageService';
import PageServicesSettings from './pageServicesSettings';
import PageEquipment from './pageEquipment';
import PageStatistics from './pageStatistics';
import CheckRoster from "../../Panels/PanelServices/CheckRoster";

export const ServicesRoutes = [
    <Route key="ServicesRoutes1" exact path={CONST.ROOT_PATH + "/services.html"} component={CheckAccess(PageServices, CONST.ACCESS.READ, true)} />,
    <Route key="ServicesRoutes2" exact path={CONST.ROOT_PATH + "/services-:serviceType.html"} component={CheckAccess(PageServices, CONST.ACCESS.READ, true)} />,
    <Route key="ServicesRoutes3" exact path={CONST.ROOT_PATH + "/service-:serviceType.:serviceId.html"} component={CheckAccess(PageService, CONST.ACCESS.READ, true)} />,
    <Route key="ServicesRoutes4" exact path={CONST.ROOT_PATH + "/service-suspend.html"} component={CheckAccess(PageServicesSettings, CONST.ACCESS.READ, true)} />,
    <Route key="ServicesRoutes4" exact path={CONST.ROOT_PATH + "/service-equipment.html"} component={CheckAccess(PageEquipment, CONST.ACCESS.READ, true)} />,
    <Route key="ServicesRoutes5" exact path={CONST.ROOT_PATH + "/statistics-:serviceType.:serviceId/:dateFrom?/:dateTo?/:chooserBtn?"} component={CheckAccess(PageStatistics, CONST.ACCESS.READ, true)} />
];

export const PagesLinks = {
    'inet': CONST.ROOT_PATH + '/service-inet.html',
    'phone': CONST.ROOT_PATH + '/service-phone.html',
    'tv': CONST.ROOT_PATH + '/service-tv.html'
};

class ChapterServices extends React.Component {

    render() {
        let menuItems = {};
        menuItems[CONST.ROOT_PATH + '/services.html'] = 'Подключенные';
        menuItems[CONST.ROOT_PATH + '/services-inet.html'] = 'Интернет';
        menuItems[CONST.ROOT_PATH + '/services-tv.html'] = 'ТВ';
        menuItems[CONST.ROOT_PATH + '/services-phone.html'] = 'Телефония';
        menuItems[CONST.ROOT_PATH + '/service-suspend.html'] = 'Приостановка обслуживания';
        menuItems[CONST.ROOT_PATH + '/service-equipment.html'] = 'Оборудование';


        if(!(this.props.services.cumulativeServiceTypes & CONST.SERVICE_TYPES.INET)) delete(menuItems[CONST.ROOT_PATH + '/services-inet.html']);
        if(!(this.props.services.cumulativeServiceTypes & CONST.SERVICE_TYPES.TV)) delete(menuItems[CONST.ROOT_PATH + '/services-tv.html']);
        if(!(this.props.services.cumulativeServiceTypes & CONST.SERVICE_TYPES.PHONE)) delete(menuItems[CONST.ROOT_PATH + '/services-phone.html']);

        return (
            <div>
                <SubNavBlock title="Услуги" items={menuItems}/>
                <CheckRoster from={"ChapterServices"}>
                    <div>
                        {this.props.children}
                    </div>
                </CheckRoster>
            </div>
        )
    }
}

function mapStateToProps(state) {
    return {
        services: state.panelServices
//        authData: state.authenticate,
//        servicesData: state.panelServices
    }
}

function mapDispatchToProps(dispatch) {
    return {
//        elementActions: bindActionCreators(SubNavBlock, dispatch),
//        authenticate: bindActionCreators(SubNavBlock, dispatch)
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ChapterServices);
